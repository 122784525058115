import { LOCATION_CHANGE } from 'connected-react-router';
import { ADD_PART_FAILURE,
  ADD_PART_REQUESTED,
  ADD_PART_SUCCESS,
  DELETE_PART_FAILURE,
  DELETE_PART_REQUESTED,
  DELETE_PART_SUCCESS,
  FETCH_PART_FAILURE,
  FETCH_PART_REQUESTED,
  FETCH_PART_SUCCESS,
  FETCH_SINGLE_PART_FAILURE,
  FETCH_SINGLE_PART_REQUESTED,
  FETCH_SINGLE_PART_SUCCESS,
  FETCH_PART_FILTER_FAILURE,
  FETCH_PART_FILTER_REQUESTED,
  FETCH_PART_FILTER_SUCCESS,
  UPDATE_PART_FORM } from '../actions/part-action-types';

const initialState = {
  addPartStatus: 'pending',
  deletePartStatus: 'pending',
  fetchPartStatus: 'pending',
  fetchSinglePartStatus: 'pending',
  form: {
    chapter_id: '',
    created_date: null,
    grade_id: '',
    part_name: '',
    part_number: '',
    school_year_id: '',
    subject_id: '',
  },
  parts: [],
};

export default function part(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_PART_FAILURE:
      return {
        ...state,
        addPartStatus: 'failure',
      };

    case ADD_PART_REQUESTED:
      return {
        ...state,
        addPartStatus: 'creating',
      };

    case ADD_PART_SUCCESS:
      return {
        ...state,
        addPartStatus: 'success',
      };

    case DELETE_PART_FAILURE:
      return {
        ...state,
        deletePartStatus: 'failure',
      };

    case DELETE_PART_REQUESTED:
      return {
        ...state,
        deletePartStatus: 'deleting',
      };

    case DELETE_PART_SUCCESS:
      return {
        ...state,
        deletePartStatus: 'success',
        parts: [...state.parts].filter((Parts) => !(payload.toString().includes(Parts.id.toString()))),

      };

    case FETCH_PART_FAILURE:
      return {
        ...state,
        fetchPartStatus: 'failure',
      };

    case FETCH_PART_REQUESTED:
      return {
        ...state,
        fetchPartStatus: 'fetching',
      };

    case FETCH_PART_SUCCESS:
      return {
        ...state,
        fetchPartStatus: 'success',
        parts: payload,
      };

      case FETCH_PART_FILTER_FAILURE:
        return {
          ...state,
          fetchPartStatus: 'failure',
        };
  
      case FETCH_PART_FILTER_REQUESTED:
        return {
          ...state,
          fetchPartStatus: 'fetching',
        };
  
      case FETCH_PART_FILTER_SUCCESS:
        return {
          ...state,
          fetchPartStatus: 'success',
          parts: payload,
        };
  

    case FETCH_SINGLE_PART_FAILURE:
      return {
        ...state,
        fetchSinglePartStatus: 'failure',
      };

    case FETCH_SINGLE_PART_REQUESTED:
      return {
        ...state,
        fetchSinglePartStatus: 'fetching',
      };

    case FETCH_SINGLE_PART_SUCCESS:
      return {
        ...state,
        fetchSinglePartStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_PART_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { FETCH_EXAM_SUCCESS, FETCH_MARK_PRIMARY_SUCCESS } from "../actions/mark-rule-primary-action-type";

const intitalState = {
    markPrimary: null,
    allExam:null,

};

const markPrimaryReducer = (state = intitalState, action) => {
    switch (action.type) {
        case FETCH_MARK_PRIMARY_SUCCESS:
            return {
                ...state,
                markPrimary: action.payload,
            }
            case FETCH_EXAM_SUCCESS:
                return {
                    ...state,
                    allExam: action.payload,
                }
        default:
            return { ...state };
    }
};
export default markPrimaryReducer;
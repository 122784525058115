import { string } from 'prop-types';
import React from 'react';
import Utils from '../../utility';

const ImageCellRenderer = ({
  className, image,
}) => (image && image.length ? (
  <img width="35px" height="35px" className={className} src={encodeURI(image)} alt="" />
) : <img width="35px" height="35px" className={className} src={Utils.getImage('user-placeholder.png')} alt="Profile Pic" />);

ImageCellRenderer.propTypes = {
  className: string,
  image: string.isRequired,
};

ImageCellRenderer.defaultProps = { className: 'rounded-circle' };

export default ImageCellRenderer;

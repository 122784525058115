import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { CREATE_SCHOOL_YEAR,
  createSchoolYearFailure,
  createSchoolYearRequested,
  createSchoolYearSuccess,
  DELETE_SCHOOL_YEAR,
  deleteSchoolYearFailure,
  deleteSchoolYearRequested,
  deleteSchoolYearSuccess,
  EDIT_SCHOOL_YEAR,
  editSchoolYearFailure,
  editSchoolYearRequested,
  editSchoolYearSuccess,
  FETCH_SINGLE_SCHOOL_YEAR,
  fetchSingleSchoolYearFailure,
  fetchSingleSchoolYearRequested,
  fetchSingleSchoolYearSuccess,
  FETCH_SCHOOL_YEAR,
  fetchSchoolYearFailure,
  fetchSchoolYearRequested,
  fetchSchoolYearSuccess ,
  DELETE_SCHOOL_YEAR_QUARTER
} from '../actions/school-year-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from "i18next";

export function* createSchoolYearHandler({ payload }) {
  yield put(createSchoolYearRequested());
 
  const body = new FormData();

  console.log(payload, 'payload');
  body.append('school_start_year', moment(payload.school_start_year).format('yyyy').toString());
  body.append('back_to_school_date', moment(payload.back_to_school_date).format('MM/DD/yyyy').toString());
  body.append('school_end_year', moment(payload.school_end_year).format('yyyy').toString());
  body.append('registration_start_date', moment(payload.registration_start_date).format('MM/DD/yyyy').toString());
  body.append('school_year_end_date', moment(payload.school_year_end_date).format('MM/DD/YYYY').toString());

  const request = {
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-schoolyear',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createSchoolYearFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/school-year');
      },
      icon: 'success',
      message: i18next.t('skullman:setting.schoolYear.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(createSchoolYearSuccess());
  }
}

export function* editSchoolYearHandler({ payload }) {
  yield put(editSchoolYearRequested());
  const request = {
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
     params: {
       back_to_school_date: moment(payload.back_to_school_date).format('MM/DD/yyyy').toString(),
      registration_start_date: moment(payload.registration_start_date).format('MM/DD/yyyy').toString(),
      school_end_year: moment(payload.school_end_year).format('yyyy').toString(),
     school_start_year: moment(payload.school_start_year).format('yyyy').toString(),
       school_year_end_date: moment(payload.school_year_end_date).format('MM/DD/YYYY').toString(),
    },
    url: `update-schoolyear/${payload.school_year_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editSchoolYearFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname:'/school-year'});
    //   },
    //   icon: 'success',
    //   message: 'School year updated successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);
    yield put(editSchoolYearSuccess());
  }
}

export function* deleteSchoolYearHandler({ payload }) {
  yield put(deleteSchoolYearRequested());

  const request = {
    method: 'DELETE',
    url: `delete-schoolyear/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteSchoolYearFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:setting.schoolYear.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteSchoolYearSuccess(payload));
  }
}

export function* fetchSchoolYearHandler({ payload }) {
  yield put(fetchSchoolYearRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'list-schoolyear',
  };

  const {
    data, error,
  } = yield call(httpClient, request);
console.log(data);
  if (error) {
    yield put(fetchSchoolYearFailure(error));
  } else {
    let list_school=data.data?.result || [];
    let school_current='';
let arr_list_school=[];
    for (let i = 0; i < list_school.length; i++) {
      let backToSchool=(list_school[i]?.back_to_school_date ? moment(list_school[i]?.back_to_school_date, 'X').format('DD/MM/YYYY') : '');

      let endSchool=(list_school[i]?.school_year_end_date ? moment(list_school[i]?.school_year_end_date, 'X').format('DD/MM/YYYY') : '');
     /* console.log("back");
      console.log( backToSchool);
      console.log("end");
      console.log( endSchool);
      console.log("moment");
      console.log( moment().format('DD/MM/YYYY'));
      console.log("moment2");
      console.log(moment(moment().format('DD/MM/YYYY'),'DD/MM/YYYY'));
*/
      let isbetween= moment(moment().format('DD/MM/YYYY'),'DD/MM/YYYY').isBetween(moment(backToSchool,'DD/MM/YYYY'), moment(endSchool,'DD/MM/YYYY')); // true

      if(isbetween === true){

      school_current=list_school[i];
      break;
    }

  }
  console.log("school_current");
console.log( school_current);
arr_list_school.push(school_current);

for (let i = 0; i < list_school.length; i++) {
  arr_list_school.indexOf(list_school[i]) === -1 ? arr_list_school.push(list_school[i]): "";// console.log("This item already exists");
  
}
 // var stringToFilter = school_current?.school_year_id;


console.log( arr_list_school);
  console.log( list_school);
  yield put(fetchSchoolYearSuccess(arr_list_school));
  }
}


export function* fetchSingleSchoolYearHandler({ payload }) {
  yield put(fetchSingleSchoolYearRequested());

  const request = {
    method: 'GET',
    url: `view-schoolyear/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleSchoolYearFailure(error));
  } else {
    const { result } = data.data;

    const school_year_id1 = result.school_year_id;

    delete result.school_year_id;
    const res = {
      ...result,
      back_to_school_date: moment(result.back_to_school_date, 'X').toDate(),
      registration_start_date: moment(result.registration_start_date, 'X').toDate(),
      school_end_year: moment(result.school_end_year, 'YYYY').toDate(),
      school_start_year: moment(result.school_start_year, 'YYYY').toDate(),
      school_year_end_date: moment(result.school_year_end_date, 'X').toDate(),
      school_year_id1,
    };

    yield put(fetchSingleSchoolYearSuccess(res));
  }
}

export function* deleteSchoolYearQuarter({ payload }) {
  const request = {
    method: 'DELETE',
    url: `delete-schoolyear-quater/${payload}`,
  };

 yield call(httpClient, request);

}


function* SchoolYears() {
  yield all([
    takeLatest(CREATE_SCHOOL_YEAR, createSchoolYearHandler),
    takeLatest(DELETE_SCHOOL_YEAR, deleteSchoolYearHandler),
    takeLatest(EDIT_SCHOOL_YEAR, editSchoolYearHandler),
    takeLatest(FETCH_SCHOOL_YEAR, fetchSchoolYearHandler),
    takeLatest(FETCH_SINGLE_SCHOOL_YEAR, fetchSingleSchoolYearHandler),
    takeLatest(DELETE_SCHOOL_YEAR_QUARTER,deleteSchoolYearQuarter)
  ]);
}

export default SchoolYears;


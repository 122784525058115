import { bool, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Utils from '../../utility';

const LinkableImageCellRenderer = ({
  href, className, text, pagination, image,
}) => {
  const newTo = {
    pathname: href,
    param1: localStorage.getItem('pagination') || 1,
  };

  if (href && href.length) {
    return (
      <>
        {image && image.length ? (
          <img width="35px" height="35px" className="rounded-circle mr-2" src={encodeURI(image)} alt="" />
        ) : <img width="35px" height="35px" className="rounded-circle mr-2" src={Utils.getImage('user-placeholder.png')} alt="Profile Pic" />}
        <Link to={newTo} className={`${className}`}>
          {text}
        </Link>
      </>
    );
  }

  return <></>;
};

LinkableImageCellRenderer.propTypes = {
  className: string,
  href: string.isRequired,
  image: string.isRequired,
  text: string.isRequired,
};

LinkableImageCellRenderer.defaultProps = {
  className: 'rounded-circle',
  // icon: false,
};

export default LinkableImageCellRenderer;

import React, { Fragment } from "react";

import { Nav, Button, NavItem, Popover } from "reactstrap";

import { AreaChart, Area, ResponsiveContainer } from "recharts";

import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";

import CountUp from "react-countup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import city2 from "../../../assets/utils/images/dropdown-header/city2.jpg";
import avatar6 from "../../../assets/utils/images/avatars/2.jpg";
import MobileMenu from "../../AppHeader/Components/MobileMenu";

import Tabs from "react-responsive-tabs";
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

// Dropdown Tabs Content
import ChatExample from "./TabsContent/ChatExample";
import TimelineEx from "./TabsContent/TimelineExample";
import SysErrEx from "./TabsContent/SystemExample";


import carbonuserMultiple from "../../../assets/img/carbonuserMultiple.svg";
import carbonuserMultipleActive from "../../../assets/img/carbonuserMultipleActive.svg";
import rihome5Line from "../../../assets/img/rihome5Line.svg";
import rihome5LineInactive from "../../../assets/img/rihome5LineInactive.svg"
import icomoonFreemeter from "../../../assets/img/icomoonFreemeter.svg";
import icomoonFreemeterActive from "../../../assets/img/icomoonFreemeterActive.svg";
import materialSymbolsscho from "../../../assets/img/materialSymbolsscho.svg";
import tablerclipboardList from "../../../assets/img/tablerclipboardList.svg";
import tablerclipboardListActive from "../../../assets/img/tablerclipboardListActive.svg";



// {(user?.user_type_id === 4 || user?.user_type_id === 5 || user?.user_type_id === 6 || user?.user_type_id === 7) ?(


const FooterMobile =(user,activeDrawerMenu)=> {
    const data55 = [
        { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
        { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
        { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
        { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
        { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
        { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
        { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
        { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
        { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
        { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
        { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
        { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
      ];
      
      const tabsContent = [
        {
          title: "Messages",
          content: <ChatExample />,
        },
        {
          title: "Events",
          content: <TimelineEx />,
        },
        {
          title: "Errors",
          content: <SysErrEx />,
        },
      ];
      
      function getTabs() {
        return tabsContent.map((tab, index) => ({
          title: tab.title,
          getContent: () => tab.content,
          key: index,
        }));
      }
    const [popoverOpen1, setPopoverOpen1]=React.useState(false);
    const [popoverOpen2, setPopoverOpen2]=React.useState(false);
    const [popoverOpen3, setPopoverOpen3]=React.useState(false);
    const location = useLocation();
    const tutorStudentSelected = JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) ? JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) : [];
    const [selectChange, setSelectChange]=React.useState([]);
    const [selectChangecount, setSelectChangecount]=React.useState(false);
   /* React.useEffect(() => {
      
      if(selectChange && selectChange !==tutorStudentSelected){
      setSelectChange(tutorStudentSelected);
      setSelectChangecount(1);  
      }else{
        setSelectChangecount(0);   
      }
      console.log(selectChange)
      console.log(tutorStudentSelected)
    
    }, [selectChangecount]);*/

  const dispatch = useDispatch();
  const [t] = useTranslation('skullman');

    const toggle1 =(event) =>{
        event.preventDefault();
        /*this.setState({
          popoverOpen1: !this.state.popoverOpen1,
        });*/
        setPopoverOpen1(!popoverOpen1);
      }
    
   const toggle2=(event) =>{
        event.preventDefault();
       /* this.setState({
          popoverOpen2: !this.state.popoverOpen2,
        });*/
        setPopoverOpen2(!popoverOpen2);
      }
    
    const  toggle3=(event) =>{
        event.preventDefault();
       /* this.setState({
          popoverOpen3: !this.state.popoverOpen3,
        });*/
        setPopoverOpen3(!popoverOpen3);
      }
 

      const settings = {
        className: "",
        centerMode: false,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        dots: true,
        arrows: false,
      };

      const onLinkClick = (event) => {
        //dispatch(toggleDrawerMenu(null));
        setClicked(true);
       // toggleMobileSidebar();
       //toggleMobileSidebar_false();
        event.preventDefault();
      };
      const getActive = (url) => {
        const activePathname = location.pathname;
    
        return activePathname.indexOf(url) > -1;
      };
     console.log(user?.user?.user_type_id )
 return(
    
      <Fragment>
        <div className="footer-dots" style={{height:'100%'}}>
          <ContainerFoot style={{width:'100%',height:'100%'}}>
        <div className="rectangle-34625552 custom-footer-wrapper" style={{height:'100%'}}>
                <div className="flex-container custom-footer" style={{height:'100%'}}>
                <a href="#"  className={getActive('/school-wall') ? 'icon-gradient bg-love-kiss' : ''}  id="PopoverFooter-1" style={{height:'100%'}}>
                <Container style={{height:'100%'}}>
                
            <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
                <Link
                to="/school-wall"
                className={getActive('/school-wall') ? 'mm-active custom-footer-link' : 'custom-footer-link'}
                style={{height:'100%'}}
              >
               
                <img alt="loading error!" className="rihome-5-line" src={getActive('/school-wall') ? rihome5Line : rihome5LineInactive} />
                   
                    <span className="schedule footer-text">{t('menu.home')}</span>
                    </Link>
                    </div>
                   
              </Container>
              </a>
          </div>
            {/* <div className="dots-separator" />*/}
          <div className="flex-container-1 custom-footer" style={{height:'100%'}}>
          <a href="#"  className={getActive('/schedule') ? 'icon-gradient bg-love-kiss' : ''}  id="PopoverFooter-3" style={{height:'100%'}}>
          <Container style={{height:'100%'}}>
            <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
            <Link
                                to="/schedule"
                                className={getActive('/schedule') ? 'mm-active custom-footer-link' : 'custom-footer-link'}
                                style={{height:'100%'}}
                              >
                                <img alt="loading error!" className="icomoon-freemeter" src={ getActive('/schedule') ? icomoonFreemeterActive : icomoonFreemeter} />
                    <span className="schedule footer-text">{t('menu.schedule')}</span>
                              </Link>
              </div>
                    
            
         
              </Container>
              </a>
         </div>
          {/* <div className="dots-separator" />*/}
          <div className="flex-container-2 custom-footer" style={{height:'100%'}}>
        
          {(user?.user?.user_type_id === 5 || user?.user?.user_type_id === 6 || user?.user?.user_type_id === 7) ?(
          <a href="#"  className={getActive(`/student-details/${tutorStudentSelected?.value}`) ? 'icon-gradient bg-love-kiss' : ''}  id="PopoverFooter-3" style={{height:'100%'}}>
          <Container style={{height:'100%'}}>
            <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
            <Link
                                to={`/student-details/${tutorStudentSelected?.value}`}
                                className={getActive(`/student-details/${tutorStudentSelected?.value}`) ? 'mm-active custom-footer-link' : 'custom-footer-link'}
                                style={{height:'100%'}}
                              >
                                <img  className="tablerclipboard-list" src={getActive(`/student-details/${tutorStudentSelected?.value}`) ? tablerclipboardListActive : tablerclipboardList} alt={getActive(`/student-details/${tutorStudentSelected?.value}`) ? 'tablerclipboardListActive' : 'tablerclipboardList'}/>
                    <span className="grades footer-text">{t('menu.result')}</span>
                              </Link>
              </div>
              </Container>
          </a>):
          (user?.user?.user_type_id === 4 ) ?(
            <a href="#"  className={getActive(`/student-details/${user?.user?.student_id}`) ? 'icon-gradient bg-love-kiss' : ''}  id="PopoverFooter-3" style={{height:'100%'}}>
            <Container style={{height:'100%'}}>
              <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
              <Link
                                  to={`/student-details/${user?.user?.student_id}`}
                                  className={getActive(`/student-details/${user?.user?.student_id}`) ? 'mm-active custom-footer-link' : 'custom-footer-link'}
                                  style={{height:'100%'}}
                                >
                                   <img  className="tablerclipboard-list" src={getActive(`/student-details/${user?.user?.student_id}`) ? tablerclipboardListActive : tablerclipboardList} alt={getActive(`/student-details/${user?.user?.student_id}`) ? 'tablerclipboardListActive' : 'tablerclipboardList'}/>
                    <span className="grades footer-text">{t('menu.result')}</span>
                                </Link>
                </div>
                </Container>
            </a>):
          (
             <a href="#"  className={getActive('/classes-list') ? 'icon-gradient bg-love-kiss' : ''}  id="PopoverFooter-3" style={{height:'100%'}}>
             <Container style={{height:'100%'}}>
               <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
               <Link
                                   to="/classes-list"
                                   className={getActive('/classes-list') ? 'mm-active custom-footer-link' : 'custom-footer-link'}
                                   style={{height:'100%'}}
                                 >
                                    <img className="tablerclipboard-list" src={getActive('/classes-list') ? tablerclipboardListActive : tablerclipboardList} alt={getActive('/classes-list') ? 'tablerclipboardListActive' : 'tablerclipboardList'}/>
                    <span className="grades footer-text">{t('menu.result')}</span>
                                 </Link>
                 </div>
                 </Container>
             </a>
          )}

                </div>
          
          
            {/* <div className="dots-separator" />*/}
          
          <div className="flex-container-3 custom-footer" style={{height:'100%'}}>
                    
           
          <a href="#"  className={getActive('/chats') ? 'icon-gradient bg-love-kiss' : ''}  id="PopoverFooter-4" style={{height:'100%'}}>
          <Container style={{height:'100%'}}>
            <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
            <Link
                                to="/chats"
                                className={getActive('/chats') ? 'mm-active custom-footer-link' : 'custom-footer-link'}
                                style={{height:'100%'}}
                              >
                                <img alt="loading error!" className="carbonuser-multiple" src={getActive('/chats') ? carbonuserMultipleActive : carbonuserMultiple} />
                    <span className=" footer-text">Chat</span>
                              </Link>
                              </div>
                              </Container>
          </a>
          </div>
          {/*(user?.user?.user_type_id !== 5 && user?.user?.user_type_id !== 6 && user?.user?.user_type_id !== 7 && user?.user?.user_type_id !== 4 ) ?(*/}
            <div style={{height:'100%'}}>
            {/* <div className="dots-separator" />*/}
          <div className="flex-container-4 custom-footer" style={{height:'100%'}}>
                    
           
                    <a href="#"  className={getActive('/chatscan') ? 'icon-gradient bg-love-kiss' : ''}  id="PopoverFooter-4" style={{height:'100%'}}>
                    <Container style={{height:'100%'}}>
                      <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
                      <MobileMenu user={user} activeDrawerMenu={activeDrawerMenu}/>
                                        </div>
                                        </Container>
                    </a>
                    </div></div>
{/*}):<div></div>}*/}
           
            
          
          </div>
          </ContainerFoot>
        </div>
        
      </Fragment>
      
    );
  }


export default FooterMobile;
const Container = styled.div`
.sub-menu {
    float: left;
    display: block;
    text-align: center;
  }
  
  .sub-menu .menu-title {
    display: block;
    margin-top: 1px;
  }`;


  const ContainerFoot =styled.div`
  .mobile-view {
    color: #000;
    width: 100%;
    height: 100%;
    background-color: #f1f4f6;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-family: Inter, Arial;
    font-size: .625rem;
    font-weight: 400;
    line-height: 12px;
    display: flex;
  }
  
  .background {
    width: 95%;
    background-color: #fff;
    border-radius: 29px;
    flex-direction: column;
    flex-basis: 62%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 35px;
    display: flex;
    position: relative;
    top: -14px;
  }
  .rectangle-34625542 {
    position: relative;
    top: 5px;
    color: #fff;
    width: 75%;
    background-color: #f7b924;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    margin-left: 1px;
    padding-left: 5px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
  }
  
  .class-tle-a-4-esp {
    margin-left: 3px;
  }
  
  .rectangle-34625543 {
    color: #fff;
    width: 75%;
    background-color: #3ac47d;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
    padding-left: 4px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
  }
  
  .class-tle-a-4-all {
    margin-left: 2px;
  }
  
  .rectangle-34625544 {
    position:relative;
    top: -6px;
    color: #fff;
    width: 75%;
    background-color: #3f6ad8;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
    padding-left: 4px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
  }
  
  .class-1-er-a-4-all {
    margin-left: 2px;
  }
  
  .rectangle-34625545 {
    color: #fff;
    width: 75%;
    background-color: #f7b924;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    margin-left: 1px;
    padding-right: 6px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
    position: relative;
    top: -13px;
  }
  
  .rectangle-34625552 {
    width: 100%;
   /*background-color: #fff;*/
    flex-direction: row;
    flex-basis: 12%;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 3px;
    display: flex;
  }
  
  .flex-container {
    color: #15399e;
    height: 65%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 700;
    display: flex;
  }
  
  .rihome-5-line {
    width: 31px;
    height: 31px;
    object-fit: cover;
  }
  
  .flex-container-1 {
    height: 65%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 1px;
    // margin-left: 9px;
    display: flex;
  }
  
  .icomoon-freemeter {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  
  .schedule {
    margin-bottom: -1px;
  }
  
  .flex-container-2 {
    height: 65%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 1px;
    // margin-left: 14px;
    display: flex;
  }
  
  .tablerclipboard-list {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  
  .grades {
    margin-bottom: -1px;
  }
  
  .flex-container-3 {
    height: 60%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // margin-left: 5px;
    display: flex;
  }
  
  .carbonuser-multiple {
    width: 26px;
    height: 26px;
    object-fit: cover;
    position: relative;
    top: 3px;
  }
  
  .reAdjust {
    position: relative;
    top: 3px;
  }
  
  .flex-container-4 {
    height: 70%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  
  .studentListMsge {
    position: relative;
    left: -13vw;}
  
  .searchIcon {
    position: relative;
    top: -37px;
    left: 36vw;
  }
  
  .Search {
    height: 47px;
    width: 387px;
    width: 89vw;
    margin-top: 12px;
    border-width: 0px;
    border-radius: 53px;
    box-shadow: 0px 0px 9px -6px;
  
  }
  
  input[placeholder="Search here"]r {
    position: absolute;
    /* left: 20px; */
  }
  
  .placeholder {
    width: 28px;
    height: 30px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    border-radius: 42px;
    margin-bottom: 1px;
    margin-left: 12px;
    box-shadow: 0px 0px 8px -3px;
  }
  
  .headerStyle {
    color: #0f0f0f;
    width: 100%;
    background-color: #fff;
    flex-direction: row;
    flex-basis: 6%;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1px;
    font-size: 1.188rem;
    font-weight: 600;
    line-height: 55px;
    display: flex;
  }
  
  input::placeholder {
    padding-left: 20px;
  }
  
  input {
    padding-left: 20px;
  }
  
  .searchField {
    color: #aaa;
    width: 90%;
    margin-top: 6px;
    background-color: #fff;
    border: none;
    border-radius: 53px;
    flex-direction: row;
    flex-basis: 5%;
    justify-content: flex-start;
    align-items: stretch;
    font-family: Poppins, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    display: flex;
  }
  
  .placeholder-1 {
    width: 35px;
    height: 35px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    border-radius: 42px;
    margin-bottom: 1px;
  }
  
  .rectangle-10 {
    width: 32px;  
    height: 32px;
    object-fit: cover;
    border-radius: 100px;
    margin-top: 1px;
    transform: rotate(180deg);
  }
  
  .material-symbolsscho {
    width: 35px;
    height: 35px;
    object-fit: cover;
  }
    @media  screen and (min-width: 390px) and (min-height: 844px) and (max-width: 391px) and (max-height: 845px) {
      .mobile-view{
        width: 390px;
      }
      .Search{
        position: relative;
        top: -71px;
      } 
      .searchIcon{
        top: -108px;
      }
      .headerStyle{
        position: relative;
        top: -69px;  
      }
        .background{
          top: -87px;
        }
        .rectangle-34625552{
          flex-basis: 8%;
        }
        .studentListMsge{
          top: 3.8px;
        }
    }
    @media  screen and (min-width: 360px) and (min-height: 740px) and (max-width: 361px) and (max-height: 741px) {
      .rectangle-34625552{
        flex-basis: 9%;
      }
      .studentListMsge{
        top: 3.8px;
      }
      .Search{
        position: relative;
        top: -31px !important;
      }
      .headerStyle{
        position: relative;
        top: -30px !important;
      }
      .searchIcon{
        top: -68px !important;
      }
      .background{
        top: -46px;
      }
  
      .adjust{
        position: relative;
        top: -3px;
      }
    }
  `;

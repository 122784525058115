/** User types for role based access **
  'USER_TYPE_SUPER_ADMIN'=>1,
  'USER_TYPE_SCHOOL_ADMIN'=>2,
  'USER_TYPE_SCHOOL_SUB_ADMIN'=>8,
  'USER_TYPE_TEACHER'=>3,
  'USER_TYPE_STUDENT'=>4,
  'USER_TYPE_PARENT'=>5,
 */
export const IMAGE_FORMATS = ['jpeg', 'gif', 'png', 'jpg', 'svg', 'webp', 'jfif', 'pjpeg', 'pjp', 'apng', 'avif'];

export const USER_SUPER_ADMIN = 1;

export const USER_SCHOOL_ADMIN = 2;

export const USER_SCHOOL_SUB_ADMIN = 8;

export const USER_TEACHER = 3;

export const USER_STUDENT = 4;

export const USER_PARENT = 5;

export const USER_PARENT2 = 6;

export const USER_PARENT3 = 7;

export const USER_TEACHER2 = 20;

export const VIDEO_FORMATS = ['mp4', '3gp', '3gpp2', '3gp2', 'mkv', 'mov', 'avi', 'webm'];

export const ALLOWED_BULK_IMPORTS = [
  // 'Classes',
  // 'Grades',
  'Enrollment',
  'Cost Item',
  'Cost Reason',
  'Source Of Income',
  'Income Reason',
  'Payment Types',
  'Outflows',
  'Entries',
  'Installment',
  'School Year',
  'Income Forecast',
  'Expenses Forecast',
];

export const SUPER_ADMIN_ALLOWED_BULK_IMPORTS = [
  'Schools',
];

export const SCHEDULE = 'schedule';
export const SCHEDULE_VIEW_SCHEDULE = 'schedule.view.schedule';
export const SCHEDULE_CREATE_SLOT = 'schedule.create.slot';
export const SCHEDULE_UPDATE_SLOT = 'schedule.update.slot';
export const SCHEDULE_DELETE_SLOT = 'schedule.delete.slot';
export const TUITION_ENROLLMENT = 'tuition_enrollment';
export const TUITION_ENROLLMENT_LIST = 'tuition_enrollment.list';
export const TUITION_ENROLLMENT_ADD = 'tuition_enrollment.add';
export const TUITION_ENROLLMENT_UPDATE = 'tuition_enrollment.update';
export const TUITION_ENROLLMENT_DELETE = 'tuition_enrollment.delete';
export const TUITION_ENROLLMENT_BULK_IMPORT = 'tuition_enrollment.bulk.import';
export const TUITION_PAYMENT = 'tuition_payment';
export const TUITION_PAYMENT_LIST = 'tuition_payment.list';
export const TUITION_PAYMENT_UPDATE_DATE_TIME = 'tuition_payment.updatedatetime';
export const TUITION_PAYMENT_NEW_PAYMENT_INSTALLMENT = 'tuition_payment.new.payment.installment';
export const TUITION_PAYMENT_DELETE_PAYMENT_INSYALLMENT = 'tuition_payment.delete.payment.insyallment';
export const GRADES_STUDENTS = 'grades_students';
export const GRADES_STUDENTS_VIEW_STUDENT_LIST = 'grades_students.view.student.list';
export const GRADES_ATTENDANCEMANAGEMENT = 'grades_attendancemanagement';
export const GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST = 'grades_attendancemanagement.view.attendance.list';
export const GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE = 'grades_attendancemanagement.new.attendance';
export const GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE = 'grades_attendancemanagement.edit.attendance';
export const GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE = 'grades_attendancemanagement.delete.attendance';
export const GRADES_MARKMANAGEMENT = 'grades_markmanagement';
export const GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST = 'grades_markmanagement.view.marks.list';
export const GRADES_MARKMANAGEMENT_NEW_MARK = 'grades_markmanagement.new.mark';
export const GRADES_MARKMANAGEMENT_EDIT_MARKS = 'grades_markmanagement.edit.marks';
export const GRADES_MARKMANAGEMENT_DELETE_MARKS = 'grades_markmanagement.delete.marks';
export const GRADES_MARKMANAGEMENT_VALIDATE_MARKS = 'grades_markmanagement.validate.marks';
export const GRADES_MARKREPORT = 'grades_markreport';
export const GRADES_MARKREPORT_GRADES__MARK_REPORT = 'grades_markreport.grades.mark.report';
export const FINANCE_OUTFLOWS = 'finance_outflows';
export const FINANCE_OUTFLOWS_LIST = 'finance_outflows.list';
export const FINANCE_OUTFLOWS_ADD = 'finance_outflows.add';
export const FINANCE_OUTFLOWS_UPDATE = 'finance_outflows.update';
export const FINANCE_OUTFLOWS_UPDATE_DATE = 'finance_outflows.update_date';
export const FINANCE_OUTFLOWS_DELETE = 'finance_outflows.delete';
export const FINANCE_OUTFLOWS_BULK_IMPORT = 'finance_outflows.bulk.import';
export const FINANCE_ENTRIES = 'finance_entries';
export const FINANCE_ENTRIES_LIST = 'finance_entries.list';
export const FINANCE_ENTRIES_ADD = 'finance_entries.add';
export const FINANCE_ENTRIES_UPDATE = 'finance_entries.update';
export const FINANCE_ENTRIES_UPDATE_DATE = 'finance_entries.update_date';
export const FINANCE_ENTRIES_DELETE = 'finance_entries.delete';
export const FINANCE_ENTRIES_BULK_IMPORT = 'finance_entries.bulk.import';
export const USERS = 'users';
export const USERS_LIST = 'users.list';
export const USERS_ADD = 'users.add';
export const USERS_UPDATE = 'users.update';
export const USERS_DELETE = 'users.delete';
export const DEPARTMENT = 'department';
export const DEPARTMENT_LIST = 'department.list';
export const DEPARTMENT_ADD = 'department.add';
export const DEPARTMENT_UPDATE = 'department.update';
export const DEPARTMENT_DELETE = 'department.delete';
export const SUBJECT = 'subject';
export const SUBJECT_LIST = 'subject.list';
export const SUBJECT_ADD = 'subject.add';
export const SUBJECT_UPDATE = 'subject.update';
export const SUBJECT_DELETE = 'subject.delete';
export const SCHOOLSTAFF_TEACHERS = 'schoolstaff_teachers';
export const SCHOOLSTAFF_TEACHERS_LIST = 'schoolstaff_teachers.list';
export const SCHOOLSTAFF_TEACHERS_ADD = 'schoolstaff_teachers.add';
export const SCHOOLSTAFF_TEACHERS_UPDATE = 'schoolstaff_teachers.update';
export const SCHOOLSTAFF_TEACHERS_DELETE = 'schoolstaff_teachers.delete';
export const REPORTING_ENROLLMENT_FINANCIAL_REPORT = 'reporting_enrollment_financial_report';
export const REPORTING_ENROLLMENT_FINANCIAL_REPORT_VIEW_REPORT = 'reporting_enrollment_financial_report_view_report';
export const REPORTING_ENTRIESDAILYREPORT = 'reporting_entriesdailyreport';
export const REPORTING_ENTRIESDAILYREPORT_VIEW_REPORT = 'reporting_entriesdailyreport.view.report';
export const SCHOOLWALL = 'schoolwall';
export const SCHOOLWALL_VIEW_WALL = 'schoolwall.view.wall';
export const SCHOOLWALL_ADD_POST = 'schoolwall.add.post';
export const SCHOOLWALL_UPDATE_POST = 'schoolwall.update.post';
export const SCHOOLWALL_DELETE_POST = 'schoolwall.delete.post';
export const PEDAGOGICALFOLLOWUP_CHAPTER = 'pedagogicalfollowup_chapter';
export const PEDAGOGICALFOLLOWUP_CHAPTER_LIST = 'pedagogicalfollowup_chapter.list';
export const PEDAGOGICALFOLLOWUP_CHAPTER_ADD = 'pedagogicalfollowup_chapter.add';
export const PEDAGOGICALFOLLOWUP_CHAPTER_UPDATE = 'pedagogicalfollowup_chapter.update';
export const PEDAGOGICALFOLLOWUP_CHAPTER_DELETE = 'pedagogicalfollowup_chapter.delete';
export const PEDAGOGICALFOLLOWUP_PART = 'pedagogicalfollowup_part';
export const PEDAGOGICALFOLLOWUP_PART_LIST = 'pedagogicalfollowup_part.list';
export const PEDAGOGICALFOLLOWUP_PART_ADD = 'pedagogicalfollowup_part.add';
export const PEDAGOGICALFOLLOWUP_PART_UPDATE = 'pedagogicalfollowup_part.update';
export const PEDAGOGICALFOLLOWUP_PART_DELETE = 'pedagogicalfollowup_part.delete';
export const PEDAGOGICALFOLLOWUP_FORECAST = 'pedagogicalfollowup_forecast';
export const PEDAGOGICALFOLLOWUP_FORECAST_LIST = 'pedagogicalfollowup_forecast.list';
export const PEDAGOGICALFOLLOWUP_FORECAST_ADD = 'pedagogicalfollowup_forecast.add';
export const PEDAGOGICALFOLLOWUP_FORECAST_UPDATE = 'pedagogicalfollowup_forecast.update';
export const PEDAGOGICALFOLLOWUP_FORECAST_DELETE = 'pedagogicalfollowup_forecast.delete';
export const PEDAGOGICALFOLLOWUP_PROGRESSION = 'pedagogicalfollowup_progression';
export const PEDAGOGICALFOLLOWUP_PROGRESSION_LIST = 'pedagogicalfollowup_progression.list';
export const PEDAGOGICALFOLLOWUP_PROGRESSION_ADD = 'pedagogicalfollowup_progression.add';
export const PEDAGOGICALFOLLOWUP_PROGRESSION_UPDATE = 'pedagogicalfollowup_progression.update';
export const PEDAGOGICALFOLLOWUP_PROGRESSION_DELETE = 'pedagogicalfollowup_progression.delete';
export const PEDAGOGICALFOLLOWUP_REPORT = 'pedagogicalfollowup_report';
export const PEDAGOGICALFOLLOWUP_REPORT_VIEW = 'pedagogicalfollowup_report.view';
export const SETTINGS_SCHOOLINFORMATION = 'settings_schoolinformation';
export const SETTINGS_SCHOOLINFORMATION_VIEW = 'settings_schoolinformation.view';
export const SETTINGS_SCHOOLINFORMATION_EDIT = 'settings_schoolinformation.edit';
export const SETTINGS_SCHOOLYEAR = 'settings_schoolyear';
export const SETTINGS_SCHOOLYEAR_LIST = 'settings_schoolyear.list';
export const SETTINGS_SCHOOLYEAR_ADD = 'settings_schoolyear.add';
export const SETTINGS_SCHOOLYEAR_UPDATE = 'settings_schoolyear.update';
export const SETTINGS_SCHOOLYEAR_DELETE = 'settings_schoolyear.delete';
export const SETTINGS_HONNORROLL = 'settings_honnorroll';
export const SETTINGS_HONNORROLL_VIEW = 'settings_honnorroll.view';
export const SETTINGS_HONNORROLL_EDIT = 'settings_honnorroll.edit';
export const SETTINGS_HONNORROLL_LIST = 'settings_honnorroll.list';
export const SETTINGS_QUALIFICATION = 'settings_qualification';
export const SETTINGS_QUALIFICATION_ADD = 'settings_qualification.add';
export const SETTINGS_QUALIFICATION_UPDATE = 'settings_qualification.update';
export const SETTINGS_QUALIFICATION_DELETE = 'settings_qualification.delete';
export const SETTINGS_GRADES = 'settings_grades';
export const SETTINGS_GRADES_LIST = 'settings_grades.list';
export const SETTINGS_GRADES_ADD = 'settings_grades.add';
export const SETTINGS_GRADES_UPDATE = 'settings_grades.update';
export const SETTINGS_GRADES_DELETE = 'settings_grades.delete';
export const SETTINGS_WEEKS = 'settings_weeks';
export const SETTINGS_WEEKS_LIST = 'settings_weeks.list';
export const SETTINGS_WEEKS_ADD = 'settings_weeks.add';
export const SETTINGS_WEEKS_UPDATE = 'settings_weeks.update';
export const SETTINGS_WEEKS_DELETE = 'settings_weeks.delete';
export const SETTINGS_CLASSES = 'settings_classes';
export const SETTINGS_CLASSES_LIST = 'settings_classes.list';
export const SETTINGS_CLASSES_ADD = 'settings_classes.add';
export const SETTINGS_CLASSES_UPDATE = 'settings_classes.update';
export const SETTINGS_CLASSES_DELETE = 'settings_classes.delete';
export const SETTINGS_INSTALLMENT = 'settings_installment';
export const SETTINGS_INSTALLMENT_LIST = 'settings_installment.list';
export const SETTINGS_INSTALLMENT_ADD = 'settings_installment.add';
export const SETTINGS_INSTALLMENT_UPDATE = 'settings_installment.update';
export const SETTINGS_INSTALLMENT_DELETE = 'settings_installment.delete';
export const SETTINGS_APPRECIATION = 'settings_appreciation';
export const SETTINGS_APPRECIATION_LIST = 'settings_appreciation.list';
export const SETTINGS_APPRECIATION_ADD = 'settings_appreciation.add';
export const SETTINGS_APPRECIATION_UPDATE = 'settings_appreciation.update';
export const SETTINGS_APPRECIATION_DELETE = 'settings_appreciation.delete';
export const SETTINGS_DISCOUNTS = 'settings_discounts';
export const SETTINGS_DISCOUNTS_LIST = 'settings_discounts.list';
export const SETTINGS_DISCOUNTS_ADD = 'settings_discounts.add';
export const SETTINGS_DISCOUNTS_UPDATE = 'settings_discounts.update';
export const SETTINGS_DISCOUNTS_DELETE = 'settings_discounts.delete';
export const SETTINGS_PAYMENTTYPE = 'settings_paymenttype';
export const SETTINGS_PAYMENTTYPE_LIST = 'settings_paymenttype.list';
export const SETTINGS_PAYMENTTYPE_ADD = 'settings_paymenttype.add';
export const SETTINGS_PAYMENTTYPE_UPDATE = 'settings_paymenttype.update';
export const SETTINGS_PAYMENTTYPE_DELETE = 'settings_paymenttype.delete';
export const SETTINGS_COSTITEM = 'settings_costitem';
export const SETTINGS_COSTITEM_LIST = 'settings_costitem.list';
export const SETTINGS_COSTITEM_ADD = 'settings_costitem.add';
export const SETTINGS_COSTITEM_UPDATE = 'settings_costitem.update';
export const SETTINGS_COSTITEM_DELETE = 'settings_costitem.delete';
export const SETTINGS_COSTREASON = 'settings_costreason';
export const SETTINGS_COSTREASON_LIST = 'settings_costreason.list';
export const SETTINGS_COSTREASON_ADD = 'settings_costreason.add';
export const SETTINGS_COSTREASON_UPDATE = 'settings_costreason.update';
export const SETTINGS_COSTREASON_DELETE = 'settings_costreason.delete';
export const SETTINGS_SOURCEOFINCOME = 'settings_sourceofincome';
export const SETTINGS_SOURCEOFINCOME_LIST = 'settings_sourceofincome.list';
export const SETTINGS_SOURCEOFINCOME_ADD = 'settings_sourceofincome.add';
export const SETTINGS_SOURCEOFINCOME_UPDATE = 'settings_sourceofincome.update';
export const SETTINGS_SOURCEOFINCOME_DELETE = 'settings_sourceofincome.delete';
export const SETTINGS_INCOMEREASON = 'settings_incomereason';
export const SETTINGS_INCOMEREASON_LIST = 'settings_incomereason.list';
export const SETTINGS_INCOMEREASON_ADD = 'settings_incomereason.add';
export const SETTINGS_INCOMEREASON_UPDATE = 'settings_incomereason.update';
export const SETTINGS_INCOMEREASON_DELETE = 'settings_incomereason.delete';
export const SETTINGS_INCOMEFORECAST = 'settings_incomeforecast';
export const SETTINGS_INCOMEFORECAST_LIST = 'settings_incomeforecast.list';
export const SETTINGS_INCOMEFORECAST_ADD = 'settings_incomeforecast.add';
export const SETTINGS_INCOMEFORECAST_UPDATE = 'settings_incomeforecast.update';
export const SETTINGS_INCOMEFORECAST_DELETE = 'settings_incomeforecast.delete';
export const SETTINGS_EXPENSESFORECAST = 'settings_expensesforecast';
export const SETTINGS_EXPENSESFORECAST_LIST = 'settings_expensesforecast.list';
export const SETTINGS_EXPENSESFORECAST_ADD = 'settings_expensesforecast.add';
export const SETTINGS_EXPENSESFORECAST_UPDATE = 'settings_expensesforecast.update';
export const SETTINGS_EXPENSESFORECAST_DELETE = 'settings_expensesforecast.delete';
export const SETTINGS_OTHERPARAMETERS = 'settings_otherparameters';
export const SETTINGS_OTHERPARAMETERS_VIEW = 'settings_otherparameters.view';
export const SETTINGS_OTHERPARAMETERS_EDIT = 'settings_otherparameters.edit';
export const SETTINGS_SCHOOL = 'settings_school';
export const SETTINGS_SCHOOL_VIEW = 'settings_school.view';
export const SETTINGS_SCHOOL_EDIT = 'settings_school.edit';

export const SETTINGS_LEVEL = 'settings_level';
export const SETTINGS_LEVEL_ADD = 'settings_level.add';
export const SETTINGS_LEVEL_UPDATE = 'settings_level.update';
export const SETTINGS_LEVEL_DELETE = 'settings_level.delete';

export const SETTINGS_SUBJECT_TYPE = 'settings_subject_type';
export const SETTINGS_SUBJECT_TYPE_ADD = 'settings_subject_type.add';
export const SETTINGS_SUBJECT_TYPE_UPDATE = 'settings_subject_type.update';
export const SETTINGS_SUBJECT_TYPE_DELETE = 'settings_subject_type.delete';

export const SETTINGS_SKILLS = 'settings_skills';
export const SETTINGS_SKILLS_ADD = 'settings_skills.add';
export const SETTINGS_SKILLS_UPDATE = 'settings_skills.update';
export const SETTINGS_SKILLS_DELETE = 'settings_skills.delete';

export const SETTINGS_MARK_PRIMARY_RULE = 'settings_mark_primary_rule';
export const SETTINGS_MARK_PRIMARY_RULE_ADD = 'settings_mark_primary_rule.add';
export const SETTINGS_MARK_PRIMARY_RULE_UPDATE = 'settings_mark_primary_rule.update';
export const SETTINGS_MARK_PRIMARY_RULE_DELETE = 'settings_mark_primary_rule.delete';

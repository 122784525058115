import { bool, string, number } from 'prop-types';
import React from 'react';
import SweetAlert from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { deleteQualification } from '../../actions/qualification-action-types';
import { push } from 'connected-react-router';
import "../../../public/css/customstyle.css";
import { deleteLevel } from '../../actions/level-action-types';
import { deleteSubjectType } from '../../actions/subject-type-action-types';

const SubjectTypebuttonCellRenderer = ({
  href, className,isDelete,id
}) => {

  const dispatch = useDispatch();

  
  const onDelete = () => {
    SweetAlert.fire({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      icon: 'warning',
      showCancelButton: true,
      text: 'Are you sure you want delete the selected Subject Type?',
      title: 'Delete Subject Type',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSubjectType(id));
      }
    });
  };
  const edit = () => {
    dispatch(push(href));
  }

  if (href && href.length) {
    return (
      <div>       
        <button className={`${className}`} type="button" onClick={edit} >
          <i className="pe-7s-pen mr-1"></i>
          <span>Edit</span>
        </button>          
        {
          isDelete && (                                         
                        <button className={`${className}`} type="button" onClick={onDelete} >
                          <i className="lnr-trash mr-1" ></i>
                          <span>Delete</span>
                        </button>                                                        
                      )
        }
      </div>     
    );
  }

  return <></>;
};

SubjectTypebuttonCellRenderer.propTypes = {
  className: string,
  href: string.isRequired,
};

SubjectTypebuttonCellRenderer.defaultProps = {
  className: '',
};

export default SubjectTypebuttonCellRenderer;

import {all, call, put, takeLatest} from 'redux-saga/effects';
import {ADD_EXAMTYPE,
    addExamTypeRequested,
    addExamTypeSuccess,
    addExamTypeFailure,
    FETCH_EXAMTYPE,
    fetchExamTypeRequested,
    fetchExamTypeSuccess,
    fetchExamTypeFailure
} from "../actions/typeofexam-action-types";
import httpClient from "./http-client";

export function* addExamTypeHandler({payload}){
    yield put(addExamTypeRequested());

    const body = new FormData();

    body.append('examtypes', JSON.stringify(payload.examTypes));

    const request = {
        url: 'add-update-examtype',
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: body
    };

    const {error} = yield call(httpClient, request);

    if(error){
        yield put(addExamTypeFailure(error));
    }else{
        yield put(addExamTypeSuccess());
    }
}

export function* fetchExamTypeHandler(){
    yield put(fetchExamTypeRequested());

    const request = {
        url: 'get-examtypes',
        method: 'GET',
        headers: {'Content-Type': 'multipart/form-data'}
    };

    const {data, error} = yield call(httpClient, request);

    if(error){
        yield put(fetchExamTypeFailure(error));
    }
    else{
        yield put(fetchExamTypeSuccess((data.data.result.length > 0)?data.data.result:[
            {
                id: 0,
                exam_type_name: '',
                percentage: '',
                editable: true,
                removed: false
            }
        ]));
    }
}

function* ExamType(){
    yield all([
        takeLatest(ADD_EXAMTYPE, addExamTypeHandler),
        takeLatest(FETCH_EXAMTYPE, fetchExamTypeHandler)
    ]);
}

export default ExamType;
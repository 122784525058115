import { string, object } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Utils from '../../utility';
import '../../../public/css/customstyle.css'

const MarksViewStudentRenderer = ({ student }) => (
  <div className="d-flex align-items-center text-left py-2">
    <img
      width="40px"
      height="40px"
      className="rounded-circle mr-2"
      src={
        student?.student_profile_picture
          ? student?.student_profile_picture
          : Utils.getImage('user-placeholder.png')
      }
      alt="Profile Pic"
    />
    <div className="" style={{ lineHeight: "initial","max-width":"570px","min-width":"226px",  width: "226px" }}>
      <span className="widget-heading">
        {student?.student_last_name} &nbsp;
        {student?.student_first_name}
      </span>
      <span className="widget-subheading d-block">{student?.student_registration_no}</span>
    </div>
    <div className="" > {
      student?.attendance === 0?
        <span className='badge badge-danger ml-3 float-right'>A</span> :
          student?.attendance === 1? <span className='badge badge-success ml-3 float-right'>P</span>:
            student?.attendance === 2? <span className='badge badgepurple ml-3 float-right'>J</span>: ''
    }     
    </div>
  </div>
);

MarksViewStudentRenderer.propTypes = {
  student: object.isRequired,
};

export default MarksViewStudentRenderer;

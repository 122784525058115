import {  } from "../actions/level-action-types";
import { FETCH_SINGLE_SKILLS_SUCCESS, FETCH_SKILLS_SUCCESS } from "../actions/skill-action-types";
import { FETCH_SINGLE_SUBJECT_TYPE_SUCCESS, FETCH_SUBJECT_TYPE_BY_LEVEL_ID_SUCCESS, FETCH_SUBJECT_TYPE_SUCCESS } from "../actions/subject-type-action-types";

const intitalState = {
    skills: [],
    singleSkills:[],
};

const skillsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case FETCH_SKILLS_SUCCESS:
            return {
                ...state,
                skills: action.payload,
            }
        case FETCH_SINGLE_SKILLS_SUCCESS:
                return {
                    ...state,
                    singleSkills: action.payload,
                }

        default:
            return { ...state };
    }
};
export default skillsReducer;
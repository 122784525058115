import { createAction } from 'redux-actions';

export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const createPayment = createAction(CREATE_PAYMENT);

export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';
export const createPaymentFailure = createAction(CREATE_PAYMENT_FAILURE);

export const CREATE_PAYMENT_REQUESTED = 'CREATE_PAYMENT_REQUESTED';
export const createPaymentRequested = createAction(CREATE_PAYMENT_REQUESTED);

export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const createPaymentSuccess = createAction(CREATE_PAYMENT_SUCCESS);

export const CREATE_TRANSPORT_PAYMENT = 'CREATE_TRANSPORT_PAYMENT';
export const createTransportPayment = createAction(CREATE_TRANSPORT_PAYMENT);

export const CREATE_TRANSPORT_PAYMENT_FAILURE = 'CREATE_TRANSPORT_PAYMENT_FAILURE';
export const createTransportPaymentFailure = createAction(CREATE_TRANSPORT_PAYMENT_FAILURE);

export const CREATE_TRANSPORT_PAYMENT_REQUESTED = 'CREATE_TRANSPORT_PAYMENT_REQUESTED';
export const createTransportPaymentRequested = createAction(CREATE_TRANSPORT_PAYMENT_REQUESTED);

export const CREATE_TRANSPORT_PAYMENT_SUCCESS = 'CREATE_TRANSPORT_PAYMENT_SUCCESS';
export const createTransportPaymentSuccess = createAction(CREATE_TRANSPORT_PAYMENT_SUCCESS);

export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const deletePayment = createAction(DELETE_PAYMENT);

export const DELETE_PAYMENT_FAILURE = 'DELETE_PAYMENT_FAILURE';
export const deletePaymentFailure = createAction(DELETE_PAYMENT_FAILURE);

export const DELETE_PAYMENT_REQUESTED = 'DELETE_PAYMENT_REQUESTED';
export const deletePaymentRequested = createAction(DELETE_PAYMENT_REQUESTED);

export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const deletePaymentSuccess = createAction(DELETE_PAYMENT_SUCCESS);

export const EDIT_PAYMENT = 'EDIT_PAYMENT';
export const editPayment = createAction(EDIT_PAYMENT);

export const EDIT_PAYMENT_FAILURE = 'EDIT_PAYMENT_FAILURE';
export const editPaymentFailure = createAction(EDIT_PAYMENT_FAILURE);

export const EDIT_PAYMENT_REQUESTED = 'EDIT_PAYMENT_REQUESTED';
export const editPaymentRequested = createAction(EDIT_PAYMENT_REQUESTED);

export const EDIT_PAYMENT_SUCCESS = 'EDIT_PAYMENT_SUCCESS';
export const editPaymentSuccess = createAction(EDIT_PAYMENT_SUCCESS);

export const FETCH_FILTER_PAYMENT = 'FETCH_FILTER_PAYMENT';
export const fetchFilterPayment = createAction(FETCH_FILTER_PAYMENT);

export const FETCH_FILTER_PAYMENT_FAILURE = 'FETCH_FILTER_PAYMENT_FAILURE';
export const fetchFilterPaymentFailure = createAction(FETCH_FILTER_PAYMENT_FAILURE);

export const FETCH_FILTER_PAYMENT_REQUESTED = 'FETCH_FILTER_PAYMENT_REQUESTED';
export const fetchFilterPaymentRequested = createAction(FETCH_FILTER_PAYMENT_REQUESTED);

export const FETCH_FILTER_PAYMENT_SUCCESS = 'FETCH_FILTER_PAYMENT_SUCCESS';
export const fetchFilterPaymentSuccess = createAction(FETCH_FILTER_PAYMENT_SUCCESS);

export const FETCH_PAYMENT = 'FETCH_PAYMENT';
export const fetchPayment = createAction(FETCH_PAYMENT);

export const FETCH_PAYMENT_FAILURE = 'FETCH_PAYMENT_FAILURE';
export const fetchPaymentFailure = createAction(FETCH_PAYMENT_FAILURE);

export const FETCH_PAYMENT_REQUESTED = 'FETCH_PAYMENT_REQUESTED';
export const fetchPaymentRequested = createAction(FETCH_PAYMENT_REQUESTED);

export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const fetchPaymentSuccess = createAction(FETCH_PAYMENT_SUCCESS);

export const UPDATE_PAYMENT_FORM = 'UPDATE_PAYMENT_FORM';
export const updatePaymentForm = createAction(UPDATE_PAYMENT_FORM);

export const CREATE_APPLY_DISCOUNT = 'CREATE_APPLY_DISCOUNT';
export const createApplyDiscount = createAction(CREATE_APPLY_DISCOUNT);

export const UPDATE_APPLY_DISCOUNT_FORM = 'UPDATE_APPLY_DISCOUNT_FORM';
export const updateApplyDiscountForm = createAction(UPDATE_APPLY_DISCOUNT_FORM);

export const CREATE_APPLY_DISCOUNT_FAILURE = 'CREATE_APPLY_DISCOUNT_FAILURE';
export const createApplyDiscountFailure = createAction(CREATE_APPLY_DISCOUNT_FAILURE);

export const CREATE_APPLY_DISCOUNT_REQUESTED = 'CREATE_APPLY_DISCOUNT_REQUESTED';
export const createApplyDiscountRequested = createAction(CREATE_APPLY_DISCOUNT_REQUESTED);

export const CREATE_APPLY_DISCOUNT_SUCCESS = 'CREATE_APPLY_DISCOUNT_SUCCESS';
export const createApplyDiscountSuccess = createAction(CREATE_APPLY_DISCOUNT_SUCCESS);

export const DELETE_APPLY_DISCOUNT = 'DELETE_APPLY_DISCOUNT';
export const deleteApplyDiscount = createAction(DELETE_APPLY_DISCOUNT);

export const DELETE_APPLY_DISCOUNT_FAILURE = 'DELETE_APPLY_DISCOUNT_FAILURE';
export const deleteApplyDiscountFailure = createAction(DELETE_APPLY_DISCOUNT_FAILURE);

export const DELETE_APPLY_DISCOUNT_REQUESTED = 'DELETE_APPLY_DISCOUNT_REQUESTED';
export const deleteApplyDiscountRequested = createAction(DELETE_APPLY_DISCOUNT_REQUESTED);

export const DELETE_APPLY_DISCOUNT_SUCCESS = 'DELETE_APPLY_DISCOUNT_SUCCESS';
export const deleteApplyDiscountSuccess = createAction(DELETE_APPLY_DISCOUNT_SUCCESS);

export const EDIT_APPLY_DISCOUNT = 'EDIT_APPLY_DISCOUNT';
export const editApplyDiscount = createAction(EDIT_APPLY_DISCOUNT);

export const EDIT_APPLY_DISCOUNT_FAILURE = 'EDIT_APPLY_DISCOUNT_FAILURE';
export const editApplyDiscountFailure = createAction(EDIT_APPLY_DISCOUNT_FAILURE);

export const EDIT_APPLY_DISCOUNT_REQUESTED = 'EDIT_APPLY_DISCOUNT_REQUESTED';
export const editApplyDiscountRequested = createAction(EDIT_APPLY_DISCOUNT_REQUESTED);

export const EDIT_APPLY_DISCOUNT_SUCCESS = 'EDIT_APPLY_DISCOUNT_SUCCESS';
export const editApplyDiscountSuccess = createAction(EDIT_APPLY_DISCOUNT_SUCCESS);

export const FETCH_APPLY_DISCOUNT = 'FETCH_APPLY_DISCOUNT';
export const fetchApplyDiscount = createAction(FETCH_APPLY_DISCOUNT);

export const FETCH_APPLY_DISCOUNT_FAILURE = 'FETCH_APPLY_DISCOUNT_FAILURE';
export const fetchApplyDiscountFailure = createAction(FETCH_APPLY_DISCOUNT_FAILURE);

export const FETCH_APPLY_DISCOUNT_REQUESTED = 'FETCH_APPLY_DISCOUNT_REQUESTED';
export const fetchApplyDiscountRequested = createAction(FETCH_APPLY_DISCOUNT_REQUESTED);

export const FETCH_APPLY_DISCOUNT_SUCCESS = 'FETCH_APPLY_DISCOUNT_SUCCESS';
export const fetchApplyDiscountSuccess = createAction(FETCH_APPLY_DISCOUNT_SUCCESS);

import { createAction } from 'redux-actions';

export const SET_RESSOURCES = 'SET_RESSOURCES';
export const setRessources = createAction(SET_RESSOURCES);

export const EDIT_RESSOURCES = 'EDIT_RESSOURCES';
export const editRessources = createAction(EDIT_RESSOURCES);

export const EDIT_RESSOURCES_FAILURE = 'EDIT_RESSOURCES_FAILURE';
export const editRessourcesFailure = createAction(EDIT_RESSOURCES_FAILURE);

export const EDIT_RESSOURCES_REQUESTED = 'EDIT_RESSOURCES_REQUESTED';
export const editRessourcesRequested = createAction(EDIT_RESSOURCES_REQUESTED);

export const EDIT_RESSOURCES_SUCCESS = 'EDIT_RESSOURCES_SUCCESS';
export const editRessourcesSuccess = createAction(EDIT_RESSOURCES_SUCCESS);

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const setInitialState = createAction(SET_INITIAL_STATE);

export const FETCH_RESSOURCE_FAILURE = 'FETCH_RESSOURCE_FAILURE';
export const fetchRessourceFailure = createAction(FETCH_RESSOURCE_FAILURE);

export const FETCH_RESSOURCE_REQUESTED = 'FETCH_RESSOURCE_REQUESTED';
export const fetchRessourceRequested = createAction(FETCH_RESSOURCE_REQUESTED);

export const FETCH_RESSOURCE_SUCCESS = 'FETCH_RESSOURCE_SUCCESS';
export const fetchRessourceSuccess = createAction(FETCH_RESSOURCE_SUCCESS);

export const FETCH_RESSOURCES = 'FETCH_RESSOURCES';
export const fetchRessources = createAction(FETCH_RESSOURCES);
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_EMERGENCY_CONTACT_FAILURE,
  ADD_EMERGENCY_CONTACT_REQUESTED,
  ADD_EMERGENCY_CONTACT_SUCCESS,
  DELETE_EMERGENCY_CONTACT_FAILURE,
  DELETE_EMERGENCY_CONTACT_REQUESTED,
  DELETE_EMERGENCY_CONTACT_SUCCESS,
  FETCH_EMERGENCY_CONTACT_FAILURE,
  FETCH_EMERGENCY_CONTACT_REQUESTED,
  FETCH_EMERGENCY_CONTACT_SUCCESS,
  FETCH_SINGLE_EMERGENCY_CONTACT_FAILURE,
  FETCH_SINGLE_EMERGENCY_CONTACT_REQUESTED,
  FETCH_SINGLE_EMERGENCY_CONTACT_SUCCESS,
  UPDATE_EMERGENCY_CONTACT_FORM
} from '../actions/emergency-contact-action-type';

const initialState = {
  createEmergencyContactStatus: 'pending',
  deleteEmergencyContactStatus: 'pending',
  fetchSingleEmergencyContactStatus: 'pending',
  fetchEmergencyContactsStatus: 'pending',
  form: {
    employee_id: '',
    is_primary: '',
    name: '',
    relationship: '',
    phone_no_one: '',
    phone_no_two: '',
  },
  emergencyContacts: [],
};

export default function EmergencyContacts(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_EMERGENCY_CONTACT_FAILURE:
      return {
        ...state,
        createEmergencyContactStatus: 'failure',
      };

    case ADD_EMERGENCY_CONTACT_REQUESTED:
      return {
        ...state,
        createEmergencyContactStatus: 'creating',
      };

    case ADD_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        // class_list: payload.class_list,
        createEmergencyContactStatus: 'success',
       // file: encodeURI(payload.image),
       // profile_picture: encodeURI(payload.image),
      };

    case DELETE_EMERGENCY_CONTACT_FAILURE:
      return {
        ...state,
        deleteEmergencyContactStatus: 'failure',
      };

    case DELETE_EMERGENCY_CONTACT_REQUESTED:
      return {
        ...state,
        deleteEmergencyContactStatus: 'deleting',
      };

    case DELETE_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        deleteEmergencyContactStatus: 'success',
        EmergencyContacts: [...state.EmergencyContacts].filter((emergencyContactItem) => !(payload.toString().includes(emergencyContactItem.id.toString()))),
      };

    case FETCH_EMERGENCY_CONTACT_FAILURE:
      return {
        ...state,
        fetchEmergencyContactsStatus: 'failure',
      };

    case FETCH_EMERGENCY_CONTACT_REQUESTED:
      return {
        ...state,
        fetchEmergencyContactsStatus: 'fetching',
      };

    case FETCH_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        fetchEmergencyContactsStatus: 'success',
        EmergencyContacts: payload,
      };

    case FETCH_SINGLE_EMERGENCY_CONTACT_FAILURE:
      return {
        ...state,
        fetchSingleEmergencyContactStatus: 'failure',
      };

    case FETCH_SINGLE_EMERGENCY_CONTACT_REQUESTED:
      return {
        ...state,
        fetchSingleEmergencyContactStatus: 'fetching',
      };

    case FETCH_SINGLE_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        fetchSingleEmergencyContactStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.image),
          profile_picture: encodeURI(payload.image),
        },
      };

    case UPDATE_EMERGENCY_CONTACT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

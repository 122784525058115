import { LOCATION_CHANGE } from 'connected-react-router';
import { ADD_DEPARTMENT_FAILURE,
  ADD_DEPARTMENT_REQUESTED,
  ADD_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT_REQUESTED,
  DELETE_DEPARTMENT_SUCCESS,
  FETCH_DEPARTMENT_FAILURE,
  FETCH_DEPARTMENT_REQUESTED,
  FETCH_DEPARTMENT_SUCCESS,
  FETCH_SINGLE_DEPARTMENT_FAILURE,
  FETCH_SINGLE_DEPARTMENT_REQUESTED,
  FETCH_SINGLE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FORM } from '../actions/department-action-type';

const initialState = {
  addDepartmentStatus: 'pending',
  deleteDepartmentStatus: 'pending',
  department: [],
  fetchDepartmentStatus: 'pending',
  fetchSingleDepartmentStatus: 'pending',
  form: {
    department_code: '',
    department_name: '',
    status: null,
  },
};

export default function department(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_DEPARTMENT_FAILURE:
      return {
        ...state,
        addDepartmentStatus: 'failure',
      };

    case ADD_DEPARTMENT_REQUESTED:
      return {
        ...state,
        addDepartmentStatus: 'creating',
      };

    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        addDepartmentStatus: 'success',
      };

    case DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        deleteDepartmentStatus: 'failure',
      };

    case DELETE_DEPARTMENT_REQUESTED:
      return {
        ...state,
        deleteDepartmentStatus: 'deleting',
      };

    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        deleteDepartmentStatus: 'success',
        department: [...state.department].filter((departments) => !(payload.toString().includes(departments.id.toString()))),

      };

    case FETCH_DEPARTMENT_FAILURE:
      return {
        ...state,
        fetchDepartmentStatus: 'failure',
      };

    case FETCH_DEPARTMENT_REQUESTED:
      return {
        ...state,
        fetchDepartmentStatus: 'fetching',
      };

    case FETCH_DEPARTMENT_SUCCESS:
      return {
        ...state,
        department: payload,
        fetchDepartmentStatus: 'success',
      };

    case FETCH_SINGLE_DEPARTMENT_FAILURE:
      return {
        ...state,
        fetchSingleDepartmentStatus: 'failure',
      };

    case FETCH_SINGLE_DEPARTMENT_REQUESTED:
      return {
        ...state,
        fetchSingleDepartmentStatus: 'fetching',
      };

    case FETCH_SINGLE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        fetchSingleDepartmentStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_DEPARTMENT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import React, {useState , Fragment  } from 'react';
import {useCookies} from 'react-cookie';
import i18next from "i18next";
import './language-switcher.scss';
import Flag from "react-flagkit";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
  import {isMobile} from 'react-device-detect';

  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import city2 from "../../assets/utils/images/dropdown-header/city2.jpg";

const LanguageSwitcher = () => {
    const [cookies, setCookies] = useCookies();

    const [activeLanguage, setActiveLanguage] = useState(() => {
        return {
            short: cookies?.language?cookies.language.short:"en",
            long:  cookies?.language?cookies.language.long:"English"
        };
    });
   // i18next.changeLanguage(activeLanguage.short);
    const changeLanguage = (language, languageFull) => {
        let lang = {
            short: language,
            long: languageFull
        }

        setActiveLanguage(lang);
        setCookies('language', lang, {path: '/'});
        i18next.changeLanguage(language);
    };

    return (
 
<Fragment>
        <div className="header-dots">
        <UncontrolledDropdown>
        <DropdownToggle className={!isMobile ? "p-0 me-2" : `p-0`} color="link">
          <div className="icon-wrapper icon-wrapper-alt rounded-circle">
          {!isMobile ? <div className="icon-wrapper-bg bg-focus" /> : null} 
          {!isMobile ? <div className="language-icon">
            {(activeLanguage.short == 'en' && 'active') ?<Flag className={!isMobile ? `me-3 opacity-8 ` : `me-3`} country="US" size="40" /> :(activeLanguage.short == 'fr' && 'active') ?
            <Flag className="me-3 opacity-8" country="FR" size="40"/> :
            (activeLanguage.short == 'es' && 'active') ? <Flag className={!isMobile ? `me-3 opacity-8 ` : `me-3`} country="ES" size="40"/>:<Flag className="me-3 opacity-8" country="DE" size="40"/>}
            </div>
            : 
            <div className='custom-language-warapper'>
             <div className="language-icon custom-language-icon">
            {(activeLanguage.short == 'en' && 'active') ?<Flag className={!isMobile ? `me-3 opacity-8 ` : `me-3`} country="US" size="100%" /> :(activeLanguage.short == 'fr' && 'active') ?
            <Flag className="me-3 opacity-8" country="FR" size="100%"/> :
            (activeLanguage.short == 'es' && 'active') ? <Flag className={!isMobile ? `me-3 opacity-8 ` : `me-3`} country="ES" size="100%"/>:<Flag className="me-3 opacity-8" country="DE" size="100%"/>}
            </div>
             </div>
            } 
            


             

          </div>
        </DropdownToggle>
        <DropdownMenu end className="rm-pointers">
          <div className="dropdown-menu-header">
            <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
              <div className="menu-header-image opacity-05"
                style={{
                  backgroundImage: "url(" + city2 + ")",
                }}/>
              <div className="menu-header-content text-center text-white">
                <h6 className="menu-header-subtitle mt-0">
                  Choose Language
                </h6>
              </div>
            </div>
          </div>
          <DropdownItem header>Popular Languages</DropdownItem>
          <DropdownItem onClick={(e) => changeLanguage('en', 'English')}  active={activeLanguage.short == 'en' && 'active'}>
            <Flag className="me-3 opacity-8" country="US" />
            USA
          </DropdownItem>
          <DropdownItem onClick={(e) => changeLanguage('fr', 'French')} active={activeLanguage.short == 'fr' && 'active'}>
            <Flag className="me-3 opacity-8" country="FR" />
            France
          </DropdownItem>
         
          <DropdownItem divider />
          <DropdownItem header>Others</DropdownItem>
          <DropdownItem onClick={(e) => changeLanguage('de', 'Deutsch')} active={activeLanguage.short == 'de' && 'active'}>
            <Flag className="me-3 opacity-8" country="DE" />
            Germany
          </DropdownItem>
          <DropdownItem onClick={(e) => changeLanguage('es', 'Spanish')} active={activeLanguage.short == 'es' && 'active'}>
            <Flag className="me-3 opacity-8" country="ES" />
            Spain
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      </div>
      </Fragment>
    );
}

export default LanguageSwitcher;
import {all} from 'redux-saga/effects';
import app from './app';
import bulkUpload from './bulk-upload';
import classes from './classes';
import schools from './schools';
import grades from './grades';
import posts from './posts';
import students from './students';
import costItem from './cost-of-item';
import sourceOfIncome from './source-of-income';
import user from './user';
import outflow from './outflow';
import payment from './payment';
import paymentType from './payment-type';
import entries from './entries';
import installment from './installment';
import schoolYears from './school-year';
import enrollments from './enrollment';
import costReason from './cost-reason';
import incomeReason from './income-reason';
import fileDownloader from './file-downloader';
import incomeForecast from './income-forecast';
import expensesForecast from './expenses-forecast';
import tiles from './tiles';
import subUsers from './sub-user';
import FinanceReport from './financial-reports';
import dailyEntries from './daily-entries';
import transport from './transport';
import otherParameters from './other-parameters';
import totalUsers from './total-users';
import discount from './discount';
import department from './department';
import teacher from './teachers';
import Subject from './subjects';
import mark from './mark';
import schedule from './schedule';
import Chapter from './chapter';
import Part from './part';
import Forecast from './forecast';
import progression from './progression';
import Appreciation from './appreciation';
import Attendance from './attendance';
import ExamAttendance from './examattendance';
import Report from './reports';
import WeekNumber from './weeknumber';
import Qualification from './qualification';
import Sequence from "./sequence";
import ExamType from "./Examtype";
import Quarter from "./Quarters";
import MarkReportGenerator from './marks-report-request';
import UserType from './user-type';
import Ressources from './ressources';
import employee from './employees';
import EmergencyContacts from './emergency-contacts';
import Bank from './bank';
import service from './service';
import family from './families';
import education from './educations';
import professionalExperience from './professional-experiences';
import Levels from './level';
import SubjectType from './subjectType';
import Skills from './skills';
import MarkPrimary from './markPrimary';
import MarkPrimaryGeneratedReport from './markPrimaryGeneratedReport';

const sagas = function* sagas() {
    yield all([
        app(),
        bulkUpload(),
        incomeReason(),
        schoolYears(),
        costReason(),
        enrollments(),
        schools(),
        grades(),
        posts(),
        students(),
        discount(),
        costItem(),
        dailyEntries(),
        sourceOfIncome(),
        installment(),
        classes(),
        outflow(),
        payment(),
        paymentType(),
        entries(),
        fileDownloader(),
        user(),
        incomeForecast(),
        expensesForecast(),
        subUsers(),
        tiles(),
        FinanceReport(),
        transport(),
        otherParameters(),
        totalUsers(),
        department(),
        teacher(),
        Subject(),
        mark(),
        schedule(),
        Chapter(),
        Part(),
        Report(),
        Forecast(),
        progression(),
        Appreciation(),
        Attendance(),
        ExamAttendance(),
        WeekNumber(),
        Qualification(),
        Sequence(),
        ExamType(),
        Quarter(),
        MarkReportGenerator(),
        UserType(),
        Ressources(),
        employee(),
        EmergencyContacts(),
        Bank(),
        service(),
        family(),
        education(),
        professionalExperience(),
        Levels(),
        SubjectType(),
        Skills(),
        MarkPrimary(),
        MarkPrimaryGeneratedReport()
    ]);
};

export default sagas;

import { LOCATION_CHANGE } from 'connected-react-router';
import { FETCH_TOTAL_USERS_FAILURE,
  FETCH_TOTAL_USERS_REQUESTED,
  FETCH_TOTAL_USERS_SUCCESS } from '../actions/total-users-action-type';

const initialState = {
  fetchTotalUsersStatus: 'pending',
  form: {
    cost_item_code: '',
    cost_item_label: '',
    status: null,
  },

  totalUsers: [],
};

export default function totalUsers(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case FETCH_TOTAL_USERS_FAILURE:
      return {
        ...state,
        fetchTotalUsersStatus: 'failure',
      };

    case FETCH_TOTAL_USERS_REQUESTED:
      return {
        ...state,
        fetchTotalUsersStatus: 'fetching',
      };

    case FETCH_TOTAL_USERS_SUCCESS:
      return {
        ...state,
        fetchTotalUsersStatus: 'success',
        totalUsers: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import enGB from "./en/en-GB.json";
import frFR from "./fr/fr-FR.json";
import esES from "./es/es-ES.json";
import deDE from "./de/de-DE.json";
import i18next from "i18next";

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',                              // language to use
    resources: {
        en: {
            skullman: enGB
        },
        fr: {
            skullman: frFR
        },
        
        es: {
            skullman: esES
        },
        
        de: {
            skullman: deDE
        },
    },
});
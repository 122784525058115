import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  FETCH_USERTYPE,
  FETCH_EMPLOYEE_USERTYPE,
  fetchUserTypeFailure,
  fetchUserTypeRequested,
  fetchEmployeeUserTypeRequested,
  fetchUserTypeSuccess
} from '../actions/user-types-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import i18next from "i18next";

export function* fetchUserTypeHandler({ payload }) {
  yield put(fetchUserTypeRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'user-type-list',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    console.log(error);
    console.log(data);
    yield put(fetchUserTypeFailure(error));
  } else {
    yield put(fetchUserTypeSuccess(data.result.data));
  }
}

export function* fetchEmployeeUserTypeHandler({ payload }) {
  yield put(fetchEmployeeUserTypeRequested());
  console.log(payload);
  const request = {
    method: 'GET',
    params: payload,
    url: 'employees-user-type-list',
  };

  const { data, error } = yield call(httpClient, request);
  console.log(data.data.result);

  if (error) {

    yield put(fetchUserTypeFailure(error));
  } else {

    yield put(fetchUserTypeSuccess(data.data.result));
  }
}

function* UserType() {
  yield all([
    takeLatest(FETCH_USERTYPE, fetchUserTypeHandler),
    takeLatest(FETCH_EMPLOYEE_USERTYPE, fetchEmployeeUserTypeHandler)

  ]);
}

export default UserType;

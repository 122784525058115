import React, { Fragment } from "react";
import { Helmet } from 'react-helmet';
import { any, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Drawer, Footer, Header } from '../components';
import { USER_SCHOOL_ADMIN, USER_SCHOOL_SUB_ADMIN, USER_PARENT, USER_STUDENT, USER_TEACHER } from '../constants';
import { isMobile } from 'react-device-detect';

// Layout
import AppHeader from "../Layout/AppHeader";
import AppSidebar from "../Layout/AppSidebar/";
import AppFooter from "../Layout/AppFooter/";

// Theme Options
import ThemeOptions from "../Layout/ThemeOptions/";

const PublicLayout = ({
  children, title,
}) => {
  const {
    activeDrawerMenu, drawer, user,
  } = useSelector((store) => ({
    activeDrawerMenu: store.app.activeDrawerMenu,
    drawer: store.app.drawer,
    user: store.user.userDetail,
  }));
  console.log("activeDrawerMenu",activeDrawerMenu)
  const isDrawerOpen = drawer === 'visible';
  const drawerClass = isDrawerOpen ? '' : 'closed-sidebar';
  const schoolName = [USER_SCHOOL_ADMIN, USER_SCHOOL_SUB_ADMIN, USER_PARENT, USER_STUDENT, USER_TEACHER].includes(user?.user_type_id) ? user.name.capitalizeEachLetter() : '';

  /*return (
    <div className={`app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header ${drawerClass}`}>
      <Helmet>
        <title>{`${title} - Skulman`}</title>
      </Helmet>
      <Header isDrawerOpen={isDrawerOpen} title={schoolName} user={user} />
      <div className="app-main">
        <Drawer user={user} activeDrawerMenu={activeDrawerMenu} />
        <div className="app-main__outer">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};*/

  return (
    <Fragment>
      {/*<ThemeOptions />*/}
      <AppHeader user={user}  backgroundColor="bg-night-sky sidebar-text-light" />
      {/* !isMobile ? (  <AppHeader user={user}/>):''*/}
      {/*<div className="app-main" style={isMobile ? ({ paddingTop: 0 }) : {}}>*/}
      <div className="app-main" >
        <div>
          {!isMobile ? (
            <AppSidebar user={user} activeDrawerMenu={activeDrawerMenu} backgroundColor="bg-night-sky sidebar-text-light" />
          ) : ''}
         
        </div>
        <div className="app-main__outer">
          {children}
          <div>
            {isMobile ? (<AppFooter user={user} activeDrawerMenu={activeDrawerMenu}/>) : ""}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PublicLayout.propTypes = {
  children: any.isRequired,
  title: string.isRequired,
};

export default PublicLayout;

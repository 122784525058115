import { createAction } from 'redux-actions';

export const ADD_QUALIFICATION = 'ADD_QUALIFICATION';
export const addQualification = createAction(ADD_QUALIFICATION);

export const ADD_QUALIFICATION_SUCCESS = 'ADD_QUALIFICATION_SUCCESS';
export const addQualificationSuccess = createAction(ADD_QUALIFICATION_SUCCESS);

export const DELETE_QUALIFICATION = 'DELETE_QUALIFICATION';
export const deleteQualification = createAction(DELETE_QUALIFICATION);

export const DELETE_QUALIFICATION_SUCCESS = 'DELETE_QUALIFICATION_SUCCESS';
export const deleteQualificationSuccess = createAction(DELETE_QUALIFICATION_SUCCESS);

export const FETCH_QUALIFICATION = 'FETCH_QUALIFICATION';
export const fetchQualification = createAction(FETCH_QUALIFICATION);

export const FETCH_QUALIFICATION_SUCCESS = 'FETCH_QUALIFICATION_SUCCESS';
export const fetchQualificationSuccess = createAction(FETCH_QUALIFICATION_SUCCESS);

export const EDIT_QUALIFICATION = 'EDIT_QUALIFICATION';
export const editQualification = createAction(EDIT_QUALIFICATION);

export const EDIT_QUALIFICATION_SUCCESS = 'EDIT_QUALIFICATION_SUCCESS';
export const editQualificationSuccess = createAction(EDIT_QUALIFICATION_SUCCESS);

export const FETCH_SINGLE_QUALIFICATION = 'FETCH_SINGLE_QUALIFICATION';
export const fetchSingleQualification = createAction(FETCH_SINGLE_QUALIFICATION);

export const FETCH_SINGLE_QUALIFICATION_SUCCESS = 'FETCH_SINGLE_QUALIFICATION_SUCCESS';
export const fetchSingleQualificationSuccess = createAction(FETCH_SINGLE_QUALIFICATION_SUCCESS);

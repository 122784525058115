import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_EDUCATION_FAILURE,
  ADD_EDUCATION_REQUESTED,
  ADD_EDUCATION_SUCCESS,
  DELETE_EDUCATION_FAILURE,
  DELETE_EDUCATION_REQUESTED,
  DELETE_EDUCATION_SUCCESS,
  FETCH_EDUCATION_FAILURE,
  FETCH_EDUCATION_REQUESTED,
  FETCH_EDUCATION_SUCCESS,
  FETCH_SINGLE_EDUCATION_FAILURE,
  FETCH_SINGLE_EDUCATION_REQUESTED,
  FETCH_SINGLE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION_FORM
} from '../actions/education-action-type';

const initialState = {
  createEducationStatus: 'pending',
  deleteEducationStatus: 'pending',
  fetchSingleEducationStatus: 'pending',
  fetchEducationsStatus: 'pending',
  form: {
    employee_id: '',
    institution: '',
    subject: '',
    degree: '',
    grade: '',
    from_date: '0000-00-00 00:00:00',
    to_date: '0000-00-00 00:00:00',
  },
  educations: [],
};

export default function Educations(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_EDUCATION_FAILURE:
      return {
        ...state,
        createEducationStatus: 'failure',
      };

    case ADD_EDUCATION_REQUESTED:
      return {
        ...state,
        createEducationStatus: 'creating',
      };

    case ADD_EDUCATION_SUCCESS:
      return {
        ...state,
        // class_list: payload.class_list,
        createEducationStatus: 'success',
       // file: encodeURI(payload.image),
       // profile_picture: encodeURI(payload.image),
      };

    case DELETE_EDUCATION_FAILURE:
      return {
        ...state,
        deleteEducationStatus: 'failure',
      };

    case DELETE_EDUCATION_REQUESTED:
      return {
        ...state,
        deleteEducationStatus: 'deleting',
      };

    case DELETE_EDUCATION_SUCCESS:
      return {
        ...state,
        deleteEducationStatus: 'success',
        Educations: [...state.Educations].filter((educationItem) => !(payload.toString().includes(educationItem.id.toString()))),
      };

    case FETCH_EDUCATION_FAILURE:
      return {
        ...state,
        fetchEducationsStatus: 'failure',
      };

    case FETCH_EDUCATION_REQUESTED:
      return {
        ...state,
        fetchEducationsStatus: 'fetching',
      };

    case FETCH_EDUCATION_SUCCESS:
      return {
        ...state,
        fetchEducationsStatus: 'success',
        Educations: payload,
      };

    case FETCH_SINGLE_EDUCATION_FAILURE:
      return {
        ...state,
        fetchSingleEducationStatus: 'failure',
      };

    case FETCH_SINGLE_EDUCATION_REQUESTED:
      return {
        ...state,
        fetchSingleEducationStatus: 'fetching',
      };

    case FETCH_SINGLE_EDUCATION_SUCCESS:
      return {
        ...state,
        fetchSingleEducationStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.image),
          profile_picture: encodeURI(payload.image),
        },
      };

    case UPDATE_EDUCATION_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import {createAction} from "redux-actions";

export const ADD_SEQUENCE = 'ADD_SEQUENCE';
export const addSequence = createAction(ADD_SEQUENCE);

export const ADD_SEQUENCE_FAILURE = 'ADD_SEQUENCE_FAILURE';
export const addSequenceFailure = createAction(ADD_SEQUENCE_FAILURE);

export const ADD_SEQUENCE_SUCCESS = 'ADD_SEQUENCE_SUCCESS';
export const addSequenceSuccess = createAction(ADD_SEQUENCE_SUCCESS);

export const ADD_SEQUENCE_REQUESTED = 'ADD_SEQUENCE_REQUESTED';
export const addSequenceRequested = createAction(ADD_SEQUENCE_REQUESTED);

export const DELETE_SEQUENCE = 'DELETE_SEQUENCE';
export const deleteSequence = createAction(DELETE_SEQUENCE);

export const DELETE_SEQUENCE_FAILURE = 'DELETE_SEQUENCE_FAILURE';
export const deleteSequenceFailure = createAction(DELETE_SEQUENCE_FAILURE);

export const DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_SUCCESS';
export const deleteSequenceSuccess = createAction(DELETE_SEQUENCE_SUCCESS);

export const DELETE_SEQUENCE_REQUESTED = 'DELETE_SEQUENCE_REQUESTED';
export const deleteSequenceRequested = createAction(DELETE_SEQUENCE_REQUESTED);

export const FETCH_SEQUENCE = 'FETCH_SEQUENCE';
export const fetchSequence = createAction(FETCH_SEQUENCE);

export const FETCH_SEQUENCE_FAILURE = 'FETCH_SEQUENCE_FAILURE';
export const fetchSequenceFailure = createAction(FETCH_SEQUENCE_FAILURE);

export const FETCH_SEQUENCE_SUCCESS = 'FETCH_SEQUENCE_SUCCESS';
export const fetchSequenceSuccess = createAction(FETCH_SEQUENCE_SUCCESS);

export const FETCH_SEQUENCE_REQUESTED = 'FETCH_SEQUENCE_REQUESTED';
export const fetchSequenceRequested = createAction(FETCH_SEQUENCE_REQUESTED);

export const EDIT_SEQUENCE = 'EDIT_SEQUENCE';
export const editSequence = createAction(EDIT_SEQUENCE);

export const EDIT_SEQUENCE_FAILURE = 'EDIT_SEQUENCE_FAILURE';
export const editSequenceFailure = createAction(EDIT_SEQUENCE_FAILURE);

export const EDIT_SEQUENCE_SUCCESS = 'EDIT_SEQUENCE_SUCCESS';
export const editSequenceSuccess = createAction(EDIT_SEQUENCE_SUCCESS);

export const EDIT_SEQUENCE_REQUESTED = 'EDIT_SEQUENCE_REQUESTED';
export const editSequenceRequested = createAction(EDIT_SEQUENCE_REQUESTED);

export const FETCH_SINGLE_SEQUENCE = 'FETCH_SINGLE_SEQUENCE';
export const fetchSingleSequence = createAction(FETCH_SINGLE_SEQUENCE);

export const FETCH_SINGLE_SEQUENCE_FAILURE = 'FETCH_SINGLE_SEQUENCE_FAILURE';
export const fetchSingleSequenceFailure = createAction(FETCH_SINGLE_SEQUENCE_FAILURE);

export const FETCH_SINGLE_SEQUENCE_SUCCESS = 'FETCH_SINGLE_SEQUENCE_SUCCESS';
export const fetchSingleSequenceSuccess = createAction(FETCH_SINGLE_SEQUENCE_SUCCESS);

export const FETCH_SINGLE_SEQUENCE_REQUESTED = 'FETCH_SINGLE_SEQUENCE_REQUESTED';
export const fetchSingleSequenceRequested = createAction(FETCH_SINGLE_SEQUENCE_REQUESTED);

export const UPDATE_SEQUENCE_FORM = 'UPDATE_SEQUENCE_FORM';
export const updateSequenceForm = createAction(UPDATE_SEQUENCE_FORM);
import React from 'react';
import { useDispatch } from 'react-redux';
import { shape, string } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from './button';
import { toggleDrawerMenu } from '../actions/app-action-types';
import { USER_SUPER_ADMIN, 
        USER_SCHOOL_ADMIN, 
        USER_SCHOOL_SUB_ADMIN,
        USER_TEACHER,
        SCHEDULE_VIEW_SCHEDULE,
        SCHOOLWALL_VIEW_WALL, 
        SCHEDULE, SCHOOLWALL, 
        TUITION_ENROLLMENT, 
        TUITION_ENROLLMENT_LIST, 
        TUITION_PAYMENT_LIST, 
        TUITION_PAYMENT,
        GRADES_STUDENTS_VIEW_STUDENT_LIST, 
        GRADES_STUDENTS,
        GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST, 
        GRADES_ATTENDANCEMANAGEMENT,
        GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,
        GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,
        GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,
        GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST, 
        GRADES_MARKMANAGEMENT ,
        GRADES_MARKREPORT_GRADES__MARK_REPORT,
        GRADES_MARKREPORT, 
        FINANCE_ENTRIES,
        FINANCE_ENTRIES_LIST, 
        FINANCE_OUTFLOWS_LIST,
        FINANCE_OUTFLOWS, 
        DEPARTMENT_LIST, 
        DEPARTMENT, 
        SUBJECT_LIST, 
        SUBJECT, 
        SCHOOLSTAFF_TEACHERS_LIST, 
        SCHOOLSTAFF_TEACHERS, 
        REPORTING_ENROLLMENT_FINANCIAL_REPORT_VIEW_REPORT, 
        REPORTING_ENROLLMENT_FINANCIAL_REPORT, 
        REPORTING_ENTRIESDAILYREPORT_VIEW_REPORT, 
        REPORTING_ENTRIESDAILYREPORT, 
        PEDAGOGICALFOLLOWUP_CHAPTER, 
        PEDAGOGICALFOLLOWUP_CHAPTER_LIST, 
        PEDAGOGICALFOLLOWUP_PART, 
        PEDAGOGICALFOLLOWUP_PART_LIST, 
        PEDAGOGICALFOLLOWUP_FORECAST, 
        PEDAGOGICALFOLLOWUP_FORECAST_LIST, 
        PEDAGOGICALFOLLOWUP_PROGRESSION, 
        PEDAGOGICALFOLLOWUP_PROGRESSION_LIST, 
        PEDAGOGICALFOLLOWUP_REPORT, 
        PEDAGOGICALFOLLOWUP_REPORT_VIEW, 
        SETTINGS_APPRECIATION_LIST, 
        SETTINGS_CLASSES, 
        SETTINGS_SCHOOLINFORMATION,
        SETTINGS_SCHOOLINFORMATION_VIEW, 
        SETTINGS_SCHOOLYEAR, 
        SETTINGS_SCHOOLYEAR_LIST, 
        SETTINGS_HONNORROLL, 
        SETTINGS_HONNORROLL_VIEW, 
        SETTINGS_QUALIFICATION, 
        SETTINGS_QUALIFICATION_ADD, 
        SETTINGS_GRADES, 
        SETTINGS_GRADES_LIST, 
        SETTINGS_WEEKS, 
        SETTINGS_WEEKS_LIST, 
        SETTINGS_CLASSES_LIST, 
        SETTINGS_INSTALLMENT, 
        SETTINGS_INSTALLMENT_LIST, 
        SETTINGS_APPRECIATION, 
        SETTINGS_DISCOUNTS, 
        SETTINGS_DISCOUNTS_LIST, 
        SETTINGS_PAYMENTTYPE, 
        SETTINGS_PAYMENTTYPE_LIST, 
        SETTINGS_COSTITEM, 
        SETTINGS_COSTITEM_LIST, 
        SETTINGS_COSTREASON, 
        SETTINGS_COSTREASON_LIST, 
        SETTINGS_SOURCEOFINCOME, 
        SETTINGS_SOURCEOFINCOME_LIST, 
        SETTINGS_INCOMEREASON, 
        SETTINGS_COSTREASON_ADD,
        SETTINGS_INCOMEFORECAST, 
        SETTINGS_INCOMEFORECAST_LIST, 
        SETTINGS_EXPENSESFORECAST, 
        SETTINGS_EXPENSESFORECAST_LIST,
        SETTINGS_OTHERPARAMETERS, 
        SETTINGS_OTHERPARAMETERS_VIEW, 
        SETTINGS_LEVEL, 
        SETTINGS_LEVEL_ADD, 
        SETTINGS_SUBJECT_TYPE, 
        SETTINGS_SUBJECT_TYPE_ADD, 
        SETTINGS_SKILLS, 
        SETTINGS_SKILLS_ADD,
        SETTINGS_MARK_PRIMARY_RULE, 
        SETTINGS_MARK_PRIMARY_RULE_ADD
 } from '../constants';
 import { isMobile } from 'react-device-detect';

const Drawer_new = ({
  activeDrawerMenu, user,toggleMobileSidebar,toggleMobileSidebar_false
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [t] = useTranslation('skullman');
  const [clicked, setClicked] = React.useState(false);
  const tutorStudentSelected = JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) ? JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) : [];

  const onClick = (payload) => {
    const activeMenu = payload === activeDrawerMenu ? null : payload;
    
    dispatch(toggleDrawerMenu(activeMenu));
  };

  const onLinkClick = (event) => {
    dispatch(toggleDrawerMenu(null));
    setClicked(true);
   // toggleMobileSidebar();
   //toggleMobileSidebar_false();
    event.preventDefault();
  };

  const getActive = (url) => {
    const activePathname = location.pathname;

    return activePathname.indexOf(url) > -1;
  };

  const onDropDownHide = () => {
    
  };

  React.useEffect(() => {
    
    toggleMobileSidebar();
   //toggleMobileSidebar_false();
  }, [ clicked]);

  return (

   
        <div>
          <ul className="vertical-nav-menu mt-4">
          {user?.permissions?.find(perm => perm === SCHOOLWALL_VIEW_WALL || perm === SCHOOLWALL)
                            && (
            <li>
             
              <Link
                to="/school-wall"
                className={getActive('/school-wall') ? 'mm-active' : ''}
                onClick={location.pathname === '//school-wall' ? onLinkClick : onDropDownHide}
              >
                <i className="metismenu-icon pe-7s-home" />
                {t('menu.home')}
              </Link>
            </li>
                    )}

            {
             /* (user?.user_type_id === USER_SCHOOL_ADMIN
                            || user?.user_type_id === USER_SCHOOL_SUB_ADMIN) && (*/
              (user?.user_type_id === 9999) && (
                <li className={`has-dropdown ${activeDrawerMenu === 'employee' ? 'mm-active' : ''}`}>
                  <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('employee')}>
                    <i className="metismenu-icon pe-7s-id" />
                    {t('menu.hrms')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'employee' ? 'mm-show' : ''}`}>
                    <li>
                      <Link
                          to="/employee"
                          className={location.pathname === '/employee' ? 'mm-active' : ''}
                          onClick={location.pathname === '/employee' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.employee')}
                        </Link>
                    </li>
                    <li>
                      <Link
                          to="/employee-leave"
                          className={location.pathname === '/employee-leave' ? 'mm-active' : ''}
                          onClick={location.pathname === '/employee-leave' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.leave')}
                        </Link>
                    </li>

                    <li>
                      <Link
                          to="/admin-attendance"
                          className={location.pathname === '/admin-attendance' ? 'mm-active' : ''}
                          onClick={location.pathname === '/admin-attendance' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.attendance.admin')}
                        </Link>
                    </li>

                    <li>
                      <Link
                          to="/employee-salary"
                          className={location.pathname === '/employee-salary' ? 'mm-active' : ''}
                          onClick={location.pathname === '/employee-salary' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.salary')}
                        </Link>
                    </li>
                  </ul>
                </li>
                            )}

            {/*(user?.user_type_id === USER_SCHOOL_ADMIN
                            || user?.user_type_id === USER_TEACHER
                            || user?.user_type_id === USER_SCHOOL_SUB_ADMIN
                            || user?.user_type_id === USER_SUPER_ADMIN) */
                            /*user?.permissions?.find(perm => perm === SCHEDULE_VIEW_SCHEDULE) ? user?.permissions?.find(perm => perm === SCHEDULE_VIEW_SCHEDULE) :
                            (user?.user_type_id === USER_SCHOOL_ADMIN
                              || user?.user_type_id === USER_TEACHER
                              || user?.user_type_id === USER_SCHOOL_SUB_ADMIN
                              || user?.user_type_id === USER_SUPER_ADMIN) */
                              user?.permissions?.find(perm => perm === SCHEDULE_VIEW_SCHEDULE || perm === SCHEDULE)
                            && (
                            <li>
                <Link
                                to="/schedule"
                                className={getActive('/schedule') ? 'mm-active' : ''}
                                onClick={location.pathname === '/schedule' ? onLinkClick : onDropDownHide}
                              >
                                <i className="metismenu-icon pe-7s-timer" />
                                {t('menu.schedule')}
                              </Link>
              </li>
            )}

            {user?.user_type_id === USER_SUPER_ADMIN && (
              <li>
                <Link
                  to="/schools"
                  className={getActive('/schools') ? 'mm-active' : ''}
                  onClick={location.pathname === '/schools' ? onLinkClick : onDropDownHide}
                >
                  <i className="metismenu-icon pe-7s-display2" />
                  {t('menu.schools')}
                </Link>
              </li>
            )}

            {( user?.permissions?.find(perm => perm === TUITION_ENROLLMENT_LIST || perm === TUITION_ENROLLMENT || perm === TUITION_PAYMENT_LIST || perm === TUITION_PAYMENT))
                            && (
              <li className={`has-dropdown ${activeDrawerMenu === 'tuition' ? 'mm-active' : ''}`}>
                <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('tuition')}>
                  <i className="metismenu-icon pe-7s-id" />
                  {t('menu.tuition')}
                  <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                </a>
                <ul className={`mm-collapse ${activeDrawerMenu === 'tuition' ? 'mm-show' : ''}`}>
                {( user?.permissions?.find(perm => perm === TUITION_ENROLLMENT_LIST || perm === TUITION_ENROLLMENT))
                            && (
                  <li>
                  
                    <Link
                      to="/enrollments"
                      className={getActive('/enrollments') ? 'mm-active' : ''}
                      onClick={location.pathname === '/enrollments' ? onLinkClick : onDropDownHide}
                    >
                      <i className="metismenu-icon" />
                      {t('menu.tuitionSub.enrollment')}
                    </Link>
                  </li>
                            )}
                            {( user?.permissions?.find(perm => perm === TUITION_PAYMENT_LIST || perm === TUITION_PAYMENT))
                            && (
                  <li>
                    {(user?.user_type_id === 5 || user?.user_type_id === 6 || user?.user_type_id === 7) ?( 
                      <>
                      { (tutorStudentSelected?.value)&&(
                    <Link
                    to={`/payment-info/${tutorStudentSelected?.value}`}
                    className={getActive(`/payment-info/${tutorStudentSelected?.value}`) ? 'mm-active' : ''}
                    onClick={location.pathname === `/payment-info/${tutorStudentSelected?.value}`  ? onLinkClick : onDropDownHide}
                  >
                    <i className="metismenu-icon" />
                    {t('menu.tuitionSub.payment')}
                  </Link>)
                  }</>)
                    :
                    (user?.user_type_id !== 5 && user?.user_type_id !== 6 && user?.user_type_id !== 7) ?(
                    <Link
                      to="/payment"
                      className={getActive('/payment') ? 'mm-active' : ''}
                      onClick={location.pathname === '/payment' ? onLinkClick : onDropDownHide}
                    >
                      <i className="metismenu-icon" />
                      {t('menu.tuitionSub.payment')}
                    </Link>):<div></div>
}
                  </li>
                            )}
                </ul>
              </li>
            )}

{( user?.permissions?.find(perm => perm === GRADES_STUDENTS_VIEW_STUDENT_LIST || perm === GRADES_STUDENTS || 
perm === GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST || perm === GRADES_ATTENDANCEMANAGEMENT ||
perm === GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST || perm === GRADES_MARKMANAGEMENT ||
perm === GRADES_MARKREPORT_GRADES__MARK_REPORT || perm === GRADES_MARKREPORT))
                            && (
                            <>
                <li className={`has-dropdown ${activeDrawerMenu === 'grades-management' ? 'mm-active' : ''}`}>
                                <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('grades-management')}>
                    <i className="metismenu-icon pe-7s-note2" />
                    {t('menu.marks')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                                <ul className={`mm-collapse ${activeDrawerMenu === 'grades-management' ? 'mm-show' : ''}`}>
                                {( user?.permissions?.find(perm => perm === GRADES_STUDENTS_VIEW_STUDENT_LIST || perm === GRADES_STUDENTS))
                            && (
                              <>
                              {(user?.user_type_id === 5 || user?.user_type_id === 6 || user?.user_type_id === 7) ?( 
<div>
<li>
  {/*
                                    <Link
                        to={`/student-details/${user?.user?.student_id}`}
                        className={location.pathname === `/student-details/${user?.user?.student_id}` ? 'mm-active' : ''}
                        onClick={location.pathname === `/student-details/${user?.user?.student_id}` ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.gradesSub.marksManagement')}
                      </Link>
                              */}
                               { (tutorStudentSelected?.value)&&(
                    <Link
                    to={`/student-details/${tutorStudentSelected?.value}`}
                    className={getActive(`/student-details/${tutorStudentSelected?.value}`) ? 'mm-active' : ''}
                    onClick={location.pathname === `/student-details/${tutorStudentSelected?.value}`  ? onLinkClick : onDropDownHide}
                  >
                    <i className="metismenu-icon" />
                        {t('menu.gradesSub.marksManagement')}
                  </Link>)
                  }
                                  </li>
</div>
                              ):
                    <>          
{(user?.user_type_id === 4 ) ?( 
<>
<Link
                    to={`/student-details/${user?.user?.userId}`}
                    className={getActive(`/student-details/${user?.user?.userId}`) ? 'mm-active' : ''}
                    onClick={location.pathname === `/student-details/${user?.user?.userId}`  ? onLinkClick : onDropDownHide}
                  >
                    <i className="metismenu-icon" />
                        {t('menu.gradesSub.marksManagement')}
                  </Link>                      
                             
                              </>
 
                             )
                             : (
                    <li>
                                    <Link
                        to="/classes-list"
                        className={location.pathname === '/classes-list' ? 'mm-active' : ''}
                        onClick={location.pathname === '/classes-list' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.gradesSub.students')}
                      </Link>
                                  </li>
                              )}
                              </>
                              }
</>
                            )}
                             
 {( user?.permissions?.find(perm => perm === GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST || perm === GRADES_MARKMANAGEMENT))
                            && (
                             
                    <li>
                                    <Link
                        to="/marks-management"
                        className={location.pathname === '/marks-management' ? 'mm-active' : ''}
                        onClick={location.pathname === '/marks-management' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.gradesSub.marksManagement')}
                      </Link>
                                  </li>
                              )}
                            

{( user?.permissions?.find(perm => perm === GRADES_MARKREPORT_GRADES__MARK_REPORT || perm === GRADES_MARKREPORT))
                            && (
                      <li>
                        <Link
                                      to="/marks-report"
                                      className={location.pathname === '/marks-report' ? 'mm-active' : ''}
                                      onClick={location.pathname === '/marks-report' ? onLinkClick : onDropDownHide}
                                    >
                                      <i className="metismenu-icon" />
                                      {t('menu.gradesSub.marksReport')}
                                    </Link>
                    </li>
                    )}

{( user?.permissions?.find(perm => perm === GRADES_MARKREPORT_GRADES__MARK_REPORT || perm === GRADES_MARKREPORT))
                            && (
                      <li>
                        <Link
                                      to="/marks-primary-report"
                                      className={location.pathname === '/marks-primary-report' ? 'mm-active' : ''}
                                      onClick={location.pathname === '/marks-primary-report' ? onLinkClick : onDropDownHide}
                                    >
                                      <i className="metismenu-icon" />
                                      {t('menu.gradesSub.marksPrimaryReport')}
                                    </Link>
                    </li>
                    )}
                  </ul>
                              </li>
              </>
            )}
            { ( user?.permissions?.find(perm => perm === GRADES_ATTENDANCEMANAGEMENT
             || perm === GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST
             || perm === GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE
             || perm === GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE
             || perm === GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE
             ))
                            && (
                            <>
                <li className={`has-dropdown ${activeDrawerMenu === 'attendance-management' ? 'mm-active' : ''}`}>
                                <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('attendance-management')}>
                    <i className="metismenu-icon pe-7s-users" />
                    {t('menu.attendanceMenu')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                                <ul className={`mm-collapse ${activeDrawerMenu === 'attendance-management' ? 'mm-show' : ''}`}>
                    <li>
                                    <Link
                       to="/attendance-at-lesson"
                       className={location.pathname === '/attendance-at-lesson' ? 'mm-active' : ''}
                       onClick={location.pathname === '/attendance-at-lesson' ? onLinkClick : onDropDownHide}
                     >
                        <i className="metismenu-icon" />
                        {t('menu.attendanceManagementSub.attendanceatlesson')}
                      </Link>
                                  </li>
                    <li>
                                    <Link
                        to="/attendance-at-exam"
                        className={location.pathname === '/attendance-at-exam' ? 'mm-active' : ''}
                        onClick={location.pathname === '/attendance-at-exam' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.attendanceManagementSub.attendanceatexam')}
                      </Link>
                                  </li>
                   
                  </ul>
                              </li>
              </>
            )}


{( user?.permissions?.find(perm => perm === FINANCE_ENTRIES || perm === FINANCE_ENTRIES_LIST || perm === FINANCE_OUTFLOWS_LIST || perm === FINANCE_OUTFLOWS))
                           && (

              <>
                <li className={`has-dropdown ${activeDrawerMenu === 'finance' ? 'mm-active' : ''}`}>
                  <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('finance')}>
                    <i className="metismenu-icon pe-7s-cash" />
                    {t('menu.finance')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'finance' ? 'mm-show' : ''}`}>
                  {( user?.permissions?.find(perm => perm === FINANCE_OUTFLOWS_LIST || perm === FINANCE_OUTFLOWS))
                            && (
                    <li>
                      <Link
                          to="/outflows"
                          className={location.pathname === '/outflows' ? 'mm-active' : ''}
                          onClick={location.pathname === '/outflows' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.financeSub.outflows')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === FINANCE_ENTRIES || perm === FINANCE_ENTRIES_LIST))
                            && (
                    <li>
                      <Link
                          to="/entries"
                          className={location.pathname === '/entries' ? 'mm-active' : ''}
                          onClick={location.pathname === '/entries' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.financeSub.entries')}
                        </Link>
                    </li>
                            )}
                  </ul>
                </li>
                </>
)}

                {/* <li className={`has-dropdown ${activeDrawerMenu === 'classes-management' ? 'mm-active' : ''}`}>
                  <a href='javascript:void(0);' className="dropdownToggle" onClick={() => onClick('classes-management')}>
                    <i className="metismenu-icon pe-7s-display2" />
                    Classes Management
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'classes-management' ? 'mm-show' : ''}`}>

                    <li>
                      <Link to="/classes-schedule" className={location.pathname === '/classes-schedule' ? 'mm-active' : ''} onClick={location.pathname === '/classes-schedule' ? onLinkClick : onDropDownHide}>
                        <i className="metismenu-icon" />
                        Schedule
                      </Link>
                    </li>
                  </ul>
                </li> */}
               {/* <li>
                  <Link
                    to="/sub-user"
                    className={location.pathname === '/sub-user' ? 'mm-active' : ''}
                    onClick={location.pathname === '/sub-user' ? onLinkClick : onDropDownHide}
                  >
                    <i className="metismenu-icon pe-7s-user" />
                    {t('menu.users')}
                  </Link>
              </li>*/}
{(user?.user_type_id === USER_SCHOOL_ADMIN
                            || user?.user_type_id === USER_SCHOOL_SUB_ADMIN) && (
                <li className={`has-dropdown ${activeDrawerMenu === 'sub-user' ? 'mm-active' : ''}`}>
                  <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('sub-user')}>
                    <i className="metismenu-icon pe-7s-user" />
                    {t('menu.users')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'sub-user' ? 'mm-show' : ''}`}>
                    <li>
                      <Link
                          to="/sub-user"
                          className={location.pathname === '/sub-user' ? 'mm-active' : ''}
                          onClick={location.pathname === '/sub-user' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.users')}
                        </Link>
                    </li>
                    <li>
                      <Link
                          to="/role-user"
                          className={location.pathname === '/role-user' ? 'mm-active' : ''}
                          onClick={location.pathname === '/role-user' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.role')}
                        </Link>
                    </li>
                  </ul>
                </li>
                            )}

{( user?.permissions?.find(perm => perm === DEPARTMENT_LIST || perm === DEPARTMENT))
                            && (
                <li>
                  <Link
                    to="/department"
                    className={location.pathname === '/department' ? 'mm-active' : ''}
                    onClick={location.pathname === '/department' ? onLinkClick : onDropDownHide}
                  >
                    <i className="metismenu-icon pe-7s-portfolio" />
                    {t('menu.department')}
                  </Link>
                </li>
                            )}
                       {( user?.permissions?.find(perm => perm === SUBJECT_LIST || perm === SUBJECT))
                            && (    
                <li>
                  <Link
                    to="/subjects"
                    className={location.pathname === '/subjects' ? 'mm-active' : ''}
                    onClick={location.pathname === '/subjects' ? onLinkClick : onDropDownHide}
                  >
                    <i className="metismenu-icon pe-7s-science" />
                    {t('menu.subjects')}
                  </Link>
                </li>
                            )}
                             {( user?.permissions?.find(perm => perm === SCHOOLSTAFF_TEACHERS_LIST || perm === SCHOOLSTAFF_TEACHERS))
                            && (  
                <li className={`has-dropdown ${activeDrawerMenu === 'staff' ? 'mm-active' : ''}`}>
                  <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('staff')}>
                    <i className="metismenu-icon pe-7s-users" />
                    {t('menu.schoolStaff')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'staff' ? 'mm-show' : ''}`}>
                    <li>
                      <Link
                          to="/teacher"
                          className={location.pathname === '/teacher' ? 'mm-active' : ''}
                          onClick={location.pathname === '/teacher' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.schoolStaffSub.teachers')}
                        </Link>
                    </li>
                  </ul>
                </li>
                            )}
                {/* <li className={`has-dropdown ${activeDrawerMenu === 'student-management' ? 'mm-active' : ''}`}>
                  <a href='javascript:void(0);' className="dropdownToggle" onClick={() => onClick('student-management')}>
                    <i className="metismenu-icon pe-7s-display2" />
                    Student Management
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'student-management' ? 'mm-show' : ''}`}>
                    <li>
                      <Link to='/school-students' className={getActive('/school-students') ? 'mm-active' : ''} onClick={location.pathname === '/school-students' ? onLinkClick : onDropDownHide}>
                        <i className="metismenu-icon" />
                        Students
                      </Link>
                    </li>
                    <li>
                      <Link to="/attendance" className={location.pathname === '/attendance' ? 'mm-active' : ''} onClick={location.pathname === '/attendance' ? onLinkClick : onDropDownHide}>
                        <i className="metismenu-icon" />
                        Attendance
                      </Link>
                    </li>
                    <li>
                      <Link to="/send-message" className={location.pathname === '/send-message' ? 'mm-active' : ''} onClick={location.pathname === '/send-message' ? onLinkClick : onDropDownHide}>
                        <i className="metismenu-icon" />
                        Send Broadcast
                      </Link>
                    </li>
                  </ul>
                </li> */}
                 {( user?.permissions?.find(perm => perm === REPORTING_ENROLLMENT_FINANCIAL_REPORT_VIEW_REPORT || perm === REPORTING_ENROLLMENT_FINANCIAL_REPORT ||
                 perm === REPORTING_ENTRIESDAILYREPORT_VIEW_REPORT || perm === REPORTING_ENTRIESDAILYREPORT))
                            && ( 
                <li className={`has-dropdown ${activeDrawerMenu === 'reporting' ? 'mm-active' : ''}`}>
                  <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('reporting')}>
                    <i className="metismenu-icon pe-7s-graph2" />
                    {t('menu.reporting')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'reporting' ? 'mm-show' : ''}`}>
                  {( user?.permissions?.find(perm => perm === REPORTING_ENROLLMENT_FINANCIAL_REPORT_VIEW_REPORT || perm === REPORTING_ENROLLMENT_FINANCIAL_REPORT))
                            && ( 
                    <li>
                      <Link
                          to="/finance-reports"
                          className={location.pathname === '/finance-reports' ? 'mm-active' : ''}
                          onClick={location.pathname === '/finance-reports' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.reportingSub.enrollmentFinancialReport')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === REPORTING_ENTRIESDAILYREPORT_VIEW_REPORT || perm === REPORTING_ENTRIESDAILYREPORT))
                            && ( 
                    <li>
                      <Link
                          to="/daily-entries"
                          className={location.pathname === '/daily-entries' ? 'mm-active' : ''}
                          onClick={location.pathname === '/daily-entries' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.reportingSub.entriesDailyReport')}
                        </Link>
                    </li>
                            )}
                  </ul>
                </li>
                            )}
            
                            
            {( user?.permissions?.find(perm => perm === PEDAGOGICALFOLLOWUP_CHAPTER || perm === PEDAGOGICALFOLLOWUP_CHAPTER_LIST ||
            perm === PEDAGOGICALFOLLOWUP_PART || perm === PEDAGOGICALFOLLOWUP_PART_LIST ||
            perm === PEDAGOGICALFOLLOWUP_FORECAST || perm === PEDAGOGICALFOLLOWUP_FORECAST_LIST ||
            perm === PEDAGOGICALFOLLOWUP_PROGRESSION || perm === PEDAGOGICALFOLLOWUP_PROGRESSION_LIST ||
            perm === PEDAGOGICALFOLLOWUP_REPORT || perm === PEDAGOGICALFOLLOWUP_REPORT_VIEW))
                            && ( 
                            <>
                {/* <li>
                  <Link
                    to="/school-wall"
                    className={location.pathname === '/school-wall' ? 'mm-active' : ''}
                    onClick={location.pathname === '/school-wall' ? onLinkClick : onDropDownHide}
                  >
                    <i className="metismenu-icon pe-7s-display2" />
                    School Wall
                  </Link>
                </li> */}

                <li className={`has-dropdown ${activeDrawerMenu === 'pedagogical-topics' ? 'mm-active' : ''}`}>
                                <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('pedagogical-topics')}>
                    <i className="metismenu-icon pe-7s-study" />
                    {t('menu.pedagogicalFollowup')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                                <ul className={`mm-collapse ${activeDrawerMenu === 'pedagogical-topics' ? 'mm-show' : ''}`}>
                                {/* {( user?.permissions?.find(perm => perm === PEDAGOGICALFOLLOWUP_CHAPTER || perm === PEDAGOGICALFOLLOWUP_CHAPTER_LIST))
                            && ( 
                    <li>
                                    <Link
                        to="/pedagogical-chapters"
                        className={location.pathname === '/pedagogical-chapters' ? 'mm-active' : ''}
                        onClick={location.pathname === '/pedagogical-chapters' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.pedagogicalFollowupSub.chapters')}
                      </Link>
                                  </li>
                            )} */}
                              {/* {( user?.permissions?.find(perm => perm === PEDAGOGICALFOLLOWUP_PART || perm === PEDAGOGICALFOLLOWUP_PART_LIST))
                            && ( 
                    <li>
                                    <Link
                        to="/pedagogical-part"
                        className={location.pathname === '/pedagogical-part' ? 'mm-active' : ''}
                        onClick={location.pathname === '/pedagogical-part' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.pedagogicalFollowupSub.part')}
                      </Link>
                                  </li>
)} */}
{( user?.permissions?.find(perm => perm === PEDAGOGICALFOLLOWUP_FORECAST || perm === PEDAGOGICALFOLLOWUP_FORECAST_LIST))
                            && ( 
                    <li>
                                    <Link
                        to="/pedagogical-forecast"
                        className={location.pathname === '/pedagogical-forecast' ? 'mm-active' : ''}
                        onClick={location.pathname === '/pedagogical-forecast' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.pedagogicalFollowupSub.forecast')}
                      </Link>
                                  </li>
                            )}
                            {( user?.permissions?.find(perm => perm === PEDAGOGICALFOLLOWUP_PROGRESSION || perm === PEDAGOGICALFOLLOWUP_PROGRESSION_LIST))
                            && ( 

                    <li>
                                    <Link
                        to="/pedagogical-progression"
                        className={location.pathname === '/pedagogical-progression' ? 'mm-active' : ''}
                        onClick={location.pathname === '/pedagogical-progression' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.pedagogicalFollowupSub.progression')}
                      </Link>
                                  </li>
                            )}
                             {( user?.permissions?.find(perm => perm === PEDAGOGICALFOLLOWUP_REPORT || perm === PEDAGOGICALFOLLOWUP_REPORT_VIEW))
                            && ( 
                    <li>
                                    <Link
                        to="/pedagogical-report"
                        className={location.pathname === '/pedagogical-report' ? 'mm-active' : ''}
                        onClick={location.pathname === '/pedagogical-report' ? onLinkClick : onDropDownHide}
                      >
                        <i className="metismenu-icon" />
                        {t('menu.pedagogicalFollowupSub.report')}
                      </Link>
                                  </li>
                            )}
                  </ul>
                              </li>
              </>
            )}

{( user?.permissions?.find(perm => perm === SETTINGS_APPRECIATION_LIST || perm === SETTINGS_CLASSES ||
perm === SETTINGS_SCHOOLINFORMATION || perm === SETTINGS_SCHOOLINFORMATION_VIEW ||
perm === SETTINGS_SCHOOLYEAR || perm === SETTINGS_SCHOOLYEAR_LIST ||
perm === SETTINGS_HONNORROLL || perm === SETTINGS_HONNORROLL_VIEW ||
perm === SETTINGS_QUALIFICATION || perm === SETTINGS_QUALIFICATION_ADD ||
perm === SETTINGS_WEEKS || perm === SETTINGS_WEEKS_LIST ||
perm === SETTINGS_CLASSES || perm === SETTINGS_CLASSES_LIST ||
perm === SETTINGS_INSTALLMENT || perm === SETTINGS_INSTALLMENT_LIST ||
perm === SETTINGS_APPRECIATION_LIST || perm === SETTINGS_APPRECIATION ||
perm === SETTINGS_DISCOUNTS || perm === SETTINGS_DISCOUNTS_LIST ||
perm === SETTINGS_PAYMENTTYPE || perm === SETTINGS_PAYMENTTYPE_LIST ||
perm === SETTINGS_COSTITEM || perm === SETTINGS_COSTITEM_LIST ||
perm === SETTINGS_COSTREASON || perm === SETTINGS_COSTREASON_LIST ||
perm === SETTINGS_SOURCEOFINCOME || perm === SETTINGS_SOURCEOFINCOME_LIST ||
perm === SETTINGS_INCOMEREASON || perm === SETTINGS_COSTREASON_ADD ||
perm === SETTINGS_INCOMEFORECAST || perm === SETTINGS_INCOMEFORECAST_LIST ||
perm === SETTINGS_EXPENSESFORECAST || perm === SETTINGS_EXPENSESFORECAST_LIST ||
perm === SETTINGS_OTHERPARAMETERS || perm === SETTINGS_OTHERPARAMETERS_VIEW
))
                            && ( 
              <>
                <li className={`has-dropdown ${activeDrawerMenu === 'settings' ? 'mm-active' : ''}`}>
                  <a href="javascript:void(0);" className="dropdownToggle" onClick={() => onClick('settings')}>
                    <i className="metismenu-icon pe-7s-settings" />
                    {t('menu.settings')}
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left" />
                  </a>
                  <ul className={`mm-collapse ${activeDrawerMenu === 'settings' ? 'mm-show' : ''}`}>
                  {( user?.permissions?.find(perm => perm === SETTINGS_SCHOOLINFORMATION || perm === SETTINGS_SCHOOLINFORMATION_VIEW))
                            && ( 
                    <li>
                      <Link
                          to={`/school-information/${user.school_id}`}
                          className={getActive('/school-information') ? 'mm-active' : ''}
                          onClick={getActive('/school-information') ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.schoolInformation')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === SETTINGS_SCHOOLYEAR || perm === SETTINGS_SCHOOLYEAR_LIST))
                            && ( 
                    <li>
                      <Link
                          className={location.pathname === '/school-year' ? 'mm-active' : ''}
                          to="/school-year"
                          onClick={location.pathname === '/school-year' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.schoolYear')}
                        </Link>
                    </li>
                            )}
                             {( user?.permissions?.find(perm => perm === SETTINGS_HONNORROLL || perm === SETTINGS_HONNORROLL_VIEW))
                            && ( 
                    <li>
                      <Link
                          to={`/honor-rolls/${user.school_id}`}
                          className={getActive('/honor-rolls') ? 'mm-active' : ''}
                          onClick={getActive('/honor-rolls') ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.honorRolls')}
                        </Link>
                    </li>
                            )}
                             {( user?.permissions?.find(perm => perm === SETTINGS_QUALIFICATION || perm === SETTINGS_QUALIFICATION_ADD))
                            && ( 
                    <li>
                      <Link
                          to="/qualification"
                          className={getActive('/qualification') ? 'mm-active' : ''}
                          onClick={getActive('/qualification') ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.qualification')}
                        </Link>
                    </li>
                            )}

{( user?.permissions?.find(perm => perm === SETTINGS_LEVEL || perm === SETTINGS_LEVEL_ADD))
                            && ( 
                    <li>
                      <Link
                          to="/level"
                          className={getActive('/level') ? 'mm-active' : ''}
                          onClick={getActive('/level') ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.level')}
                        </Link>
                    </li>
                            )}

{( user?.permissions?.find(perm => perm === SETTINGS_SUBJECT_TYPE || perm === SETTINGS_SUBJECT_TYPE_ADD))
                            && ( 
                    <li>
                      <Link
                          to="/subject-type"
                          className={getActive('/subject-type') ? 'mm-active' : ''}
                          onClick={getActive('/subject-type') ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.subjectType')}
                        </Link>
                    </li>
                            )}

{( user?.permissions?.find(perm => perm === SETTINGS_SKILLS || perm === SETTINGS_SKILLS_ADD))
                            && ( 
                    <li>
                      <Link
                          to="/skill"
                          className={getActive('/skill') ? 'mm-active' : ''}
                          onClick={getActive('/skill') ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.skill')}
                        </Link>
                    </li>
                            )}
            {( user?.permissions?.find(perm => perm === SETTINGS_MARK_PRIMARY_RULE || perm === SETTINGS_MARK_PRIMARY_RULE_ADD))
              && ( 
      <li>
        <Link
            to="/marks-primary-rules"
            className={location.pathname ==='/marks-primary-rules' ? 'mm-active' : ''}
            onClick={getActive('/marks-primary-rules') ? onLinkClick : onDropDownHide}
          >
            <i className="metismenu-icon" />
            {t('menu.settingsSub.markRulesPrimary')}
          </Link>
      </li>
              )}



                            {( user?.permissions?.find(perm => perm === SETTINGS_GRADES || perm === SETTINGS_GRADES_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/grades"
                          className={location.pathname === '/grades' ? 'mm-active' : ''}
                          onClick={location.pathname === '/grades' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.grades')}
                        </Link>
                    </li>
                            )}
                           
                    <li>
                      <Link
                          to="/marks-rules"
                          className={getActive('/marks-rules') ? 'mm-active' : ''}
                          onClick={location.pathname === '/marks-rules' ? onLinkClick : onDropDownHide}
                        >
                          {t('menu.settingsSub.marksRules')}
                        </Link>
                    </li>
                    {( user?.permissions?.find(perm => perm === SETTINGS_WEEKS || perm === SETTINGS_WEEKS_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/week-number"
                          className={location.pathname === '/week-number' ? 'mm-active' : ''}
                          onClick={location.pathname === '/week-number' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.weekNumber')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === SETTINGS_CLASSES || perm === SETTINGS_CLASSES_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/classes"
                          className={location.pathname === '/classes' ? 'mm-active' : ''}
                          onClick={location.pathname === '/classes' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.classes')}
                        </Link>
                    </li>
                            )}
                             {( user?.permissions?.find(perm => perm === SETTINGS_INSTALLMENT || perm === SETTINGS_INSTALLMENT_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/installments"
                          className={location.pathname === '/installments' ? 'mm-active' : ''}
                          onClick={location.pathname === '/installments' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.installments')}
                        </Link>
                    </li>
                            )}
                             {( user?.permissions?.find(perm => perm === SETTINGS_APPRECIATION_LIST || perm === SETTINGS_APPRECIATION))
                            && ( 
                    <li>
                      <Link
                          to="/appreciation"
                          className={location.pathname === '/appreciation' ? 'mm-active' : ''}
                          onClick={location.pathname === '/appreciation' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.configureAppreciations')}
                        </Link>
                    </li>
                            )}
                              {( user?.permissions?.find(perm => perm === SETTINGS_DISCOUNTS || perm === SETTINGS_DISCOUNTS_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/discount"
                          className={getActive('/discount') ? 'mm-active' : ''}
                          onClick={location.pathname === '/discount' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.discount')}
                        </Link>
                    </li>
                            )}
                             {( user?.permissions?.find(perm => perm === SETTINGS_PAYMENTTYPE || perm === SETTINGS_PAYMENTTYPE_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/payment-types"
                          className={location.pathname === '/payment-types' ? 'mm-active' : ''}
                          onClick={location.pathname === '/payment-types' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.paymentType')}
                        </Link>
                    </li>
                            )}
                             {( user?.permissions?.find(perm => perm === SETTINGS_COSTITEM || perm === SETTINGS_COSTITEM_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/cost-of-item"
                          className={location.pathname === '/cost-of-item' ? 'mm-active' : ''}
                          onClick={location.pathname === '/cost-of-item' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.costItem')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === SETTINGS_COSTREASON || perm === SETTINGS_COSTREASON_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/cost-reason"
                          className={location.pathname === '/cost-reason' ? 'mm-active' : ''}
                          onClick={location.pathname === '/cost-reason' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.costReason')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === SETTINGS_SOURCEOFINCOME || perm === SETTINGS_SOURCEOFINCOME_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/source-of-income"
                          className={location.pathname === '/source-of-income' ? 'mm-active' : ''}
                          onClick={location.pathname === '/source-of-income' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.sourceOfIncome')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === SETTINGS_INCOMEREASON || perm === SETTINGS_COSTREASON_ADD))
                            && ( 
                    <li>
                      <Link
                          to="/income-reasons"
                          className={location.pathname === '/income-reasons' ? 'mm-active' : ''}
                          onClick={location.pathname === '/income-reasons' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.incomeReasons')}
                        </Link>
                    </li>
                            )}
                            {( user?.permissions?.find(perm => perm === SETTINGS_INCOMEFORECAST || perm === SETTINGS_INCOMEFORECAST_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/income-forecast"
                          className={getActive('/income-forecast') ? 'mm-active' : ''}
                          onClick={location.pathname === '/income-forecast' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.incomeForecast')}
                        </Link>
                    </li>
                            )}
                              {( user?.permissions?.find(perm => perm === SETTINGS_EXPENSESFORECAST || perm === SETTINGS_EXPENSESFORECAST_LIST))
                            && ( 
                    <li>
                      <Link
                          to="/expenses-forecast"
                          className={getActive('/expenses-forecast') ? 'mm-active' : ''}
                          onClick={location.pathname === '/expenses-forecast' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.expensesForecast')}
                        </Link>
                    </li>
                            )}
                              {( user?.permissions?.find(perm => perm === SETTINGS_OTHERPARAMETERS || perm === SETTINGS_OTHERPARAMETERS_VIEW))
                            && ( 
                    <li>
                      <Link
                          to="/other-parameters"
                          className={getActive('/other-parameters') ? 'mm-active' : ''}
                          onClick={location.pathname === '/other-parameters' ? onLinkClick : onDropDownHide}
                        >
                          <i className="metismenu-icon" />
                          {t('menu.settingsSub.otherParameters')}
                        </Link>
                    </li>
                            )}


                  </ul>
                  
                </li>
                {(user?.user_type_id === USER_SCHOOL_ADMIN
                            || user?.user_type_id === USER_SCHOOL_SUB_ADMIN) && (
                              <li>
             
                              <Link
                                to="/user-scan"
                                className={getActive('/user-scan') ? 'mm-active' : ''}
                                onClick={location.pathname === '//user-scan' ? onLinkClick : onDropDownHide}
                              >
                                <i className="metismenu-icon pe-7s-look" />
                                {t('menu.userscan')}
                              </Link>
                            </li>
                            )}
              </>
            )}
          </ul>
        </div>
                            )};

Drawer_new.propTypes = {
  activeDrawerMenu: string,
  user: shape({ name: string.isRequired }),
};

Drawer_new.defaultProps = {
  activeDrawerMenu: null,
  user: null,
};

export default Drawer_new;

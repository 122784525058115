import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { CREATE_OUTFLOW,
  createOutflowFailure,
  createOutflowRequested,
  createOutflowSuccess,
  DELETE_OUTFLOW,
  deleteOutflowFailure,
  deleteOutflowRequested,
  deleteOutflowSuccess,
  EDIT_OUTFLOW,
  editOutflowFailure,
  editOutflowRequested,
  editOutflowSuccess,
  FETCH_SINGLE_OUTFLOW,
  fetchSingleOutflowFailure,
  fetchSingleOutflowRequested,
  fetchSingleOutflowSuccess,
  FETCH_OUTFLOW,
  fetchOutflowFailure,
  fetchOutflowRequested,
  fetchOutflowSuccess } from '../actions/outflow-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

export function* createOutflowHandler({ payload }) {
  yield put(createOutflowRequested());
  const body = new FormData();

  body.append('cost_reason_id', payload.cost_reason_id.value);
  body.append('amount', payload.amount);
  body.append('payment_type_id', payload.payment_type_id?.value);
  body.append('bank_name', payload.bank_name);
  body.append('receipt_number', payload.receipt_number);
  body.append('created_by', payload.created_by);
  body.append('outflow_date', payload.outflow_date ? moment(payload?.outflow_date).format('yyyy-MM-DD HH:mm:ss').toString() : moment(new Date()).format('yyyy-MM-DD hh:mm:ss').toString());
  body.append('displaydatetime', payload.outflow_date ? moment(payload?.outflow_date).format('DD/MM/yyyy HH:mm').toString() : moment(new Date()).format('yyyy-MM-DD hh:mm:ss').toString());

  body.append('comments', payload.comments);
  body.append('school_year_id', payload.school_year_id);

  if (payload.outflow_date === '') {
    body.append('outflow_date', moment(new Date()).format('yyyy-MM-DD HH:mm:ss').toString());
    body.append('displaydatetime', moment(new Date()).format('DD/MM/yyyy HH:mm').toString());
  }
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-outflow',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createOutflowFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/outflows');
      },
      icon: 'success',
      message: i18next.t('skullman:finance.outflows.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(createOutflowSuccess());
  }
}

export function* editOutflowHandler({ payload }) {
  yield put(editOutflowRequested());

  const request = {
    method: 'PUT',
    params: {
      amount: payload.amount,
      bank_name: payload.bank_name,
      comments: payload.comments,
      cost_reason_id: payload.cost_reason_id.value,
      displaydatetime: payload.outflow_date ? moment(payload?.outflow_date).format('DD/MM/yyyy HH:mm').toString() : moment(new Date()).format('DD/MM/yyyy HH:mm').toString(),
      outflow_date: payload.outflow_date ? moment(payload?.outflow_date).format('yyyy-MM-DD HH:mm:ss').toString() : moment(new Date()).format('yyyy-MM-DD hh:mm:ss').toString(),
      payment_type_id: payload.payment_type_id.value,
      receipt_number: payload.receipt_number,
      school_year_id: payload.school_year_id,
    },
    url: `update-outflow/${payload.outflow_reason_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editOutflowFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname:'/outflows'});
    //   },
    //   icon: 'success',
    //   message: 'Outflow updated successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);

    yield put(editOutflowSuccess());
  }
}

export function* deleteOutflowHandler({ payload }) {
  yield put(deleteOutflowRequested());

  const request = {
    method: 'DELETE',
    url: `delete-outflow/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteOutflowFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:finance.outflows.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(deleteOutflowSuccess(payload));
  }
}

export function* fetchOutflowHandler({ payload }) {
  yield put(fetchOutflowRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'list-outflow',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchOutflowFailure(error));
  } else {
    // console.log(data.data)
    yield put(fetchOutflowSuccess(data.data.result));
  }
}

export function* fetchSingleOutflowHandler({ payload }) {
  yield put(fetchSingleOutflowRequested());

  const request = {
    method: 'GET',
    url: `view-outflow/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleOutflowFailure(error));
  } else {
    const { result } = data.data;
    const res = {
      ...data.data.result,
      cost_reason_id: {
        label: result.cost_reason_label,
        value: result.cost_reason_id,
      },

      id: result.outflow_reason_id,
      outflow_date: result.outflow_date ? moment(result?.outflow_date).toDate() : '',

      payment_type_id: {
        label: result.payment_type_label,
        value: result.payment_type_id,
      },
    };

    yield put(fetchSingleOutflowSuccess(res));
  }
}

function* Outflows() {
  yield all([
    takeLatest(CREATE_OUTFLOW, createOutflowHandler),
    takeLatest(DELETE_OUTFLOW, deleteOutflowHandler),
    takeLatest(EDIT_OUTFLOW, editOutflowHandler),
    takeLatest(FETCH_OUTFLOW, fetchOutflowHandler),
    takeLatest(FETCH_SINGLE_OUTFLOW, fetchSingleOutflowHandler),
  ]);
}

export default Outflows;

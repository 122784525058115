import { LOCATION_CHANGE } from 'connected-react-router';
import { CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_REQUESTED,
  CREATE_PAYMENT_SUCCESS,
  CREATE_TRANSPORT_PAYMENT_FAILURE,
  CREATE_TRANSPORT_PAYMENT_REQUESTED,
  CREATE_TRANSPORT_PAYMENT_SUCCESS,
  FETCH_PAYMENT_FAILURE,
  FETCH_PAYMENT_REQUESTED,
  FETCH_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FORM,
  CREATE_APPLY_DISCOUNT_FAILURE,
  CREATE_APPLY_DISCOUNT_REQUESTED,
  CREATE_APPLY_DISCOUNT_SUCCESS,
  DELETE_APPLY_DISCOUNT_FAILURE,
  DELETE_APPLY_DISCOUNT_REQUESTED,
  DELETE_APPLY_DISCOUNT_SUCCESS,
  FETCH_APPLY_DISCOUNT_FAILURE,
  FETCH_APPLY_DISCOUNT_REQUESTED,
  FETCH_APPLY_DISCOUNT_SUCCESS,
  UPDATE_APPLY_DISCOUNT_FORM} from '../actions/payment-action-type';

const initialState = {
  applyDiscount: [],
  createApplyDiscountStatus: 'pending',
  createPaymentStatus: 'pending',
  createTransportPaymentStatus: 'pending',
  deleteApplyDiscountStatus: 'pending',
  fetchApplyDiscountStatus: 'pending',
  fetchFilterPaymentStatus: 'pending',
  fetchPaymentStatus: 'pending',
  form: {
    amount: '',
    comment: '',
    discount: null,
    discount_setting_id: null,
    discount_type: null,
    grade_id: null,
    installment_id: null,
    kind: null,
    payment_date: null,
    payment_type_id: null,
    received_by: '',
    school_year_id: null,
    student_id: null,
    derogation_status:null,
    derogation_description:null,
  },
  payment: [],
  transportHistory: [],
};

export default function Payment(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        createPaymentStatus: 'failure',
      };

    case CREATE_PAYMENT_REQUESTED:
      return {
        ...state,
        createPaymentStatus: 'creating',
      };

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        createPaymentStatus: 'success',
      };
    case CREATE_TRANSPORT_PAYMENT_FAILURE:
      return {
        ...state,
        createTransportPaymentStatus: 'failure',
      };

    case CREATE_TRANSPORT_PAYMENT_REQUESTED:
      return {
        ...state,
        createTransportPaymentStatus: 'creating',
      };

    case CREATE_TRANSPORT_PAYMENT_SUCCESS:
      return {
        ...state,
        createTransportPaymentStatus: 'success',
        transportHistory: [...state.transportHistory].filter((pType) => !(payload.toString().includes(pType.receipt_id.toString()))),
      };
    case FETCH_PAYMENT_FAILURE:
      return {
        ...state,
        fetchPaymentStatus: 'failure',
      };

    case FETCH_PAYMENT_REQUESTED:
      return {
        ...state,
        fetchPaymentStatus: 'fetching',
      };

    case FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        fetchPaymentStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
        payment: payload,
      };

    case CREATE_APPLY_DISCOUNT_FAILURE:
      return {
        ...state,
        createApplyDiscountStatus: 'failure',
      };

    case CREATE_APPLY_DISCOUNT_REQUESTED:
      return {
        ...state,
        createApplyDiscountStatus: 'creating',
      };

    case CREATE_APPLY_DISCOUNT_SUCCESS:
      return {
        ...state,
        createApplyDiscountStatus: 'success',
      };

    case FETCH_APPLY_DISCOUNT_FAILURE:
      return {
        ...state,
        fetchApplyDiscountStatus: 'failure',
      };

    case FETCH_APPLY_DISCOUNT_REQUESTED:
      return {
        ...state,
        fetchApplyDiscountStatus: 'fetching',
      };

    case FETCH_APPLY_DISCOUNT_SUCCESS:
      return {
        ...state,
        applyDiscount: payload,
        fetchApplyDiscountStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };

    case DELETE_APPLY_DISCOUNT_FAILURE:
      return {
        ...state,
        deleteApplyDiscountStatus: 'failure',
      };

    case DELETE_APPLY_DISCOUNT_REQUESTED:
      return {
        ...state,
        deleteApplyDiscountStatus: 'deleting',
      };

    case DELETE_APPLY_DISCOUNT_SUCCESS:
      return {
        ...state,
        applyDiscount: [...state.applyDiscount].filter((discount) => !(payload.toString().includes(discount.student_id.toString()))),
        deleteApplyDiscountStatus: 'success',
      };
    case UPDATE_PAYMENT_FORM:
      return {
        ...state,
        form: payload,
      };

    case UPDATE_APPLY_DISCOUNT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

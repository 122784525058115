import { call, put, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { result } from 'lodash';
import { hideLoader, showLoader, showToast } from '../actions/app-action-types';
import { logoutSuccess } from '../actions/user-action-types';
import axiosInstance from '../utility/axios-instance';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';

function* HttpClient(payload, isLoader = true, auth = true) {
  const { pathname } = history.location;

  const check = pathname.includes('student-details');

  if (!navigator.onLine) {
    yield put(showToast('Please make sure you\'re connected with internet.'));

    return {
      error: true,
      result: null,
    };
  }

  if (isLoader ) {
    yield put(showLoader());
    // if (result) {
    //   yield delay(150);
    // }
  }

  try {
    const request = { ...payload };

    if (auth) {
      const authToken = yield select((store) => store.user.token);

      request.headers = { Authorization: `Bearer ${authToken}` };
    }

    const {
      data, headers,
    } = yield call(axiosInstance, request);

    console.log(data, 'http');
    // if (isLoader && !check) {
    //   yield put(hideLoader());
    // }
    if (isLoader && data && !check) {
      yield put(hideLoader());
    }

    return {
      data,
      error: null,
      headers,
    };
  } catch (error) {
    if (isLoader) {
      yield put(hideLoader());
    }

    let message = '';

    if (error?.code) {
      if (error.code === 'ECONNABORTED') {
        message = 'Please try later our servers are not responding.';
      } else if (error.code === 401) {
        yield put(logoutSuccess());
        yield put(push());
      } else if (error.code === 503) {
        message = 'Please try later our servers are not responding.';
      } else {
        message = error.message;
      }
    } else {
      message = error.message;
    }
console.log(error);
    if (message !== 'Network Error') {
      const errorPayload = {
        icon: 'error',
        message,
        title: 'Error',
      };

      Alert.alert(errorPayload);
    }
    return {
      error,
      result: null,
    };
  }
}

export default HttpClient;

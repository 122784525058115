import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_SUBJECT,
  createSubjectFailure,
  createSubjectRequested,
  createSubjectSuccess,
  DELETE_SUBJECT,
  deleteSubjectFailure,
  deleteSubjectRequested,
  deleteSubjectSuccess,
  EDIT_SUBJECT,
  editSubjectFailure,
  editSubjectRequested,
  editSubjectSuccess,
  FETCH_SINGLE_SUBJECT,
  fetchSingleSubjectFailure,
  fetchSingleSubjectRequested,
  fetchSingleSubjectSuccess,
  FETCH_SUBJECT,
  fetchSubjectFailure,
  fetchSubjectRequested,
  fetchSubjectSuccess,
  FETCH_SUBJECT_TYPES,
  DELETE_SINGLE_SUBJECT,
  fetchSubjectTypesSuccess,
} from '../actions/subject-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import { DELETE_GRADE } from '../actions/grades-action-types';
import i18next from "i18next";

const ColorList = [
  {
    label: 'Red',
    value: '#FF0000',
  },
  {
    value: '#008000',
    label: 'Green',
  },
  {
    label: 'Yellow',
    value: '#FFFF00',
  },
  { label: 'Blue', value: '#0000FF' },
  {
    label: 'Black',
    value: '#000',
  },
  {
    label: 'Orange',
    value: '#FFA500',
  },
  { label: 'Brown', value: '#A52A2A' },
  { label: 'White', value: '#fff' },
  { label: 'Teal', value: '#008080' },
  { label: 'Silver', value: '#C0C0C0' },
  { label: 'Purple', value: '#800080' },
  { label: 'Gray', value: '#696969' },
  {
    label: 'Navy Blue',
    value: '#000080',
  },
  { label: 'Azure', value: '#007fff' },
  { label: 'Pea Green', value: '#52D017' },
  {
    label: 'Ivory',
    value: '#FFFFF0',
  },
  {
    label: 'Maroon',
    value: '#800000',
  },
  {
    label: 'Olive',
    value: '#808000',
  },
  {
    label: 'Magenta',
    value: '#FF00FF',
  },
  { label: 'Lime', value: '#00FF00' },
];


export function* createSubjectHandler({ payload }) {
  yield put(createSubjectRequested());

  const request = {
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    url: 'add-subject',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createSubjectFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/subjects');
      },
      icon: 'success',
      message: i18next.t('skullman:users.subUsers.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(createSubjectSuccess());
  }
}

export function* editSubjectHandler({ payload }) {
  yield put(editSubjectRequested());
  console.log(payload);
  const request = {
    data: payload.post,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    url: `update-subject/${payload.post.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editSubjectFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname:'/subjects'});
    //   },
    //   icon: 'success',
    //   message: 'Subject updated successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);
    yield put(editSubjectSuccess());
  }
}

export function* deleteSubjectHandler({ payload }) {
  yield put(deleteSubjectRequested());
  const request = {
    method: 'DELETE',
    url: `delete-subject/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteSubjectFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:subjects.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteSubjectSuccess(payload));
  }
}

export function* fetchSubjectHandler({ payload }) {
  yield put(fetchSubjectRequested());

  const request = {
    method: 'GET',
    params: {
      class_id: payload?.class_id ? parseInt(payload?.class_id):null,
      grade_id: payload?.grade_id ? parseInt(payload?.grade_id):null,
      teacher_id: payload?.teacher_id ? parseInt(payload?.teacher_id):null,
      school_year_id: payload.school_year_id,
    },
    url: 'subjects',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSubjectFailure(error));
  } else {
    yield put(fetchSubjectSuccess(data.data.result));
    if(payload.result)
     payload.result(data.data.result);
  }
}

export function* fetchSubjectTypesHandler({}) {
  yield put(fetchSubjectRequested());

  const request = {
    method: 'GET',
    url: 'subjectTypes',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    // yield put(fetchSubjectFailure(error));
  } else {
    console.log('fetch', data.data.result);
    yield put(fetchSubjectTypesSuccess(data.data.result));
  }
}

export function* fetchSingleSubjectHandler({ payload }) {
  yield put(fetchSingleSubjectRequested());
  const request = {
    method: 'GET',
    url: `get-subject/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleSubjectFailure(error));
  } else {
    const { result } = data.data;
    let check = ColorList.findIndex((item)=>item.value ==result.color)

    let label =  check!=-1 ? ColorList[check].label:"";
    const res = {
      ...data.data.result,
      subject_id: result.Subject_id,
      subject_name: result.subject_name,
      subject_code: result.subject_code,
      color: {
        label: label,
        value: result.color,
      },
      grade_id: {
        label: result.grade_name,
        value: result.grade_id,
      },
      school_id: {
        label: result.school_name,
        value: result.school_id,
      },
      status:
        result.status === 1
          ? {
              label: 'Active',
              value: 1,
            }
          : {
              label: 'Inactive',
              value: 0,
            },
    };

    yield put(fetchSingleSubjectSuccess(res));
  }
}

export function* deleteSubjectGrade({ payload }) {
  yield put(deleteSubjectRequested());
  const request = {
    method: 'DELETE',
    url: `delete-subjectGrade/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteSubjectFailure(error));
  } else {
    // const alertProps = {
    //   icon: 'success',
    //   message: 'Subject deleted successfully.',
    //   title: 'Success',
    // };
    // Alert.alert(alertProps);
    // yield put(deleteSubjectSuccess(payload));
  }
}

function* Subject() {
  yield all([
    takeLatest(CREATE_SUBJECT, createSubjectHandler),
    takeLatest(DELETE_SUBJECT, deleteSubjectHandler),
    takeLatest(EDIT_SUBJECT, editSubjectHandler),
    takeLatest(FETCH_SUBJECT, fetchSubjectHandler),
    takeLatest(FETCH_SUBJECT_TYPES, fetchSubjectTypesHandler),
    takeLatest(FETCH_SINGLE_SUBJECT, fetchSingleSubjectHandler),
    takeLatest(DELETE_SINGLE_SUBJECT, deleteSubjectGrade),
  ]);
}

export default Subject;

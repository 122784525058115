import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ADD_ATTENDANCE_FAILURE,
  ADD_ATTENDANCE_REQUESTED,
  ADD_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_FAILURE,
  DELETE_ATTENDANCE_REQUESTED,
  DELETE_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_FAILURE,
  FETCH_ATTENDANCE_REQUESTED,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_TEACHER_ATTENDANCE_FAILURE,
  FETCH_TEACHER_ATTENDANCE_REQUESTED,
  FETCH_TEACHER_ATTENDANCE_SUCCESS,
  FETCH_SINGLE_ATTENDANCE_FAILURE,
  FETCH_SINGLE_ATTENDANCE_REQUESTED,
  FETCH_SINGLE_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_FORM,
  SET_INIT_STATE
} from '../actions/attendance-action-types';
import { SET_INITIAL_STATE } from '../actions/school-year-action-type';

const initialState = {
  addAttendanceStatus: 'pending',
  attendance: [],
  studentAttendance: [],
  deleteAttendanceStatus: 'pending',
  fetchAttendanceStatus: 'pending',
  fetchSingleAttendanceStatus: 'pending',
  form: {
    class_id: '',
    created_date: '',
    end_date: null,
    school_year_id: '',
    start_date: null,
    // student_attandance_list: [],
    student_attendance_list: [],
    subject_id: null,
    isSchedule:false,
    skills:null,
  },

  teacherAttendance: [],
};

export default function Attendance(state = initialState, { payload, type }) {

  switch (type) {
    case ADD_ATTENDANCE_FAILURE:
      return {
        ...state,
        addAttendanceStatus: 'failure',
      };

    case ADD_ATTENDANCE_REQUESTED:
      return {
        ...state,
        addAttendanceStatus: 'creating',
      };

    case ADD_ATTENDANCE_SUCCESS:
      return {
        ...state,
        addAttendanceStatus: 'success',
        form: {
          ...state.form,
          attendance_subject_id: payload?.attendance_subject_id || state.form?.attendance_subject_id,
        },
      };

    case DELETE_ATTENDANCE_FAILURE:
      return {
        ...state,
        deleteAttendanceStatus: 'failure',
      };

    case DELETE_ATTENDANCE_REQUESTED:
      return {
        ...state,
        deleteAttendanceStatus: 'deleting',
      };

    case DELETE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendance: [...state.attendance].filter((Attendances) => !payload.toString().includes(Attendances.id.toString())),
        deleteAttendanceStatus: 'success',
      };

    case FETCH_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchAttendanceStatus: 'failure',
      };

    case FETCH_ATTENDANCE_REQUESTED:
      return {
        ...state,
        fetchAttendanceStatus: 'fetching',
      };

    case FETCH_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendance: payload,
        fetchAttendanceStatus: 'success',
      };

    case FETCH_TEACHER_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchAttendanceStatus: 'failure',
      };

    case FETCH_TEACHER_ATTENDANCE_REQUESTED:
      return {
        ...state,
        fetchAttendanceStatus: 'fetching',
      };

    case FETCH_TEACHER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        teacherAttendance: payload,
        fetchAttendanceStatus: 'success',
      };

    case FETCH_SINGLE_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchSingleAttendanceStatus: 'failure',
      };

    case FETCH_SINGLE_ATTENDANCE_REQUESTED:
      return {
        ...state,
        fetchSingleAttendanceStatus: 'fetching',
      };

    case FETCH_SINGLE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        fetchSingleAttendanceStatus: 'success',
        studentAttendance: payload,
      };
    case UPDATE_ATTENDANCE_FORM:
      
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:{
      if(!state.form.isSchedule){
        return { ...initialState };
      }
      else
      {
        return { ...state };
      }
    
    }
    

    case SET_INIT_STATE:
      return { ...initialState };

    default:
      return state;
  }
}

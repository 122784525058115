import { createAction } from 'redux-actions';

export const ADD_PART = 'ADD_PART';
export const addPart = createAction(ADD_PART);

export const ADD_PART_FAILURE = 'ADD_PART_FAILURE';
export const addPartFailure = createAction(ADD_PART_FAILURE);

export const ADD_PART_REQUESTED = 'ADD_PART_REQUESTED';
export const addPartRequested = createAction(ADD_PART_REQUESTED);

export const ADD_PART_SUCCESS = 'ADD_PART_SUCCESS';
export const addPartSuccess = createAction(ADD_PART_SUCCESS);

export const DELETE_PART = 'DELETE_PART';
export const deletePart = createAction(DELETE_PART);

export const DELETE_PART_FAILURE = 'DELETE_PART_FAILURE';
export const deletePartFailure = createAction(DELETE_PART_FAILURE);

export const DELETE_PART_REQUESTED = 'DELETE_PART_REQUESTED';
export const deletePartRequested = createAction(DELETE_PART_REQUESTED);

export const DELETE_PART_SUCCESS = 'DELETE_PART_SUCCESS';
export const deletePartSuccess = createAction(DELETE_PART_SUCCESS);

export const FETCH_PART = 'FETCH_PART';
export const fetchPart = createAction(FETCH_PART);

export const FETCH_PART_FAILURE = 'FETCH_PART_FAILURE';
export const fetchPartFailure = createAction(FETCH_PART_FAILURE);

export const FETCH_PART_REQUESTED = 'FETCH_PART_REQUESTED';
export const fetchPartRequested = createAction(FETCH_PART_REQUESTED);

export const FETCH_PART_SUCCESS = 'FETCH_PART_SUCCESS';
export const fetchPartSuccess = createAction(FETCH_PART_SUCCESS);

export const EDIT_PART = 'EDIT_PART';
export const editPart = createAction(EDIT_PART);

export const EDIT_PART_FAILURE = 'EDIT_PART_FAILURE';
export const editPartFailure = createAction(EDIT_PART_FAILURE);

export const EDIT_PART_REQUESTED = 'EDIT_PART_REQUESTED';
export const editPartRequested = createAction(EDIT_PART_REQUESTED);

export const EDIT_PART_SUCCESS = 'EDIT_PART_SUCCESS';
export const editPartSuccess = createAction(EDIT_PART_SUCCESS);

export const FETCH_SINGLE_PART = 'FETCH_SINGLE_PART';
export const fetchSinglePart = createAction(FETCH_SINGLE_PART);

export const FETCH_SINGLE_PART_FAILURE = 'FETCH_SINGLE_PART_FAILURE';
export const fetchSinglePartFailure = createAction(FETCH_SINGLE_PART_FAILURE);

export const FETCH_SINGLE_PART_REQUESTED = 'FETCH_SINGLE_PART_REQUESTED';
export const fetchSinglePartRequested = createAction(FETCH_SINGLE_PART_REQUESTED);

export const FETCH_SINGLE_PART_SUCCESS = 'FETCH_SINGLE_PART_SUCCESS';
export const fetchSinglePartSuccess = createAction(FETCH_SINGLE_PART_SUCCESS);

export const UPDATE_PART_FORM = 'UPDATE_PART_FORM';
export const updatePartForm = createAction(UPDATE_PART_FORM);

export const FETCH_PART_FILTER = 'FETCH_PART_FILTER';
export const fetchPartFilter = createAction(FETCH_PART_FILTER);

export const FETCH_PART_FILTER_FAILURE = 'FETCH_PART_FILTER_FAILURE';
export const fetchPartFilterFailure = createAction(FETCH_PART_FILTER_FAILURE);

export const FETCH_PART_FILTER_REQUESTED = 'FETCH_PART_FILTER_REQUESTED';
export const fetchPartFilterRequested = createAction(FETCH_PART_FILTER_REQUESTED);

export const FETCH_PART_FILTER_SUCCESS = 'FETCH_PART_FILTER_SUCCESS';
export const fetchPartFilterSuccess = createAction(FETCH_PART_FILTER_SUCCESS);
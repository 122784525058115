import { LOCATION_CHANGE } from 'connected-react-router';
import { FETCH_PROGRESSION_SUCCESS, ADD_PROGRESSION_SUCCESS, FETCH_SINGLE_PROGRESSION_SUCCESS,
  DELETE_PROGRESSION_SUCCESS, UPDATE_PROGRESSION_FORM } from '../actions/progression-action-types';

const initialState = {
  addProgressionStatus: 'pending',
  deleteProgressionStatus: 'pending',
  error: false,
  fetchProgressionStatus: 'pending',
  fetchSingleProgressionStatus: 'pending',
  form: {
    chapter_id: '',
    class_id: '',
    comment: '',
    date: '',
    id: '',
    part_id: '',
    school_year_id: '',
    subject_id: '',
    teacher_id: '',
    week_number_id: '',
    quarter_id:'',
    progression_status:''
  },
  // isLoading: false,
  progressions: [],
};

export default (state = initialState, {
  type, payload,
}) => {
  switch (type) {
    case FETCH_PROGRESSION_SUCCESS:
      return {
        ...state,
        progressions: payload,
      };

    case ADD_PROGRESSION_SUCCESS:
      return {
        ...state,
        addProgressionStatus: 'success',
      };

    case DELETE_PROGRESSION_SUCCESS:
      return {
        ...state,
        progressions: [...state.progressions].filter((progression) => !(payload.toString().includes(progression.id.toString()))),

      };
    case FETCH_SINGLE_PROGRESSION_SUCCESS:
      return {
        ...state,
        fetchSingleProgressionStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_PROGRESSION_FORM:
      return {
        ...state,
        form: payload,
      };
    case LOCATION_CHANGE:
      return { ...initialState };
    default:
      return state;
  }
};

import { createAction } from 'redux-actions';

export const ADD_SERVICE = 'ADD_SERVICE';
export const addService = createAction(ADD_SERVICE);

export const ADD_SERVICE_FAILURE = 'ADD_SERVICE_FAILURE';
export const addServiceFailure = createAction(ADD_SERVICE_FAILURE);

export const ADD_SERVICE_REQUESTED = 'ADD_SERVICE_REQUESTED';
export const addServiceRequested = createAction(ADD_SERVICE_REQUESTED);

export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const addServiceSuccess = createAction(ADD_SERVICE_SUCCESS);

export const DELETE_SERVICE = 'DELETE_SERVICE';
export const deleteService = createAction(DELETE_SERVICE);

export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';
export const deleteServiceFailure = createAction(DELETE_SERVICE_FAILURE);

export const DELETE_SERVICE_REQUESTED = 'DELETE_SERVICE_REQUESTED';
export const deleteServiceRequested = createAction(DELETE_SERVICE_REQUESTED);

export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const deleteServiceSuccess = createAction(DELETE_SERVICE_SUCCESS);

export const FETCH_SERVICE = 'FETCH_SERVICE';
export const fetchService = createAction(FETCH_SERVICE);

export const FETCH_SERVICE_FAILURE = 'FETCH_SERVICE_FAILURE';
export const fetchServiceFailure = createAction(FETCH_SERVICE_FAILURE);

export const FETCH_SERVICE_REQUESTED = 'FETCH_SERVICE_REQUESTED';
export const fetchServiceRequested = createAction(FETCH_SERVICE_REQUESTED);

export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const fetchServiceSuccess = createAction(FETCH_SERVICE_SUCCESS);

export const EDIT_SERVICE = 'EDIT_SERVICE';
export const editService = createAction(EDIT_SERVICE);

export const EDIT_SERVICE_FAILURE = 'EDIT_SERVICE_FAILURE';
export const editServiceFailure = createAction(EDIT_SERVICE_FAILURE);

export const EDIT_SERVICE_REQUESTED = 'EDIT_SERVICE_REQUESTED';
export const editServiceRequested = createAction(EDIT_SERVICE_REQUESTED);

export const EDIT_SERVICE_SUCCESS = 'EDIT_SERVICE_SUCCESS';
export const editServiceSuccess = createAction(EDIT_SERVICE_SUCCESS);

export const FETCH_SINGLE_SERVICE = 'FETCH_SINGLE_SERVICE';
export const fetchSingleService = createAction(FETCH_SINGLE_SERVICE);

export const FETCH_SINGLE_SERVICE_FAILURE = 'FETCH_SINGLE_SERVICE_FAILURE';
export const fetchSingleServiceFailure = createAction(FETCH_SINGLE_SERVICE_FAILURE);

export const FETCH_SINGLE_SERVICE_REQUESTED = 'FETCH_SINGLE_SERVICE_REQUESTED';
export const fetchSingleServiceRequested = createAction(FETCH_SINGLE_SERVICE_REQUESTED);

export const FETCH_SINGLE_SERVICE_SUCCESS = 'FETCH_SINGLE_SERVICE_SUCCESS';
export const fetchSingleServiceSuccess = createAction(FETCH_SINGLE_SERVICE_SUCCESS);

export const UPDATE_SERVICE_FORM = 'UPDATE_SERVICE_FORM';
export const updateServiceForm = createAction(UPDATE_SERVICE_FORM);

import { LOCATION_CHANGE } from 'connected-react-router';
import { SET_USERTYPE,
    SET_INITIAL_STATE,
    FETCH_USERTYPE_FAILURE,
    FETCH_USERTYPE_REQUESTED,
    FETCH_USERTYPE_SUCCESS } from '../actions/user-types-action-type';

const initialState = {
  form: {
    user_type_id: null,
    user_type_name: null,
    user_type_status: null,
  },
  userType: [],
};

export default function userType(state = initialState, {
  payload, type,
}) {
  // console.log(type,payload)
  switch (type) {
    case FETCH_USERTYPE_FAILURE:
      return {
        ...state,
        fetchUserTypeStatus: 'failure',
      };

    case FETCH_USERTYPE_REQUESTED:
      return {
        ...state,
        fetchUserTypeStatus: 'fetching',
      };

    case FETCH_USERTYPE_SUCCESS:
      return {
        ...state,
        fetchUserTypeStatus: 'success',
        form: { ...state.form },
        userType: payload,

      };

   

    case LOCATION_CHANGE:
      return { ...state };

    case SET_INITIAL_STATE:
      return { ...initialState };

    case SET_USERTYPE:
      return {
        ...state,
        form: {
          ...state.form,
          user_type_id: {
            label: payload.user_type_name,
            value: payload.user_type_id,
          },
        },
      };

    default:
      return state;
  }
}

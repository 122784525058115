import { all, call, put, takeLatest } from 'redux-saga/effects';
import SweetAlert from 'sweetalert2';
import { CREATE_ENROLLMENT,
  createEnrollmentFailure,
  createEnrollmentRequested,
  createEnrollmentSuccess,
  DELETE_ENROLLMENT,
  deleteEnrollmentFailure,
  deleteEnrollmentRequested,
  deleteEnrollmentSuccess,
  EDIT_ENROLLMENT,
  editEnrollmentFailure,
  editEnrollmentRequested,
  editEnrollmentSuccess,
  EXISTING_REGISTRATION_NUMBER,
  existingRegistrationNumberFailure,
  existingRegistrationNumberRequested,
  existingRegistrationNumberSuccess,
  FETCH_SINGLE_ENROLLMENT,
  fetchSingleEnrollmentFailure,
  fetchSingleEnrollmentRequested,
  fetchSingleEnrollmentSuccess,
  FETCH_ENROLLMENT,
  fetchEnrollmentFailure,
  fetchEnrollmentRequested,
  fetchEnrollmentSuccess,
  FETCH_ENROLLED_STUDENTS,
  fetchEnrolledStudentsFailure,
  fetchEnrolledStudentsRequested,
  fetchEnrolledStudentsSuccess,
  fetchStudentsInstallmentRequested,
  fetchStudentsInstallmentSuccess,
  fetchStudentsInstallmentFailure,
  fetchStudentProfileFailure,
  fetchStudentProfileRequested,
  fetchStudentProfileSuccess,
  // fetchTransportFailure,
  // fetchTransportRequested,
  // fetchTransportSuccess,
  fetchInstallmentRecapFailure,
  fetchInstallmentRecapRequested,
  fetchInstallmentRecapSuccess,
  fetchInstallmentHistoryFailure,
  fetchInstallmentHistoryRequested,
  fetchInstallmentHistorySuccess,
  fetchTransportRecapFailure,
  fetchTransportRecapRequested,
  fetchTransportRecapSuccess,
  fetchTransportHistoryFailure,
  fetchTransportHistoryRequested,
  fetchTransportHistorySuccess,
  deletePaymentHistoryFailure,
  deletePaymentHistoryRequested,
  deletePaymentHistorySuccess,
  DELETE_PAYMENT_HISTORY,
  DELETE_TRANSPORT_HISTORY,
  deleteTransportHistoryFailure,
  deleteTransportHistoryRequested,
  deleteTransportHistorySuccess,
  fetchStudentsInstallmentSeqRequested,
  fetchStudentsInstallmentSeqSuccess,
  fetchStudentsInstallmentSeqFailure,} from '../actions/enrollment-action-type';

import httpClient from './http-client';
import EnrolmentHelper from '../utility/enrollment-methods';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

//const location = useLocation();

export function* createEnrollmentHandler({ payload }) {
  yield put(createEnrollmentRequested());
  const body = EnrolmentHelper.getRequestBody(payload?.form, payload?.type);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-enrollment',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(createEnrollmentFailure(error));
  } else {
    if (payload.type === 4 && payload.isSubmission) {
      SweetAlert.fire({
        allowOutsideClick: false,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        confirmButtonText: i18next.t('skullman:tution.enrollment.confirmMessage'),
        icon: 'success',
        showCancelButton: false,
        text: i18next.t('skullman:tution.enrollment.createMessage'),
        title: '',
      }).then((result) => {
        if (result?.isConfirmed) {
          history.push('/enrollments');
        }
      });
    }
    const response = {
      student_id: data?.data?.id,
      student_registration_no: data?.data?.student_registration_no,
    };

    if (payload?.callback) {
      payload?.callback();
    }

    yield put(createEnrollmentSuccess(response));
  }
}

export function* editEnrollmentHandler({ payload }) {
  yield put(editEnrollmentRequested());
  const body = EnrolmentHelper.getRequestBody(payload?.form, payload?.type);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'update-enrollment',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(editEnrollmentFailure(error));
  } else {
    if (payload.type === 4 && payload?.isSubmission) {
      SweetAlert.fire({
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        confirmButtonText: i18next.t('skullman:tution.enrollment.confirmMessage'),
        icon: 'success',
        showCancelButton: false,
        text: i18next.t('skullman:tution.enrollment.updateMessage'),
        title: '',
      }).then((result) => {
        if (result?.isConfirmed) {
              history.push('/enrollments');
        }
      });
    }

    const response = {
      student_id: data?.data?.id,
      student_registration_no: data?.data?.student_registration_no,
    };

    if (payload.callback) {
      payload.callback();
    }
    yield put(editEnrollmentSuccess(response));
  }
}

export function* deleteEnrollmentHandler({ payload }) {
  yield put(deleteEnrollmentRequested());

  const request = {
    method: 'DELETE',
    params: { school_year_id: payload?.school_year_id },
    url: `delete-enrollment/${payload?.stringIds}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteEnrollmentFailure(error));
  } else {
    SweetAlert.fire({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: i18next.t('skullman:tution.enrollment.confirmMessage'),
      icon: 'success',
      showCancelButton: false,
      text: i18next.t('skullman:tution.enrollment.deleteMessage'),
      title: '',
    });
    yield put(deleteEnrollmentSuccess(payload));
  }
}

export function* deleteTransportHandler({ payload }) {
  yield put(deleteTransportHistoryRequested());
//delete-all-transport-history
const all=payload?.all;

let url=`delete-transport-history/${payload?.receipt_id}`;

if(all==1){
url=`delete-all-transport-history/${payload?.receipt_id}`;
}

  
const request = {
  method: 'DELETE',
  params: {
    school_year_id: payload?.school_year_id,
    student_id: payload?.student_id,
  },
  url: url,
};

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteTransportHistoryFailure(error));
  } else {
    SweetAlert.fire({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: i18next.t('skullman:tution.enrollment.confirmMessage'),
      icon: 'success',
      showCancelButton: false,
      text: i18next.t('skullman:tution.enrollment.deletePaymenMessage'),
      title: '',
    }).then((result) => {
      put(deleteTransportHistorySuccess(payload));
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
}
export function* deletePaymentHandler({ payload }) {
  yield put(deletePaymentHistoryRequested());

  const request = {
    method: 'DELETE',
    params: {
      school_year_id: payload?.school_year_id,
      student_id: payload?.student_id,
    },
    url: `delete-Latest-payment/${payload?.receipt_id}`,
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deletePaymentHistoryFailure(error));
  } else {
    SweetAlert.fire({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: i18next.t('skullman:tution.enrollment.confirmMessage'),
      icon: 'success',
      showCancelButton: false,
      text: i18next.t('skullman:tution.enrollment.deletePaymenMessage'),
      title: '',
    }).then((result) => {
      put(deletePaymentHistorySuccess(payload));
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
}
export function* fetchEnrollmentHandler({ payload }) {
  yield put(fetchEnrollmentRequested());

  const request = {
    method: 'GET',
    params: {
      filter: payload?.selectedOption,
      school_year_id: payload?.school_year_id,
    },
    url: 'list-enrollment',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchEnrollmentFailure(error));
  } else {
    yield put(fetchEnrollmentSuccess(data?.data?.result));
  }
}

export function* fetchSingleEnrollmentHandler({ payload }) {
  yield put(fetchSingleEnrollmentRequested());
  yield put(fetchStudentProfileRequested());
  yield put(fetchStudentsInstallmentRequested());
  yield put(fetchStudentsInstallmentSeqRequested());
  // yield put(fetchTransportRequested());
  yield put(fetchInstallmentRecapRequested());
  yield put(fetchInstallmentHistoryRequested());
  yield put(fetchTransportRecapRequested());
  yield put(fetchTransportHistoryRequested());
  const request = {
    method: 'GET',
    params: { school_year_id: payload?.school_year_id },
    url: `view-enrollment/${payload?.student_id}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);
console.log(data);
  if (error) {
    yield put(fetchSingleEnrollmentFailure(error));
    yield put(fetchStudentProfileFailure(error));
    yield put(fetchStudentsInstallmentFailure(error));
    yield put(fetchStudentsInstallmentSeqFailure(error));
    yield put(fetchInstallmentRecapFailure(error));
    yield put(fetchInstallmentHistoryFailure(error));
    yield put(fetchTransportRecapFailure(error));
    yield put(fetchTransportHistoryFailure(error));
    // yield put(fetchTransportFailure(error));
  } else {
    const response = EnrolmentHelper?.mapResponse(data);

    yield put(fetchStudentProfileSuccess(data?.data?.result));
    yield put(fetchSingleEnrollmentSuccess(response));
    yield put(fetchStudentsInstallmentSuccess(data?.data?.installment_dropdown));
    yield put(fetchStudentsInstallmentSeqSuccess(data?.data?.installment_seq|| []));

    // yield put(fetchTransportSuccess(data.data?.tranport_details || []));
    yield put(fetchInstallmentRecapSuccess(data?.data?.installment_recap || []));
    yield put(fetchInstallmentHistorySuccess(data?.data?.installment_history || []));
    yield put(fetchTransportRecapSuccess(data?.data?.transport_recap || []));
    yield put(fetchTransportHistorySuccess(data?.data?.transport_history || []));
  }
}

export function* fetchEnrolledStudentsHandler({ payload }) {
  yield put(fetchEnrolledStudentsRequested());

  const request = {
    method: 'POST',
    url: `auto-complete?name=${payload?.keyword}&&school_year_id=${payload?.school_year_id}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request, false);

  if (error) {
    yield put(fetchEnrolledStudentsFailure(error));
  } else {
    yield put(fetchEnrolledStudentsSuccess(data?.result?.data));
    if (payload.callback) {
      payload.callback(data?.result?.data);
    }
  }
}

export function* existingRegistrationHandler({ payload }) {
  yield put(existingRegistrationNumberRequested());
  const body = new FormData();

  body.append('student_registration_no', payload?.student_registration_no);
  body.append('school_year_id', payload?.school_year_id);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'existing-registration-no',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  return data;
  // if (error) {
  //   yield put(existingRegistrationNumberFailure(error));
  // } else {
  //   yield put(existingRegistrationNumberSuccess(data?.data?.key));
  // }
}

function* Enrollment() {
  yield all([
    takeLatest(CREATE_ENROLLMENT, createEnrollmentHandler),
    takeLatest(DELETE_ENROLLMENT, deleteEnrollmentHandler),
    takeLatest(DELETE_PAYMENT_HISTORY, deletePaymentHandler),
    takeLatest(DELETE_TRANSPORT_HISTORY, deleteTransportHandler),
    takeLatest(EDIT_ENROLLMENT, editEnrollmentHandler),
    takeLatest(EXISTING_REGISTRATION_NUMBER, existingRegistrationHandler),
    takeLatest(FETCH_ENROLLMENT, fetchEnrollmentHandler),
    takeLatest(FETCH_SINGLE_ENROLLMENT, fetchSingleEnrollmentHandler),
    takeLatest(FETCH_ENROLLED_STUDENTS, fetchEnrolledStudentsHandler),
  ]);
}

export default Enrollment;

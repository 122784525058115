import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { ADD_TRANSPORT,
  addTransportFailure,
  addTransportRequested,
  addTransportSuccess,
  DELETE_TRANSPORT,
  deleteTransportFailure,
  deleteTransportRequested,
  deleteTransportSuccess,
  EDIT_TRANSPORT,
  editTransportFailure,
  editTransportRequested,
  editTransportSuccess,
  FETCH_SINGLE_TRANSPORT,
  fetchSingleTransportFailure,
  fetchSingleTransportRequested,
  fetchSingleTransportSuccess,
  FETCH_TRANSPORT,
  fetchTransportFailure,
  fetchTransportRequested,
  fetchTransportSuccess,
  fetchTransport } from '../actions/transport-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from "i18next";

export function* addTransportHandler({ payload }) {
  yield put(addTransportRequested());
  const body = new FormData();

  body.append('amount_to_pay', payload.amount_to_pay);
  body.append('deadline', moment(payload.deadline).format('DD/MM/yyyy').toString());
  body.append('quater_name', payload.quater_name.value);
  body.append('student_id', payload.student_id);
  body.append('school_year_id', payload.school_year_id);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'save-transport',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(addTransportFailure(error));
  } else {
    const alertProps = {
      callback: () => {

      },
      icon: 'success',
        message: i18next.t('skullman:payment.installment.createTransportMessage'),
        title: i18next.t('skullman:common.successMessage'),
    };

    const response = { response: data.responsecode };

    Alert.alert(alertProps);
    yield put(addTransportSuccess(response));
    yield put(fetchTransport({
      school_year_id: payload.school_year_id,
      student_id: payload.student_id,
    }));
  }
}

export function* editTransportHandler({ payload }) {
  yield put(editTransportRequested());

  const body = new FormData();

  body.append('amount_to_pay', payload.amount_to_pay);
  body.append('deadline', moment(payload.deadline).format('DD/MM/yyyy').toString());
  body.append('quater_name', payload.quater_name.value);
  body.append('student_id', payload.student_id);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-transport/${payload.id}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(editTransportFailure(error));
  } else {
    const alertProps = {
      callback: () => {
      },
      icon: 'success',
        message: i18next.t('skullman:payment.installment.updateTransportMessage'),
        title: i18next.t('skullman:common.successMessage'),
    };

    const response = { response: data.responsecode };

    Alert.alert(alertProps);
    yield put(editTransportSuccess(response));
    yield put(fetchTransport({
      school_year_id: payload.school_year_id,
      student_id: payload.student_id,
    }));
  }
}

export function* deleteTransportHandler({ payload }) {
  yield put(deleteTransportRequested());
  const request = {
    method: 'DELETE',
    url: `delete-transport/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteTransportFailure(error));
  } else {
    const alertProps = {
      callback: () => {

      },
      icon: 'success',
        message: i18next.t('skullman:payment.installment.deleteTransportMessage'),
        title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteTransportSuccess(payload));
    yield put(fetchTransport({
      school_year_id: payload.school_year_id,
      student_id: payload.student_id,
    }));
  }
}

export function* fetchSingleTransportHandler({ payload }) {
  yield put(fetchSingleTransportRequested());
  const request = {
    method: 'GET',
    url: `view-transport/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleTransportFailure(error));
  } else {
    const { tranport } = data.data;
    const res = {
      ...data.data.transport,
      amount_to_pay: tranport.amount_to_pay,
      deadline: moment(tranport.deadline, 'X').toDate(),
      id: tranport.id,
      quater_name: {
        label: tranport.quater_name,
        value: tranport.quater_name,
      },
    };

    console.log(res.deadline);
    yield put(fetchSingleTransportSuccess(res));
  }
}

export function* fetchTransportHandler({ payload }) {
  yield put(fetchTransportRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'list-transport',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchTransportFailure(error));
  } else {
    // console.log(data.data.result)
    yield put(fetchTransportSuccess(data?.data?.tranport_details || []));
  }
}

function* Transports() {
  yield all([
    takeLatest(ADD_TRANSPORT, addTransportHandler),
    takeLatest(DELETE_TRANSPORT, deleteTransportHandler),
    takeLatest(EDIT_TRANSPORT, editTransportHandler),
    takeLatest(FETCH_TRANSPORT, fetchTransportHandler),
    takeLatest(FETCH_SINGLE_TRANSPORT, fetchSingleTransportHandler),
  ]);
}

export default Transports;

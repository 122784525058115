import { createAction } from 'redux-actions';

export const ADD_FORECAST = 'ADD_FORECAST';
export const addForecast = createAction(ADD_FORECAST);

export const ADD_FORECAST_FAILURE = 'ADD_FORECAST_FAILURE';
export const addForecastFailure = createAction(ADD_FORECAST_FAILURE);

export const ADD_FORECAST_REQUESTED = 'ADD_FORECAST_REQUESTED';
export const addForecastRequested = createAction(ADD_FORECAST_REQUESTED);

export const ADD_FORECAST_SUCCESS = 'ADD_FORECAST_SUCCESS';
export const addForecastSuccess = createAction(ADD_FORECAST_SUCCESS);

export const DELETE_FORECAST = 'DELETE_FORECAST';
export const deleteForecast = createAction(DELETE_FORECAST);

export const DELETE_FORECAST_FAILURE = 'DELETE_FORECAST_FAILURE';
export const deleteForecastFailure = createAction(DELETE_FORECAST_FAILURE);

export const DELETE_FORECAST_REQUESTED = 'DELETE_FORECAST_REQUESTED';
export const deleteForecastRequested = createAction(DELETE_FORECAST_REQUESTED);

export const DELETE_FORECAST_SUCCESS = 'DELETE_FORECAST_SUCCESS';
export const deleteForecastSuccess = createAction(DELETE_FORECAST_SUCCESS);

export const FETCH_FORECAST = 'FETCH_FORECAST';
export const fetchForecast = createAction(FETCH_FORECAST);

export const FETCH_FORECAST_FAILURE = 'FETCH_FORECAST_FAILURE';
export const fetchForecastFailure = createAction(FETCH_FORECAST_FAILURE);

export const FETCH_FORECAST_REQUESTED = 'FETCH_FORECAST_REQUESTED';
export const fetchForecastRequested = createAction(FETCH_FORECAST_REQUESTED);

export const FETCH_FORECAST_SUCCESS = 'FETCH_FORECAST_SUCCESS';
export const fetchForecastSuccess = createAction(FETCH_FORECAST_SUCCESS);

export const EDIT_FORECAST = 'EDIT_FORECAST';
export const editForecast = createAction(EDIT_FORECAST);

export const EDIT_FORECAST_FAILURE = 'EDIT_FORECAST_FAILURE';
export const editForecastFailure = createAction(EDIT_FORECAST_FAILURE);

export const EDIT_FORECAST_REQUESTED = 'EDIT_FORECAST_REQUESTED';
export const editForecastRequested = createAction(EDIT_FORECAST_REQUESTED);

export const EDIT_FORECAST_SUCCESS = 'EDIT_FORECAST_SUCCESS';
export const editForecastSuccess = createAction(EDIT_FORECAST_SUCCESS);

export const FETCH_SINGLE_FORECAST = 'FETCH_SINGLE_FORECAST';
export const fetchSingleForecast = createAction(FETCH_SINGLE_FORECAST);

export const FETCH_SINGLE_FORECAST_FAILURE = 'FETCH_SINGLE_FORECAST_FAILURE';
export const fetchSingleForecastFailure = createAction(FETCH_SINGLE_FORECAST_FAILURE);

export const FETCH_SINGLE_FORECAST_REQUESTED = 'FETCH_SINGLE_FORECAST_REQUESTED';
export const fetchSingleForecastRequested = createAction(FETCH_SINGLE_FORECAST_REQUESTED);

export const FETCH_SINGLE_FORECAST_SUCCESS = 'FETCH_SINGLE_FORECAST_SUCCESS';
export const fetchSingleForecastSuccess = createAction(FETCH_SINGLE_FORECAST_SUCCESS);

export const UPDATE_FORECAST_FORM = 'UPDATE_FORECAST_FORM';
export const updateForecastForm = createAction(UPDATE_FORECAST_FORM);

export const FETCH_WEEK_NUMBER = 'FETCH_WEEK_NUMBER';
export const fetchWeekNumber = createAction(FETCH_WEEK_NUMBER);

export const FETCH_WEEK_NUMBER_FAILURE = 'FETCH_WEEK_NUMBER_FAILURE';
export const fetchWeekNumberFailure = createAction(FETCH_FORECAST_FAILURE);

export const FETCH_WEEK_NUMBER_REQUESTED = 'FETCH_WEEK_NUMBER_REQUESTED';
export const fetchWeekNumberRequested = createAction(FETCH_WEEK_NUMBER_REQUESTED);

export const FETCH_WEEK_NUMBER_SUCCESS = 'FETCH_WEEK_NUMBER_SUCCESS';
export const fetchWeekNumberSuccess = createAction(FETCH_WEEK_NUMBER_SUCCESS);


export const FETCH_FORCAST_FILTER = 'FETCH_FORCAST_FILTER';
export const fetchForcastFilter = createAction(FETCH_FORCAST_FILTER);

export const FETCH_FORCAST_FILTER_FAILURE = 'FETCH_FORCAST_FILTER_FAILURE';
export const fetchForcastFilterFailure = createAction(FETCH_FORCAST_FILTER_FAILURE);

export const FETCH_FORCAST_FILTER_REQUESTED = 'FETCH_FORCAST_FILTER_REQUESTED';
export const fetchForcastFilterRequested = createAction(FETCH_FORCAST_FILTER_REQUESTED);

export const FETCH_FORCAST_FILTER_SUCCESS = 'FETCH_FORCAST_FILTER_SUCCESS';
export const fetchForcastFilterSuccess = createAction(FETCH_FORCAST_FILTER_SUCCESS);

import React, {  useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import{ updateStudentTutorForm} from '../../../actions/user-action-types';

const StudentMenu = ({  user }) => {
  const dispatch = useDispatch();
  const { form,dtutorStudents
  } = useSelector((store) => (
    {
      form: store?.schoolYears?.form,
      dtutorStudents: store?.user?.userDetail?.studentsTutor,
    }));

    const {school_year_id}=form;
  

  const [isStudentList, setIsStudentList] = useState(false);
  const [isDefaultFill, setIsDefaultFill] = useState(false);
  const tutorStudents = JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID)) ? JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID)) : [];
  const [isStudentIdSelected, setIsStudentIdSelected] = useState(false);JSON.parse
  const tutorStudentSelected = school_year_id && JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) &&  
  JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)).length>0 && 
  (dtutorStudents)?.
  filter((year) => year.sSchool_year_id === school_year_id?.value && year.sId=== JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)).value) 
  && (dtutorStudents)?.
  filter((year) => year.sSchool_year_id === school_year_id?.value && year.sId=== JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)).value) ?.length>0 ? 
  JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) : [];

  const [defaultStudent, setDefaultStudent] = useState([]); 

  useEffect(() => {
    if (tutorStudents && tutorStudents.length > 0) {
 
      const updates = {
        ...form1,
        /*student_filter: {
          label: tutorStudents[0].sFirstName +' '+ tutorStudents[0].sLastName +' '+ tutorStudents[0].cClassName,
          value: tutorStudents[0].sId,
        },*/
        student_filter:  tutorStudents?.filter((year) => year.sSchool_year_id === school_year_id)?.map((tutor) => ({
          label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
          value: tutor?.sId,
        }))[0],
        school_year_id:school_year_id?.value,
      };
      setForm1(updates);
      console.log(tutorStudents);
     console.log(tutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)?.map((tutor) => ({
      label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
      value: tutor?.sId,
    })
    )[0]
    );
    
       
      setIsStudentIdSelected(true);


      const updates1 = {
        ...form1,
        studentTutor: dtutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value),
        school_year_id:school_year_id?.value,
        student_filter: student_filter

      };

      setIsStudentList(true);
      dispatch(updateStudentTutorForm(updates1));
    }
  }, [isStudentList]);
  

  React.useEffect(() => {
    if (dtutorStudents && dtutorStudents.length > 0) {
     // if(!tutorStudentSelected){
      if(!defaultStudent){
        const defaultapp=dtutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)?.map((tutor) => ({
          label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
          value: tutor?.sId
        }))[0];
        console.log(value_app);
        console.log(defaultapp);
        const value_app=typeof defaultapp !== "undefined"?  defaultapp:{};
        localStorage.setItem(process.env.MOBILE_STUDENT_ID_SELECTED,JSON.stringify(value_app));
        
        setDefaultStudent(
          dtutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)?.map((tutor) => ({
            label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
            value: tutor?.sId,
          }))[0]
       );

      }
    }
    if((user?.user_type_id === 5 || user?.user_type_id === 6 || user?.user_type_id === 7) ){
    if(dtutorStudents && tutorStudentSelected?.length<=0){
      const defaultapp=dtutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)?.map((tutor) => ({
        label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
        value: tutor?.sId,
      }))[0];
     
      const value_app=typeof defaultapp !== undefined? defaultapp:{};
      console.log(value_app);
      console.log(defaultapp);
      if(value_app){
      localStorage.setItem(process.env.MOBILE_STUDENT_ID_SELECTED,JSON.stringify(value_app));
      }
    }
  }
  //  }
  }, [defaultStudent]);

  React.useEffect(() => {
    if (dtutorStudents && dtutorStudents.length > 0) {
     // if(!tutorStudentSelected){
      if(!defaultStudent){
        const defaultapp=dtutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)?.map((tutor) => ({
          label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
          value: tutor?.sId,
        }))[0];
        console.log(value_app);
        console.log(defaultapp);
        const value_app=typeof defaultapp !== "undefined"?  defaultapp:{};
        localStorage.setItem(process.env.MOBILE_STUDENT_ID_SELECTED,JSON.stringify(value_app));
    

      setDefaultStudent(
        dtutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)?.map((tutor) => ({
          label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
          value: tutor?.sId,
        }))[0]
     );

      }
    }
     
  //  }
  }, []);


  const [form1, setForm1] = useState({
    //student_filter: '' 
  });
  const { student_filter } = form1;

  const onSelectChange = (name, value) => {
    var student_id = value.value;
    const updates = {
      ...form1,
      student_filter: value,
      school_year_id:school_year_id?.value,
    };

    setForm1(updates);
    
    localStorage.setItem(
      process.env.MOBILE_STUDENT_ID_SELECTED,
      JSON.stringify(value),
    );


    const updates1 = {
      ...form1,
      studentTutor: tutorStudents?.filter(element => element?.sId ==student_id),
      school_year_id:school_year_id?.value,
    };

   dispatch(updateStudentTutorForm(updates1));
   window.location.reload();
  };
console.log("schoolyear",school_year_id);
 

  return (
              <>
                  {(user?.user_type_id === 5 || user?.user_type_id === 6 || user?.user_type_id === 7) ?(
             
                 <>                    
                       {defaultStudent && tutorStudents?.length > 0 ?
                        <Select
                           id="student_filter"
                           name="student_filter"
                           placeholder='Select Student'
                           className="student_input"
                           style={{control: (base) => ({
                             ...base,
                             minHeight: 'initial',
                             fontSize:'10px'
                           }),
                           valueContainer: (base) => ({
                             ...base,
                             fontSize:'xx-small'
                           }),
                           clearIndicator: (base) => ({
                             ...base,
                             padding: `${(30 - 20 - 1 - 1) / 2}px`,
                             fontSize:'10px'
                           }),
                           dropdownIndicator: (base) => ({
                             ...base,
                             padding: `${(30 - 20 - 1 - 1) / 2}px`,
                             fontSize:'10px'
                           })}}
                           onChange={(value) => onSelectChange('student_filter', value)}
                           options={
                             tutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)
                             .map((reason) => ({
                               label: `${reason.sFirstName}` + ' ' + `${reason.sLastName}` + ' '
                                + `${reason.cClassName}`,
                               value: reason.sId,
                             }))}
                           value={(user?.user_type_id === 5 || user?.user_type_id === 6 || user?.user_type_id === 7) && tutorStudentSelected?.length<=0?
                            (dtutorStudents?.filter((year) => year.sSchool_year_id === school_year_id?.value)?.map((tutor) => ({
        label: tutor?.sFirstName +' '+ tutor?.sLastName +' '+ tutor?.cClassName,
        value: tutor?.sId,
      }))[0]):(tutorStudentSelected || defaultStudent || student_filter)}
                           width="100px"
                         />
                         : ''}
                     </>
                    
                  
                   ):<div></div>
                            }
                  
            
           </>
        )}
export default StudentMenu;

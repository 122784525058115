import { createAction } from 'redux-actions';

export const ADD_EMERGENCY_CONTACT = 'ADD_EMERGENCY_CONTACT';
export const addEmergencyContact = createAction(ADD_EMERGENCY_CONTACT);

export const ADD_EMERGENCY_CONTACT_FAILURE = 'ADD_EMERGENCY_CONTACT_FAILURE';
export const addEmergencyContactFailure = createAction(ADD_EMERGENCY_CONTACT_FAILURE);

export const ADD_EMERGENCY_CONTACT_REQUESTED = 'ADD_EMERGENCY_CONTACT_REQUESTED';
export const addEmergencyContactRequested = createAction(ADD_EMERGENCY_CONTACT_REQUESTED);

export const ADD_EMERGENCY_CONTACT_SUCCESS = 'ADD_EMERGENCY_CONTACT_SUCCESS';
export const addEmergencyContactSuccess = createAction(ADD_EMERGENCY_CONTACT_SUCCESS);

export const DELETE_EMERGENCY_CONTACT = 'DELETE_EMERGENCY_CONTACT';
export const deleteEmergencyContact = createAction(DELETE_EMERGENCY_CONTACT);

export const DELETE_EMERGENCY_CONTACT_FAILURE = 'DELETE_EMERGENCY_CONTACT_FAILURE';
export const deleteEmergencyContactFailure = createAction(DELETE_EMERGENCY_CONTACT_FAILURE);

export const DELETE_EMERGENCY_CONTACT_REQUESTED = 'DELETE_EMERGENCY_CONTACT_REQUESTED';
export const deleteEmergencyContactRequested = createAction(DELETE_EMERGENCY_CONTACT_REQUESTED);

export const DELETE_EMERGENCY_CONTACT_SUCCESS = 'DELETE_EMERGENCY_CONTACT_SUCCESS';
export const deleteEmergencyContactSuccess = createAction(DELETE_EMERGENCY_CONTACT_SUCCESS);

export const FETCH_EMERGENCY_CONTACT = 'FETCH_EMERGENCY_CONTACT';
export const fetchEmergencyContact = createAction(FETCH_EMERGENCY_CONTACT);

export const FETCH_EMERGENCY_CONTACT_FAILURE = 'FETCH_EMERGENCY_CONTACT_FAILURE';
export const fetchEmergencyContactFailure = createAction(FETCH_EMERGENCY_CONTACT_FAILURE);

export const FETCH_EMERGENCY_CONTACT_REQUESTED = 'FETCH_EMERGENCY_CONTACT_REQUESTED';
export const fetchEmergencyContactRequested = createAction(FETCH_EMERGENCY_CONTACT_REQUESTED);

export const FETCH_EMERGENCY_CONTACT_SUCCESS = 'FETCH_EMERGENCY_CONTACT_SUCCESS';
export const fetchEmergencyContactSuccess = createAction(FETCH_EMERGENCY_CONTACT_SUCCESS);

export const EDIT_EMERGENCY_CONTACT = 'EDIT_EMERGENCY_CONTACT';
export const editEmergencyContact = createAction(EDIT_EMERGENCY_CONTACT);

export const EDIT_EMERGENCY_CONTACT_FAILURE = 'EDIT_EMERGENCY_CONTACT_FAILURE';
export const editEmergencyContactFailure = createAction(EDIT_EMERGENCY_CONTACT_FAILURE);

export const EDIT_EMERGENCY_CONTACT_REQUESTED = 'EDIT_EMERGENCY_CONTACT_REQUESTED';
export const editEmergencyContactRequested = createAction(EDIT_EMERGENCY_CONTACT_REQUESTED);

export const EDIT_EMERGENCY_CONTACT_SUCCESS = 'EDIT_EMERGENCY_CONTACT_SUCCESS';
export const editEmergencyContactSuccess = createAction(EDIT_EMERGENCY_CONTACT_SUCCESS);

export const FETCH_SINGLE_EMERGENCY_CONTACT = 'FETCH_SINGLE_EMERGENCY_CONTACT';
export const fetchSingleEmergencyContact = createAction(FETCH_SINGLE_EMERGENCY_CONTACT);

export const FETCH_SINGLE_EMERGENCY_CONTACT_FAILURE = 'FETCH_SINGLE_EMERGENCY_CONTACT_FAILURE';
export const fetchSingleEmergencyContactFailure = createAction(FETCH_SINGLE_EMERGENCY_CONTACT_FAILURE);

export const FETCH_SINGLE_EMERGENCY_CONTACT_REQUESTED = 'FETCH_SINGLE_EMERGENCY_CONTACT_REQUESTED';
export const fetchSingleEmergencyContactRequested = createAction(FETCH_SINGLE_EMERGENCY_CONTACT_REQUESTED);

export const FETCH_SINGLE_EMERGENCY_CONTACT_SUCCESS = 'FETCH_SINGLE_EMERGENCY_CONTACT_SUCCESS';
export const fetchSingleEmergencyContactSuccess = createAction(FETCH_SINGLE_EMERGENCY_CONTACT_SUCCESS);

export const UPDATE_EMERGENCY_CONTACT_FORM = 'UPDATE_EMERGENCY_CONTACT_FORM';
export const updateEmergencyContactForm = createAction(UPDATE_EMERGENCY_CONTACT_FORM);

export const DELETE_EMERGENCY_CONTACT_CLASS = 'DELETE_EMERGENCY_CONTACT_CLASS';
export const deleteEmergencyContactClass = createAction(DELETE_EMERGENCY_CONTACT_CLASS);


import React from 'react';
import { bool, func, string } from 'prop-types';
import Button from './button';
import Utils from '../utility';
import {useTranslation} from "react-i18next";
import { Row, Col, Card, CardBody,CardTitle,Form,
  FormGroup,
  Label,
  Input,
  //Table,
  CardHeader, } from "reactstrap";
import SearchBar from './mobile/SearchBar';
import { isMobile } from 'react-device-detect';

const DashboardHeader = ({
  title, onAdd, onEdit, onDelete,addText, buttonStyle,  onImport, onExcelExport, onPDFExport, supportAdd, supportEdit, supportDelete, supportBulkImport, supportPDFExport, supportExcelExport,onPDFInsolvExport,supportPDFInsolvExport
}) =>{
  const [t] = useTranslation('skullman');
  return(
    <CardHeader style={isMobile ? {padding:'0px 0px',backgroundColor:'transparent',border:'none'} : {}}>
       <CardTitle className="font-size-lg text-capitalize font-weight-normal flex-fill" >
        {isMobile ? 
        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <SearchBar onChange={(event) => setQuery(event.target.value)} />
      </div>
      : <div>{title}</div>
      }  
          </CardTitle>
      {/* <CardTitle className="font-size-lg text-capitalize font-weight-normal flex-fill">{title}</CardTitle> */}
      <div >
      <div className="actions" >
        {supportAdd && (
          <Button style={isMobile ?{width: '317px',height: '43px',borderRadius: '8px',backgroundColor: '#555BD4',border: 'none',boxShadow: 'none',fontSize: '14px',fontWeight: '500'}:{}} onClick={onAdd} className={buttonStyle === true ? "btn-shadow btn-wide btn btn-sm btn-info text-capitalize" : "btn-pill btn-shadow btn-wide btn btn-sm btn-info text-capitalize"}>
            { t('tution.payment.installment.add')}
          </Button>
        )}
        {supportEdit && (
          <Button style={isMobile ?{width: '317px',height: '43px',borderRadius: '8px',backgroundColor: '#555BD4',border: 'none',boxShadow: 'none',fontSize: '14px',fontWeight: '500'}:{}} onClick={onEdit} className="btn-pill btn-shadow btn-wide btn btn-sm btn-primary text-capitalize ml-2">
            { t('users.edit')}
          </Button>
        )}
        {supportDelete && (
          <Button style={isMobile ?{width: '317px',height: '43px',borderRadius: '8px',backgroundColor: '#555BD4',border: 'none',boxShadow: 'none',fontSize: '14px',fontWeight: '500'}:{}} onClick={onDelete} className="btn-pill btn-shadow btn-wide btn btn-sm btn-danger spacing">
            { t('tution.payment.installment.delete')}
          </Button>
        )}
        {supportBulkImport && (
          <Button style={isMobile ?{width: '317px',height: '43px',borderRadius: '8px',backgroundColor: '#555BD4',border: 'none',boxShadow: 'none',fontSize: '14px',fontWeight: '500'}:{}} onClick={onImport} className="btn-pill btn-shadow btn-wide btn btn-sm btn-primary">
            { t('tution.payment.installment.bulkImport')}
          </Button>
        )}
        {supportExcelExport && (
          <Button style={isMobile ?{width: '317px',height: '43px',borderRadius: '8px',backgroundColor: '#555BD4',border: 'none',boxShadow: 'none',fontSize: '14px',fontWeight: '500'}:{}} onClick={onExcelExport} className="ml-2 p-0 btn">
            <img
              src={Utils.getImage('icons/excel.svg')}
              height={35}
              data-toggle="tooltip"
              data-placement="top"
              title="Export to Excel"
              alt='Export'
            />
          </Button>
        )}
        { supportPDFExport && (
          <Button style={isMobile ?{width: '317px',height: '43px',borderRadius: '8px',backgroundColor: '#555BD4',border: 'none',boxShadow: 'none',fontSize: '14px',fontWeight: '500'}:{}} onClick={onPDFExport} className=" mr-3 ml-2 p-0 btn">
            <img alt="Export PDF" src={Utils.getImage('icons/pdf.svg')} height={35} data-toggle="tooltip" data-placement="top" title="Export to PDF" />
          </Button>
        )}
        { supportPDFInsolvExport && (
          <Button style={isMobile ?{width: '317px',height: '43px',borderRadius: '8px',backgroundColor: '#555BD4',border: 'none',boxShadow: 'none',fontSize: '14px',fontWeight: '500'}:{}} onClick={onPDFInsolvExport} className=" mr-3 ml-2 p-0 btn">
            <img alt="Export Insolvable PDF" src={Utils.getImage('icons/insolvent.svg')} height={35} data-toggle="tooltip" data-placement="top" title="Export Insolvable to PDF" />
          </Button>
        )}
      </div>
      </div>
    </CardHeader>
  );
}



DashboardHeader.propTypes = {
  onAdd: func.isRequired,
  onDelete: func.isRequired,
  onExcelExport: func.isRequired,
  onImport: func.isRequired,
  onPDFExport: func.isRequired,
  supportAdd: bool,
  supportBulkImport: bool,
  supportDelete: bool,
  supportEdit: bool,
  supportExcelExport: bool,
  supportPDFExport: bool,
  title: string.isRequired,
};

DashboardHeader.defaultProps = {
  supportAdd: true,
  supportBulkImport: true,
  supportDelete: true,
  supportEdit: false,
  supportExcelExport: true,
  supportPDFExport: false,
  addText: "Add"
};

export default DashboardHeader;

import { Bell } from "react-feather";
import styled from "styled-components";
import React from "react";
import { IoIosCalendar } from "react-icons/io";
import {
  Button,
} from "reactstrap";
import "react-toastify/ReactToastify.min.css";
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  color: info;
  :hover {
    cursor: pointer;
  }
  span {
    position: absolute;
    top: -8px;
    right: -8px;
    background: red;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Trigger = ({ count, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
     <Bell />
      <span>{count}</span>
    </Wrapper>
  );
}

import { string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CommentTextAreaRenderer = ({  data, _onChangeComment,class_id, student_id, }) => {
  const { form } = useSelector((store) => ({ form: store.Attendance.form }));
  const [checkedcomment, setCheckedcomment] = React.useState(null); 

  const onChangeText = (e) => {

    setCheckedcomment(e.target.value);
    const update = {
      comment: e.target.value,
      class_id,
      student_id,
    }; 
    _onChangeComment(update);
  }
  console.log('Thi is my data', data);
  
  return (
    <>
      <textarea defaultValue={data}   name="comment" onChange={onChangeText} className=" form-control" />
    </>
  );
};

CommentTextAreaRenderer.propTypes = {
  className: string,
};

export default CommentTextAreaRenderer;

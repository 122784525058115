import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CREATE_OTHER_PARAMETERS,
  createOtherParametersFailure,
  createOtherParametersRequested,
  createOtherParametersSuccess,
  EDIT_OTHER_PARAMETERS,
  editOtherParametersFailure,
  editOtherParametersRequested,
  editOtherParametersSuccess,
  FETCH_SINGLE_OTHER_PARAMETERS,
  fetchSingleOtherParametersFailure,
  fetchSingleOtherParametersRequested,
  fetchSingleOtherParametersSuccess } from '../actions/other-parameters-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

export function* createOtherParametersHandler({ payload }) {
  yield put(createOtherParametersRequested());
  yield put(fetchSingleOtherParametersRequested());
  const body = new FormData();

  body.append('no_of_days_before_being_almost_late_for_payment', payload.no_of_days_before_being_almost_late_for_payment);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'other-parameter',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createOtherParametersFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        window.location.reload();
      },
      icon: 'success',
      message: i18next.t('skullman:setting.otherParameter.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(createOtherParametersSuccess());
  }
}

export function* editOtherParametersHandler({ payload }) {
  yield put(editOtherParametersRequested());
  const request = {
    method: 'PUT',
    params: { no_of_days_before_being_almost_late_for_payment: payload.no_of_days_before_being_almost_late_for_payment },
    url: `update-OtherParameters/${payload.OtherParameters_reason_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editOtherParametersFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/other-parameters');
      },
      icon: 'success',
      message: i18next.t('skullman:setting.otherParameter.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(editOtherParametersSuccess());
  }
}

export function* fetchSingleOtherParametersHandler() {
  yield put(fetchSingleOtherParametersRequested());

  const request = {
    method: 'GET',
    url: 'get-other-parameter',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleOtherParametersFailure(error));
  } else {
    const res = { ...data.data };

    yield put(fetchSingleOtherParametersSuccess(res));
  }
}

function* otherParameters() {
  yield all([
    takeLatest(CREATE_OTHER_PARAMETERS, createOtherParametersHandler),
    takeLatest(EDIT_OTHER_PARAMETERS, editOtherParametersHandler),
    takeLatest(FETCH_SINGLE_OTHER_PARAMETERS, fetchSingleOtherParametersHandler),
  ]);
}

export default otherParameters;

import { LOCATION_CHANGE } from 'connected-react-router';
import { SET_RESSOURCES,
    EDIT_RESSOURCES,
    SET_INITIAL_STATE,
    FETCH_RESSOURCE_FAILURE,
    FETCH_RESSOURCE_REQUESTED,
    FETCH_RESSOURCE_SUCCESS } from '../actions/ressources-action-type';

const initialState = {
  form: {
    ressource_id: null,
    ressource_name: null,
    ressource_code: null,
    permission_name: null,
    permission_code: null,
    status:null,
  },
  ressources: [],
};

export default function ressources(state = initialState, {
  payload, type,
}) {
  // console.log(type,payload)
  switch (type) {
    case FETCH_RESSOURCE_FAILURE:
      return {
        ...state,
        fetchRessourceStatus: 'failure',
      };

    case FETCH_RESSOURCE_REQUESTED:
      return {
        ...state,
        fetchRessourceStatus: 'fetching',
      };

    case FETCH_RESSOURCE_SUCCESS:
      return {
        ...state,
        fetchRessourceStatus: 'success',
        form: { ...state.form },
        ressources: payload,

      };
      case EDIT_RESSOURCES:
        return {
          ...state,
          form: payload,
        };
   

    case LOCATION_CHANGE:
      return { ...state };

    case SET_INITIAL_STATE:
      return { ...initialState };

    default:
      return state;
  }
}

import {all, call, put, takeLatest} from 'redux-saga/effects';
import {ADD_SEQUENCE,
addSequenceFailure,
addSequenceRequested,
addSequenceSuccess,
DELETE_SEQUENCE,
deleteSequenceFailure,
deleteSequenceRequested,
deleteSequenceSuccess,
EDIT_SEQUENCE,
editSequenceFailure,
editSequenceRequested,
editSequenceSuccess,
FETCH_SEQUENCE,
fetchSequenceFailure,
fetchSequenceRequested,
fetchSequenceSuccess} from "../actions/sequence-action-type";
import httpClient from "./http-client";

export function* addSequenceHandler({payload}){
    yield put(addSequenceRequested());
    const body = new FormData();

    body.append('sequences', JSON.stringify(payload.sequences));
    body.append('school_year_id', payload.school_year_id);

    const request = {
        data: body,
        headers: {'Content-Type': 'multipart/form-data'},
        method: 'POST',
        url: 'add-update-sequence'
    };

    const {error} = yield call(httpClient, request);

    if(error){
        yield put(addSequenceFailure(error));
    }else{
        yield put(addSequenceSuccess());
    }
}

export function* editSequenceHandler({payload}){
    yield put(editSequenceRequested());

    const body = new FormData();

    body.append('sequences', payload.sequences);
    body.append('school_year_id', payload.school_year_id);

    const request = {
        data: body,
        headers: {'Content-Type': 'multipart/form-data'},
        method: 'POST',
        url: 'update-sequence'
    };

    const {error} = yield call(httpClient, request);

    if(error){
        yield put(editSequenceFailure(error));
    }else{
        yield put(editSequenceSuccess());
    }

}

export function* deleteSequenceHandler({payload}){
    yield put(deleteSequenceRequested());

    const request = {
        headers: {'Content-Type': 'multipart/form-data'},
        method: 'GET',
        url: 'sequence/delete/'+payload.sequenceId
    };

    const {error} = yield call(httpClient, request);

    if(error){
        yield put(deleteSequenceFailure(error));
    }else{
        yield put(deleteSequenceSuccess());
    }
}

export function* fetchSequenceHandler({payload}){
    yield put(fetchSequenceRequested());

    let request = {
        url: '/get-sequences',
        params: payload,
        method: 'GET'
    }

    const {
        data, error
    } = yield call(httpClient, request);

    if(error){
        yield put(fetchSequenceFailure(error));
    }else {
        yield put(fetchSequenceSuccess((data.data.result.length > 0)?data.data.result:[
            {
                id: 0,
                sequence_name: '',
                percentage: '',
                editable: true,
                removed: false
            }
            ])
        );
    }
}


function* Sequence(){
    yield all([
        takeLatest(ADD_SEQUENCE, addSequenceHandler),
        takeLatest(DELETE_SEQUENCE, deleteSequenceHandler),
        takeLatest(EDIT_SEQUENCE, editSequenceHandler),
        takeLatest(FETCH_SEQUENCE, fetchSequenceHandler)
    ]);
}

export default Sequence;
import { LOCATION_CHANGE } from "connected-react-router";
import {
    MARKS_REPORT_REQUEST,
    MARKS_REPORT_REQUEST_CREATED,
    MARKS_REPORT_REQUEST_SUCCESS,
    MARKS_REPORT_REQUEST_FAILURE,
    FETCH_MARKS_REPORT_REQUEST,
    FETCH_MARKS_REPORT_REQUEST_CREATED,
    FETCH_MARKS_REPORT_REQUEST_SUCCESS,
    FETCH_MARKS_REPORT_REQUEST_FAILURE,
    DOWNLOAD_MARKS_REPORT,
    DOWNLOAD_MARKS_REPORT_SUCCESS,
    DOWNLOAD_MARKS_REPORT_FAILURE,
    DOWNLOAD_MARKS_REPORT_REQUESTED
} from '../actions/marks-report-request-action-types'

const initialState = {
    marksReportRequestStatus: 'requested',
    fetchMarksReportRequestStatus: 'requested',
    downloadMarksReportStatus: 'requested',
    requestedReports: [],
    form: {
        type: '',
        status: '',
        comment: ''
    }
};

export default function MarksReportRequest(state = initialState, {
    payload, type
}) {
    switch(type){
        case MARKS_REPORT_REQUEST: 
            return {
                ...state,
                marksReportRequestStatus: 'requested'
            };

        case MARKS_REPORT_REQUEST_CREATED: 
            return {
                ...state,
                marksReportRequestStatus: 'created'
            };

        case MARKS_REPORT_REQUEST_SUCCESS:
            return {
                ...state,
                marksReportRequestStatus: 'success'
            };

        case MARKS_REPORT_REQUEST_FAILURE:
            return {
                ...state,
                marksReportRequestStatus: 'failure'
            };
        
        case FETCH_MARKS_REPORT_REQUEST: {
            return {
                ...state,
                fetchMarksReportRequestStatus: 'requested'
            };
        }

        case FETCH_MARKS_REPORT_REQUEST_CREATED:
            return {
                ...state,
                fetchMarksReportRequestStatus: 'created'
            };

        case FETCH_MARKS_REPORT_REQUEST_SUCCESS:
            return {
                ...state,
                requestedReports: payload,
                fetchMarksReportRequestStatus: 'success'
            }
        
        case FETCH_MARKS_REPORT_REQUEST_FAILURE: 
            return {
                ...state,
                fetchMarksReportRequestStatus: 'failure'
            }

        case DOWNLOAD_MARKS_REPORT: 
            return {
                ...state,
                downloadMarksReportStatus: 'requested'
            }

        case DOWNLOAD_MARKS_REPORT_REQUESTED: 
            return {
                ...state,
                downloadMarksReportStatus: 'created'
            }

        case DOWNLOAD_MARKS_REPORT_SUCCESS: 
            return {
                ...state,
                downloadMarksReportStatus: 'success'
            }

        case DOWNLOAD_MARKS_REPORT_FAILURE:
            return {
                ...state,
                downloadMarksReportStatus: 'failure'
            }

        case LOCATION_CHANGE:
            return {...initialState};

        default:
            return state;
    }
}
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  Input,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import styled from "styled-components";
import { IoIosAdd } from "react-icons/io";
import Dropzone from "./dropzone";

export default function AddPostModal() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      <AddButton onClick={toggle}>
        <IoIosAdd style={{ color: "white", fontSize: 35 }} />
      </AddButton>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <ModalHeading>Add Post</ModalHeading>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <TitleInput placeholder="Enter title" />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <TextArea
                    type="textarea"
                    placeholder="Description here"
                    rows={5}
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="startDate">Start Date</Label>
                  <TitleInput
                    name="startDate"
                    type="date"
                    placeholder="Start Date"
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="endDate">End Date</Label>
                  <TitleInput name="endDate" type="date" />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <Dropzone />
                </FormGroup>
              </Col>
              <Footer xs={12}>
                <CloseButton onClick={toggle}>Close</CloseButton>
                <SaveButton type="submit">Save</SaveButton>
              </Footer>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

const AddButton = styled(Button)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #555bd4;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.14);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalHeading = styled.h1`
  color: rgba(0, 0, 0, 0.71);
  font-size: 17px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 5px;
`;

const TitleInput = styled(Input)`
  height: 45px;
`;

const TextArea = styled(Input)``;

const CloseButton = styled(Button)`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  color: black;
  width: 86px;
  margin-right: 5px;
`;

const SaveButton = styled(Button)`
  background: #555bd4;
  border-radius: 6px;
  width: 86px;
  margin-left: 5px;
`;

const Footer = styled(Col)`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottm: 5px;
`;

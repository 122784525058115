import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_PROFESSIONAL_EXPERIENCE_FAILURE,
  ADD_PROFESSIONAL_EXPERIENCE_REQUESTED,
  ADD_PROFESSIONAL_EXPERIENCE_SUCCESS,
  DELETE_PROFESSIONAL_EXPERIENCE_FAILURE,
  DELETE_PROFESSIONAL_EXPERIENCE_REQUESTED,
  DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS,
  FETCH_PROFESSIONAL_EXPERIENCE_FAILURE,
  FETCH_PROFESSIONAL_EXPERIENCE_REQUESTED,
  FETCH_PROFESSIONAL_EXPERIENCE_SUCCESS,
  FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_FAILURE,
  FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_REQUESTED,
  FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_SUCCESS,
  UPDATE_PROFESSIONAL_EXPERIENCE_FORM
} from '../actions/professional-experience-action-type';

const initialState = {
  createProfessionalExperienceStatus: 'pending',
  deleteProfessionalExperienceStatus: 'pending',
  fetchSingleProfessionalExperienceStatus: 'pending',
  fetchProfessionalExperiencesStatus: 'pending',
  form: {
    employee_id: '',
    company_name: '',
    location: '',
    job_name: '',
    from_date: '0000-00-00 00:00:00',
    to_date: '0000-00-00 00:00:00',
  },
  professionalExperiences: [],
};

export default function ProfessionalExperiences(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        createProfessionalExperienceStatus: 'failure',
      };

    case ADD_PROFESSIONAL_EXPERIENCE_REQUESTED:
      return {
        ...state,
        createProfessionalExperienceStatus: 'creating',
      };

    case ADD_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        // class_list: payload.class_list,
        createProfessionalExperienceStatus: 'success',
       // file: encodeURI(payload.image),
       // profile_picture: encodeURI(payload.image),
      };

    case DELETE_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        deleteProfessionalExperienceStatus: 'failure',
      };

    case DELETE_PROFESSIONAL_EXPERIENCE_REQUESTED:
      return {
        ...state,
        deleteProfessionalExperienceStatus: 'deleting',
      };

    case DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        deleteProfessionalExperienceStatus: 'success',
        ProfessionalExperiences: [...state.ProfessionalExperiences].filter((professionalExperienceItem) => !(payload.toString().includes(professionalExperienceItem.id.toString()))),
      };

    case FETCH_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        fetchProfessionalExperiencesStatus: 'failure',
      };

    case FETCH_PROFESSIONAL_EXPERIENCE_REQUESTED:
      return {
        ...state,
        fetchProfessionalExperiencesStatus: 'fetching',
      };

    case FETCH_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        fetchProfessionalExperiencesStatus: 'success',
        ProfessionalExperiences: payload,
      };

    case FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        fetchSingleProfessionalExperienceStatus: 'failure',
      };

    case FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_REQUESTED:
      return {
        ...state,
        fetchSingleProfessionalExperienceStatus: 'fetching',
      };

    case FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        fetchSingleProfessionalExperienceStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.image),
          profile_picture: encodeURI(payload.image),
        },
      };

    case UPDATE_PROFESSIONAL_EXPERIENCE_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import React from 'react';
import {
    USER_SUPER_ADMIN,
    USER_SCHOOL_ADMIN,
    USER_SCHOOL_SUB_ADMIN,
    USER_PARENT,
    USER_STUDENT,
    USER_TEACHER,
    SCHOOLWALL,
    SCHOOLWALL_ADD_POST,
    SCHOOLWALL_VIEW_WALL,
    SCHOOLWALL_DELETE_POST,
    SCHOOLWALL_UPDATE_POST,
    SETTINGS_SCHOOLINFORMATION,
    SETTINGS_SCHOOLINFORMATION_EDIT,
    SETTINGS_SCHOOLINFORMATION_VIEW,
    SETTINGS_HONNORROLL,
    SETTINGS_HONNORROLL_VIEW,
    SETTINGS_HONNORROLL_LIST,
    SETTINGS_HONNORROLL_EDIT,
    SETTINGS_QUALIFICATION,
    SETTINGS_QUALIFICATION_UPDATE,
    SETTINGS_QUALIFICATION_ADD,
    SETTINGS_SCHOOLYEAR,
    SETTINGS_SCHOOLYEAR_ADD,
    SETTINGS_SCHOOLYEAR_DELETE,
    SETTINGS_SCHOOLYEAR_LIST,
    SETTINGS_SCHOOLYEAR_UPDATE,
    USERS,
    USERS_ADD,
    USERS_LIST,
    USERS_DELETE,
    USERS_UPDATE,
    SETTINGS_CLASSES,
    SETTINGS_CLASSES_ADD,
    SETTINGS_CLASSES_DELETE,
    SETTINGS_CLASSES_LIST,
    SETTINGS_APPRECIATION_UPDATE,
    SETTINGS_CLASSES_UPDATE,
    PEDAGOGICALFOLLOWUP_CHAPTER,
    PEDAGOGICALFOLLOWUP_CHAPTER_ADD,
    PEDAGOGICALFOLLOWUP_CHAPTER_DELETE,
    PEDAGOGICALFOLLOWUP_CHAPTER_LIST,
    GRADES_ATTENDANCEMANAGEMENT,
    GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,
    GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,
    GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST,
    PEDAGOGICALFOLLOWUP_PART,
    PEDAGOGICALFOLLOWUP_PART_ADD,
    PEDAGOGICALFOLLOWUP_PART_LIST,
    PEDAGOGICALFOLLOWUP_PART_DELETE,
    PEDAGOGICALFOLLOWUP_PART_UPDATE,
    PEDAGOGICALFOLLOWUP_REPORT,
    PEDAGOGICALFOLLOWUP_REPORT_VIEW,
    PEDAGOGICALFOLLOWUP_FORECAST,
    PEDAGOGICALFOLLOWUP_FORECAST_ADD,
    PEDAGOGICALFOLLOWUP_FORECAST_DELETE,
    PEDAGOGICALFOLLOWUP_FORECAST_LIST,
    PEDAGOGICALFOLLOWUP_FORECAST_UPDATE,
    PEDAGOGICALFOLLOWUP_PROGRESSION,
    PEDAGOGICALFOLLOWUP_PROGRESSION_ADD,
    PEDAGOGICALFOLLOWUP_PROGRESSION_DELETE,
    PEDAGOGICALFOLLOWUP_PROGRESSION_LIST,
    PEDAGOGICALFOLLOWUP_PROGRESSION_UPDATE,
    SETTINGS_SCHOOL,
    SETTINGS_SCHOOL_VIEW,
    SETTINGS_SCHOOL_EDIT,
    SETTINGS_WEEKS,
    SETTINGS_WEEKS_ADD,
    SETTINGS_WEEKS_DELETE,
    SETTINGS_WEEKS_LIST,
    SETTINGS_WEEKS_UPDATE,
    SCHEDULE,
    SCHEDULE_CREATE_SLOT,
    SCHEDULE_DELETE_SLOT,
    SCHEDULE_UPDATE_SLOT,
    SCHEDULE_VIEW_SCHEDULE,
    GRADES_MARKMANAGEMENT,
    GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,
    GRADES_MARKMANAGEMENT_DELETE_MARKS,
    GRADES_MARKMANAGEMENT_NEW_MARK,
    GRADES_MARKMANAGEMENT_VALIDATE_MARKS,
    GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST,
    GRADES_MARKREPORT,
    GRADES_MARKREPORT_GRADES__MARK_REPORT,
    SETTINGS_APPRECIATION,
    SETTINGS_APPRECIATION_ADD,
    SETTINGS_APPRECIATION_DELETE,
    SETTINGS_APPRECIATION_LIST,
    GRADES_STUDENTS,
    GRADES_STUDENTS_VIEW_STUDENT_LIST,
    TUITION_ENROLLMENT,
    TUITION_ENROLLMENT_ADD,
    TUITION_ENROLLMENT_BULK_IMPORT,
    TUITION_ENROLLMENT_DELETE,
    TUITION_ENROLLMENT_LIST,
    TUITION_ENROLLMENT_UPDATE,
    FINANCE_OUTFLOWS,
    FINANCE_OUTFLOWS_ADD,
    FINANCE_OUTFLOWS_BULK_IMPORT,
    FINANCE_OUTFLOWS_DELETE,
    FINANCE_OUTFLOWS_LIST,
    FINANCE_OUTFLOWS_UPDATE,
    FINANCE_OUTFLOWS_UPDATE_DATE,
    SETTINGS_INSTALLMENT,
    SETTINGS_INSTALLMENT_ADD,
    SETTINGS_INSTALLMENT_DELETE,
    SETTINGS_INSTALLMENT_LIST,
    SETTINGS_INSTALLMENT_UPDATE,
    FINANCE_ENTRIES,
    FINANCE_ENTRIES_ADD,
    FINANCE_ENTRIES_BULK_IMPORT,
    FINANCE_ENTRIES_DELETE,
    FINANCE_ENTRIES_LIST,
    FINANCE_ENTRIES_UPDATE,
    FINANCE_ENTRIES_UPDATE_DATE,
    REPORTING_ENROLLMENT_FINANCIAL_REPORT,
    REPORTING_ENROLLMENT_FINANCIAL_REPORT_VIEW_REPORT,
    REPORTING_ENTRIESDAILYREPORT,
    REPORTING_ENTRIESDAILYREPORT_VIEW_REPORT,
    SETTINGS_PAYMENTTYPE,
    SETTINGS_PAYMENTTYPE_ADD,
    SETTINGS_PAYMENTTYPE_DELETE,
    SETTINGS_PAYMENTTYPE_LIST,
    SETTINGS_PAYMENTTYPE_UPDATE,
    TUITION_PAYMENT,
    TUITION_PAYMENT_DELETE_PAYMENT_INSYALLMENT,
    TUITION_PAYMENT_LIST,
    TUITION_PAYMENT_NEW_PAYMENT_INSTALLMENT,
    TUITION_PAYMENT_UPDATE_DATE_TIME,
    SETTINGS_OTHERPARAMETERS,
    SETTINGS_OTHERPARAMETERS_EDIT,
    SETTINGS_OTHERPARAMETERS_VIEW,
    SETTINGS_DISCOUNTS,
    SETTINGS_DISCOUNTS_ADD,
    SETTINGS_DISCOUNTS_DELETE,
    SETTINGS_DISCOUNTS_LIST,
    SETTINGS_DISCOUNTS_UPDATE,
    DEPARTMENT,
    DEPARTMENT_ADD,
    DEPARTMENT_DELETE,
    DEPARTMENT_LIST,
    DEPARTMENT_UPDATE,
    SUBJECT,
    SUBJECT_ADD,
    SUBJECT_DELETE,
    SUBJECT_LIST,
    SUBJECT_UPDATE,
    SCHOOLSTAFF_TEACHERS,
    SCHOOLSTAFF_TEACHERS_ADD,
    SCHOOLSTAFF_TEACHERS_DELETE,
    SCHOOLSTAFF_TEACHERS_LIST,
    SCHOOLSTAFF_TEACHERS_UPDATE,
    SETTINGS_GRADES,
    SETTINGS_GRADES_ADD,
    SETTINGS_GRADES_DELETE,
    SETTINGS_GRADES_LIST,
    SETTINGS_GRADES_UPDATE,
    SETTINGS_COSTITEM,
    SETTINGS_COSTITEM_ADD,
    SETTINGS_COSTITEM_DELETE,
    SETTINGS_COSTITEM_LIST,
    SETTINGS_COSTITEM_UPDATE,
    SETTINGS_COSTREASON,
    SETTINGS_COSTREASON_ADD,
    SETTINGS_COSTREASON_DELETE,
    SETTINGS_COSTREASON_LIST,
    SETTINGS_INCOMEREASON,
    SETTINGS_INCOMEREASON_ADD,
    SETTINGS_INCOMEREASON_UPDATE,
    SETTINGS_INCOMEREASON_DELETE,
    SETTINGS_INCOMEREASON_LIST,
    SETTINGS_SOURCEOFINCOME,
    SETTINGS_SOURCEOFINCOME_ADD,
    SETTINGS_SOURCEOFINCOME_DELETE,
    SETTINGS_SOURCEOFINCOME_LIST,
    SETTINGS_SOURCEOFINCOME_UPDATE,
    SETTINGS_INCOMEFORECAST,
    SETTINGS_INCOMEFORECAST_ADD,
    SETTINGS_INCOMEFORECAST_DELETE,
    SETTINGS_INCOMEFORECAST_LIST,
    SETTINGS_INCOMEFORECAST_UPDATE,
    SETTINGS_EXPENSESFORECAST,
    SETTINGS_EXPENSESFORECAST_ADD,
    SETTINGS_EXPENSESFORECAST_DELETE,
    SETTINGS_EXPENSESFORECAST_LIST,
    SETTINGS_EXPENSESFORECAST_UPDATE,
    ALLOWED_BULK_IMPORTS,
    SETTINGS_LEVEL,
    SETTINGS_LEVEL_ADD,
    SETTINGS_LEVEL_UPDATE,
    SETTINGS_LEVEL_DELETE,

    SETTINGS_SUBJECT_TYPE,
    SETTINGS_SUBJECT_TYPE_ADD,
    SETTINGS_SUBJECT_TYPE_UPDATE,
    SETTINGS_SUBJECT_TYPE_DELETE,

    SETTINGS_SKILLS,
    SETTINGS_SKILLS_ADD,
    SETTINGS_SKILLS_UPDATE,
    SETTINGS_SKILLS_DELETE,

    SETTINGS_MARK_PRIMARY_RULE,
    SETTINGS_MARK_PRIMARY_RULE_ADD,
    SETTINGS_MARK_PRIMARY_RULE_UPDATE,
    SETTINGS_MARK_PRIMARY_RULE_DELETE
} from '../constants';

// Common
const NotFound = React.lazy(() => import('../pages/not-found'));
const ForbiddenAccess = React.lazy(() => import('../pages/forbidden-access'));
const BulkUpload = React.lazy(() => import('../pages/bulk-upload'));

// User onboarding
const Login = React.lazy(() => import('../pages/user/login'));
const ForgotPassword = React.lazy(() => import('../pages/user/forgot-password'));
const ChangePassword = React.lazy(() => import('../pages/user/change-password'));
const MyProfile = React.lazy(() => import('../pages/user/my-profile'));
const EditProfile = React.lazy(() => import('../pages/user/edit-profile'));

// School Wall
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const SchoolWall = React.lazy(() => import('../pages/school-wall'));

// Schools
const Schools = React.lazy(() => import('../pages/schools'));
const AddEditSchool = React.lazy(() => import('../pages/schools/add-edit-school'));

const AddEditHonorRolls = React.lazy(() => import('../pages/schools/add-edit-honor-rolls'));

// Schools year
const SchoolYear = React.lazy(() => import('../pages/school-year'));
const AddEditSchoolYear = React.lazy(() => import('../pages/school-year/add-edit-school-year'));

// Grades
const Grades = React.lazy(() => import('../pages/grades'));
const AddEditGrade = React.lazy(() => import('../pages/grades/add-edit-grade'));

// Students
const Students = React.lazy(() => import('../pages/students'));
const AddEditStudent = React.lazy(() => import('../pages/students/add-edit-student'));

// outflows
const Outflow = React.lazy(() => import('../pages/outflows'));
const AddEditOutflow = React.lazy(() => import('../pages/outflows/add-edit-outflow'));

// installment
const Installment = React.lazy(() => import('../pages/installments'));
const AddEditInstallment = React.lazy(() => import('../pages/installments/add-edit-installment'));

// entries
const Entries = React.lazy(() => import('../pages/entries'));
const AddEditEntries = React.lazy(() => import('../pages/entries/add-edit-entries'));
const DailyEntries = React.lazy(() => import('../pages/reporting/daily-entry-report'));
const FinanceReports = React.lazy(() => import('../pages/reporting'));

// payment type
const PaymentType = React.lazy(() => import('../pages/payment-types'));
const AddEditPaymentType = React.lazy(() => import('../pages/payment-types/add-edit-payment-type'));

const Payment = React.lazy(() => import('../pages/payment'));
const PaymentInfo = React.lazy(() => import('../pages/payment/payment-info'));

// cost of item
const CostofItem = React.lazy(() => import('../pages/cost-of-item'));
const AddEditCostofItem = React.lazy(() => import('../pages/cost-of-item/add-edit-cost-of-item'));

// cost reason
const CostReason = React.lazy(() => import('../pages/cost-reason'));
const AddEditCostReason = React.lazy(() => import('../pages/cost-reason/add-edit-cost-reason'));

// income reason
const IncomeReason = React.lazy(() => import('../pages/income-reason'));
const AddEditIncomeReason = React.lazy(() => import('../pages/income-reason/add-edit-income-reason'));

// source of income
const SourceofIncome = React.lazy(() => import('../pages/source-of-income'));
const AddEditSourceofIncome = React.lazy(() => import('../pages/source-of-income/add-edit-source-of-income'));

// Classes
const Classes = React.lazy(() => import('../pages/classes'));
const AddEditClass = React.lazy(() => import('../pages/classes/add-edit-class'));

// Enrollment
const Enrollment = React.lazy(() => import('../pages/enrollment'));
const AddEditEnrollment = React.lazy(() => import('../pages/enrollment/add-edit-enrollment'));

// Income Forecast
const IncomeForecast = React.lazy(() => import('../pages/income-forecast'));
const AddEditIncomeForecast = React.lazy(() => import('../pages/income-forecast/add-edit-income-forecast'));

// Expenses Forecast
const ExpensesForecast = React.lazy(() => import('../pages/expenses-forecast'));
const AddEditExpensesForecast = React.lazy(() => import('../pages/expenses-forecast/add-edit-forecast'));

// Classes students
const ClassesList = React.lazy(() => import('../pages/grades-management'));
const ClassStudents = React.lazy(() => import('../pages/grades-management/class-students'));

// Sub Users
const SubUser = React.lazy(() => import('../pages/sub-users'));
const AddEditSubUser = React.lazy(() => import('../pages/sub-users/add-edit-sub-user'));

//Roles
const RoleUser = React.lazy(() => import('../pages/role-user/responsabilities'));

// total-users
const TotalUsers = React.lazy(() => import('../pages/total-users'));

// discount
const Discount = React.lazy(() => import('../pages/discount'));
const AddEditDiscount = React.lazy(() => import('../pages/discount/add-edit-discount'));

// subject
const Subjects = React.lazy(() => import('../pages/subjects'));
const AddEditSubject = React.lazy(() => import('../pages/subjects/add-edit-subject'));

// teacher
const Teacher = React.lazy(() => import('../pages/teachers'));
const AddEditTeacher = React.lazy(() => import('../pages/teachers/add-edit-teacher'));
const TeacherSchedule = React.lazy(() => import('../pages/teachers/teacher-schedule'));

// department
const Department = React.lazy(() => import('../pages/department'));
const AddEditDepartment = React.lazy(() => import('../pages/department/add-edit-department'));

const MarksManagement = React.lazy(() => import('../pages/marks-management'));
const MarksList = React.lazy(() => import('../pages/marks-management/List'));
const MarksView = React.lazy(() => import('../pages/marks-management/View'));
const MarksReport = React.lazy(() => import('../pages/marks-management/Report'));
const MarksReportPrimary = React.lazy(() => import('../pages/marks-management/Primary-Report'))
const MarksUpdate = React.lazy(() => import('../pages/marks-management/Edit'));
const MarksGeneral = React.lazy(() => import('../pages/marks-report-request/mark-pdf-general'));


const StudentView = React.lazy(() => import('../pages/grades-management/student-view'));
const StudentAbsentDetail = React.lazy(() => import('../pages/grades-management/student-absent-detail'));
const Appreciation = React.lazy(() => import('../pages/appreciation'));
const Schedule = React.lazy(() => import('../pages/schedule'));

// pedagogical followup
const PedagogicalTopics = React.lazy(() => import('../pages/pedagogical-followup/topics'));
const PedagogicalChapters = React.lazy(() => import('../pages/pedagogical-followup/chapters'));
const AddTopic = React.lazy(() => import('../pages/pedagogical-followup/topics/add'));
const AddChapters = React.lazy(() => import('../pages/pedagogical-followup/chapters/add'));
const AddForecast = React.lazy(() => import('../pages/pedagogical-followup/forecast/add'));
const AddNewForecast = React.lazy(() => import('../pages/pedagogical-followup/forecast/NewAddForecast'));
const AddProgression = React.lazy(() => import('../pages/pedagogical-followup/progression/add'));
const PedagogicalReport = React.lazy(() => import('../pages/pedagogical-followup/report'));
const Pedagogicalforecast = React.lazy(() => import('../pages/pedagogical-followup/forecast'));
const Pedagogicalprogression = React.lazy(() => import('../pages/pedagogical-followup/progression'));

const AttendanceManagement = React.lazy(() => import('../pages/attendance-management'));
const AttendanceList = React.lazy(() => import('../pages/attendance-management/List'));
const AttendanceUpdate = React.lazy(() => import('../pages/attendance-management/Edit'));
const AttendanceView = React.lazy(() => import('../pages/attendance-management/View'));

const AddWeekNumber = React.lazy(() => import('../pages/weeknumber/add'));
const WeekNumber = React.lazy(() => import('../pages/weeknumber'));

const AddEditQualification = React.lazy(() => import('../pages/qualification/add'));
const QualificationList = React.lazy(() => import('../pages/qualification'));
const LevelList = React.lazy(() => import('../pages/Levels'));
const AddEditLevel = React.lazy(() => import('../pages/Levels/add'));

const SubjectTypeList = React.lazy(() => import('../pages/Subject-type'));
const AddEditSubjectType = React.lazy(() => import('../pages/Subject-type/add'));

const SkillList = React.lazy(() => import('../pages/skills'));

const AddEditSkill = React.lazy(() => import('../pages/skills/add'));

// other-parameters
const OtherParameters = React.lazy(() => import('../pages/other-parameters'));

const MarkRules = React.lazy(() => import('../pages/marks-rules'));
const MarkRulesPrimary = React.lazy(() => import('../pages/primary-mark-rules'));

// UserScan
const UserScan = React.lazy(() => import('../pages/user-scan'));
const MarksReportList = React.lazy(() => import('../pages/marks-report-request'));
const MarkPrimaryReportList = React.lazy(() => import('../pages/mark-primary-report-request'))

// Pages made by Stagebit 
const AttendanceAtLesson = React.lazy(()=>import('../pages/attendance-at-lesson'))
const AttendanceAtLessonList = React.lazy(() => import('../pages/attendance-at-lesson/List'));
const AttendanceAtLessonUpdate = React.lazy(() => import('../pages/attendance-at-lesson/Edit'));
const LessonAttendanceView = React.lazy(() => import('../pages/attendance-at-lesson/View'));
const ExamAttendanceView = React.lazy(() => import('../pages/attendance-at-exam/View'));
const AttendanceAtExam = React.lazy(()=>import('../pages/attendance-at-exam'))
const AttendanceAtExamList = React.lazy(() => import('../pages/attendance-at-exam/List'));
const AttendanceAtExamUpdate = React.lazy(() => import('../pages/attendance-at-exam/Edit'));

const Chat2 = React.lazy(() => import('../components/PublicMessagesPage'));

// Employees page
//const Employee = React.lazy(() => import('../pages/employees'));
//const AddEditEmployee = React.lazy(() => import('../pages/employees/add-edit-employee'));*/
//const TeacherSchedule = React.lazy(() => import('../pages/teachers/teacher-schedule'));
// Employee
const Employee = React.lazy(() => import('../pages/employee'));
const EmployeeProfile = React.lazy(() => import('../pages/employee/profile'));
const EmployeeLeave = React.lazy(() => import('../pages/employee-leave'));
const AdminAttendance = React.lazy(() => import('../pages/admin-attendance'));
const EmployeeSalary = React.lazy(() => import('../pages/employee-salary'));

//Chat
const chatSetAvatar= React.lazy(() => import('../components/chat/SetAvatar'));
const viewChat = React.lazy(() => import('../pages/chat/Chat'));
const chatLogin = React.lazy(() => import('../pages/chat/Login'));
const chatLogout = React.lazy(() => import('../components/chat/Logout'));
const chatRegister = React.lazy(() => import('../pages/chat/Register'));


const chatScan = React.lazy(() => import('../pages/chatbox/index'));
const chat = React.lazy(() => import('../pages/chatbox/home'));

const chatwhatapp = React.lazy(() => import('../pages/chatwhatsapp/index'));
const chatroom = React.lazy(() => import('../pages/chatwhatsapp/index'));
const chats = React.lazy(() => import('../components/chatwhatapp/Chat'));



const configureRoutes = () => {
    const routes = [
       /* {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: SchoolWall,
            exact: true,
            path: '/',
            title: 'school-wall',
            type: 'private',
        },*/
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/chatwhatapp',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/users/room/:roomID',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/users/room/:roomID/image',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/rooms/:roomID/image',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/rooms/*',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/users/room/:roomID/audio',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/room/:roomID/audio',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/chats',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/rooms',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/rooms/room/:roomID',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/users',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/search',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/chats/room/:roomID',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/chats/room/:roomID/image',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: chatwhatapp,
            exact: true,
            path: '/room/:roomID/image',
            title: 'Chat Whatsapp',
            type: 'private',
        },
        {
            component: Login,
            exact: true,
            path: '/',
            title: 'Login',
            type: 'public',
        },
        {
            component: Login,
            exact: true,
            path: '/login',
            title: 'Login',
            type: 'public',
        },
        {
            component: ForgotPassword,
            exact: true,
            path: '/forgot-password',
            title: 'Forgot Password',
            type: 'public',
        },
/*{
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: SchoolWall,
            exact: true,
            path: '/school-wall',
            title: 'school-wall',
            type: 'private',
        },*/
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: ChangePassword,
            exact: true,
            path: '/change-password',
            title: 'Change Password',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: MyProfile,
            exact: true,
            path: '/my-profile',
            title: 'My Profile',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: EditProfile,
            exact: true,
            path: '/edit-profile',
            title: 'Edit Profile',
            type: 'private',
        },
        // School Wall
        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_DELETE_POST,SCHOOLWALL_UPDATE_POST],
            component: SchoolWall,
            exact: true,
            path: '/school-wall',
            title: 'School Wall',
            type: 'private',
        },

        {
            accessible: [SCHOOLWALL, SCHOOLWALL_ADD_POST,SCHOOLWALL_VIEW_WALL,SCHOOLWALL_UPDATE_POST],
            component: SchoolWall,
            exact: true,
            path: '/school-wall/:post_id',
            title: 'School Wall Post Detail',
            type: 'private',
        },

        // Schools
        {//SETTINGS_SCHOOLINFORMATION,SETTINGS_SCHOOLINFORMATION_EDIT,SETTINGS_SCHOOLINFORMATION_VIEW]
            accessible: [SETTINGS_SCHOOL,SETTINGS_SCHOOL_EDIT,SETTINGS_SCHOOL_VIEW],
            component: Schools,
            exact: true,
            path: '/schools',
            title: 'Schools',
            type: 'private',
        },
        {
            accessible: [SETTINGS_SCHOOL,SETTINGS_SCHOOL_EDIT,SETTINGS_SCHOOL_VIEW],
            component: AddEditSchool,
            exact: true,
            path: '/schools/add-update-school/:publicId?',
            title: 'Add/Edit School',
            type: 'private',
        },
        {
            accessible: [SETTINGS_SCHOOLINFORMATION,SETTINGS_SCHOOLINFORMATION_EDIT,SETTINGS_SCHOOLINFORMATION_VIEW],
            component: TotalUsers,
            exact: true,
            path: '/total-users',
            title: 'School Information',
            type: 'private',
        },
        {
            accessible: [SETTINGS_SCHOOLINFORMATION,SETTINGS_SCHOOLINFORMATION_EDIT,SETTINGS_SCHOOLINFORMATION_VIEW],
            component: AddEditSchool,
            exact: true,
            path: '/school-information/:publicId',
            title: 'School Information',
            type: 'private',
        },


        {
            accessible: [SETTINGS_HONNORROLL,SETTINGS_HONNORROLL_VIEW,SETTINGS_HONNORROLL_LIST,SETTINGS_HONNORROLL_EDIT],
            component: AddEditHonorRolls,
            exact: true,
            path: '/honor-rolls/:publicId',
            title: 'Honor Rolls',
            type: 'private',
        },


        {
            accessible: [SETTINGS_QUALIFICATION,SETTINGS_QUALIFICATION_UPDATE],
            component: QualificationList,
            exact: true,
            path: '/qualification',
            title: 'Qualification',
            type: 'private',
        },


        {
            accessible: [SETTINGS_QUALIFICATION,SETTINGS_QUALIFICATION_UPDATE,SETTINGS_QUALIFICATION_ADD],
            component: AddEditQualification,
            exact: true,
            path: '/add-qualification',
            title: 'Add Qualification',
            type: 'private',
        },

        {
            accessible: [SETTINGS_QUALIFICATION,SETTINGS_QUALIFICATION_UPDATE,SETTINGS_QUALIFICATION_ADD],
            component: AddEditQualification,
            exact: true,
            path: '/add-qualification/:publicId',
            title: 'Add / Edit Qualification',
            type: 'private',
        },
        {
            accessible: [SETTINGS_LEVEL,SETTINGS_LEVEL_ADD,SETTINGS_LEVEL_UPDATE,SETTINGS_LEVEL_DELETE],
            component: LevelList,
            exact: true,
            path: '/level',
            title: 'Level',
            type: 'private',
        },

        {
            accessible: [SETTINGS_LEVEL,SETTINGS_LEVEL_ADD,SETTINGS_LEVEL_UPDATE],
            component: AddEditLevel,
            exact: true,
            path: '/add-level',
            title: 'Add Level',
            type: 'private',
        },

        {
            accessible: [SETTINGS_LEVEL,SETTINGS_LEVEL_ADD,SETTINGS_LEVEL_UPDATE],
            component: AddEditLevel,
            exact: true,
            path: '/add-level/:publicId',
            title: 'Add / Edit Level',
            type: 'private',
        },

        {
            accessible: [SETTINGS_SUBJECT_TYPE,SETTINGS_SUBJECT_TYPE_ADD,SETTINGS_SUBJECT_TYPE_UPDATE,SETTINGS_SUBJECT_TYPE_DELETE],
            component: SubjectTypeList,
            exact: true,
            path: '/subject-type',
            title: 'Subject Type',
            type: 'private',
        },

        {
            accessible: [SETTINGS_SUBJECT_TYPE,SETTINGS_SUBJECT_TYPE_ADD,SETTINGS_SUBJECT_TYPE_UPDATE],
            component: AddEditSubjectType,
            exact: true,
            path: '/add-subject-type',
            title: 'Add Subject Type',
            type: 'private',
        },

        {
            accessible: [SETTINGS_SUBJECT_TYPE,SETTINGS_SUBJECT_TYPE_ADD,SETTINGS_SUBJECT_TYPE_UPDATE,SETTINGS_SUBJECT_TYPE_DELETE],
            component: AddEditSubjectType,
            exact: true,
            path: '/add-subject-type/:publicId',
            title: 'Add / Edit Subject Type',
            type: 'private',
        },
        
        {
            accessible: [SETTINGS_SKILLS,SETTINGS_SKILLS_ADD,SETTINGS_SKILLS_UPDATE,SETTINGS_SKILLS_DELETE],
            component: SkillList,
            exact: true,
            path: '/skill',
            title: 'Skill',
            type: 'private',
        },

        {
            accessible: [SETTINGS_SKILLS,SETTINGS_SKILLS_ADD,SETTINGS_SKILLS_UPDATE,SETTINGS_SKILLS_DELETE],
            component: AddEditSkill,
            exact: true,
            path: '/add-skill',
            title: 'Add Skill',
            type: 'private',
        },

        {
            accessible: [SETTINGS_SKILLS,SETTINGS_SKILLS_ADD,SETTINGS_SKILLS_UPDATE,SETTINGS_SKILLS_DELETE],
            component: AddEditSkill,
            exact: true,
            path: '/add-skill/:publicId',
            title: 'Add / Edit Skill',
            type: 'private',
        },


        // School Years
        {
            accessible: [SETTINGS_SCHOOLYEAR,SETTINGS_SCHOOLYEAR_ADD,SETTINGS_SCHOOLYEAR_DELETE,SETTINGS_SCHOOLYEAR_LIST,SETTINGS_SCHOOLYEAR_UPDATE],
            component: SchoolYear,
            exact: true,
            path: '/school-year',
            title: 'School year',
            type: 'private',
        },
        {
            accessible: [SETTINGS_SCHOOLYEAR,SETTINGS_SCHOOLYEAR_ADD,SETTINGS_SCHOOLYEAR_DELETE,SETTINGS_SCHOOLYEAR_LIST,SETTINGS_SCHOOLYEAR_UPDATE],
            component: AddEditSchoolYear,
            exact: true,
            path: '/add-update-school-year/:publicId?',
            title: 'Add/Edit School Year',
            type: 'private',
        },
        // subusers
        {
            accessible: [USERS,USERS_ADD,USERS_LIST,USERS_DELETE,USERS_UPDATE],
            component: SubUser,
            exact: true,
            path: '/sub-user',
            title: 'Sub User',
            type: 'private',
        },
         // roleuser
         {
            accessible: [USERS,USERS_ADD,USERS_LIST,USERS_DELETE,USERS_UPDATE],
            component: RoleUser,
            exact: true,
            path: '/role-user',
            title: 'Role User',
            type: 'private',
        },
        {
            accessible: [USERS,USERS_ADD,USERS_LIST,USERS_DELETE,USERS_UPDATE],
            component: AddEditSubUser,
            exact: true,
            path: '/add-update-sub-user/:publicId?',
            title: 'Add/EditSub User',
            type: 'private',
        },
        // Classes
        {
            accessible: [SETTINGS_CLASSES,SETTINGS_CLASSES_ADD,SETTINGS_CLASSES_DELETE,SETTINGS_CLASSES_LIST,SETTINGS_CLASSES_UPDATE],
            component: Classes,
            exact: true,
            path: '/classes/:isPromotion?',
            title: 'Classes',
            type: 'private',
        },
        {
            accessible: [SETTINGS_CLASSES,SETTINGS_CLASSES_ADD,SETTINGS_CLASSES_DELETE,SETTINGS_CLASSES_LIST,SETTINGS_CLASSES_UPDATE],
            component: AddEditClass,
            exact: true,
            path: '/add-update-class/:publicId?',
            title: 'Add/Edit Class',
            type: 'private',
        },

        {
            accessible: [GRADES_STUDENTS,GRADES_STUDENTS_VIEW_STUDENT_LIST],
            component: ClassesList,
            exact: true,
            path: '/classes-list',
            title: 'Classes List',
            type: 'private',
        },

        // Pedagogical Follow

        {
            accessible: [PEDAGOGICALFOLLOWUP_PART,PEDAGOGICALFOLLOWUP_PART_ADD,PEDAGOGICALFOLLOWUP_PART_LIST,PEDAGOGICALFOLLOWUP_PART_DELETE,PEDAGOGICALFOLLOWUP_PART_UPDATE],
            component: PedagogicalTopics,
            exact: true,
            path: '/pedagogical-part',
            title: 'Part',
            type: 'private',
        },

        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceManagement,
            exact: true,
            path: '/attendance-management',
            title: 'Attendance Management ',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceList,
            exact: true,
            path: '/attendance-list/:classId?/:subjectId?',
            title: 'Attendance Management ',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceView,
            exact: true,
            path: '/attendance-details/:subjectId?/:id?',
            title: 'Attendance Details',
            type: 'private',
        },
        {
            accessible: [PEDAGOGICALFOLLOWUP_CHAPTER,PEDAGOGICALFOLLOWUP_CHAPTER_ADD,PEDAGOGICALFOLLOWUP_CHAPTER_DELETE,PEDAGOGICALFOLLOWUP_CHAPTER_DELETE,PEDAGOGICALFOLLOWUP_CHAPTER_LIST],
            
            component: PedagogicalChapters,
            exact: true,
            path: '/pedagogical-chapters',
            title: 'Chapters',
            type: 'private',
        },
        {
            accessible: [PEDAGOGICALFOLLOWUP_REPORT, PEDAGOGICALFOLLOWUP_REPORT_VIEW],
            component: PedagogicalReport,
            exact: true,
            path: '/pedagogical-report',
            title: 'Pedagogical Report',
            type: 'private',
        },

        {
            accessible: [PEDAGOGICALFOLLOWUP_FORECAST, PEDAGOGICALFOLLOWUP_FORECAST_ADD, PEDAGOGICALFOLLOWUP_FORECAST_DELETE, PEDAGOGICALFOLLOWUP_FORECAST_LIST,PEDAGOGICALFOLLOWUP_FORECAST_UPDATE],
            component: Pedagogicalforecast,
            exact: true,
            path: '/pedagogical-forecast',
            title: 'Pedagogical forecast',
            type: 'private',
        },
        {
            accessible: [PEDAGOGICALFOLLOWUP_PROGRESSION, PEDAGOGICALFOLLOWUP_PROGRESSION_ADD, PEDAGOGICALFOLLOWUP_PROGRESSION_DELETE, PEDAGOGICALFOLLOWUP_PROGRESSION_LIST,PEDAGOGICALFOLLOWUP_PROGRESSION_UPDATE],
            component: Pedagogicalprogression,
            exact: true,
            path: '/pedagogical-progression',
            title: 'Pedagogical progression',
            type: 'private',
        },

        {
            accessible: [PEDAGOGICALFOLLOWUP_PART,PEDAGOGICALFOLLOWUP_PART_ADD,PEDAGOGICALFOLLOWUP_PART_LIST,PEDAGOGICALFOLLOWUP_PART_DELETE,PEDAGOGICALFOLLOWUP_PART_UPDATE],
           
            component: AddTopic,
            exact: true,
            path: '/add-part/:publicId?',
            title: 'Add Part',
            type: 'private',
        },
        {
            accessible: [PEDAGOGICALFOLLOWUP_PROGRESSION, PEDAGOGICALFOLLOWUP_PROGRESSION_ADD, PEDAGOGICALFOLLOWUP_PROGRESSION_DELETE, PEDAGOGICALFOLLOWUP_PROGRESSION_LIST,PEDAGOGICALFOLLOWUP_PROGRESSION_UPDATE],
           
            component: AddProgression,
            exact: true,
            path: '/add-progression/:publicId?',
            title: 'Add Progression',
            type: 'private',
        },

        {
            accessible: [PEDAGOGICALFOLLOWUP_FORECAST, PEDAGOGICALFOLLOWUP_FORECAST_ADD, PEDAGOGICALFOLLOWUP_FORECAST_DELETE, PEDAGOGICALFOLLOWUP_FORECAST_LIST,PEDAGOGICALFOLLOWUP_FORECAST_UPDATE],
           
            component: AddForecast,
            exact: true,
            path: '/add-forecast/:publicId?',
            title: 'Add Forecast',
            type: 'private',
        },
        {
            accessible: [PEDAGOGICALFOLLOWUP_FORECAST, PEDAGOGICALFOLLOWUP_FORECAST_ADD, PEDAGOGICALFOLLOWUP_FORECAST_DELETE, PEDAGOGICALFOLLOWUP_FORECAST_LIST,PEDAGOGICALFOLLOWUP_FORECAST_UPDATE],
           
            component: AddNewForecast,
            exact: true,
            path: '/new-add-forecast/:publicId?',
            title: 'Add New Forecast',
            type: 'private',
        },
        

        {
            accessible: [PEDAGOGICALFOLLOWUP_CHAPTER,PEDAGOGICALFOLLOWUP_CHAPTER_ADD,PEDAGOGICALFOLLOWUP_CHAPTER_DELETE,PEDAGOGICALFOLLOWUP_CHAPTER_DELETE,PEDAGOGICALFOLLOWUP_CHAPTER_LIST],
             component: AddChapters,
            exact: true,
            path: '/add-chapters/:publicId?',
            title: 'Add Chapters',
            type: 'private',
        },

        {
            accessible: [SETTINGS_WEEKS, SETTINGS_WEEKS_ADD,SETTINGS_WEEKS_DELETE,SETTINGS_WEEKS_LIST, SETTINGS_WEEKS_UPDATE],
            component: WeekNumber,
            exact: true,
            path: '/week-number',
            title: 'Week-Number',
            type: 'private',
        },

        {
            accessible: [SETTINGS_WEEKS,SETTINGS_WEEKS_ADD ],
            component: AddWeekNumber,
            exact: true,
            path: '/add-weeknumber/:publicId?',
            title: 'Add WeekNumber',
            type: 'private',
        },


        {
            accessible: [SCHEDULE,SCHEDULE_CREATE_SLOT,SCHEDULE_DELETE_SLOT,SCHEDULE_UPDATE_SLOT,SCHEDULE_VIEW_SCHEDULE],
            component: Schedule,
            exact: true,
            path: '/schedule',
            title: 'Schedule',
            type: 'private',
        },
        {
            accessible: [GRADES_MARKMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_MARKMANAGEMENT_DELETE_MARKS,
                GRADES_MARKMANAGEMENT_NEW_MARK,GRADES_MARKMANAGEMENT_VALIDATE_MARKS,GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST],
            component: MarksManagement,
            exact: true,
            path: '/marks-management',
            title: 'Marks Management',
            type: 'private',
        },
        {
            accessible: [GRADES_MARKMANAGEMENT,GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST],
            component: MarksList,
            exact: true,
            path: '/marks-management/list/:classId?',
            title: 'Marks List',
            type: 'private',
        },
        {
            accessible: [GRADES_MARKMANAGEMENT,GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST,GRADES_MARKMANAGEMENT_VALIDATE_MARKS],
            component: MarksView,
            exact: true,
            path: '/marks-details/:classId/:classSubjectId',
            title: 'Marks Details',
            type: 'private',
        },
        {
            accessible: [GRADES_MARKREPORT,GRADES_MARKREPORT_GRADES__MARK_REPORT],
            component: MarksReport,
            exact: true,
            path: '/marks-report',
            title: 'Marks Reports',
            type: 'private',
        },
        {
            accessible: [GRADES_MARKREPORT,GRADES_MARKREPORT_GRADES__MARK_REPORT],
            component: MarksReportPrimary,
            exact: true,
            path: '/marks-primary-report',
            title: 'Marks Primary Reports',
            type: 'private',
        },
        {
            accessible: [SETTINGS_APPRECIATION_UPDATE,SETTINGS_APPRECIATION,
                SETTINGS_APPRECIATION_ADD,SETTINGS_APPRECIATION_DELETE,SETTINGS_APPRECIATION_LIST],
            component: Appreciation,
            exact: true,
            path: '/appreciation/:publicId?',
            title: 'Appreciation',
            type: 'private',
        },
        {
            accessible:  [GRADES_MARKMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_MARKMANAGEMENT_DELETE_MARKS,
                GRADES_MARKMANAGEMENT_NEW_MARK,GRADES_MARKMANAGEMENT_VALIDATE_MARKS,GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST],
            component: MarksUpdate,
            exact: true,
            path: '/marks-update/:classId/:classSubjectId',
            title: 'Marks Update',
            type: 'private',
        },
        {
            accessible:  [GRADES_MARKMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_MARKMANAGEMENT_DELETE_MARKS,
                GRADES_MARKMANAGEMENT_NEW_MARK,GRADES_MARKMANAGEMENT_VALIDATE_MARKS,GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST],
            component: MarksUpdate,
            exact: true,
            path: '/marks-update/:classId?',
            title: 'Marks Update',
            type: 'private',
        },
        {
            accessible: [SETTINGS_APPRECIATION_UPDATE,SETTINGS_APPRECIATION],
            component: AttendanceUpdate,
            exact: true,
            path: '/attendance-update/:classId?/:attendanceId?',
            title: 'Attendance Update',
            type: 'private',
        },

        {
            accessible: [GRADES_STUDENTS,GRADES_STUDENTS_VIEW_STUDENT_LIST],
            component: StudentAbsentDetail,
            exact: true,
            path: '/student-absent-detail/:publicId/:publicId1?',
            title: 'Student Absent Details',
            type: 'private',
        },
        {
            accessible: [GRADES_STUDENTS,GRADES_STUDENTS_VIEW_STUDENT_LIST],
            component: StudentView,
            exact: true,
            path: '/student-details/:publicId?',
            title: 'Student Details',
            type: 'private',
        },

        {
            accessible: [GRADES_STUDENTS,GRADES_STUDENTS_VIEW_STUDENT_LIST],
            component: ClassStudents,
            exact: true,
            path: '/class-students/:publicId?',
            title: 'Class Students',
            type: 'private',
        },
        // Students
        {
            accessible: [GRADES_STUDENTS,GRADES_STUDENTS_VIEW_STUDENT_LIST],
            component: Students,
            exact: true,
            path: '/school-students/:isPromotion?',
            title: 'Students',
            type: 'private',
        },
        {
            accessible: [GRADES_STUDENTS,GRADES_STUDENTS_VIEW_STUDENT_LIST],
            component: AddEditStudent,
            exact: true,
            path: '/school-students/add-update-student/:publicId?',
            title: 'Add/Edit Student',
            type: 'private',
        },

        // Enrollment
        {
            accessible: [TUITION_ENROLLMENT,TUITION_ENROLLMENT_ADD,TUITION_ENROLLMENT_BULK_IMPORT,
                TUITION_ENROLLMENT_DELETE,TUITION_ENROLLMENT_LIST,TUITION_ENROLLMENT_UPDATE],
            component: Enrollment,
            exact: true,
            isAuthRequired: true,
            path: '/enrollments',
            title: 'Enrollment',
            type: 'private',
        },
        {
            accessible: [TUITION_ENROLLMENT,TUITION_ENROLLMENT_ADD,TUITION_ENROLLMENT_UPDATE],
            component: AddEditEnrollment,
            exact: true,
            isAuthRequired: true,
            path: '/enrollments/add-update-enrollment/:publicId?/:isPromotion?',
            title: 'Add/Edit Enrollment',
            type: 'private',
        },

        // Outflows
        {
            accessible: [FINANCE_OUTFLOWS,FINANCE_OUTFLOWS_ADD,FINANCE_OUTFLOWS_BULK_IMPORT,
                FINANCE_OUTFLOWS_DELETE,FINANCE_OUTFLOWS_LIST,FINANCE_OUTFLOWS_UPDATE,FINANCE_OUTFLOWS_UPDATE_DATE],
            component: Outflow,
            exact: true,
            isAuthRequired: true,
            path: '/outflows',
            title: 'Outflows',
            type: 'private',
        },
        {
            accessible: [FINANCE_OUTFLOWS,FINANCE_OUTFLOWS_ADD,FINANCE_OUTFLOWS_UPDATE,FINANCE_OUTFLOWS_UPDATE_DATE],
            component: AddEditOutflow,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-outflow/:publicId?',
            title: 'Add/Edit Outflow',
            type: 'private',
        },

        // installments
        {
            accessible: [SETTINGS_INSTALLMENT,SETTINGS_INSTALLMENT_ADD,SETTINGS_INSTALLMENT_DELETE,
                SETTINGS_INSTALLMENT_LIST,SETTINGS_INSTALLMENT_UPDATE],
            component: Installment,
            exact: true,
            isAuthRequired: true,
            path: '/installments',
            title: 'Installments',
            type: 'private',
        },
        {
            accessible: [SETTINGS_INSTALLMENT,SETTINGS_INSTALLMENT_ADD,
                SETTINGS_INSTALLMENT_LIST,SETTINGS_INSTALLMENT_UPDATE],
            component: AddEditInstallment,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-installment/:publicId?',
            title: 'Add/Edit Installment',
            type: 'private',
        },

        // entries
        {
            accessible: [FINANCE_ENTRIES,FINANCE_ENTRIES_ADD,FINANCE_ENTRIES_BULK_IMPORT,
                FINANCE_ENTRIES_DELETE,FINANCE_ENTRIES_LIST,FINANCE_ENTRIES_UPDATE,FINANCE_ENTRIES_UPDATE_DATE],
            component: Entries,
            exact: true,
            isAuthRequired: true,
            path: '/entries',
            title: 'Entries',
            type: 'private',
        },
        {
            accessible: [FINANCE_ENTRIES,FINANCE_ENTRIES_ADD,
              FINANCE_ENTRIES_LIST,FINANCE_ENTRIES_UPDATE,FINANCE_ENTRIES_UPDATE_DATE],
            component: AddEditEntries,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-entries/:publicId?',
            title: 'Add/Edit Entries',
            type: 'private',
        },
        {
            accessible: [REPORTING_ENTRIESDAILYREPORT,REPORTING_ENTRIESDAILYREPORT_VIEW_REPORT],
            component: DailyEntries,
            exact: true,
            isAuthRequired: true,
            path: '/daily-entries',
            title: 'Daily Entries',
            type: 'private',
        },
        {
            accessible: [REPORTING_ENROLLMENT_FINANCIAL_REPORT,REPORTING_ENROLLMENT_FINANCIAL_REPORT_VIEW_REPORT],
            component: FinanceReports,
            exact: true,
            isAuthRequired: true,
            path: '/finance-reports',
            title: 'Finance Reports',
            type: 'private',
        },

        // payment type
        {
            accessible: [SETTINGS_PAYMENTTYPE,SETTINGS_PAYMENTTYPE_ADD,
                SETTINGS_PAYMENTTYPE_DELETE,SETTINGS_PAYMENTTYPE_LIST,SETTINGS_PAYMENTTYPE_UPDATE],
            component: PaymentType,
            exact: true,
            isAuthRequired: true,
            path: '/payment-types',
            title: 'Payment Type',
            type: 'private',
        },
        {
            accessible: [SETTINGS_PAYMENTTYPE,SETTINGS_PAYMENTTYPE_ADD,
                SETTINGS_PAYMENTTYPE_LIST,SETTINGS_PAYMENTTYPE_UPDATE],
            component: AddEditPaymentType,
            exact: true,
            isAuthRequired: true,
            path: '/add-edit-payment-type/:publicId?',
            title: 'Add/Edit Payment Type',
            type: 'private',
        },

        // payment
        {
            accessible: [TUITION_PAYMENT,
                TUITION_PAYMENT_LIST],
            component: Payment,
            exact: true,
            isAuthRequired: true,
            path: '/payment',
            title: 'Payment ',
            type: 'private',
        },

        {
            accessible:[TUITION_PAYMENT,TUITION_PAYMENT_DELETE_PAYMENT_INSYALLMENT,
                TUITION_PAYMENT_LIST,TUITION_PAYMENT_NEW_PAYMENT_INSTALLMENT,TUITION_PAYMENT_UPDATE_DATE_TIME],
            component: PaymentInfo,
            exact: true,
            isAuthRequired: true,
            path: '/payment-info/:publicId?',
            title: 'Payment Info',
            type: 'private',
        },

        // OtherParameters
        {
            accessible: [SETTINGS_OTHERPARAMETERS,SETTINGS_OTHERPARAMETERS_EDIT,SETTINGS_OTHERPARAMETERS_VIEW],
            component: OtherParameters,
            exact: true,
            isAuthRequired: true,
            path: '/other-parameters/:publicId?',
            title: 'Other Parameters',
            type: 'private',
        },
        // discount
        {
            accessible: [SETTINGS_DISCOUNTS,SETTINGS_DISCOUNTS_LIST],
            component: Discount,
            exact: true,
            isAuthRequired: true,
            path: '/discount',
            title: 'Discount',
            type: 'private',
        },
        {
            accessible: [SETTINGS_DISCOUNTS,SETTINGS_DISCOUNTS_ADD,SETTINGS_DISCOUNTS_DELETE,SETTINGS_DISCOUNTS_LIST,SETTINGS_DISCOUNTS_UPDATE],
            component: AddEditDiscount,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-discount/:publicId?',
            title: 'Add Edit Discount',
            type: 'private',
        },

        // department
        {
            accessible: [DEPARTMENT,DEPARTMENT_ADD,DEPARTMENT_LIST],
            component: Department,
            exact: true,
            isAuthRequired: true,
            path: '/department',
            title: 'Department',
            type: 'private',
        },
        {
            accessible:  [DEPARTMENT,DEPARTMENT_ADD,DEPARTMENT_DELETE,DEPARTMENT_LIST,DEPARTMENT_UPDATE],
            component: AddEditDepartment,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-department/:publicId?',
            title: 'Add Edit Department',
            type: 'private',
        },
        // subject
        {
            accessible: [SUBJECT,SUBJECT_LIST],
            component: Subjects,
            exact: true,
            isAuthRequired: true,
            path: '/subjects',
            title: 'Subjects',
            type: 'private',
        },
        {
            accessible: [SUBJECT,SUBJECT_ADD,SUBJECT_DELETE,SUBJECT_LIST,SUBJECT_UPDATE],
            component: AddEditSubject,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-subject/:publicId?',
            title: 'Add Edit Subject',
            type: 'private',
        },
        // teacher
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_LIST],
            component: Teacher,
            exact: true,
            isAuthRequired: true,
            path: '/teacher/:isPromotion?',
            title: 'Teacher',
            type: 'private',
        },
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_ADD,SCHOOLSTAFF_TEACHERS_DELETE,SCHOOLSTAFF_TEACHERS_LIST,SCHOOLSTAFF_TEACHERS_UPDATE],
            component: AddEditTeacher,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-teacher/:publicId?',
            title: 'Add Edit Teacher',
            type: 'private',
        },
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_ADD,SCHOOLSTAFF_TEACHERS_DELETE,SCHOOLSTAFF_TEACHERS_LIST,SCHOOLSTAFF_TEACHERS_UPDATE],
            component: TeacherSchedule,
            exact: true,
            isAuthRequired: true,
            path: '/teacher-schedule/:publicId?',
            title: 'Add Edit Teacher',
            type: 'private',
        },

        // Grades
        {
            accessible: [SETTINGS_GRADES,SETTINGS_GRADES_LIST],
            component: Grades,
            exact: true,
            path: '/grades',
            title: 'Grades',
            type: 'private',
        },
        {
            accessible: [SETTINGS_GRADES,SETTINGS_GRADES_ADD,SETTINGS_GRADES_DELETE,SETTINGS_GRADES_LIST,SETTINGS_GRADES_UPDATE],
            component: AddEditGrade,
            exact: true,
            path: '/add-update-grade/:publicId?',
            title: 'Grade',
            type: 'private',
        },

        // Marks Rules
        {
            accessible: [GRADES_MARKMANAGEMENT,GRADES_MARKMANAGEMENT_VALIDATE_MARKS,GRADES_MARKMANAGEMENT_NEW_MARK],
          component: MarkRules,
          path: '/marks-rules',
          title: 'Marks Rules',
          type: 'private'
        },
        {
            accessible: [SETTINGS_MARK_PRIMARY_RULE, SETTINGS_MARK_PRIMARY_RULE_ADD,SETTINGS_MARK_PRIMARY_RULE_UPDATE,SETTINGS_MARK_PRIMARY_RULE_DELETE],
          component: MarkRulesPrimary,
          path: '/marks-primary-rules',
          title: 'Marks Rules Primary',
          type: 'private'
        },
        

        // cost of item
        {
            accessible: [SETTINGS_COSTITEM,SETTINGS_COSTITEM_LIST],
            component: CostofItem,
            exact: true,
            isAuthRequired: true,
            path: '/cost-of-item',
            title: 'Cost of Item',
            type: 'private',
        },
        {
            accessible: [SETTINGS_COSTITEM,SETTINGS_COSTITEM_ADD,SETTINGS_COSTITEM_DELETE,SETTINGS_COSTITEM_LIST,SETTINGS_COSTITEM_UPDATE],
            component: AddEditCostofItem,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-cost-of-item/:publicId?',
            title: 'Cost of Item',
            type: 'private',
        },

        // cost reason
        {
            accessible: [SETTINGS_COSTREASON,SETTINGS_COSTREASON_LIST],
            component: CostReason,
            exact: true,
            isAuthRequired: true,
            path: '/cost-reason',
            title: 'Cost Reason',
            type: 'private',
        },
        {
            accessible: [SETTINGS_COSTREASON,SETTINGS_COSTREASON_ADD,SETTINGS_COSTREASON_DELETE,SETTINGS_COSTREASON_LIST,SETTINGS_COSTITEM_UPDATE],
            component: AddEditCostReason,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-cost-reason/:publicId?',
            title: 'Cost Reason',
            type: 'private',
        },

        // income reason
        {
            accessible: [SETTINGS_INCOMEREASON,SETTINGS_INCOMEREASON_LIST],
            component: IncomeReason,
            exact: true,
            isAuthRequired: true,
            path: '/income-reasons',
            title: 'Income Reason',
            type: 'private',
        },
        {
            accessible: [SETTINGS_INCOMEREASON,SETTINGS_INCOMEREASON_ADD,SETTINGS_INCOMEREASON_UPDATE,SETTINGS_INCOMEREASON_DELETE,SETTINGS_INCOMEREASON_LIST],
            component: AddEditIncomeReason,
            exact: true,
            isAuthRequired: true,
            path: '/add-update-income-reason/:publicId?',
            title: 'Income Reason',
            type: 'private',
        },

        // Schools
        {
            accessible: [SETTINGS_SOURCEOFINCOME,SETTINGS_SOURCEOFINCOME_LIST],
            component: SourceofIncome,
            exact: true,
            path: '/source-of-income',
            title: 'Source of Income',
            type: 'private',
        },
        {
            accessible: [SETTINGS_SOURCEOFINCOME,SETTINGS_SOURCEOFINCOME_ADD,SETTINGS_SOURCEOFINCOME_DELETE,SETTINGS_SOURCEOFINCOME_LIST,SETTINGS_SOURCEOFINCOME_UPDATE],
            component: AddEditSourceofIncome,
            exact: true,
            path: '/add-update-source-of-income/:publicId?',
            title: 'Source of Income',
            type: 'private',
        },

        // Income Forecast
        {
            accessible: [SETTINGS_INCOMEFORECAST,SETTINGS_INCOMEFORECAST_LIST],
            component: IncomeForecast,
            exact: true,
            path: '/income-forecast',
            title: 'Income Forecast',
            type: 'private',
        },
        {
            accessible: [SETTINGS_INCOMEFORECAST,SETTINGS_INCOMEFORECAST_ADD,SETTINGS_INCOMEFORECAST_DELETE,SETTINGS_INCOMEFORECAST_LIST,SETTINGS_INCOMEFORECAST_UPDATE],
            component: AddEditIncomeForecast,
            exact: true,
            path: '/income-forecast/add-edit-income-forecast/:publicId?',
            title: 'Add/Edit Income Forecast',
            type: 'private',
        },

        // Expenses Forecast
        {
            accessible: [SETTINGS_EXPENSESFORECAST,SETTINGS_EXPENSESFORECAST_LIST],
            component: ExpensesForecast,
            exact: true,
            path: '/expenses-forecast',
            title: 'Expenses Forecast',
            type: 'private',
        },
        {
            accessible: [SETTINGS_EXPENSESFORECAST,SETTINGS_EXPENSESFORECAST_ADD,SETTINGS_EXPENSESFORECAST_DELETE,SETTINGS_EXPENSESFORECAST_LIST,SETTINGS_EXPENSESFORECAST_UPDATE],
            component: AddEditExpensesForecast,
            exact: true,
            path: '/expenses-forecast/add-edit-expenses-forecast/:publicId?',
            title: 'Add/Edit Expenses Forecast',
            type: 'private',
        },
        // Bulk Import
        {
            accessible: [FINANCE_ENTRIES_BULK_IMPORT,TUITION_ENROLLMENT_BULK_IMPORT,FINANCE_OUTFLOWS_BULK_IMPORT],
            component: BulkUpload,
            exact: true,
            path: '/bulk-import',
            title: 'Bulk Import',
            type: 'private',
        },

        // User Scan
        {
            accessible: [USERS,USERS_LIST],
           component: UserScan,
           exact: true,
           path: '/user-scan',
           title: 'User Scan',
           type: 'private',
        },
        // Routes made by Stagebit for Attendance at Lesson and At Exam
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,
                GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceAtLesson,
            exact: true,
            path: '/attendance-at-lesson',
            title: 'Attendance At Lesson ',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT
                ,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceAtLessonList,
            exact: true,
            path: '/attendance-at-lesson-list/:classId?/:subjectId?',
            title: 'Attendance At Lesson  ',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,
                GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceAtLessonUpdate,
            exact: true,
            path: '/attendance-at-lesson-update/:classId?/:attendanceId?',
            title: 'Attendance At Lesson Update',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,
                GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceAtExam,
            exact: true,
            path: '/attendance-at-exam',
            title: 'Attendance At Exam ',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceAtExamList,
            exact: true,
            path: '/attendance-at-exam-list/:classId?/:subjectId?',
            title: 'Attendance At Exam  ',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,
                GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: AttendanceAtExamUpdate,
            exact: true,
            path: '/attendance-at-exam-update/:classId?/:attendanceId?',
            title: 'Attendance At Exam Update',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,
                GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: LessonAttendanceView,
            exact: true,
            path: '/lesson-attendance-details/:subjectId?/:id?',
            title: 'Attendance Details',
            type: 'private',
        },
        {
            accessible: [GRADES_ATTENDANCEMANAGEMENT,GRADES_ATTENDANCEMANAGEMENT_DELETE_ATTENDANCE,
                GRADES_ATTENDANCEMANAGEMENT_EDIT_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_NEW_ATTENDANCE,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST],
            component: ExamAttendanceView,
            exact: true,
            path: '/exam-attendance-details/:subjectId?/:id?',
            title: 'Attendance Details',
            type: 'private',
        },
        
        // Marks generated reports
        {
            accessible: [GRADES_MARKREPORT,GRADES_MARKREPORT_GRADES__MARK_REPORT],
            component: MarksReportList,
            exact: true,
            path: '/view-mark-reports',
            title: 'Marks reports requests',
            type: 'private'
        },
        {
            accessible: [GRADES_MARKREPORT,GRADES_MARKREPORT_GRADES__MARK_REPORT],
            component: MarkPrimaryReportList,
            exact: true,
            path: '/view-mark-primary-reports',
            title: 'Marks reports requests',
            type: 'private'
        },
        {
            accessible: [GRADES_MARKREPORT,GRADES_MARKREPORT_GRADES__MARK_REPORT],
            component: MarksGeneral,
            exact: true,
            path: '/marks-report-general/:class_id?/:report_type?/:sequence_id?/:quarter_id?/:reportId?',
            title: 'PV General reports requests',
            type: 'private'
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: Chat2,
            exact: true,
            path: '/chat2',
            title: 'Chat2',
            type: 'private'
        },
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_LIST],
            component: Employee,
            exact: true,
            isAuthRequired: true,
            path: '/employee',
            title: 'Employee',
            type: 'private',
        },
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_LIST],
            component: EmployeeProfile,
            exact: true,
           // isAuthRequired: true,
            //path: '/profile-employee',
            path: '/profile-employee/:publicId?',
            title: 'Employee profile',
            type: 'private',
        },
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_LIST],
            component: EmployeeLeave,
            exact: true,
            isAuthRequired: true,
            path: '/employee-leave',
            title: 'Employee Leave',
            type: 'private',
        },
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_LIST],
            component: AdminAttendance,
            exact: true,
            isAuthRequired: true,
            path: '/admin-attendance',
            title: 'Attendance (Admin)',
            type: 'private',
        },
        {
            accessible: [SCHOOLSTAFF_TEACHERS,SCHOOLSTAFF_TEACHERS_LIST],
            component: EmployeeSalary,
            exact: true,
            isAuthRequired: true,
            path: '/employee-salary',
            title: 'Employee Salary',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: chatSetAvatar,
            exact: true,
            isAuthRequired: true,
            path: '/chatSetAvatar',
            title: 'chat SetAvatar',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: viewChat,
            exact: true,
            isAuthRequired: true,
            path: '/viewChat',
            title: 'view Chat',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: chatLogin,
            exact: true,
            isAuthRequired: true,
            path: '/chatLogin',
            title: 'chat Login',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: chatRegister,
            exact: true,
            isAuthRequired: true,
            path: '/chatRegister',
            title: 'chat Register',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: chatLogout,
            exact: true,
            isAuthRequired: true,
            path: '/chatLogout',
            title: 'chat Logout',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: chat,
            exact: true,
            isAuthRequired: true,
            path: '/chat',
            title: 'chat',
            type: 'private',
        },
        {
            accessible: [SCHOOLWALL,SCHOOLWALL_VIEW_WALL],
            component: chatScan,
            exact: true,
            isAuthRequired: true,
            path: '/chatscan',
            title: 'chat Scan',
            type: 'private',
        },
        {
        // General routes
            component: ForbiddenAccess,
            exact: true,
            path: '/forbidden-access',
            title: '403 Forbidden Access',
            type: 'error',
        },
        {
            component: NotFound,
            exact: true,
            path: '*',
            title: '404 Not Found',
            type: 'error',
        },

       
    ];

    return routes;
};

export default configureRoutes;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { 
  FETCH_REPORT,
  fetchReportFailure,
  fetchReportRequested,
  fetchReportSuccess, } from '../actions/report-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import moment from 'moment';
 
 
export function* fetchReportHandler({ payload }) {
  yield put(fetchReportRequested());
  const class_id= payload?.class_id?.value;
  const subject_id= payload.subject_id?.value;
  const teacher_id=payload.teacher_id?.value;
  const request = {
    method: 'GET',
    params: {
        class_id: class_id,
        school_year_id: payload.school_year_id,
        selected_date: (payload?.selected_date ? moment(payload.selected_date).format('yyyy-MM-DD').toString() : ''),
        subject_id: subject_id,
        teacher_id: teacher_id,
     },
    url: 'report-pedagogical',
  };
  console.log(request);
  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchReportFailure(error));
  } else {
    yield put(fetchReportSuccess(data.data.result || []));
  }
}
 
 
function* Report() {
  yield all([ 
    takeLatest(FETCH_REPORT, fetchReportHandler), 
  ]);
}

export default Report;

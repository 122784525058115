import {createAction} from "redux-actions";

export const ADD_EXAMTYPE = 'ADD_EXAMTYPE';
export const addExamType = createAction(ADD_EXAMTYPE);

export const ADD_EXAMTYPE_REQUESTED = 'ADD_EXAMTYPE_REQUESTED';
export const addExamTypeRequested = createAction(ADD_EXAMTYPE_REQUESTED);

export const ADD_EXAMTYPE_SUCCESS = 'ADD_EXAMTYPE_SUCCESS';
export const addExamTypeSuccess = createAction(ADD_EXAMTYPE_SUCCESS);

export const ADD_EXAMTYPE_FAILURE = 'ADD_EXAMTYPE_FAILURE';
export const addExamTypeFailure = createAction(ADD_EXAMTYPE_FAILURE);

export const FETCH_EXAMTYPE = 'FETCH_EXAMTYPE';
export const fetchExamType = createAction(FETCH_EXAMTYPE);

export const FETCH_EXAMTYPE_REQUESTED = 'FETCH_EXAMTYPE_REQUESTED';
export const fetchExamTypeRequested = createAction(FETCH_EXAMTYPE_REQUESTED);

export const FETCH_EXAMTYPE_SUCCESS = 'FETCH_EXAMTYPE_SUCCESS';
export const fetchExamTypeSuccess = createAction(FETCH_EXAMTYPE_SUCCESS);

export const FETCH_EXAMTYPE_FAILURE = 'FETCH_EXAMTYPE_FAILURE';
export const fetchExamTypeFailure = createAction(FETCH_EXAMTYPE_FAILURE);

import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { CREATE_ENTRIES,
  createEntriesFailure,
  createEntriesRequested,
  createEntriesSuccess,
  DELETE_ENTRIES,
  deleteEntriesFailure,
  deleteEntriesRequested,
  deleteEntriesSuccess,
  EDIT_ENTRIES,
  editEntriesFailure,
  editEntriesRequested,
  editEntriesSuccess,
  FETCH_SINGLE_ENTRIES,
  fetchSingleEntriesFailure,
  fetchSingleEntriesRequested,
  fetchSingleEntriesSuccess,
  FETCH_ENTRIES,
  fetchEntriesFailure,
  fetchEntriesRequested,
  fetchEntriesSuccess } from '../actions/entries-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

export function* createEntriesHandler({ payload }) {
  yield put(createEntriesRequested());
  const body = new FormData();

  body.append('student_id', payload?.student_id?.value);
  body.append('amount', payload?.amount);
  body.append('school_year_id', payload?.school_year_id);
  body.append('bank_name', payload?.bank_name);
  body.append('comment', payload?.comment);
  body.append('created_by', payload?.created_by);
  body.append('income_reason_id', payload?.income_reason_id?.value);
  body.append('entry_date', payload?.entry_date ? moment(payload?.entry_date).format('yyyy-MM-DD HH:mm:ss').toString() : moment(new Date()).format('yyyy-MM-DD hh:mm:ss').toString());
  body.append('displaydatetime', payload?.entry_date ? moment(payload?.entry_date).format('DD/MM/yyyy HH:mm').toString() : moment(new Date()).format('yyyy-MM-DD hh:mm:ss').toString());

  if (payload.entry_date === '') {
    body.append('displaydatetime', moment(new Date()).format('DD/MM/yyyy HH:mm').toString());
    body.append('entry_date', moment(new Date()).format('yyyy-MM-DD HH:mm:ss').toString());
  }
  body.append('paid_by', payload?.paid_by);
  body.append('payment_type_id', payload?.payment_type_id?.value);
  body.append('receipt_number', payload?.receipt_number);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-entries',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createEntriesFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/entries');
      },
      icon: 'success',
      message: '',
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(createEntriesSuccess());
  }
}

export function* editEntriesHandler({ payload }) {
  yield put(editEntriesRequested());

  const request = {
    method: 'PUT',
    params: {
      amount: payload.amount,
      bank_name: payload.bank_name,
      comment: payload.comment,
      displaydatetime: payload.entry_date ? moment(payload?.outflow_date).format('DD/MM/yyyy HH:mm').toString() : moment(new Date()).format('DD/MM/yyyy HH:mm').toString(),
      entry_date: payload.entry_date ? moment(payload?.entry_date).format('yyyy-MM-DD HH:mm:ss').toString() : moment(new Date()).format('yyyy-MM-DD hh:mm:ss').toString(),
      income_reason_id: payload.income_reason_id.value,
      paid_by: payload.paid_by,
      payment_type_id: payload.payment_type_id?.value,
      receipt_number: payload.receipt_number,
      student_id: payload.student_id.value,
      school_year_id: payload?.school_year_id,
    },
    url: `update-entries/${payload.entry_reason_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editEntriesFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname:'/entries'});
    //   },
    //   icon: 'success',
    //   message: 'Entries updated successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);
    yield put(editEntriesSuccess());
  }
}

export function* deleteEntriesHandler({ payload }) {
  yield put(deleteEntriesRequested());

  const request = {
    method: 'DELETE',
    url: `delete-entries/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteEntriesFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:finance.entries.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(deleteEntriesSuccess(payload));
  }
}

export function* fetchEntriesHandler({ payload }) {
  yield put(fetchEntriesRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'list-entries',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchEntriesFailure(error));
  } else {
    yield put(fetchEntriesSuccess(data.data.result));
  }
}

export function* fetchSingleEntriesHandler({ payload }) {
  yield put(fetchSingleEntriesRequested());

  const request = {
    method: 'GET',
    url: `view-entries/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleEntriesFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,

      entry_date: result.entry_date ? moment(result.entry_date).toDate() : '',
      id: result.entry_reason_id,
      income_reason_id: {
        label: result.income_reason_description,
        value: result.income_reason_id,
      },
      payment_type_id: {
        label: result.payment_type_label,
        value: result.payment_type_id,
      },
      status: result.status === 1 ? {
        label: 'Active',
        value: 1,
      } : {
        label: 'Inactive',
        value: 0,
      },
      student_id: {
        label: result.student_first_name,
        value: result.student_id,
      },
    };

    console.log(res.entry_date);
    yield put(fetchSingleEntriesSuccess(res));
  }
}

function* Entries() {
  yield all([
    takeLatest(CREATE_ENTRIES, createEntriesHandler),
    takeLatest(DELETE_ENTRIES, deleteEntriesHandler),
    takeLatest(EDIT_ENTRIES, editEntriesHandler),
    takeLatest(FETCH_ENTRIES, fetchEntriesHandler),
    takeLatest(FETCH_SINGLE_ENTRIES, fetchSingleEntriesHandler),
  ]);
}

export default Entries;

import {all, call, put, takeLatest} from 'redux-saga/effects';
import {
    MARKS_REPORT_REQUEST,
    marksReportRequestCreated,
    marksReportRequestSuccess,
    marksReportRequestFailure,
    FETCH_MARKS_REPORT_REQUEST,
    fetchMarksReportRequestCreated,
    fetchMarksReportRequestSuccess,
    fetchMarksReportRequestFailure,
    DOWNLOAD_MARKS_REPORT,
    downloadMarksReportRequested,
    downloadMarksReportSuccess,
    downloadMarksReportFailure
} from '../actions/marks-report-request-action-types';
import Alert from '../utility/alert';
import httpClient from './http-client';
import { history } from '../index'; 
import i18next from 'i18next';


export function* marksReportRequestHandler({ payload }){
    yield put(marksReportRequestCreated());

    const body = new FormData();

    body.append('school_year_id', payload.school_year_id);
    body.append('report_card_type', payload.report_card_type);
    body.append('class_id', payload.class_id);
    body.append('quater_id', payload.quater_id);
    body.append('sequence_id', payload.sequence_id);

    const request = {
        url: 'marks-report/create',
        method: 'POST',
        data: body,
        headers: {'Content-Type': 'multipart/form-data'}
    };

    const { data, error } = yield call(httpClient, request);

    if(error){
        yield put(marksReportRequestFailure());
    }else {
        yield put(marksReportRequestSuccess());

        const alertProps = {
            callback: () => {
              history.push('/marks-report');
            },
            icon: 'success',
            message: data.message,
            title: i18next.t('skullman:common.successMessage'),
          };
      
          Alert.alert(alertProps);
    }
}

export function* fetchMarksReportRequestHandler({ payload }){
    yield put(fetchMarksReportRequestCreated());

    const request = {
        url: 'marks-report/list',
        method: 'GET',
        params: payload
    };

    console.log("REQUEST", request);

    const { data, error } = yield call(httpClient, request);

    if(error){
        yield put(fetchMarksReportRequestFailure(error));
    }else{
        yield put(fetchMarksReportRequestSuccess(data.result || []));
    }
}

export function* downloadMarksReportHandler({ payload }){
    yield put(downloadMarksReportRequested())
    
    const request = {
        url: '/download/report',
        params: payload,
        responseType: 'blob',
        method: 'GET'
    };

    const {data, error} = yield call(httpClient, request);

    if (error) {
        yield put(downloadMarksReportFailure(error));
      }
      else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.setAttribute('download', 'Marks-reports.pdf');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        yield put(downloadMarksReportSuccess());
      }
}

function* MarksReportGenerator(){
    yield all([
        takeLatest(MARKS_REPORT_REQUEST, marksReportRequestHandler),
        takeLatest(FETCH_MARKS_REPORT_REQUEST, fetchMarksReportRequestHandler),
        takeLatest(DOWNLOAD_MARKS_REPORT, downloadMarksReportHandler)
    ]);
}

export default MarksReportGenerator;
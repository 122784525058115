import { LOCATION_CHANGE } from 'connected-react-router';
import { 
  ADD_QUALIFICATION_SUCCESS,
  DELETE_QUALIFICATION_SUCCESS,
  FETCH_QUALIFICATION_SUCCESS,
  FETCH_SINGLE_QUALIFICATION_SUCCESS,
  FETCH_QUALIFICATION_FILTER_SUCCESS} from '../actions/qualification-action-types';

const initialState = {
  addChapterStatus: 'pending',
  Qualifications: [],
  chapterFilter: [],
  deleteChapterStatus: 'pending',
  fetchChapterStatus: 'pending',
  fetchSingleChapterStatus: 'pending',
  form: {
    qualification_name: '',
    status: ''
  },
};

export default function Qualification(state = initialState, {
  payload, type,
}) {
  switch (type) {

    case ADD_QUALIFICATION_SUCCESS:
      return {
        ...state,
        addChapterStatus: 'success',
      };



    case DELETE_QUALIFICATION_SUCCESS:
      return {
        ...state,
        Qualifications: [...state.chapters].filter((Chapters) => !(payload.toString().includes(Chapters.id.toString()))),
        deleteChapterStatus: 'success',

      };


  

    case FETCH_QUALIFICATION_SUCCESS:
      return {
        ...state,
        Qualifications: payload,
        fetchChapterStatus: 'success',
      };

  
   

    case FETCH_SINGLE_QUALIFICATION_SUCCESS:
      return {
        ...state,
        fetchSingleChapterStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };


    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

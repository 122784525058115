import { createAction } from 'redux-actions';


export const FETCH_LEVEL = 'FETCH_LEVEL';
export const fetchLevel = createAction(FETCH_LEVEL);

export const FETCH_LEVEL_SUCCESS = 'FETCH_LEVEL_SUCCESS';
export const fetchLevelSuccess = createAction(FETCH_LEVEL_SUCCESS);

export const FETCH_SINGLE_LEVEL = 'FETCH_SINGLE_LEVEL';
export const fetchSingleLevel = createAction(FETCH_SINGLE_LEVEL);

export const FETCH_SINGLE_LEVEL_SUCCESS = 'FETCH_SINGLE_LEVEL_SUCCESS';
export const fetchSingleLevelSuccess = createAction(FETCH_SINGLE_LEVEL_SUCCESS);

export const ADD_LEVEL = 'ADD_LEVEL';
export const addLevel = createAction(ADD_LEVEL);

export const EDIT_LEVEL = 'EDIT_LEVEL';
export const editLevel = createAction(EDIT_LEVEL);

export const DELETE_LEVEL = 'DELETE_LEVEL';
export const deleteLevel = createAction(DELETE_LEVEL);
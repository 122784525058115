import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_SERVICE,
  addServiceFailure,
  addServiceRequested,
  addServiceSuccess,
  DELETE_SERVICE,
  deleteServiceFailure,
  deleteServiceRequested,
  deleteServiceSuccess,
  FETCH_SERVICE,
  fetchServiceFailure,
  fetchServiceRequested,
  fetchServiceSuccess,
  FETCH_SINGLE_SERVICE,
  fetchSingleServiceFailure,
  fetchSingleServiceRequested,
  fetchSingleServiceSuccess,
  EDIT_SERVICE,
  editServiceFailure,
  editServiceRequested,
  editServiceSuccess } from '../actions/service-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

export function* addServiceHandler({ payload }) {
  yield put(addServiceRequested());
  const body = new FormData();

  body.append('code_service', payload.code_service);
  body.append('name', payload.name);
 // body.append('school_year_id', payload.school_year_id);
  body.append('status', payload.status.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-service',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addServiceFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/service');
      },
      icon: 'success',
      message: i18next.t('skullman:department.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addServiceSuccess());
  }
}

export function* editServiceHandler({ payload }) {
  yield put(editServiceRequested());

  const body = new FormData();

  body.append('code_service', payload.code_service);
  body.append('name', payload.name);
  body.append('school_year_id', payload.school_year_id);
  body.append('status', payload.status.value);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-service/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editServiceFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname:'/department'});
    //   },
    //   icon: 'success',
    //   message: 'department updated successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);
    yield put(editServiceSuccess());
  }
}

export function* deleteServiceHandler({ payload }) {
  yield put(deleteServiceRequested());

  const request = {
    method: 'DELETE',
    url: `delete-service/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteServiceFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
        message: i18next.t('skullman:department.deleteMessage'),
        title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteServiceSuccess(payload));
  }
}

export function* fetchServiceHandler({ payload }) {
  yield put(fetchServiceRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'services',
  };
  const {
    data, error,
  } = yield call(httpClient, request);
  if (error) {
    yield put(fetchServiceFailure(error));
  } else {
    yield put(fetchServiceSuccess(data.data.result || []));
  }
}

export function* fetchSingleServiceHandler({ payload }) {
  yield put(fetchSingleServiceRequested());

  const request = {
    method: 'GET',
    url: `get-service/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleServiceFailure(error));
    
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      id: result.id,
      status: result.status === 1 ? {
        label: 'Active',
        value: 1,
      } : {
        label: 'Inactive',
        value: 0,
      },
    };

    yield put(fetchSingleServiceSuccess(res));
  }
}

function* service() {
  yield all([
    takeLatest(ADD_SERVICE, addServiceHandler),
    takeLatest(DELETE_SERVICE, deleteServiceHandler),
    takeLatest(FETCH_SERVICE, fetchServiceHandler),
    takeLatest(FETCH_SINGLE_SERVICE, fetchSingleServiceHandler),
    takeLatest(EDIT_SERVICE, editServiceHandler),
  ]);
}

export default service;

import { bool, string, number } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { deleteQualification } from '../../actions/qualification-action-types';
import {useTranslation} from "react-i18next";

const LinkableCellRenderer = ({
  href, className, text, icon,isDelete,id
}) => {
  const newTo = {
    pathname: href,
    param1: localStorage.getItem('pagination') || 1,
  };
  const dispatch = useDispatch();
  const [t] = useTranslation('skullman');
  // pagination=localStorage.getItem('pagination');

  
  const onDelete = () => {
    SweetAlert.fire({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      icon: 'warning',
      showCancelButton: true,
      text: 'Are you sure you want delete the selected Qualification?',
      title: 'Delete Qualification',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteQualification(id));
      }
    });
  };


  //console.log('test',localStorage.getItem('pagination'));
  if (href && href.length) {
    return (
      <div class={{display:'flex',justifyContent:'center',alignItems:'center'}}>
       <Link to={newTo} className={`${className}`}>
       {t('linkableCellRenderer.'+text) === "linkableCellRenderer."+text ? text : t('linkableCellRenderer.'+text)}
        {icon
          ? <i className="lnr-eye"> </i>
          : ''}
      </Link>
          
          {
            isDelete && (
              <span className={`${className}`}
              style={{background:'transparent'}}
                            
                                  type="button"
                                  onClick={onDelete}
                                  // style={{ fontSize: '1rem', padding: '0px' }}
                                >
                                  <i
                                    className="pe-7s-trash ml-auto"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=" "
                                    data-original-title=""
                                  />
                                </span>
            )
          }
    

      </div>
     
    );
  }

  return <></>;
};

LinkableCellRenderer.propTypes = {
  className: string,
  href: string.isRequired,
  icon: bool.isRequired,
  text: string.isRequired,
};

LinkableCellRenderer.defaultProps = {
  className: '',
  // icon: false,
};

export default LinkableCellRenderer;

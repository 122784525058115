import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';
import { connectRouter } from 'connected-react-router';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import app from './app';
import bulkUpload from './bulk-upload';
import classes from './classes';
import schools from './schools';
import grades from './grades';
import costItem from './cost-of-item';
import costReason from './cost-reason';
import incomeReason from './income-reason';
import sourceOfIncome from './source-of-income';
import students from './students';
import outflow from './outflow';
import user from './user';
import payment from './payment';
import paymentType from './payment-type';
import entries from './entries';
import dailyEntries from './daily-entries';
import posts from './posts';
import installment from './installment';
import schoolYears from './school-year';
import enrollment from './enrollment';
import incomeForecast from './income-forecast';
import expensesForecast from './expenses-forecast';
import tiles from './tiles';
import subUsers from './sub-user';
import financialReport from './financial-reports';
import transport from './transport';
import otherParameters from './other-parameters';
import totalUsers from './total-users';
import discount from './discount';
import department from './department';
import subjects from './subjects';
import teachers from './teacher';
import mark from './mark';
import schedule from './schedule';
import chapter from './chapters';
import part from './part';
import forecast from './forecast';
import progression from './progressions';
import Appreciation from './appreciation';
import Attendance from './attendance';
import ExamAttendance from './examattendance';
import Report from './report';
import weeknumber from './weeknumber';
import qualification from './qualification';
import sequence from './sequence';
import ExamType from './examtype';
import Quarters from "./quarters";
import UserType from './user-type';
import Ressources from './ressources';
import ThemeOptions  from './ThemeOptions';
import employees from './employee';
import EmergencyContacts from './emergency-contact';
import Bank from './bank';
import MarksReportRequest from './marks-report-request';
import service from './service';
import family from './family';
import education from './education';
import ProfessionalExperience from './professional-experience';
import levelReducer from './levelReducer'
import subjectTypeReducer from './subjectTypeReducer';
import skillsReducer from './skillsReducer';
import markPrimaryReducer from './markPrimaryReducer';
import markPrimaryReportReducer from './markPrimaryReportReducer';

const config = {
  blacklist: ['app'],
  key: 'primary',
  stateReconciler: autoMergeLevel2,
  storage,
};

export default (history) => persistCombineReducers(config, {
  Appreciation,
  Attendance,
  ExamAttendance,
  app,
  bulkUpload,
  chapter,
  classes,
  costItem,
  costReason,
  dailyEntries,
  department,
  discount,
  enrollment,
  entries,
  expensesForecast,
  financialReport,
  forecast,
  grades,
  incomeForecast,
  incomeReason,
  installment,
  mark,
  otherParameters,
  outflow,
  part,
  payment,
  paymentType,
  posts,
  progression,
  Report,
  router: connectRouter(history),
  schedule,
  schoolYears,
  schools,
  sourceOfIncome,
  students,
  subUsers,
  subjects,
  teachers,
  tiles,
  totalUsers,
  transport,
  user,
  weeknumber,
  qualification,
  sequence,
  ExamType,
  Quarters,
  MarksReportRequest,
  UserType,
  Ressources,
  ThemeOptions,
  employees,
  EmergencyContacts,
  Bank,
  service,
  family,
  education,
  ProfessionalExperience,
  levelReducer,
  subjectTypeReducer,
  skillsReducer,
  markPrimaryReducer,
  markPrimaryReportReducer
});

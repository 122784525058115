import { LOCATION_CHANGE } from 'connected-react-router';
import { ADD_APPRECIATION_FAILURE,
  ADD_APPRECIATION_REQUESTED,
  ADD_APPRECIATION_SUCCESS,
  DELETE_APPRECIATION_FAILURE,
  DELETE_APPRECIATION_REQUESTED,
  DELETE_APPRECIATION_SUCCESS,
  FETCH_APPRECIATION_FAILURE,
  FETCH_APPRECIATION_REQUESTED,
  FETCH_APPRECIATION_SUCCESS,
  FETCH_TEACHER_APPRECIATION_FAILURE,
  FETCH_TEACHER_APPRECIATION_REQUESTED,
  FETCH_TEACHER_APPRECIATION_SUCCESS,
  FETCH_SINGLE_APPRECIATION_FAILURE,
  FETCH_SINGLE_APPRECIATION_REQUESTED,
  FETCH_SINGLE_APPRECIATION_SUCCESS,
  UPDATE_APPRECIATION_FORM } from '../actions/appreciation-action-types';

const initialState = {
  addAppreciationStatus: 'pending',
  appreciation: [],
  deleteAppreciationStatus: 'pending',
  fetchAppreciationStatus: 'pending',
  fetchSingleAppreciationStatus: 'pending',
  form: {
    appreciation: '',
    color: '',
    marks_from: '',
    marks_to: '',
    school_year_id: null,
    type: '',
    long_appreciation:'',
    level_id:'',
  },
  teacherAppreciation: [],
};

export default function Appreciation(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_APPRECIATION_FAILURE:
      return {
        ...state,
        addAppreciationStatus: 'failure',
      };

    case ADD_APPRECIATION_REQUESTED:
      return {
        ...state,
        addAppreciationStatus: 'creating',
      };

    case ADD_APPRECIATION_SUCCESS:
      return {
        ...state,
        addAppreciationStatus: 'success',
      };

    case DELETE_APPRECIATION_FAILURE:
      return {
        ...state,
        deleteAppreciationStatus: 'failure',
      };

    case DELETE_APPRECIATION_REQUESTED:
      return {
        ...state,
        deleteAppreciationStatus: 'deleting',
      };

    case DELETE_APPRECIATION_SUCCESS:
      return {
        ...state,
        appreciation: [...state.appreciation].filter((Appreciations) => !(payload.toString().includes(Appreciations.id.toString()))),
        deleteAppreciationStatus: 'success',

      };

    case FETCH_APPRECIATION_FAILURE:
      return {
        ...state,
        fetchAppreciationStatus: 'failure',
      };

    case FETCH_APPRECIATION_REQUESTED:
      return {
        ...state,
        fetchAppreciationStatus: 'fetching',
      };

    case FETCH_APPRECIATION_SUCCESS:
      return {
        ...state,
        appreciation: payload,
        fetchAppreciationStatus: 'success',
      };

    case FETCH_TEACHER_APPRECIATION_FAILURE:
      return {
        ...state,
        fetchAppreciationStatus: 'failure',
      };

    case FETCH_TEACHER_APPRECIATION_REQUESTED:
      return {
        ...state,
        fetchAppreciationStatus: 'fetching',
      };

    case FETCH_TEACHER_APPRECIATION_SUCCESS:
      return {
        ...state,
        teacherAppreciation: payload,
        fetchAppreciationStatus: 'success',
      };

    case FETCH_SINGLE_APPRECIATION_FAILURE:
      return {
        ...state,
        fetchSingleAppreciationStatus: 'failure',
      };

    case FETCH_SINGLE_APPRECIATION_REQUESTED:
      return {
        ...state,
        fetchSingleAppreciationStatus: 'fetching',
      };

    case FETCH_SINGLE_APPRECIATION_SUCCESS:
      return {
        ...state,
        fetchSingleAppreciationStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_APPRECIATION_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

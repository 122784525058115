import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_EMPLOYEE_FAILURE,
  ADD_EMPLOYEE_REQUESTED,
  ADD_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUESTED,
  DELETE_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_REQUESTED,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_SINGLE_EMPLOYEE_FAILURE,
  FETCH_SINGLE_EMPLOYEE_REQUESTED,
  FETCH_SINGLE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FORM
} from '../actions/employee-action-type';

const initialState = {
  createEmployeeStatus: 'pending',
  deleteEmployeeStatus: 'pending',
  fetchSingleEmployeeStatus: 'pending',
  fetchEmployeesStatus: 'pending',
  form: {
    first_name: '',
    last_name: '',
    employee_code: '',
    address: null,
    email: '',
    phone_no: null,
    profile_picture: '',
    gender: '',
    department_id: '',
    date_of_birth: '0000-00-00 00:00:00',
    school_year_id: '',
    join_date: '0000-00-00 00:00:00',
    user_name: '',
    name: '',
    status: '',
    pin_code: null,
    state: null,
    country: null,
    passport_no: null,
    passport_exp_no: null,
    nationality: null,
    religion: null,
    marital_status: null,
    spouse_job: null,
    children_no: null,
    reporter_id: null,
    bank_id: '',
    pf_id: '',
    user_type_id: '',
    password: '',
    service_id: '',
  },
  employees: [],
};

export default function employees(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        createEmployeeStatus: 'failure',
      };

    case ADD_EMPLOYEE_REQUESTED:
      return {
        ...state,
        createEmployeeStatus: 'creating',
      };

    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        // class_list: payload.class_list,
        createEmployeeStatus: 'success',
       // file: encodeURI(payload.image),
       // profile_picture: encodeURI(payload.image),
      };

    case DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        deleteEmployeeStatus: 'failure',
      };

    case DELETE_EMPLOYEE_REQUESTED:
      return {
        ...state,
        deleteEmployeeStatus: 'deleting',
      };

    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        deleteEmployeeStatus: 'success',
        employees: [...state.employees].filter((employeeItem) => !(payload.toString().includes(employeeItem.id.toString()))),
      };

    case FETCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        fetchEmployeesStatus: 'failure',
      };

    case FETCH_EMPLOYEE_REQUESTED:
      return {
        ...state,
        fetchEmployeesStatus: 'fetching',
      };

    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        fetchEmployeesStatus: 'success',
        employees: payload,
      };

    case FETCH_SINGLE_EMPLOYEE_FAILURE:
      return {
        ...state,
        fetchSingleEmployeeStatus: 'failure',
      };

    case FETCH_SINGLE_EMPLOYEE_REQUESTED:
      return {
        ...state,
        fetchSingleEmployeeStatus: 'fetching',
      };

    case FETCH_SINGLE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        fetchSingleEmployeeStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.image),
          profile_picture: encodeURI(payload.image),
        },
      };

    case UPDATE_EMPLOYEE_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { LOCATION_CHANGE } from 'connected-react-router';
import { ADD_WEEKNUMBER_FAILURE,
  ADD_WEEKNUMBER_REQUESTED,
  ADD_WEEKNUMBER_SUCCESS,
  DELETE_WEEKNUMBER_FAILURE,
  DELETE_WEEKNUMBER_REQUESTED,
  DELETE_WEEKNUMBER_SUCCESS,
  FETCH_WEEKNUMBER_FAILURE,
  FETCH_WEEKNUMBER_REQUESTED,
  FETCH_WEEKNUMBER_SUCCESS,
  FETCH_SINGLE_WEEKNUMBER_FAILURE,
  FETCH_SINGLE_WEEKNUMBER_REQUESTED,
  FETCH_SINGLE_WEEKNUMBER_SUCCESS,
  FETCH_WEEKNUMBER_FILTER_FAILURE,
  FETCH_WEEKNUMBER_FILTER_REQUESTED,
  FETCH_WEEKNUMBER_FILTER_SUCCESS,
  UPDATE_WEEKNUMBER_FORM } from '../actions/weeknumber-action-types';

const initialState = {
  addChapterStatus: 'pending',
  weeknumbers: [],
  chapterFilter: [],
  deleteChapterStatus: 'pending',
  fetchChapterStatus: 'pending',
  fetchSingleChapterStatus: 'pending',
  form: {
    week_number: '',
    week_from: null,
    week_to: null,
    quarter_id: '',
    school_year_id: ''
  },
};

export default function WeekNumber(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_WEEKNUMBER_FAILURE:
      return {
        ...state,
        addChapterStatus: 'failure',
      };

    case ADD_WEEKNUMBER_REQUESTED:
      return {
        ...state,
        addChapterStatus: 'creating',
      };

    case ADD_WEEKNUMBER_SUCCESS:
      return {
        ...state,
        addChapterStatus: 'success',
      };

    case DELETE_WEEKNUMBER_FAILURE:
      return {
        ...state,
        deleteChapterStatus: 'failure',
      };

    case DELETE_WEEKNUMBER_REQUESTED:
      return {
        ...state,
        deleteChapterStatus: 'deleting',
      };

    case DELETE_WEEKNUMBER_SUCCESS:
      return {
        ...state,
        weeknumbers: [...state.chapters].filter((Chapters) => !(payload.toString().includes(Chapters.id.toString()))),
        deleteChapterStatus: 'success',

      };

    case FETCH_WEEKNUMBER_FAILURE:
      return {
        ...state,
        fetchChapterStatus: 'failure',
      };

    case FETCH_WEEKNUMBER_REQUESTED:
      return {
        ...state,
        fetchChapterStatus: 'fetching',
      };

    case FETCH_WEEKNUMBER_SUCCESS:
      return {
        ...state,
        weeknumbers: payload,
        fetchChapterStatus: 'success',
      };

      case FETCH_WEEKNUMBER_FILTER_FAILURE:
        return {
          ...state,
          fetchChapterStatus: 'failure',
        };
  
      case FETCH_WEEKNUMBER_FILTER_REQUESTED:
        return {
          ...state,
          fetchChapterStatus: 'fetching',
        };
  
      case FETCH_WEEKNUMBER_FILTER_SUCCESS:
        return {
          ...state,
          weeknumbers: payload,
          fetchChapterStatus: 'success',
        };

    case FETCH_SINGLE_WEEKNUMBER_FAILURE:
      return {
        ...state,
        fetchSingleChapterStatus: 'failure',
      };

    case FETCH_SINGLE_WEEKNUMBER_REQUESTED:
      return {
        ...state,
        fetchSingleChapterStatus: 'fetching',
      };

    case FETCH_SINGLE_WEEKNUMBER_SUCCESS:
      return {
        ...state,
        fetchSingleChapterStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_WEEKNUMBER_FORM:{
      return {
        ...state,
        form: payload,
      };
    }
     

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import db,{ auth, provider,signIn } from '../components/chatwhatapp/firebase';
import { CHANGE_PASSWORD,
  changePasswordFailure,
  changePasswordRequested,
  changePasswordSuccess,
  FORGOT_PASSWORD,
  forgotPasswordFailure,
  forgotPasswordRequested,
  forgotPasswordSuccess,
  FETCH_MY_PROFILE_DETAIL,
  fetchMyProfileDetailFailure,
  fetchMyProfileDetailRequested,
  fetchMyProfileDetailSuccess,
  LOGIN,
  LOGIN_SUCCESS,
  loginFailure,
  loginRequested,
  loginSuccess,
  LOGOUT,
  logoutFailure,
  logoutRequested,
  logoutSuccess,
  UPDATE_PROFILE_DETAIL,
  updateProfileDetailFailure,
  updateProfileDetailRequested,
  updateProfileDetailSuccess } from '../actions/user-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import { setSchoolYear } from '../actions/school-year-action-type';
import i18next from "i18next";
import axios from "axios";
import { logoutRoute } from "../utility/APIRoutes";
import { webConstants } from "../pages/chatbox/utils/webConstants"

    

export function* loginHandler({ payload }) {
  yield put(loginRequested());
  localStorage?.clear();
  const request = {
    data: payload,
    method: 'POST',
    url: 'login',
  };

  const {
    data, error,
  } = yield call(httpClient, request, true, false);

  if (error) {
    yield put(loginFailure(error));
  } else {
    yield put(loginSuccess(data.data));
    console.log(data.data)
    console.log("checkkkk",data)
    signIn(data?.data);
    if (data.data && data.data.school_year_id) yield put(setSchoolYear(data.data));
    yield delay(200);
   /* localStorage.setItem(
      process.env.REACT_APP_LOCALHOST_KEY,
      JSON.stringify(data.data)
    );*/
    localStorage.setItem(
      process.env.schollY,
      JSON.stringify( {
        label: `${data?.data.start_year} / ${data?.data.end_year}`,
        value: data?.data.school_year_id,
      }),
    );
    localStorage.setItem(
      webConstants.USER_CONNECTED,
      JSON.stringify(data?.data),
    );

    localStorage.setItem(
      process.env.MOBILE_STUDENT_ID,
      JSON.stringify(data?.data?.studentsTutor),
    );

    yield put(push('/school-wall'));
  }
}

export function* forgotPasswordHandler({ payload }) {
  yield put(forgotPasswordRequested());

  const request = {
    method: 'PUT',
    params: payload,
    url: 'forgot-password',
  };

  const { error } = yield call(httpClient, request, true, false);

  if (error) {
    yield put(forgotPasswordFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/login');
      },
      icon: 'success',
      message: i18next.t('skullman:users.passwordResetMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(forgotPasswordSuccess());
  }
}

export function* logoutHandler() {
  yield put(logoutRequested());

  const request = {
    method: 'PUT',
    url: 'logout',
  };

  const { error } = yield call(httpClient, request, true, true);
  const chatkeyUserKey = process.env.REACT_APP_LOCALHOST_KEY+process.env.REACT_APP_HOST_KEY;
  const chatkeyUser = localStorage.getItem(chatkeyUserKey);
  const firebase_chatkeyUser = localStorage.getItem("FIREBASE_USER_CONNECTED");
  if (error) {
    yield put(logoutFailure(error));
  } else {
    yield put(logoutSuccess());

    if(firebase_chatkeyUser){
      console.log(firebase_chatkeyUser);
      auth.signOut();
      db.doc('/users/' + firebase_chatkeyUser?.uid).set({ state: "offline" }, { merge: true });

      localStorage.removeItem(firebase_chatkeyUser);
    }
    if(chatkeyUser){
    const id =async () =>  await JSON.parse(
      chatkeyUser
    )._id;
    const data = async () => await axios.get(`${logoutRoute}/${id}`);
    if (data.status === 200) {
      
      localStorage.removeItem(chatkeyUser);
     
    }
  }
  localStorage.clear();
    yield put(push('/login'));
  }
}

export function* changePasswordHandler({ payload }) {
  yield put(changePasswordRequested());

  const request = {
    method: 'POST',
    params: payload,
    url: 'change-password',
  };

  const { error } = yield call(httpClient, request, true, true);

  if (error) {
    yield put(changePasswordFailure(error));
  } else {
    yield put(changePasswordSuccess());
    yield put(push('/my-profile'));
  }
}

export function* fetchProfileHandler({ payload }) {
  yield put(fetchMyProfileDetailRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'my-profile',
  };

  const {
    data, error,
  } = yield call(httpClient, request, true, true);

  if (error) {
    yield put(fetchMyProfileDetailFailure(error));
  } else {
    yield put(fetchMyProfileDetailSuccess(data.data));
  }
}

export function* updateProfileHandler({ payload }) {
  yield put(updateProfileDetailRequested());
  const body = new FormData();

  body.append('name', payload.name);
  body.append('email', payload.email);
  body.append('password', payload.password);
  body.append('phone_number', payload.phone_number);

  if (payload.image) {
    body.append('image', payload.image);
  }

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'profile-update',
  };

  const { error } = yield call(httpClient, request, true, true);

  if (error) {
    yield put(updateProfileDetailFailure(error));
  } else {
    yield put(updateProfileDetailSuccess());
    yield put(push('/my-profile'));
  }
}

function* user() {
  yield all([
    takeLatest(CHANGE_PASSWORD, changePasswordHandler),
    takeLatest(FORGOT_PASSWORD, forgotPasswordHandler),
    takeLatest(LOGIN, loginHandler),
    takeLatest(LOGOUT, logoutHandler),
    takeLatest([LOGIN_SUCCESS, FETCH_MY_PROFILE_DETAIL], fetchProfileHandler),
    takeLatest(UPDATE_PROFILE_DETAIL, updateProfileHandler),
  ]);
}

export default user;

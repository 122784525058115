import { createAction } from 'redux-actions';

export const ADD_TEACHER = 'ADD_TEACHER';
export const addTeacher = createAction(ADD_TEACHER);

export const ADD_TEACHER_FAILURE = 'ADD_TEACHER_FAILURE';
export const addTeacherFailure = createAction(ADD_TEACHER_FAILURE);

export const ADD_TEACHER_REQUESTED = 'ADD_TEACHER_REQUESTED';
export const addTeacherRequested = createAction(ADD_TEACHER_REQUESTED);

export const ADD_TEACHER_SUCCESS = 'ADD_TEACHER_SUCCESS';
export const addTeacherSuccess = createAction(ADD_TEACHER_SUCCESS);

export const DELETE_TEACHER = 'DELETE_TEACHER';
export const deleteTeacher = createAction(DELETE_TEACHER);

export const DELETE_TEACHER_FAILURE = 'DELETE_TEACHER_FAILURE';
export const deleteTeacherFailure = createAction(DELETE_TEACHER_FAILURE);

export const DELETE_TEACHER_REQUESTED = 'DELETE_TEACHER_REQUESTED';
export const deleteTeacherRequested = createAction(DELETE_TEACHER_REQUESTED);

export const DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS';
export const deleteTeacherSuccess = createAction(DELETE_TEACHER_SUCCESS);

export const FETCH_TEACHER = 'FETCH_TEACHER';
export const fetchTeacher = createAction(FETCH_TEACHER);

export const FETCH_TEACHER_FAILURE = 'FETCH_TEACHER_FAILURE';
export const fetchTeacherFailure = createAction(FETCH_TEACHER_FAILURE);

export const FETCH_TEACHER_REQUESTED = 'FETCH_TEACHER_REQUESTED';
export const fetchTeacherRequested = createAction(FETCH_TEACHER_REQUESTED);

export const FETCH_TEACHER_SUCCESS = 'FETCH_TEACHER_SUCCESS';
export const fetchTeacherSuccess = createAction(FETCH_TEACHER_SUCCESS);

export const EDIT_TEACHER = 'EDIT_TEACHER';
export const editTeacher = createAction(EDIT_TEACHER);

export const EDIT_TEACHER_FAILURE = 'EDIT_TEACHER_FAILURE';
export const editTeacherFailure = createAction(EDIT_TEACHER_FAILURE);

export const EDIT_TEACHER_REQUESTED = 'EDIT_TEACHER_REQUESTED';
export const editTeacherRequested = createAction(EDIT_TEACHER_REQUESTED);

export const EDIT_TEACHER_SUCCESS = 'EDIT_TEACHER_SUCCESS';
export const editTeacherSuccess = createAction(EDIT_TEACHER_SUCCESS);

export const FETCH_SINGLE_TEACHER = 'FETCH_SINGLE_TEACHER';
export const fetchSingleTeacher = createAction(FETCH_SINGLE_TEACHER);

export const FETCH_SINGLE_TEACHER_FAILURE = 'FETCH_SINGLE_TEACHER_FAILURE';
export const fetchSingleTeacherFailure = createAction(FETCH_SINGLE_TEACHER_FAILURE);

export const FETCH_SINGLE_TEACHER_REQUESTED = 'FETCH_SINGLE_TEACHER_REQUESTED';
export const fetchSingleTeacherRequested = createAction(FETCH_SINGLE_TEACHER_REQUESTED);

export const FETCH_SINGLE_TEACHER_SUCCESS = 'FETCH_SINGLE_TEACHER_SUCCESS';
export const fetchSingleTeacherSuccess = createAction(FETCH_SINGLE_TEACHER_SUCCESS);

export const UPDATE_TEACHER_FORM = 'UPDATE_TEACHER_FORM';
export const updateTeacherForm = createAction(UPDATE_TEACHER_FORM);

export const DELETE_TEACHER_CLASS = 'DELETE_TEACHER_CLASS';
export const deleteTeacherClass = createAction(DELETE_TEACHER_CLASS);


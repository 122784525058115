import { string } from 'prop-types';
import React from 'react';
import "../../../public/css/customstyle.css";
import {useTranslation} from "react-i18next";

const BadgeCellRenderer = ({
  className, text,
}) => {
  const [t] = useTranslation('skullman');
  return(text && text.length ? (
    <span className={`${className}`}>
      {t('badgeCellRenderer.'+text) === "badgeCellRenderer."+text? text :t('badgeCellRenderer.'+text)}      
    </span>
  ) : null);
}

BadgeCellRenderer.propTypes = {
  className: string,
  text: string.isRequired,
};

BadgeCellRenderer.defaultProps = { className: 'badge badge-success' };

export default BadgeCellRenderer;

import { createAction } from 'redux-actions';

export const FETCH_SINGLE_TRANSPORT = 'FETCH_SINGLE_TRANSPORT';
export const fetchSingleTransport = createAction(FETCH_SINGLE_TRANSPORT);

export const FETCH_SINGLE_TRANSPORT_FAILURE = 'FETCH_SINGLE_TRANSPORT_FAILURE';
export const fetchSingleTransportFailure = createAction(FETCH_SINGLE_TRANSPORT_FAILURE);

export const FETCH_SINGLE_TRANSPORT_REQUESTED = 'FETCH_SINGLE_TRANSPORT_REQUESTED';
export const fetchSingleTransportRequested = createAction(FETCH_SINGLE_TRANSPORT_REQUESTED);

export const FETCH_SINGLE_TRANSPORT_SUCCESS = 'FETCH_SINGLE_TRANSPORT_SUCCESS';
export const fetchSingleTransportSuccess = createAction(FETCH_SINGLE_TRANSPORT_SUCCESS);

export const DELETE_TRANSPORT = 'DELETE_TRANSPORT';
export const deleteTransport = createAction(DELETE_TRANSPORT);

export const DELETE_TRANSPORT_FAILURE = 'DELETE_TRANSPORT_FAILURE';
export const deleteTransportFailure = createAction(DELETE_TRANSPORT_FAILURE);

export const DELETE_TRANSPORT_REQUESTED = 'DELETE_TRANSPORT_REQUESTED';
export const deleteTransportRequested = createAction(DELETE_TRANSPORT_REQUESTED);

export const DELETE_TRANSPORT_SUCCESS = 'DELETE_TRANSPORT_SUCCESS';
export const deleteTransportSuccess = createAction(DELETE_TRANSPORT_SUCCESS);

export const ADD_TRANSPORT = 'ADD_TRANSPORT';
export const addTransport = createAction(ADD_TRANSPORT);

export const ADD_TRANSPORT_FAILURE = 'ADD_TRANSPORT_FAILURE';
export const addTransportFailure = createAction(ADD_TRANSPORT_FAILURE);

export const ADD_TRANSPORT_REQUESTED = 'ADD_TRANSPORT_REQUESTED';
export const addTransportRequested = createAction(ADD_TRANSPORT_REQUESTED);

export const ADD_TRANSPORT_SUCCESS = 'ADD_TRANSPORT_SUCCESS';
export const addTransportSuccess = createAction(ADD_TRANSPORT_SUCCESS);

export const EDIT_TRANSPORT = 'EDIT_TRANSPORT';
export const editTransport = createAction(EDIT_TRANSPORT);

export const EDIT_TRANSPORT_FAILURE = 'EDIT_TRANSPORT_FAILURE';
export const editTransportFailure = createAction(EDIT_TRANSPORT_FAILURE);

export const EDIT_TRANSPORT_REQUESTED = 'EDIT_TRANSPORT_REQUESTED';
export const editTransportRequested = createAction(EDIT_TRANSPORT_REQUESTED);

export const EDIT_TRANSPORT_SUCCESS = 'EDIT_TRANSPORT_SUCCESS';
export const editTransportSuccess = createAction(EDIT_TRANSPORT_SUCCESS);

export const FETCH_TRANSPORT = 'FETCH_TRANSPORT';
export const fetchTransport = createAction(FETCH_TRANSPORT);

export const FETCH_TRANSPORT_FAILURE = 'FETCH_TRANSPORT_FAILURE';
export const fetchTransportFailure = createAction(FETCH_TRANSPORT_FAILURE);

export const FETCH_TRANSPORT_REQUESTED = 'FETCH_TRANSPORT_REQUESTED';
export const fetchTransportRequested = createAction(FETCH_TRANSPORT_REQUESTED);

export const FETCH_TRANSPORT_SUCCESS = 'FETCH_TRANSPORT_SUCCESS';
export const fetchTransportSuccess = createAction(FETCH_TRANSPORT_SUCCESS);

export const UPDATE_TRANSPORT_FORM = 'UPDATE_TRANSPORT_FORM';
export const updateTransportForm = createAction(UPDATE_TRANSPORT_FORM);

export const UPDATE_TRANSPORT_ADD_RESPONSE = 'UPDATE_TRANSPORT_ADD_RESPONSE';
export const updateTransportAddResponse = createAction(UPDATE_TRANSPORT_ADD_RESPONSE);

import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_TEACHER,
  addTeacherFailure,
  addTeacherRequested,
  addTeacherSuccess,
  DELETE_TEACHER,
  deleteTeacherFailure,
  deleteTeacherRequested,
  deleteTeacherSuccess,
  EDIT_TEACHER,
  editTeacherFailure,
  editTeacherRequested,
  editTeacherSuccess,
  FETCH_SINGLE_TEACHER,
  fetchSingleTeacherFailure,
  fetchSingleTeacherRequested,
  fetchSingleTeacherSuccess,
  FETCH_TEACHER,
  fetchTeacherFailure,
  fetchTeacherRequested,
  fetchTeacherSuccess,
  DELETE_TEACHER_CLASS,
} from '../actions/teacher-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import i18next from "i18next";


export function* createTeacherHandler({ payload }) {
  yield put(addTeacherRequested());
  // const classValues = payload && payload.class_list && payload.class_list.length > 0 ? payload?.class_list?.map((u) => u.value) : [];
  //  const SubjectValues = payload && payload.Subject_id && payload.Subject_id.length > 0 ? payload?.Subject_id?.map((u) => u.value) : [];

  const classId = payload?.class_list?.map((u) => u.class_id);
  const subjectId = payload?.class_list?.map((u) => u.subject_id);
  const classArray = [];
  //classArray.push(classId,subjectId);
  const body = new FormData();
  console.log(JSON.stringify(payload.class_list));
  console.log(payload.profile_picture);
  body.append('user_name', payload.user_name);
  body.append('first_name', payload.first_name);
  body.append('last_name', payload.last_name);
  body.append('department_id', payload.department_id);
  body.append('email', payload.email);
  body.append('school_year_id', payload.school_year_id);
  if (payload.highest_qualification) {
    body.append('highest_qualification', payload.highest_qualification);
  }
  body.append('phone_no', payload.phone_no);
  body.append('address', payload.address);
  body.append('status', payload.status);
  if (payload.profile_picture) {
    body.append('profile_picture', payload.profile_picture);
  }
  body.append('class_list', JSON.stringify(payload.class_list));

  console.log(payload);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-teacher',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addTeacherFailure(error));
  } else {
    yield put(addTeacherSuccess());
    // const alertProps = {
    //   callback: () => {
    //     history.push('/teacher');
    //   },
    //   icon: 'success',
    //   message: 'Teacher created successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);
  }
}

export function* editTeacherHandler({ payload }) {
  // console.log(payload, payload.profile_picture);
  yield put(editTeacherRequested());
  const classValues = payload && payload.class_id && payload.class_id.length > 0 ? payload?.class_id?.map((u) => u.value) : [];
  const SubjectValues =
    payload && payload.Subject_id && payload.Subject_id.length > 0 ? payload?.Subject_id?.map((u) => u.value) : [];

  const body = new FormData();

  // body.append('first_name', payload.first_name);
  // body.append('last_name', payload.last_name);
  // body.append('email', payload.email);
  // body.append('Subject_id', SubjectValues && SubjectValues.length > 0 ? SubjectValues.join() : '');
  // body.append('school_year_id', payload.school_year_id);
  // body.append('department_id', payload.department_id.value);
  // if (payload.highest_qualification) {
  //   body.append('highest_qualification', payload.highest_qualification.value);
  // }
  // body.append('phone_no', payload.phone_no);
  // body.append('class_id', classValues && classValues.length > 0 ? classValues.join() : '');
  // body.append('address', payload.address);
  // body.append('status', payload.status.value);
  // if (payload.profile_picture && payload.profile_picture?.size > 0) {
  //   body.append('profile_picture', payload.profile_picture);
  // }

  body.append('user_name', payload.user_name);
  body.append('first_name', payload.first_name);
  body.append('last_name', payload.last_name);
  body.append('department_id', payload.department_id);
  body.append('email', payload.email);
  body.append('school_year_id', payload.school_year_id);
  if (payload.highest_qualification) {
    body.append('highest_qualification', payload.highest_qualification);
  }
  body.append('phone_no', payload.phone_no);
  body.append('address', payload.address);
  body.append('status', payload.status);
  if (payload?.profile_picture && !payload.profile_picture.toString().includes('http')) {
    body.append('profile_picture', payload.profile_picture);
  }
  body.append('class_list', JSON.stringify(payload.class_list));

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-teacher/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editTeacherFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname:'/teacher'});
    //   },
    //   icon: 'success',
    //   message: 'Teacher updated successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);
    yield put(editTeacherSuccess());
  }
}

export function* deleteTeacherHandler({ payload }) {
  yield put(deleteTeacherRequested());
  const request = {
    method: 'DELETE',
    url: `delete-teacher/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteTeacherFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:schoolStaff.teachers.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteTeacherSuccess(payload));
  }
}

export function* fetchTeacherHandler({ payload }) {
  yield put(fetchTeacherRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'teachers',
  };
console.log(payload)
  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchTeacherFailure(error));
  } else {
    yield put(fetchTeacherSuccess(data.data.result));
    if(payload?.result)
    {
      payload.result(data.data.result)
    }
  }
}

export function* fetchSingleTeacherHandler({ payload }) {
  yield put(fetchSingleTeacherRequested());
  const request = {
    method: 'GET',
    url: `get-teacher/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleTeacherFailure(error));
  } else {
    const { result } = data.data;

    const department_id = (typeof result?.department_id === 'string' ? result?.department_id : result?.department_id?.toString()).split(','); //result?.department_id?.split(',');
    const department_name = (typeof result?.department_name === 'string' ? result?.department_name : result?.department_name?.toString()).split(',');//result?.department_name?.split(',');

    let department_arr = [];
    for (let index = 0; index < department_id.length; index++) {
      let value = department_id[index];
      let label = department_name[index];

      let obj = {};
      obj.label = label;
      obj.value = value;
      department_arr.push(obj);
    }

    const res = {
      ...data.data.result,
      department_id: department_arr,
      highest_qualification: {
        label: result.qualification_name,
        value: result.highest_qualification,
      },
      id: result.id,

      status:
        result.status === 1
          ? {
              label: 'Active',
              value: 1,
            }
          : {
              label: 'Inactive',
              value: 0,
            },
    };

    yield put(fetchSingleTeacherSuccess(res));
  }
}

export function* deleteTeacherClass({ payload }) {
  const request = {
    method: 'DELETE',
    url: `delete-teacher-class/${payload}`,
  };

  yield call(httpClient, request);
}

function* teacher() {
  yield all([
    takeLatest(ADD_TEACHER, createTeacherHandler),
    takeLatest(DELETE_TEACHER, deleteTeacherHandler),
    takeLatest(EDIT_TEACHER, editTeacherHandler),
    takeLatest(FETCH_TEACHER, fetchTeacherHandler),
    takeLatest(FETCH_SINGLE_TEACHER, fetchSingleTeacherHandler),
    takeLatest(DELETE_TEACHER_CLASS, deleteTeacherClass),
  ]);
}

export default teacher;

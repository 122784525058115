import React, { Fragment } from "react";

import {
  IoIosGrid,
  IoIosAnalytics,
} from "react-icons/io";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  DropdownItem,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Collapse,
  Fade,NavLink,
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { isMobile } from 'react-device-detect';
import PerfectScrollbar from "react-perfect-scrollbar";
import bg4 from "../../../assets/utils/images/dropdown-header/abstract4.jpg";
import city2 from "../../../assets/utils/images/dropdown-header/city2.jpg";
import { USER_SUPER_ADMIN, USER_SCHOOL_ADMIN, USER_SCHOOL_SUB_ADMIN, USER_TEACHER,SCHEDULE_VIEW_SCHEDULE,SCHOOLWALL_VIEW_WALL, SCHEDULE, SCHOOLWALL, TUITION_ENROLLMENT, TUITION_ENROLLMENT_LIST, TUITION_PAYMENT_LIST, TUITION_PAYMENT,
    GRADES_STUDENTS_VIEW_STUDENT_LIST, GRADES_STUDENTS,GRADES_ATTENDANCEMANAGEMENT_VIEW_ATTENDANCE_LIST, GRADES_ATTENDANCEMANAGEMENT,
     GRADES_MARKMANAGEMENT_VIEW_MARKS_LIST, GRADES_MARKMANAGEMENT ,GRADES_MARKREPORT_GRADES__MARK_REPORT,GRADES_MARKREPORT, FINANCE_ENTRIES, FINANCE_ENTRIES_LIST, FINANCE_OUTFLOWS_LIST, FINANCE_OUTFLOWS, DEPARTMENT_LIST, DEPARTMENT, SUBJECT_LIST, SUBJECT, SCHOOLSTAFF_TEACHERS_LIST, SCHOOLSTAFF_TEACHERS, REPORTING_ENROLLMENT_FINANCIAL_REPORT_VIEW_REPORT, REPORTING_ENROLLMENT_FINANCIAL_REPORT, REPORTING_ENTRIESDAILYREPORT_VIEW_REPORT, REPORTING_ENTRIESDAILYREPORT, PEDAGOGICALFOLLOWUP_CHAPTER, PEDAGOGICALFOLLOWUP_CHAPTER_LIST, PEDAGOGICALFOLLOWUP_PART, PEDAGOGICALFOLLOWUP_PART_LIST, PEDAGOGICALFOLLOWUP_FORECAST, PEDAGOGICALFOLLOWUP_FORECAST_LIST, PEDAGOGICALFOLLOWUP_PROGRESSION, PEDAGOGICALFOLLOWUP_PROGRESSION_LIST, PEDAGOGICALFOLLOWUP_REPORT, PEDAGOGICALFOLLOWUP_REPORT_VIEW, SETTINGS_APPRECIATION_LIST, SETTINGS_CLASSES, SETTINGS_SCHOOLINFORMATION, SETTINGS_SCHOOLINFORMATION_VIEW, SETTINGS_SCHOOLYEAR, SETTINGS_SCHOOLYEAR_LIST, SETTINGS_HONNORROLL, SETTINGS_HONNORROLL_VIEW, SETTINGS_QUALIFICATION, SETTINGS_QUALIFICATION_ADD, SETTINGS_GRADES, SETTINGS_GRADES_LIST, SETTINGS_WEEKS, SETTINGS_WEEKS_LIST, SETTINGS_CLASSES_LIST, SETTINGS_INSTALLMENT, SETTINGS_INSTALLMENT_LIST, SETTINGS_APPRECIATION, SETTINGS_DISCOUNTS, SETTINGS_DISCOUNTS_LIST, SETTINGS_PAYMENTTYPE, SETTINGS_PAYMENTTYPE_LIST, SETTINGS_COSTITEM, SETTINGS_COSTITEM_LIST, SETTINGS_COSTREASON, SETTINGS_COSTREASON_LIST, SETTINGS_SOURCEOFINCOME, SETTINGS_SOURCEOFINCOME_LIST, SETTINGS_INCOMEREASON, SETTINGS_COSTREASON_ADD, SETTINGS_INCOMEFORECAST, SETTINGS_INCOMEFORECAST_LIST, SETTINGS_EXPENSESFORECAST, SETTINGS_EXPENSESFORECAST_LIST, SETTINGS_OTHERPARAMETERS, SETTINGS_OTHERPARAMETERS_VIEW
   } from '../../../constants';
import Flag from "react-flagkit";
import { useDispatch, useSelector  } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Drawer_new from "../../../components/drawser_new";
import NavMobile from "../../AppNav/VerticalNavWrapper";
import { setEnableMobileMenu } from "../../../reducers/ThemeOptions";
import LanguageSwitcher from "../../../components/language-switcher";
import {push} from "connected-react-router";
import materialSymbolsscho from "../../../assets/img/materialSymbolsscho.svg";
import materialSymbolsschoActive from "../../../assets/img/materialSymbolsschoActive.svg";
import { fetchSchoolYear, updateSchoolYearForm, setInitialState, setCurrentYearOnDb } from '../../../actions/school-year-action-type';
import Select from 'react-select';
//import StudentMenu from "./StudentMenu";

const MobileMenu = (user,activeDrawerMenu) =>{
  
const dispatch = useDispatch();
  const [active, setActive] = React.useState( false);
  const location = useLocation();
  const [t] = useTranslation('skullman');
  const [collapse, setCollapse] = React.useState( false);
  const [accordion, setAccordion] = React.useState( [true, false, false]);
  const [custom, setCustom] = React.useState( [true, false]);
  const [status, setStatus] = React.useState("Closed");
  const [fadeIn, setFadeIn] = React.useState( true);
  const [timeout, setTimeout] = React.useState(300);
  
  //School Year
  const {
    schoolYears, form
  } = useSelector((store) => (
    {
      form: store?.schoolYears?.form,
      schoolYears: store?.schoolYears?.schoolYears,
    }));
    console.log(schoolYears);
   /* const defaultYear={
      label: `${schoolYears[0]?.school_start_year} / ${schoolYears[0]?.school_end_year}`,
      value: schoolYears[0]?.school_year_id,
    };*/
const {school_year_id}=form;

//const schollY = JSON.parse(localStorage.getItem(process.env.schollY)) ? JSON.parse(localStorage.getItem(process.env.schollY)) : []

    React.useEffect(() => {
      const request = { filter: 'active' };
      
      dispatch(fetchSchoolYear(request));
     
    }, []);
  
    React.useEffect(() => {
  
      const updates = {
        ...form,
        school_year_id
      };
  
      dispatch(updateSchoolYearForm(updates));
    }, []);
  
    const onSelectChange = (name, value) => {
    
     localStorage.setItem(
      process.env.schollY,
      JSON.stringify(value),
    );

     // setSchool_year_id_d(value);
      const updates = {
        ...form,
        school_year_id: value,
      };
  
      dispatch(updateSchoolYearForm(updates));
     /* setTimeout(() => {
        window.location.reload(); 
      }, 100);*/
  
    };
    //End
  const onEntering= () =>{
    setStatus("Opening...");
  }

  const onEntered = () =>{
    setStatus("Opened");
  }

  const onExiting = () =>{
    setStatus("Closing...");
  }

  const onExited = () =>{
    setStatus("Closed");
  }

  const toggle = () =>{
    setCollapse(!collapse);
  }

  const toggleAccordion =(tab)=> {
    const prevState = accordion;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    setAccordion(state);
  }

  const toggleCustom=(tab) =>{
    const prevState = custom;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    setCustom(state);
  }

  const toggleFade = () =>{
    setFadeIn(!fadeIn);
  }
  const [enableMobileMenu, setEnableMobileMenu] = React.useState( false);
  const toggleMobileSidebar = () => {
    setEnableMobileMenu(!enableMobileMenu);
  };

  const toggleMobileSidebar_false = () => {
    setEnableMobileMenu(true);
  };

    return (
      <Fragment>
        <div style={{height:'100%'}}>
          <UncontrolledDropdown style={{height:'100%'}}>
            <DropdownToggle className="p-0 me-2" color="link" style={{height:'100%'}}>
              {/*<div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg bg-primary" />
                <IoIosGrid color="#3f6ad8" fontSize="23px" />
    </div>*/}
    
          <Container style={{height:'100%'}}>
            <div class="sub-menu custom-footer-div" style={{height:'100%'}}>
           < ContainerFoot style={{height:'100%'}}>
           <div className="custom-footer-link" style={{height:'100%'}}>
           <img alt="loading error!" className="material-symbolsscho" src={materialSymbolsscho} />
                    <span className='adjust footer-text'>{t('menu.other')}</span>
           </div>
            
                    </ContainerFoot>        
              </div>
              </Container>
           
            </DropdownToggle>
           
            <DropdownMenu end className="dropdown-menu-xl rm-pointers">
              <div className="dropdown-menu-header">
                <div className="dropdown-menu-header-inner bg-plum-plate">
                  <div className="menu-header-image"
                    style={{
                      backgroundImage: "url(" + bg4 + ")",
                    }}/>
                  <div className="menu-header-content text-white">
                  <Nav>
                  <NavItem className="nav-item-header" >
                  <Row>
                    <Col>
                    <div style={{marginTop:'10px'}}>
                <LanguageSwitcher />
                </div >
                </Col>
                <Col>
                <div style={{minWidth:"150px"}}>
                  <div style={{color: "white"}}>{t('setting.schoolYear.listOfSchoolYears')}</div>
                  {schoolYears && schoolYears?.length>0 ?
                                <Select
          isSearchable
          name="school_year_id"
          id="school_year_id"
          loadingMessage="Please wait loading"
          placeholder="Select School  Year"
          onChange={(value) => onSelectChange('school_year_id', value)}
          options={schoolYears?.map((schoolYear) => ({
            label: `${schoolYear?.school_start_year} / ${schoolYear?.school_end_year}`,
            value: schoolYear?.school_year_id,
          }))}
          value={school_year_id }
          />
        : <Select
        isSearchable
        name="school_year_id"
        id={"school_year_id"}
        loadingMessage="Please wait loading"
        placeholder="Select School  Year"
        isDisabled={true}
        />}</div>
       
        
     
        
        </Col>
       
          </Row>
         {/* <Row>
            <Col><div style={{minWidth:"150px"}}>
            <StudentMenu user={user}/>
              </div>
            </Col>
   
        </Row>*/}
                                  </NavItem>
                                 
                                 
                                  
                                  </Nav>
                                 
                    {/*<Nav>
                    <NavItem >
                    <h5 className="menu-header-title">My Account</h5>
                                  </NavItem>
                                  <Row>
                                    <Col>
                                  <NavItem >
                                    <NavLink href="/my-profile">
                                    <h6 className="menu-header-subtitle" style={{color:'white'}}> Settings  </h6>
                                    </NavLink>
                                  </NavItem>
                                  </Col>
                                  <Col>
                                  <NavItem >
                                    <NavLink  href="/change-password">
                                        <h6 className="menu-header-subtitle" style={{color:'white'}}>Recover Password</h6></NavLink>
                                  </NavItem>
                                  </Col>
                                  </Row>
                </Nav>*/}
                  </div>
                </div>
              </div>
              <Card >
                <CardBody style={{height:'400px'}}>
              <PerfectScrollbar>

             {/* <div className="grid-menu grid-menu-xl grid-menu-3col">
              
                <Row className="g-0s">
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">  {" "} </i>
                      Automation
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">  {" "} </i>
                      Automation
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">  {" "} </i>
                      Automation
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">  {" "} </i>
                      Automation
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">  {" "} </i>
                      Automation
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">
                        {" "}
                      </i>
                      Reports
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                      Settings
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                      Content
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                      Activity
                    </Button>
                  </Col>
                  <Col xl="4" sm="6">
                    <Button className="btn-icon-vertical btn-square btn-transition" outline color="link">
                      <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                      Contacts
                    </Button>
                  </Col>
                </Row>
                
                </div>*/}
                

                <div className="app-sidebar__inner">
               
                  <NavMobile user={user?.user?.user} activeDrawerMenu={user?.user?.activeDrawerMenu}  />
            </div>

              </PerfectScrollbar>
              </CardBody>
               
              </Card>
            </DropdownMenu>
           
          </UncontrolledDropdown>
    
        </div>
      </Fragment>
    );
  }

export default MobileMenu;
const Container = styled.div`
.sub-menu {
    float: left;
    display: block;
    text-align: center;
  }
  
  .sub-menu .menu-title {
    display: block;
    margin-top: 1px;
  }`;

  const ContainerFoot =styled.div`
  .mobile-view {
    color: #000;
    width: 100%;
    height: 100%;
    background-color: #f1f4f6;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-family: Inter, Arial;
    font-size: .625rem;
    font-weight: 400;
    line-height: 12px;
    display: flex;
  }
  
  .background {
    width: 95%;
    background-color: #fff;
    border-radius: 29px;
    flex-direction: column;
    flex-basis: 62%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 35px;
    display: flex;
    position: relative;
    top: -14px;
  }
  .rectangle-34625542 {
    position: relative;
    top: 5px;
    color: #fff;
    width: 75%;
    background-color: #f7b924;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    margin-left: 1px;
    padding-left: 5px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
  }
  
  .class-tle-a-4-esp {
    margin-left: 3px;
  }
  
  .rectangle-34625543 {
    color: #fff;
    width: 75%;
    background-color: #3ac47d;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
    padding-left: 4px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
  }
  
  .class-tle-a-4-all {
    margin-left: 2px;
  }
  
  .rectangle-34625544 {
    position:relative;
    top: -6px;
    color: #fff;
    width: 75%;
    background-color: #3f6ad8;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
    padding-left: 4px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
  }
  
  .class-1-er-a-4-all {
    margin-left: 2px;
  }
  
  .rectangle-34625545 {
    color: #fff;
    width: 75%;
    background-color: #f7b924;
    border: none;
    border-radius: 8px;
    flex-direction: row;
    flex-basis: 16%;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    margin-left: 1px;
    padding-right: 6px;
    font-family: Poppins, Arial;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 27px;
    display: flex;
    position: relative;
    top: -13px;
  }
  
  .rectangle-34625552 {
    width: 100%;
   /* background-color: #fff;*/
    flex-direction: row;
    flex-basis: 12%;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 3px;
    display: flex;
  }
  
  .flex-container {
    color: #15399e;
    height: 65%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 700;
    display: flex;
  }
  
  .rihome-5-line {
    width: 31px;
    height: 31px;
    object-fit: cover;
  }
  
  .flex-container-1 {
    height: 65%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 1px;
    margin-left: 9px;
    display: flex;
  }
  
  .icomoon-freemeter {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  
  .schedule {
    margin-bottom: -1px;
  }
  
  .flex-container-2 {
    height: 65%;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 1px;
    margin-left: 14px;
    display: flex;
  }
  
  .tablerclipboard-list {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  
  .grades {
    margin-bottom: -1px;
  }
  
  .flex-container-3 {
    height: 60%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
    display: flex;
  }
  
  .carbonuser-multiple {
    width: 26px;
    height: 26px;
    object-fit: cover;
    position: relative;
    top: 3px;
  }
  
  .reAdjust {
    position: relative;
    top: 3px;
  }
  
  .flex-container-4 {
    height: 70%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  
  .studentListMsge {
    position: relative;
    left: -13vw;}
  
  .searchIcon {
    position: relative;
    top: -37px;
    left: 36vw;
  }
  
  .Search {
    height: 47px;
    width: 387px;
    width: 89vw;
    margin-top: 12px;
    border-width: 0px;
    border-radius: 53px;
    box-shadow: 0px 0px 9px -6px;
  
  }
  
  input[placeholder="Search here"]r {
    position: absolute;
    /* left: 20px; */
  }
  
  .placeholder {
    width: 28px;
    height: 30px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    border-radius: 42px;
    margin-bottom: 1px;
    margin-left: 12px;
    box-shadow: 0px 0px 8px -3px;
  }
  
  .headerStyle {
    color: #0f0f0f;
    width: 100%;
    background-color: #fff;
    flex-direction: row;
    flex-basis: 6%;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1px;
    font-size: 1.188rem;
    font-weight: 600;
    line-height: 55px;
    display: flex;
  }
  
  input::placeholder {
    padding-left: 20px;
  }
  
  input {
    padding-left: 20px;
  }
  
  .searchField {
    color: #aaa;
    width: 90%;
    margin-top: 6px;
    background-color: #fff;
    border: none;
    border-radius: 53px;
    flex-direction: row;
    flex-basis: 5%;
    justify-content: flex-start;
    align-items: stretch;
    font-family: Poppins, Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    display: flex;
  }
  
  .placeholder-1 {
    width: 35px;
    height: 35px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    border-radius: 42px;
    margin-bottom: 1px;
  }
  
  .rectangle-10 {
    width: 32px;  
    height: 32px;
    object-fit: cover;
    border-radius: 100px;
    margin-top: 1px;
    transform: rotate(180deg);
  }
  
  .material-symbolsscho {
    width: 35px;
    height: 35px;
    object-fit: cover;
  }
    @media  screen and (min-width: 390px) and (min-height: 844px) and (max-width: 391px) and (max-height: 845px) {
      .mobile-view{
        width: 390px;
      }
      .Search{
        position: relative;
        top: -71px;
      } 
      .searchIcon{
        top: -108px;
      }
      .headerStyle{
        position: relative;
        top: -69px;  
      }
        .background{
          top: -87px;
        }
        .rectangle-34625552{
          flex-basis: 8%;
        }
        .studentListMsge{
          top: 3.8px;
        }
    }
    @media  screen and (min-width: 360px) and (min-height: 740px) and (max-width: 361px) and (max-height: 741px) {
      .rectangle-34625552{
        flex-basis: 9%;
      }
      .studentListMsge{
        top: 3.8px;
      }
      .Search{
        position: relative;
        top: -31px !important;
      }
      .headerStyle{
        position: relative;
        top: -30px !important;
      }
      .searchIcon{
        top: -68px !important;
      }
      .background{
        top: -46px;
      }
  
      .adjust{
        position: relative;
        top: -3px;
      }
    }
  `;

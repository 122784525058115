import {createAction} from "redux-actions";

export const ADD_QUARTERS = 'ADD_QUARTERS';
export const addQuarters = createAction(ADD_QUARTERS);

export const ADD_QUARTERS_REQUESTED = 'ADD_QUARTERS_REQUESTED';
export const addQuartersRequested = createAction(ADD_QUARTERS_REQUESTED);

export const ADD_QUARTERS_SUCCESS = 'ADD_QUARTERS_SUCCESS';
export const addQuartersSuccess = createAction(ADD_QUARTERS_SUCCESS);

export const ADD_QUARTERS_FAILURE = 'ADD_QUARTERS_FAILURE';
export const addQuartersFailure = createAction(ADD_QUARTERS_FAILURE);

export const EDIT_QUARTERS = 'EDIT_QUARTERS';
export const editQuarters = createAction(EDIT_QUARTERS);

export const EDIT_QUARTERS_REQUESTED = 'EDIT_QUARTERS_REQUESTED';
export const editQuartersRequested = createAction(EDIT_QUARTERS_REQUESTED);

export const EDIT_QUARTERS_SUCCESS = 'EDIT_QUARTERS_SUCCESS';
export const editQuartersSuccess = createAction(EDIT_QUARTERS_SUCCESS);

export const EDIT_QUARTERS_FAILURE = 'EDIT_QUARTERS_FAILURE';
export const editQuartersFailure = createAction(EDIT_QUARTERS_FAILURE);

export const FETCH_QUARTERS = 'FETCH_QUARTERS';
export const fetchQuarters = createAction(FETCH_QUARTERS);

export const FETCH_QUARTERS_REQUESTED = 'FETCH_QUARTERS_REQUESTED';
export const fetchQuartersRequested = createAction(FETCH_QUARTERS_REQUESTED);

export const FETCH_QUARTERS_SUCCESS = 'FETCH_QUARTERS_SUCCESS';
export const fetchQuartersSuccess = createAction(FETCH_QUARTERS_SUCCESS);

export const FETCH_QUARTERS_FAILURE = 'FETCH_QUARTERS_FAILURE';
export const fetchQuartersFailure = createAction(FETCH_QUARTERS_FAILURE);



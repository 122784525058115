import React from 'react';
import ReactDOM from 'react-dom';
import Application from './src';
import { unregister,register } from './service-worker';

const element = document.querySelector('#skulman');

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("../firebase-messaging-sw.js")
      .then(function(registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
      });
  }

ReactDOM.render(<Application />, element);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register();
//registerServiceWorker();


import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_FAMILY,
  addFamilyFailure,
  addFamilyRequested,
  addFamilySuccess,
  DELETE_FAMILY,
  deleteFamilyFailure,
  deleteFamilyRequested,
  deleteFamilySuccess,
  EDIT_FAMILY,
  editFamilyFailure,
  editFamilyRequested,
  editFamilySuccess,
  FETCH_SINGLE_FAMILY,
  fetchSingleFamilyFailure,
  fetchSingleFamilyRequested,
  fetchSingleFamilySuccess,
  FETCH_FAMILY,
  fetchFamilyFailure,
  fetchFamilyRequested,
  fetchFamilySuccess,
} from '../actions/family-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import i18next from "i18next";


export function* addFamilyHandler({ payload }) {
  yield put(addFamilyRequested());
  const body = new FormData();
  body.append('name', payload.form.name);
  body.append('relationship', payload.form.relationship);
  body.append('phone_no', payload.form.phone_no);
  body.append('dob', payload.form.dob);
  body.append('employee_id', payload.form.employee_id);
  console.log(body);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-family-member',
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(addFamilyFailure(error));
  } else {
    yield put(addFamilySuccess());
    const alertProps = {
      /*callback: () => {
        history.push('/employee');
      },*/
      icon: 'success',
      message: 'Family created successfully.',
      title: 'Success',
    };
    Alert.alert(alertProps);
  }
}

export function* editFamilyHandler({ payload }) {
  yield put(editFamilyRequested());
  const request = {
    method: 'PUT',
    params: {
      name: payload.form.name,
      relationship: payload.form.relationship,
      phone_no: payload.form.phone_no,
      dob: payload.form.dob,
      employee_id: payload.form.employee_id,
    },
    url: `update-family-member/${payload.familyId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(editFamilyFailure(error));
  } else {
    const alertProps = {
      /*callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/employee'});
      },*/
      icon: 'success',
      message: 'Family updated successfully.',
      title: 'Success',
    };
    Alert.alert(alertProps);
    yield put(editFamilySuccess());

  }
}

export function* deleteFamilyHandler({ payload }) {
  yield put(deleteFamilyRequested());
  const request = {
    method: 'DELETE',
    url: `delete-family/${payload.idToDelete}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(deleteFamilyFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:schoolStaff.employees.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };
    Alert.alert(alertProps);
    yield put(deleteFamilySuccess(payload.idToDelete));
  }
}

export function* fetchFamilyHandler({ payload }) {
  yield put(fetchFamilyRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'family-members',
  };
  const { data, error } = yield call(httpClient, request);
  if (error) {
    yield put(fetchFamilyFailure(error));
  } else {
    yield put(fetchFamilySuccess(data.data.result));
  }
}

export function* fetchSingleFamilyHandler({ payload }) {
  yield put(fetchSingleFamilyRequested());
  const request = {
    method: 'GET',
    url: `get-family-member/${payload.publicId}`,
  };
  const { data, error } = yield call(httpClient, request);
  if (error) {
    yield put(fetchSingleFamilyFailure(error));
  } else {
    yield put(fetchFamilySuccess(data.data.result));
    if (payload?.result) {
      payload.result(data.data.result)
    }
  }
}

function* Family() {
  yield all([
    takeLatest(ADD_FAMILY, addFamilyHandler),
    takeLatest(DELETE_FAMILY, deleteFamilyHandler),
    takeLatest(EDIT_FAMILY, editFamilyHandler),
    takeLatest(FETCH_FAMILY, fetchFamilyHandler),
    takeLatest(FETCH_SINGLE_FAMILY, fetchSingleFamilyHandler),
  ]);
}

export default Family;

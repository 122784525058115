import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { push } from 'connected-react-router';
import { fetchTeacherScheduleSuccess,
   FETCH_TEACHER_SCHEDULE, 
   ADD_SCHEDULE,
    addScheduleSuccess,
     addSchedule ,
     fetchScheduleSuccess,
     FETCH_SCHEDULE,
     DELETE_SCHEDULE,
     EDIT_SCHEDULE,
     editScheduleSuccess
} from '../actions/schedule-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from "i18next";

export function* fetchAllTeacherSchedule({ payload }) {
  const class_id = payload?.class_id?.value;
  const request = {
    method: 'GET',
    params: { school_year_id: payload.school_year_id,
      class_id:class_id },
    url: `get-teacherSchedule/${payload?.teacher_id}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
  } else {
    yield put(fetchTeacherScheduleSuccess(data.data.result || []));
  }
}

export function* fetchAllScheduleHandler({ payload }) {
  const class_id = payload?.class_id?.value;
  console.log(payload);
  const request = {
    method: 'GET',    
    params: { school_year_id: payload.school_year_id,
    class_id:class_id },
    url: `list_teacher_Schedules`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
  } else {
    yield put(fetchScheduleSuccess(data.data.result || []));
  }
}
export function* addScheduleHandler({ payload }) {
  const body = new FormData();

  body.append('school_year_id', payload.school_year_id);
  body.append('class_id', payload.class_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('teacher_id', payload.teacher_id.value);
  body.append('fromScheduleTime', moment(payload.fromScheduleTime).format('yyyy-MM-DD HH:mm').toString());
  body.append('toScheduleTime', moment(payload.toScheduleTime).format('yyyy-MM-DD HH:mm').toString());

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-teacherSchedule',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    console.log(error);
  } else {
    const alertProps = {
      callback: () => {
        // history.push('/schedule');
      },
      icon: 'success',
      message: i18next.t('skullman:schedule.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    // window.location.reload();
    Alert.alert(alertProps);
    yield put(addScheduleSuccess());
  }
}


export function* deleteScheduleHandler({ payload }) {


  const request = {
    method: 'DELETE',
    url: `delete_teacher_Schedules/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteWeekNumberFailure(error));
  } else {
    const alertProps = {
      callback: () => {
      },
      icon: 'success',
      message: i18next.t('skullman:schedule.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
      
    };

    Alert.alert(alertProps);
    // yield put(deleteWeekNumberSuccess(payload));
  }
}



export function* editScheduleHandler({ payload }) {
  const body = new FormData();
  body.append('school_year_id', payload.school_year_id);
  body.append('class_id', payload.class_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('teacher_id', payload.teacher_id.value);
  body.append('fromScheduleTime', moment(payload.fromScheduleTime).format('yyyy-MM-DD HH:mm').toString());
  body.append('toScheduleTime', moment(payload.toScheduleTime).format('yyyy-MM-DD HH:mm').toString());

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'update_teacher_Schedules/'+payload?.id,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    console.log(error);
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:schedule.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

   
    Alert.alert(alertProps);
    yield put(editScheduleSuccess());
  }
}



function* schedule() {
  yield all([
    takeLatest(ADD_SCHEDULE, addScheduleHandler),
    takeLatest(FETCH_TEACHER_SCHEDULE, fetchAllTeacherSchedule),
    takeLatest(FETCH_SCHEDULE, fetchAllScheduleHandler),
       takeLatest(DELETE_SCHEDULE, deleteScheduleHandler),
       takeLatest(EDIT_SCHEDULE,editScheduleHandler)
  ]);
}

export default schedule;

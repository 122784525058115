import { string, number } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { downloadFile } from '../../actions/file-downloader-action-types';

const DailyEntryCellRenderer = ({
  className, entry_reason_id,
}) => {
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const dailyEntryExport = () => {
    const request = {
      filename: 'receipt.pdf',
      params: { current_date_time: `${moment(selectedDate, 'X').format('DD/MM/YYYY')} at ${moment(selectedDate, 'X').format('HH:mm')} ` },
      url: `entry-receipt/${entry_reason_id}`,
    };

    dispatch(downloadFile(request));
  };

  return (
    <>
      <div>
        <button onClick={dailyEntryExport} className={`${className}`} type="button">
          <i className="lnr-download" data-toggle="tooltip" data-placement="top" title="Report" data-original-title="Report" />
          <span> Download Receipt</span>
        </button>
      </div>
    </>
  );
};

DailyEntryCellRenderer.propTypes = {
  className: string,
  entry_reason_id: number.isRequired,
};

DailyEntryCellRenderer.defaultProps = { className: 'btn btn-sm btn-success' };

export default DailyEntryCellRenderer;

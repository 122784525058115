import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_WEEKNUMBER,
  addWeekNumberFailure,
  addWeekNumberRequested,
  addWeekNumberSuccess,
  DELETE_WEEKNUMBER,
  deleteWeekNumberFailure,
  deleteWeekNumberRequested,
  deleteWeekNumberSuccess,
  FETCH_WEEKNUMBER,
  fetchWeekNumberFailure,
  fetchWeekNumberRequested,
  fetchWeekNumberSuccess,
  FETCH_SINGLE_WEEKNUMBER,
  fetchSingleWeekNumberFailure,
  fetchSingleWeekNumberRequested,
  fetchSingleWeekNumberSuccess,
  EDIT_WEEKNUMBER,
  editWeekNumberFailure,
  editWeekNumberRequested,
  editWeekNumberSuccess
} from '../actions/weeknumber-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import moment from 'moment'
import i18next from "i18next";

export function* addWeekNumberHandler({ payload }) {
  yield put(addWeekNumberRequested());
  const body = new FormData();

  body.append('week_number', payload.week_number);
  body.append('week_from', moment(payload.week_from).format('yyyy-MM-DD HH:mm:ss').toString());
  body.append('week_to', moment(payload.week_to).format('yyyy-MM-DD HH:mm:ss').toString());
  body.append('school_year_id', payload.school_year_id);
  body.append('quater_id', payload.quarter_id.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-weekNumber',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addWeekNumberFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/week-number');
      },
      icon: 'success',
      message: i18next.t('skullman:setting.weekNumber.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addWeekNumberSuccess());
  }
}

export function* editWeekNumberHandler({ payload }) {
  yield put(editWeekNumberRequested());

  const body = new FormData();

  body.append('week_number', payload.week_number);
  body.append('week_from', moment(payload.week_from).format('yyyy-MM-DD HH:mm:ss').toString());
  body.append('week_to', moment(payload.week_to).format('yyyy-MM-DD HH:mm:ss').toString());
  body.append('school_year_id', payload.school_year_id);
  body.append('quater_id', payload.quarter_id.value);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-weekNumber/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editWeekNumberFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push({
          params: location?.param1,
          pathname: '/week-number',
        });
      },

      icon: 'success',
      message: i18next.t('skullman:setting.weekNumber.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editWeekNumberSuccess());
  }
}

export function* deleteWeekNumberHandler({ payload }) {
  yield put(deleteWeekNumberRequested());

  const request = {
    method: 'DELETE',
    url: `delete-weekNumber/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteWeekNumberFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        window.location.reload()
      },
      icon: 'success',
      message: i18next.t('skullman:setting.weekNumber.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
      
    };

    Alert.alert(alertProps);
    yield put(deleteWeekNumberSuccess(payload));
  }
}




export function* fetchSingleWeekNumberHandler({ payload }) {
  yield put(fetchSingleWeekNumberRequested());
  const request = {
    method: 'GET',
    url: `get-weekNumber/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleWeekNumberFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      quarter_id: {
        label: result.quater_name,
        value: result.quater_id,
      },
      id: result.id,
      week_from:new Date(result.week_from),
      week_to:new Date(result.week_to)
    };

    yield put(fetchSingleWeekNumberSuccess(res));
  }
}


export function* fetchWeekNumberHandler({ payload }) {
    yield put(fetchWeekNumberRequested());
    const request = {
      method: 'GET',
      params: { school_year_id: payload.school_year_id },
      url: 'all-week-number',
    };
  
    const { data, error } = yield call(httpClient, request);
  
    if (error) {
      yield put(fetchWeekNumberFailure(error));
    } else {
      yield put(fetchWeekNumberSuccess(data.data.result || []));
    }
  }

function* WeekNumber() {
  yield all([
    takeLatest(ADD_WEEKNUMBER, addWeekNumberHandler),
    takeLatest(DELETE_WEEKNUMBER, deleteWeekNumberHandler),
    takeLatest(FETCH_WEEKNUMBER, fetchWeekNumberHandler),
    takeLatest(FETCH_SINGLE_WEEKNUMBER, fetchSingleWeekNumberHandler),
    takeLatest(EDIT_WEEKNUMBER, editWeekNumberHandler),
  ]);
}

export default WeekNumber;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_PART,
  addPartFailure,
  addPartRequested,
  addPartSuccess,
  DELETE_PART,
  deletePartFailure,
  deletePartRequested,
  deletePartSuccess,
  FETCH_PART,
  fetchPartFailure,
  fetchPartRequested,
  fetchPartSuccess,
  FETCH_SINGLE_PART,
  fetchSinglePartFailure,
  fetchSinglePartRequested,
  fetchSinglePartSuccess,
  EDIT_PART,
  editPartFailure,
  editPartRequested,
  editPartSuccess,
  FETCH_PART_FILTER,
  fetchPartFilterFailure,
  fetchPartFilterRequested,
  fetchPartFilterSuccess,
} from '../actions/part-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { fetchChapterFilter } from '../actions/chapter-action-types';
import { fetchSubject } from '../actions/subject-action-types';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { keys } from 'lodash';

export function* addPartHandler({ payload }) {
  yield put(addPartRequested());
  const body = new FormData();
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('chapter_id', payload.chapter_id.value);
  body.append('part_number', payload.part_number);
  body.append('created_date', payload.created_date);
  body.append('part_name', payload.part_name);
  body.append('school_year_id', payload.school_year_id);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-part',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addPartFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-part');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.part.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addPartSuccess());
  }
}

export function* editPartHandler({ payload }) {
  yield put(editPartRequested());

  const body = new FormData();
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('chapter_id', payload.chapter_id.value);
  body.append('part_number', payload.part_number);
  body.append('created_date', payload.created_date);
  body.append('part_name', payload.part_name);
  body.append('school_year_id', payload.school_year_id);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-part/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editPartFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-part');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.part.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editPartSuccess());
  }
}

export function* deletePartHandler({ payload }) {
  yield put(deletePartRequested());

  const request = {
    method: 'DELETE',
    url: `delete-part/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deletePartFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.part.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deletePartSuccess(payload));
  }
}

export function* fetchPartHandler({ payload }) {
  yield put(fetchPartRequested());

  const request = {
    method: 'GET',
    params: { school_year_id: payload.school_year_id },
    url: 'parts',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchPartFailure(error));
  } else {
    yield put(fetchPartSuccess(data.data.result || []));
  }
}

export function* fetchPartFilterHandler({ payload }) {
  yield put(fetchPartFilterRequested());
  const grade_id = payload?.grade_id?.value;
  const subject_id = payload?.subject_id?.value;
  const class_id = payload?.class_id?.value;
  const chapter_id = payload?.chapter_id;
  const part_name = payload?.part_name?.value;
  const part_number = payload?.part_number?.value;

  /*console.log('grfffkkpokkpkp^pl^plff----- ', chapter_id );
  console.log('grfffkkpokkpkp^pl^pl888ff----- ', typeof(chapter_id) );*/

  let string_chapter_id = "";
  let arr=[];
  if (typeof chapter_id !== 'undefined') {
      // do something
      if( !(chapter_id instanceof Array)){
        arr.push(chapter_id?.value);
      //  console.log('arrr----- ', chapter_id?.value );
      }else{
      for (var i = 0; i < chapter_id.length; i++) {
        arr.push(chapter_id[i].value);
      //  console.log("PAIR " + i + ": " + chapter_id[i].value);
    }
      }
   
  
  }
 /* console.log('grfffff----- ', arr );
  console.log('arr----- ', arr.join(',') );*/
  
  
  
/*let string_chapter_id = chapter_id ?  Object.keys(chapter_id).map((item) => {
  console.log('grfffffmùp545p----- ', item);
  return item.value;
}): '';*/


const querystring_chapter_id = encodeURIComponent(arr.join(',') )

  console.log('grfffffmùpp----- ', querystring_chapter_id);
  const request = {
    method: 'GET',
    params: {
      grade_id: grade_id,
      subject_id: subject_id,
     // chapter_id: chapter_id,
      chapter_id: arr.join(',') ,
      school_year_id: payload.school_year_id,
      class_id: class_id,
    },
    url: `/part-filter`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchPartFilterFailure(error));
  } else {
    yield put(fetchPartFilterSuccess(data.data.result || []));
  }
}

export function* fetchSinglePartHandler({ payload }) {
  yield put(fetchSinglePartRequested());
  const request = {
    method: 'GET',
    url: `get-part/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSinglePartFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      grade_id: {
        label: result.grade_name,
        value: result.grade_id,
      },
      id: result.id,
      subject_id: {
        label: result.subject_name,
        value: result.subject_id,
      },
      chapter_id: {
        label: result.chapter_title,
        value: result.chapter_id,
      },
    };

    if(result.grade_id && result.subject_id){
      let post = {
        subject_id: {
          label: result.subject_name,
          value: result.subject_id,
        },
        grade_id: {
          label: result.grade_name,
          value: result.grade_id,
        },
        school_year_id: result.school_year_id,
      };
      yield put(fetchChapterFilter(post));
    }

    if(result.grade_id){
      let param = {
        school_year_id: result.school_year_id,
        grade_id: result.grade_id
      };
      yield put(fetchSubject(param));
    }


    yield put(fetchSinglePartSuccess(res));

  }
}

function* Part() {
  yield all([
    takeLatest(ADD_PART, addPartHandler),
    takeLatest(DELETE_PART, deletePartHandler),
    takeLatest(FETCH_PART, fetchPartHandler),
    takeLatest(FETCH_PART_FILTER, fetchPartFilterHandler),
    takeLatest(FETCH_SINGLE_PART, fetchSinglePartHandler),
    takeLatest(EDIT_PART, editPartHandler),
  ]);
}

export default Part;

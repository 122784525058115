import { createAction } from 'redux-actions';


export const FETCH_SKILLS = 'FETCH_SKILLS';
export const fetchSkill = createAction(FETCH_SKILLS);

export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS';
export const fetchSkillsSuccess = createAction(FETCH_SKILLS_SUCCESS);

export const FETCH_SINGLE_SKILLS = 'FETCH_SINGLE_SKILLS';
export const fetchSingleskills = createAction(FETCH_SINGLE_SKILLS);

export const FETCH_SINGLE_SKILLS_SUCCESS = 'FETCH_SINGLE_SKILLS_SUCCESS';
export const fetchSingleSkillsSuccess = createAction(FETCH_SINGLE_SKILLS_SUCCESS);

export const ADD_SKILLS = 'ADD_SKILLS';
export const addSkills = createAction(ADD_SKILLS);

export const EDIT_SKILLS = 'EDIT_SKILLS';
export const editSkills = createAction(EDIT_SKILLS);

export const DELETE_SKILLS = 'DELETE_SKILLS';
export const deleteSkills = createAction(DELETE_SKILLS);
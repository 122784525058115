import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_EDUCATION,
  addEducationFailure,
  addEducationRequested,
  addEducationSuccess,
  DELETE_EDUCATION,
  deleteEducationFailure,
  deleteEducationRequested,
  deleteEducationSuccess,
  EDIT_EDUCATION,
  editEducationFailure,
  editEducationRequested,
  editEducationSuccess,
  FETCH_SINGLE_EDUCATION,
  fetchSingleEducationFailure,
  fetchSingleEducationRequested,
  fetchSingleEducationSuccess,
  FETCH_EDUCATION,
  fetchEducationFailure,
  fetchEducationRequested,
  fetchEducationSuccess,
} from '../actions/education-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import i18next from "i18next";


export function* addEducationHandler({ payload }) {
  yield put(addEducationRequested());
  const body = new FormData();
  body.append('institution', payload.form.institution);
  body.append('subject', payload.form.subject);
  body.append('degree', payload.form.degree);
  body.append('grade', payload.form.grade);
  body.append('from_date', payload.form.from_date);
  body.append('to_date', payload.form.to_date);
  body.append('employee_id', payload.form.employee_id);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-education',
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(addEducationFailure(error));
  } else {
    yield put(addEducationSuccess());
    const alertProps = {
      /*callback: () => {
        history.push('/employee');
      },*/
      icon: 'success',
      message: 'Education created successfully.',
      title: 'Success',
    };
    Alert.alert(alertProps);
  }
}

export function* editEducationHandler({ payload }) {
  yield put(editEducationRequested());
  const request = {
    method: 'PUT',
    params: {
      institution: payload.form.institution,
      subject: payload.form.subject,
      degree: payload.form.degree,
      grade: payload.form.grade,
      from_date: payload.form.from_date,
      to_date: payload.form.to_date,
      employee_id: payload.form.employee_id,
    },
    url: `update-education/${payload.educationId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(editEducationFailure(error));
  } else {
    const alertProps = {
      /*callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/employee'});
      },*/
      icon: 'success',
      message: 'Education updated successfully.',
      title: 'Success',
    };
    Alert.alert(alertProps);
    yield put(editEducationSuccess());

  }
}

export function* deleteEducationHandler({ payload }) {
  yield put(deleteEducationRequested());
  const request = {
    method: 'DELETE',
    url: `delete-education/${payload.idToDelete}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(deleteEducationFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:schoolStaff.employees.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };
    Alert.alert(alertProps);
    yield put(deleteEducationSuccess(payload.idToDelete));
  }
}

export function* fetchEducationHandler({ payload }) {
  yield put(fetchEducationRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'educations',
  };
  const { data, error } = yield call(httpClient, request);
  if (error) {
    yield put(fetchEducationFailure(error));
  } else {
    yield put(fetchEducationSuccess(data.data.result));
  }
}

export function* fetchSingleEducationHandler({ payload }) {
  yield put(fetchSingleEducationRequested());
  const request = {
    method: 'GET',
    url: `get-education/${payload.publicId}`,
  };
  const { data, error } = yield call(httpClient, request);
  if (error) {
    yield put(fetchSingleEducationFailure(error));
  } else {
    yield put(fetchEducationSuccess(data.data.result));
    if (payload?.result) {
      payload.result(data.data.result)
    }
  }
}

function* education() {
  yield all([
    takeLatest(ADD_EDUCATION, addEducationHandler),
    takeLatest(DELETE_EDUCATION, deleteEducationHandler),
    takeLatest(EDIT_EDUCATION, editEducationHandler),
    takeLatest(FETCH_EDUCATION, fetchEducationHandler),
    takeLatest(FETCH_SINGLE_EDUCATION, fetchSingleEducationHandler),
  ]);
}

export default education;

import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_FAMILY_FAILURE,
  ADD_FAMILY_REQUESTED,
  ADD_FAMILY_SUCCESS,
  DELETE_FAMILY_FAILURE,
  DELETE_FAMILY_REQUESTED,
  DELETE_FAMILY_SUCCESS,
  FETCH_FAMILY_FAILURE,
  FETCH_FAMILY_REQUESTED,
  FETCH_FAMILY_SUCCESS,
  FETCH_SINGLE_FAMILY_FAILURE,
  FETCH_SINGLE_FAMILY_REQUESTED,
  FETCH_SINGLE_FAMILY_SUCCESS,
  UPDATE_FAMILY_FORM
} from '../actions/family-action-type';

const initialState = {
  createFamilyStatus: 'pending',
  deleteFamilyStatus: 'pending',
  fetchSingleFamilyStatus: 'pending',
  fetchFamilysStatus: 'pending',
  form: {
    employee_id: '',
    name: '',
    relationship: '',
    phone_no: '',
    dob: '0000-00-00 00:00:00',
  },
  Families: [],
};

export default function Families(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_FAMILY_FAILURE:
      return {
        ...state,
        createFamilyStatus: 'failure',
      };

    case ADD_FAMILY_REQUESTED:
      return {
        ...state,
        createFamilyStatus: 'creating',
      };

    case ADD_FAMILY_SUCCESS:
      return {
        ...state,
        // class_list: payload.class_list,
        createFamilyStatus: 'success',
       // file: encodeURI(payload.image),
       // profile_picture: encodeURI(payload.image),
      };

    case DELETE_FAMILY_FAILURE:
      return {
        ...state,
        deleteFamilyStatus: 'failure',
      };

    case DELETE_FAMILY_REQUESTED:
      return {
        ...state,
        deleteFamilyStatus: 'deleting',
      };

    case DELETE_FAMILY_SUCCESS:
      return {
        ...state,
        deleteFamilyStatus: 'success',
        Families: [...state.Families].filter((FamilyItem) => !(payload.toString().includes(FamilyItem.id.toString()))),
      };

    case FETCH_FAMILY_FAILURE:
      return {
        ...state,
        fetchFamiliesStatus: 'failure',
      };

    case FETCH_FAMILY_REQUESTED:
      return {
        ...state,
        fetchFamiliesStatus: 'fetching',
      };

    case FETCH_FAMILY_SUCCESS:
      return {
        ...state,
        fetchFamiliesStatus: 'success',
        Families: payload,
      };

    case FETCH_SINGLE_FAMILY_FAILURE:
      return {
        ...state,
        fetchSingleFamilyStatus: 'failure',
      };

    case FETCH_SINGLE_FAMILY_REQUESTED:
      return {
        ...state,
        fetchSingleFamilyStatus: 'fetching',
      };

    case FETCH_SINGLE_FAMILY_SUCCESS:
      return {
        ...state,
        fetchSingleFamilyStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.image),
          profile_picture: encodeURI(payload.image),
        },
      };

    case UPDATE_FAMILY_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

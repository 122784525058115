import React, {useState} from 'react';
import { bool, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Select from 'react-select';
import { toggleDrawer, toggleDrawerMenu } from '../../actions/app-action-types';
import { logoutSuccess } from '../../actions/user-action-types';
import { fetchSchoolYear, updateSchoolYearForm, setInitialState, setCurrentYearOnDb } from '../../actions/school-year-action-type';
import ProfileDropDown from './profile-dropdown';
import Utils from '../../utility';
import {isMobile} from 'react-device-detect';

import LanguageSwitcher from "../language-switcher";


const Header = ({
  isDrawerOpen, title, user,
}) => {
  const dispatch = useDispatch();
  const {
    schoolYears, form,
  } = useSelector((store) => (
    {
      form: store?.schoolYears?.form,
      schoolYears: store?.schoolYears?.schoolYears,
    }));


  // eslint-disable-next-line no-nested-ternary
  const school_year_id = schoolYears.length ? (form?.school_year_id ? form?.school_year_id : {
    label: `${schoolYears[0]?.school_start_year} / ${schoolYears[0]?.school_end_year}`,
    value: schoolYears[0]?.school_year_id,
  }) : '';

  const [profile, setProfileVisibility] = React.useState(false);
  const iconClass = isDrawerOpen ? '' : 'is-active';

  React.useEffect(() => {
    const request = { filter: 'active' };

    dispatch(fetchSchoolYear(request));
  }, []);

  React.useEffect(() => {

    const updates = {
      ...form,
      school_year_id,
    };

    dispatch(updateSchoolYearForm(updates));
  }, []);

  const onSelectChange = (name, value) => {

    const updates = {
      ...form,
      [name]: value,
    };

    dispatch(updateSchoolYearForm(updates));
    setTimeout(() => {
      window.location.reload(); 
    }, 100);

  };

  const onClick = () => dispatch(toggleDrawer(isDrawerOpen ? 'hidden' : 'visible'));

  const onLogout = () => {
    dispatch(setInitialState());
    dispatch(logoutSuccess());
    dispatch(push('/'));
  };

  return (
    <div className={!isMobile ? "app-header header-shadow " : "custom-mobile-header-height"}>
      <div className="app-header__logo">
        <img className="logo-src" src={Utils.getImage('logo.png')} alt="logo" />
        <div className="header__pane ml-auto">
          <div>
            <button type="button" className={`hamburger close-sidebar-btn hamburger--elastic ${iconClass}`} onClick={onClick}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav" onClick={onClick}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </div>
      <h5 className="mb-1 d-none d-md-block">{title}</h5>

      <div className="col-sm-2">
        <Select
          isSearchable
          name="school_year_id"
          id={"school_year_id"}
          loadingMessage="Please wait loading"
          placeholder="Select School  Year"
          onChange={(value) => onSelectChange('school_year_id', value)}
          options={schoolYears?.map((schoolYear) => ({
            label: `${schoolYear?.school_start_year} / ${schoolYear?.school_end_year}`,
            value: schoolYear?.school_year_id,
          }))}
          value={school_year_id}
          defaultValue={schoolYears[0]}

        />
      </div>
      <div className="app-header__content">
        <div className="app-header-right">
            <LanguageSwitcher/>
            <ProfileDropDown
                handleDrawerMenu={() => dispatch(toggleDrawerMenu(null))}
                open={profile}
                onClick={(payload) => setProfileVisibility(payload)}
                onLogout={onLogout}
                user={user}
            />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  isDrawerOpen: bool.isRequired,
  title: string.isRequired,
  user: shape({ name: string.isRequired }),
};

Header.defaultProps = { user: null };

export default Header;

import { createAction } from 'redux-actions';

export const ADD_PROFESSIONAL_EXPERIENCE = 'ADD_PROFESSIONAL_EXPERIENCE';
export const addProfessionalExperience = createAction(ADD_PROFESSIONAL_EXPERIENCE);

export const ADD_PROFESSIONAL_EXPERIENCE_FAILURE = 'ADD_PROFESSIONAL_EXPERIENCE_FAILURE';
export const addProfessionalExperienceFailure = createAction(ADD_PROFESSIONAL_EXPERIENCE_FAILURE);

export const ADD_PROFESSIONAL_EXPERIENCE_REQUESTED = 'ADD_PROFESSIONAL_EXPERIENCE_REQUESTED';
export const addProfessionalExperienceRequested = createAction(ADD_PROFESSIONAL_EXPERIENCE_REQUESTED);

export const ADD_PROFESSIONAL_EXPERIENCE_SUCCESS = 'ADD_PROFESSIONAL_EXPERIENCE_SUCCESS';
export const addProfessionalExperienceSuccess = createAction(ADD_PROFESSIONAL_EXPERIENCE_SUCCESS);

export const DELETE_PROFESSIONAL_EXPERIENCE = 'DELETE_PROFESSIONAL_EXPERIENCE';
export const deleteProfessionalExperience = createAction(DELETE_PROFESSIONAL_EXPERIENCE);

export const DELETE_PROFESSIONAL_EXPERIENCE_FAILURE = 'DELETE_PROFESSIONAL_EXPERIENCE_FAILURE';
export const deleteProfessionalExperienceFailure = createAction(DELETE_PROFESSIONAL_EXPERIENCE_FAILURE);

export const DELETE_PROFESSIONAL_EXPERIENCE_REQUESTED = 'DELETE_PROFESSIONAL_EXPERIENCE_REQUESTED';
export const deleteProfessionalExperienceRequested = createAction(DELETE_PROFESSIONAL_EXPERIENCE_REQUESTED);

export const DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS = 'DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS';
export const deleteProfessionalExperienceSuccess = createAction(DELETE_PROFESSIONAL_EXPERIENCE_SUCCESS);

export const FETCH_PROFESSIONAL_EXPERIENCE = 'FETCH_PROFESSIONAL_EXPERIENCE';
export const fetchProfessionalExperience = createAction(FETCH_PROFESSIONAL_EXPERIENCE);

export const FETCH_PROFESSIONAL_EXPERIENCE_FAILURE = 'FETCH_PROFESSIONAL_EXPERIENCE_FAILURE';
export const fetchProfessionalExperienceFailure = createAction(FETCH_PROFESSIONAL_EXPERIENCE_FAILURE);

export const FETCH_PROFESSIONAL_EXPERIENCE_REQUESTED = 'FETCH_PROFESSIONAL_EXPERIENCE_REQUESTED';
export const fetchProfessionalExperienceRequested = createAction(FETCH_PROFESSIONAL_EXPERIENCE_REQUESTED);

export const FETCH_PROFESSIONAL_EXPERIENCE_SUCCESS = 'FETCH_PROFESSIONAL_EXPERIENCE_SUCCESS';
export const fetchProfessionalExperienceSuccess = createAction(FETCH_PROFESSIONAL_EXPERIENCE_SUCCESS);

export const EDIT_PROFESSIONAL_EXPERIENCE = 'EDIT_PROFESSIONAL_EXPERIENCE';
export const editProfessionalExperience = createAction(EDIT_PROFESSIONAL_EXPERIENCE);

export const EDIT_PROFESSIONAL_EXPERIENCE_FAILURE = 'EDIT_PROFESSIONAL_EXPERIENCE_FAILURE';
export const editProfessionalExperienceFailure = createAction(EDIT_PROFESSIONAL_EXPERIENCE_FAILURE);

export const EDIT_PROFESSIONAL_EXPERIENCE_REQUESTED = 'EDIT_PROFESSIONAL_EXPERIENCE_REQUESTED';
export const editProfessionalExperienceRequested = createAction(EDIT_PROFESSIONAL_EXPERIENCE_REQUESTED);

export const EDIT_PROFESSIONAL_EXPERIENCE_SUCCESS = 'EDIT_PROFESSIONAL_EXPERIENCE_SUCCESS';
export const editProfessionalExperienceSuccess = createAction(EDIT_PROFESSIONAL_EXPERIENCE_SUCCESS);

export const FETCH_SINGLE_PROFESSIONAL_EXPERIENCE = 'FETCH_SINGLE_PROFESSIONAL_EXPERIENCE';
export const fetchSingleProfessionalExperience = createAction(FETCH_SINGLE_PROFESSIONAL_EXPERIENCE);

export const FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_FAILURE = 'FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_FAILURE';
export const fetchSingleProfessionalExperienceFailure = createAction(FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_FAILURE);

export const FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_REQUESTED = 'FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_REQUESTED';
export const fetchSingleProfessionalExperienceRequested = createAction(FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_REQUESTED);

export const FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_SUCCESS = 'FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_SUCCESS';
export const fetchSingleProfessionalExperienceSuccess = createAction(FETCH_SINGLE_PROFESSIONAL_EXPERIENCE_SUCCESS);

export const UPDATE_PROFESSIONAL_EXPERIENCE_FORM = 'UPDATE_PROFESSIONAL_EXPERIENCE_FORM';
export const updateProfessionalExperienceForm = createAction(UPDATE_PROFESSIONAL_EXPERIENCE_FORM);

export const DELETE_PROFESSIONAL_EXPERIENCE_CLASS = 'DELETE_PROFESSIONAL_EXPERIENCE_CLASS';
export const deleteProfessionalExperienceClass = createAction(DELETE_PROFESSIONAL_EXPERIENCE_CLASS);


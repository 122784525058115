import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_EMERGENCY_CONTACT,
  addEmergencyContactFailure,
  addEmergencyContactRequested,
  addEmergencyContactSuccess,
  DELETE_EMERGENCY_CONTACT,
  deleteEmergencyContactFailure,
  deleteEmergencyContactRequested,
  deleteEmergencyContactSuccess,
  EDIT_EMERGENCY_CONTACT,
  editEmergencyContactFailure,
  editEmergencyContactRequested,
  editEmergencyContactSuccess,
  FETCH_SINGLE_EMERGENCY_CONTACT,
  fetchSingleEmergencyContactFailure,
  fetchSingleEmergencyContactRequested,
  fetchSingleEmergencyContactSuccess,
  FETCH_EMERGENCY_CONTACT,
  fetchEmergencyContactFailure,
  fetchEmergencyContactRequested,
  fetchEmergencyContactSuccess,
} from '../actions/emergency-contact-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import i18next from "i18next";


export function* addEmergencyContactHandler({ payload }) {
  yield put(addEmergencyContactRequested());
  const body = new FormData();
  body.append('name', payload.form.name);
  body.append('is_primary', payload.form.is_primary);
  body.append('relationship', payload.form.relationship);
  body.append('phone_no_one', payload.form.phone_no_one);
  body.append('phone_no_two', payload.form.phone_no_two);
  body.append('employee_id', payload.form.employee_id);
  console.log(body);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-emergency-contact',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addEmergencyContactFailure(error));
  } else {
    yield put(addEmergencyContactSuccess());
    const alertProps = {
      /*callback: () => {
        history.push('/employee');
      },*/
      icon: 'success',
      message: 'EmergencyContact created successfully.',
      title: 'Success',
    };

    Alert.alert(alertProps);
  }
}

export function* editEmergencyContactHandler({ payload }) {
  yield put(editEmergencyContactRequested());
  const request = {
    method: 'PUT',
    params: {
      name: payload.form.name,
      is_primary: payload.form.is_primary,
      relationship: payload.form.relationship,
      phone_no_one: payload.form.phone_no_one,
      phone_no_two: payload.form.phone_no_two,
      employee_id: payload.form.employee_id,
    },
    url: `update-emergency-contact/${payload.contactId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(editEmergencyContactFailure(error));
  } else {
    const alertProps = {
      /*callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/employee'});
      },*/
      icon: 'success',
      message: 'EmergencyContact updated successfully.',
      title: 'Success',
    };

    Alert.alert(alertProps);
    yield put(editEmergencyContactSuccess());

  }
}

export function* deleteEmergencyContactHandler({ payload }) {
  yield put(deleteEmergencyContactRequested());
  const request = {
    method: 'DELETE',
    url: `delete-emergency-contact/${payload.idToDelete}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(deleteEmergencyContactFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:schoolStaff.employees.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };
    Alert.alert(alertProps);
    yield put(deleteEmergencyContactSuccess(payload.idToDelete));
  }
}

export function* fetchEmergencyContactHandler({ payload }) {
  yield put(fetchEmergencyContactRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'emergency-contacts',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchEmergencyContactFailure(error));
  } else {
    yield put(fetchEmergencyContactSuccess(data.data.result));
  }
}

export function* fetchSingleEmergencyContactHandler({ payload }) {
  yield put(fetchSingleEmergencyContactRequested());
  const request = {
    method: 'GET',
    url: `get-employee/${payload.publicId}`,
  };
  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleEmergencyContactFailure(error));
  } else {
    yield put(fetchEmergencyContactSuccess(data.data.result));
    if (payload?.result) {
      payload.result(data.data.result)
    }
  }

  /*if (error) {
    yield put(fetchSingleEmergencyContactFailure(error));
  } else {
    const { result } = data.data;

    const department_id = result.department_id?.split(',');
    const department_name = result.department_name?.split(',');

    let department_arr = [];
    for (let index = 0; index < department_id.length; index++) {
      let value = department_id[index];
      let label = department_name[index];

      let obj = {};
      obj.label = label;
      obj.value = value;
      department_arr.push(obj);
    }

    const res = {
      ...data.data.result,
      department_id: department_arr,
      highest_qualification: {
        label: result.qualification_name,
        value: result.highest_qualification,
      },
      id: result.id,

      status:
        result.status === 1
          ? {
              label: 'Active',
              value: 1,
            }
          : {
              label: 'Inactive',
              value: 0,
            },
    };

    yield put(fetchSingleEmergencyContactSuccess(res));
  }*/
}

function* emergencyContact() {
  yield all([
    takeLatest(ADD_EMERGENCY_CONTACT, addEmergencyContactHandler),
    takeLatest(DELETE_EMERGENCY_CONTACT, deleteEmergencyContactHandler),
    takeLatest(EDIT_EMERGENCY_CONTACT, editEmergencyContactHandler),
    takeLatest(FETCH_EMERGENCY_CONTACT, fetchEmergencyContactHandler),
    takeLatest(FETCH_SINGLE_EMERGENCY_CONTACT, fetchSingleEmergencyContactHandler),
  ]);
}

export default emergencyContact;

import { createAction } from 'redux-actions';
 
 

export const FETCH_REPORT = 'FETCH_REPORT';
export const fetchReport = createAction(FETCH_REPORT);

export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';
export const fetchReportFailure = createAction(FETCH_REPORT_FAILURE);

export const FETCH_REPORT_REQUESTED = 'FETCH_REPORT_REQUESTED';
export const fetchReportRequested = createAction(FETCH_REPORT_REQUESTED);

export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const fetchReportSuccess = createAction(FETCH_REPORT_SUCCESS);
 
 

 
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_MARK_PRIMARY, DELETE_MARK_PRIMARY, FETCH_EXAM, FETCH_MARK_PRIMARY, fetchExamSuccess, fetchMarkPrimary, fetchMarkPrimarySuccess } from "../actions/mark-rule-primary-action-type";
import HttpClient from "./http-client";
import Alert from '../utility/alert';
import i18next from 'i18next';
import { history } from '../index';

export function* fetchMarkPrimaryHandler({ payload }) {
    const request = {
      method: 'GET',
      params: { school_year_id: payload.school_year_id },
      url: 'terms',
    };
  
    const { data, error } = yield call(HttpClient, request);

    if (error) {
    } else {
      yield put(fetchMarkPrimarySuccess(data.data || []));
    }
  }


export function* addMarkPrimaryHandler({ payload }) {
console.log("testinggInSaga",payload)
    let dataTosend = [
      ...payload.data
        // school_id:payload.school_id,
        // school_year_id:payload.school_year_id,
        // term1:payload.term1,
        // term2:payload.term2,
        // term3:payload.term3
    ]
  
    const request = {
        data: dataTosend,
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        url: `mark-rules-primary`,
    };
    const { error } = yield call(HttpClient, request);
  
    if (error) {
    } else {
      const alertProps = {
        callback: () => {
          history.push('/marks-primary-rules');
        },
        icon: 'success',
        message: i18next.t('skullman:setting.marksRules.updateMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchMarkPrimary({school_year_id:payload.school_year_id}));
    }
  }

  export function* deleteMarkPrimaryHandler({ payload }) {
    const request = {
      method: 'DELETE',
      url: `mark-rules-primary/${payload.data}`,
    };
  
    const { error } = yield call(HttpClient, request);
  
    if (error) {
      // yield put(deleteWeekNumberFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/marks-primary-rules');
        },
        icon: 'success',
        message: i18next.t('skullman:setting.marksRules.deleteMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchMarkPrimary({school_year_id:payload.school_year_id}));
    }
  }

  export function* fetchExamHandler({ payload }) {
    console.log("examPaylaod",payload)
    const request = {
      method: 'GET',
      params: { school_year_id: payload.school_year_id,term_id:payload.term_id },
      url: 'exams',
    };
  
    const { data, error } = yield call(HttpClient, request);

    if (error) {
    } else {
      yield put(fetchExamSuccess(data.data || []));
    }
  }


function* MarkPrimary() {
    yield all([
      takeLatest(FETCH_MARK_PRIMARY, fetchMarkPrimaryHandler),
      takeLatest(ADD_MARK_PRIMARY, addMarkPrimaryHandler),
      takeLatest(DELETE_MARK_PRIMARY, deleteMarkPrimaryHandler),
      takeLatest(FETCH_EXAM, fetchExamHandler),
      
    ]);
  }

  export default MarkPrimary;
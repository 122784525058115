
//import React from 'react';
import React, { Suspense, lazy, Fragment,useEffect } from "react";
import { useSelector } from 'react-redux';
import { any, arrayOf, bool, number, oneOf, oneOfType, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ErrorLayout from './error-layout';
import PrivateLayout from './private-layout';
import PublicLayout from './public-layout';
import { ToastContainer } from "react-toastify";
//import {getSocket, SendNotificationToBrowser, getLocalData} from "../pages/chatbox/utils/webHelperFunctions";
//import { webConstants } from "../pages/chatbox/utils/webConstants";
import { toast, Bounce } from "react-toastify";
//import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
//import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
//const { notifications, clear, markAllAsRead, markAsRead } = useNotificationCenter();
//import { reqNotifPermissions } from './../components/chatwhatapp/firebase';
//import { requestForToken } from './../components/chatwhatapp/firebase';
import db,{getLoggedInUser} from '../components/chatwhatapp/firebase';

const SingleRoute = ({
  accessible, component: Component, type, ...rest
}) => {
  const {
    token, userDetail,
  } = useSelector((store) => ({
    token: store.user.token,
    userDetail: store.user.userDetail,
  }));
  var arr_filtered =( userDetail?.permissions?.filter(perm => accessible?.some(x=>perm === x) ));
  var filtered =Array.isArray(arr_filtered) && arr_filtered.length;
  const checkPermissions = async () => {
    const result = await FirebaseMessaging.checkPermissions();
    return result.receive;
  };
  
  const requestPermissions = async () => {
    const result = await FirebaseMessaging.requestPermissions();
    return result.receive;
  };

  
  

  //const userConnected = useSelector((store) => store.user.userDetail);
/*
useEffect(() => {
  const socket =getSocket();
   
//     //Listens for the notification from the server
//     socket.on("notification-recieve", (data1) => {
//       const data=data1?.message;
//       console.log(data)
//       const msg=data?.msg;
//       const sentToall=data?.sentToall ? data?.sentToall :false;
//       const toUser=data?.toUser ? data?.toUser:[];
//       const notifTitle=data?.notifTitle ? data?.notifTitle:"Notification from Skulman";
//       const redirectPage=data?.redirectPage ? data?.redirectPage:"";
//       const userConnected=data?.user ? data?.user: userDetail?.userId;

//       const userStore=JSON.parse(getLocalData(webConstants.USER_CONNECTED));

//       if(userConnected !== userStore?.userId 
//       || sentToall && (userConnected !== userStore?.userId ) 
//       || toUser?.find(x => x === userStore?.userId)) {
     
      notify(msg);
      SendNotificationToBrowser(msg, userConnected,redirectPage,notifTitle,sentToall,toUser);
      }
      return () => {
        socket.disconnect();
      }
    });
}, []);
*/

useEffect(async ()=>{
  const VAPID_KEY=`${process.env.REACT_APP_FIREBASE_VAPID_KEY}`;
  const options1={vapidKey:VAPID_KEY};
  
    const delete_token= await FirebaseMessaging.deleteToken(options1);
    const delete_token1= await FirebaseMessaging.deleteToken();
    console.log(delete_token);
    console.log(delete_token1);
  
     await  checkPermissions().then(async (res) => {
       if (res.receive !== 'granted') {
        await requestPermissions().then((res) => {
           if (res.receive === 'denied') {
           //  showToast('Push Notification permission denied');
           }
           else {
          //   showToast('Push Notification permission granted');
             register();
           }
         });
       }
       else {
         register();
       }
     });


},[])

const notify = (msg) => {
  toast(msg,
  {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "bottom-center",
    type: "success",
  });
};


  if (type === 'public' && token) {
    return <Redirect from={rest.path} to="/school-wall" />;
  }

  if (type === 'private' && !token) {
    return <Redirect from={rest.path} to="/forbidden-access" />;
  }
  //console.log(userDetail?.permissions);
 /* var filtered = accessible?.filter(
    function(e) {
      return this?.indexOf(e) < 0;
    },
    userDetail?.permissions
);*/

/*
 <div>
            <p>{notifications.length}</p>
           
            <br />
            <br />
            <button onClick={clear}>Clear Notifications</button>
            <button onClick={() => markAllAsRead()}>Mark all as read</button>
            <ul>
                {notifications.map((notification) => (
                    <li
                        onClick={() => markAsRead(notification.id)}
                        key={notification.id}
                        style={
                            notification.read ? (
                                { background: 'green', color: 'silver', padding: '0 20px' }
                            ) : (
                                {
                                    border: '1px solid black',
                                    background: 'navy',
                                    color: '#fff',
                                    marginBottom: 20,
                                    cursor: 'pointer',
                                    padding: '0 20px'
                                }
                            )
                        }
                    >
                        <span>id: {notification.id}</span>
                        <p>title: {notification.data.title}</p>
                        <p>text: {notification.data.text}</p>
                    </li>
                ))}
            </ul>
        
        </div>
        */
       
      
      
      const register = async () => {
        /*await FirebaseMessaging.addListener('notificationReceived', event => {
          console.log('notificationReceived', { event });
        });*/

        await FirebaseMessaging.addListener('notificationActionPerformed', event => {
          console.log('notificationActionPerformed', { event });
        });
        const VAPID_KEY=`${process.env.REACT_APP_FIREBASE_VAPID_KEY}`;
       const options={vapidKey:VAPID_KEY};
       const f_user= localStorage.getItem("FIREBASE_USER_CONNECTED");
       const f_auth_user=getLoggedInUser();
          const  { token } = await FirebaseMessaging.getToken(options);
         // console.log(token);
         // console.log(f_user);
         // console.log(f_auth_user);
          if(token){
          if(f_auth_user!=null){
            db.collection("users").doc(f_auth_user.uid).set({
              token: token
            }, { merge: true });
          }else if(f_user){
            db.collection("users").doc(f_user.uid).set({
              token: token
            }, { merge: true });
          }
        }
      
               
          /*const getToken = async () => {
            const result = await FirebaseMessaging.getToken();
            return result.token;
          };
          
          const deleteToken = async () => {
            await FirebaseMessaging.deleteToken();
          };
          
          const getDeliveredNotifications = async () => {
            const result = await FirebaseMessaging.getDeliveredNotifications();
            return result.notifications;
          };
          
          const removeDeliveredNotifications = async () => {
            await FirebaseMessaging.removeDeliveredNotifications({
              ids: ['798dfhliblqew89pzads'],
            });
          };
          
          const removeAllDeliveredNotifications = async () => {
            await FirebaseMessaging.removeAllDeliveredNotifications();
          };
          
          const subscribeToTopic = async () => {
            await FirebaseMessaging.subscribeToTopic({ topic: 'news' });
          };
          
          const unsubscribeFromTopic = async () => {
            await FirebaseMessaging.unsubscribeFromTopic({ topic: 'news' });
          };
          
          const addTokenReceivedListener = async () => {
            await FirebaseMessaging.addListener('tokenReceived', event => {
              console.log('tokenReceived', { event });
            });
          };
          
          const addNotificationReceivedListener = async () => {
            await FirebaseMessaging.addListener('notificationReceived', event => {
              console.log('notificationReceived', { event });
            });
          };
          
          const addNotificationActionPerformedListener = async () => {
            await FirebaseMessaging.addListener('notificationActionPerformed', event => {
              console.log('notificationActionPerformed', { event });
            });
          };
          
          const removeAllListeners = async () => {
            await FirebaseMessaging.removeAllListeners();
          };*/
  
      }

//originList.filter( o => rest.find( x => o.id === x.id ) );
/*console.log(accessible);
console.log(filtered);
console.log(userDetail?.permissions);*/

  if (type === 'private' && token && !filtered) {
  //if (type === 'private' && token && !accessible?.includes(userDetail.user_type_id)) {
    return <Redirect from={rest.path} to="/forbidden-access" />;
  }

  let Layout = PublicLayout;

  if (type === 'private') {
    Layout = PrivateLayout;
  } else if (type === 'error') {
    Layout = ErrorLayout;
  }

  return (
    <Route
      path={rest.path}
      render={() => (
        <Fragment>
      <Layout {...rest}> 
          <Component {...rest} />
          {/*<ToastContainer limit={1} newestOnTop/>*/}
        </Layout> 
        </Fragment>
      )}
      {...rest}
    />
  );
};

SingleRoute.propTypes = {
  accessible: arrayOf(number),
  component: any.isRequired,
  exact: bool.isRequired,
  path: oneOfType([arrayOf(string).isRequired, string.isRequired]).isRequired,
  title: string.isRequired,
  type: oneOf(['public', 'private', 'error']).isRequired,
};

SingleRoute.defaultProps = { accessible: null };

export default SingleRoute;

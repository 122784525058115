import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_LEVEL, DELETE_LEVEL, EDIT_LEVEL, FETCH_LEVEL, FETCH_SINGLE_LEVEL, fetchLevel, fetchLevelSuccess, fetchSingleLevelSuccess } from '../actions/level-action-types';
import HttpClient from './http-client';
import Alert from '../utility/alert';
import i18next from 'i18next';
import { history } from '../index';
import { ADD_SKILLS, DELETE_SKILLS, EDIT_SKILLS, FETCH_SINGLE_SKILLS, FETCH_SKILLS, fetchSingleSkillsSuccess, fetchSkill, fetchSkillsSuccess } from '../actions/skill-action-types';

export function* fetchSkillsHandler({ payload }) {
  console.log("payloadInSkill",payload)
    const request = {
      method: 'GET',
      url: 'skills',
      params:{
        level_id:payload.level_id,
        school_year_id:payload.school_year_id
      }
    };
  
    const { data, error } = yield call(HttpClient, request);

    if (error) {
    } else {
      yield put(fetchSkillsSuccess(data.data || []));
    }
  }

  export function* fetchSingleSkillHandler({ payload }) {

    const request = {
      method: 'GET',
      url: `skills/${payload}/edit`,
    };
  
    const { data, error } = yield call(HttpClient, request);
  
    if (error) {
    } else {
      const res = data.data;
  
      yield put(fetchSingleSkillsSuccess(res));
    }
  }

  export function* addSkillHandler({ payload }) {
    console.log("i am in save subjecttyupoe")
    const body = new FormData();
    
    body.append('school_id', payload.school_id);
    body.append('school_year_id', payload.school_year_id);
    body.append('code', payload.code);
    body.append('label_fr', payload.label_fr);
    body.append('label_en', payload.label_en);
    body.append('level_id', payload.level_id);
    body.append('subject_type_id', payload.subject_type_id);
    body.append('points_written_fr', payload.points_written_fr);
    body.append('points_oral_fr', payload.points_oral_fr);
    body.append('points_attitude_fr', payload.points_attitude_fr);
    body.append('points_practice_fr', payload.points_practice_fr);
    body.append('points_written_en', payload.points_written_en);
    body.append('points_oral_en', payload.points_oral_en);
    body.append('points_attitude_en', payload.points_attitude_en);
    body.append('points_practice_en', payload.points_practice_en);
    body.append('total_point_fr', payload.total_point_fr);
    body.append('total_point_en', payload.total_point_en);
  
    const request = {
      data: body,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: 'skills',
    };
    const { error } = yield call(HttpClient, request);
  
    if (error) {
    } else {
      const alertProps = {
        callback: () => {
          history.push('/skill');
        },
        icon: 'success',
        message: i18next.t('skullman:setting.skills.createMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchSkill({school_year_id:payload.school_year_id}));
    }
    }

    export function* fetchEditSkillHandler({ payload }) {


      let dataTosend = {
        school_id:payload.school_id,
        school_year_id:payload.school_year_id,
        code:payload.code,
        label_fr:payload.label_fr,
        label_en:payload.label_en,
        level_id:payload.level_id,
    subject_type_id:payload.subject_type_id,
    points_written_fr:payload.points_written_fr,
    points_oral_fr:payload.points_oral_fr,
    points_attitude_fr:payload.points_attitude_fr,
    points_practice_fr:payload.points_practice_fr,
    points_written_en:payload.points_written_en,
    points_oral_en:payload.points_oral_en,
    points_attitude_en:payload.points_attitude_en,
    points_practice_en:payload.points_practice_en,
    total_point_en:payload.total_point_en,
     total_point_fr:payload.total_point_fr,
      }
  
      const request = {
        data: dataTosend,
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        url: `skills/${payload.id}`,
      };
    
      const { error } = yield call(HttpClient, request);
    
      if (error) {
        // yield put(editWeekNumberFailure(error));
      } else {
        const alertProps = {
          callback: () => {
            history.push('/skill');
          },
    
          icon: 'success',
          message: i18next.t('skullman:setting.skills.updateMessage'),
          title: i18next.t('skullman:common.successMessage'),
        };
    
        Alert.alert(alertProps);
        yield put(fetchSkill({school_year_id:payload.school_year_id}));
      }
    }

    export function* deleteSkilleHandler({ payload }) {
      const request = {
        method: 'DELETE',
        url: `skills/${payload}`,
      };
    
      const { error } = yield call(HttpClient, request);
    
      if (error) {
        // yield put(deleteWeekNumberFailure(error));
      } else {
        const alertProps = {
          callback: () => {
            history.push('/skill');
          },
          icon: 'success',
          message: i18next.t('skullman:setting.skills.deleteMessage'),
          title: i18next.t('skullman:common.successMessage'),
        };
    
        Alert.alert(alertProps);
        yield put(fetchSkill({school_year_id:payload.school_year_id}));
      }
    }







 

function* Skills() {
    yield all([
      takeLatest(FETCH_SKILLS, fetchSkillsHandler),
      takeLatest(FETCH_SINGLE_SKILLS, fetchSingleSkillHandler),
      takeLatest(ADD_SKILLS, addSkillHandler),
      takeLatest(EDIT_SKILLS, fetchEditSkillHandler),
      takeLatest(DELETE_SKILLS, deleteSkilleHandler),
      
    ]);
  }

  export default Skills;
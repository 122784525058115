import React from "react";
import { string, number } from 'prop-types';
import { useDispatch } from "react-redux";
import { push } from 'connected-react-router';

const DownloadReport = ({
    reportId,
    sequence_id,
    class_id,
    quarter_id,
    report_type,
    text,
    className,
    sequence_name,
    status
}) => {

    const dispatch = useDispatch();

    const onDownloadReport = (e, reportId) => {

       /* const params = {
            'id': reportId,
            sequence_id,
            class_id,
            quarter_id,
            sequence_name
        };*/

        //dispatch(downloadMarksReport(params));
        dispatch(push(`/marks-report-general/${class_id}/${report_type}/${sequence_id}/${quarter_id}/${reportId}`));
       // console.log(e);
       // console.log(status)
       // console.log(sequence_name)//status == "completed" &&
    };

        return (
            <>
            {report_type && status == "completed" &&
            <button 
                className={`${className}`} 
                type="button"
                onClick={ (e) => onDownloadReport(e, reportId)}
                style={{ cursor: "pointer" }}
            >
                <i class="fa fa-download mr-2"></i> {text}
            </button>}
            </>
        )
    

  {/*  return (
        <span className={`${className}`}>{text}</span>
    );
  */}
}

DownloadReport.propTypes = {
    'reportId': number.isRequired,
    'className': string,
    'text': string,
    'status': string.isRequired
  };

DownloadReport.defaultProps = {
    'actionUrl': '',
    'className': 'btn btn-primary btn-sm',
    'text': 'PV Genral'
};

export default DownloadReport;
import { createAction } from 'redux-actions';

export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const addDepartment = createAction(ADD_DEPARTMENT);

export const ADD_DEPARTMENT_FAILURE = 'ADD_DEPARTMENT_FAILURE';
export const addDepartmentFailure = createAction(ADD_DEPARTMENT_FAILURE);

export const ADD_DEPARTMENT_REQUESTED = 'ADD_DEPARTMENT_REQUESTED';
export const addDepartmentRequested = createAction(ADD_DEPARTMENT_REQUESTED);

export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const addDepartmentSuccess = createAction(ADD_DEPARTMENT_SUCCESS);

export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const deleteDepartment = createAction(DELETE_DEPARTMENT);

export const DELETE_DEPARTMENT_FAILURE = 'DELETE_DEPARTMENT_FAILURE';
export const deleteDepartmentFailure = createAction(DELETE_DEPARTMENT_FAILURE);

export const DELETE_DEPARTMENT_REQUESTED = 'DELETE_DEPARTMENT_REQUESTED';
export const deleteDepartmentRequested = createAction(DELETE_DEPARTMENT_REQUESTED);

export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const deleteDepartmentSuccess = createAction(DELETE_DEPARTMENT_SUCCESS);

export const FETCH_DEPARTMENT = 'FETCH_DEPARTMENT';
export const fetchDepartment = createAction(FETCH_DEPARTMENT);

export const FETCH_DEPARTMENT_FAILURE = 'FETCH_DEPARTMENT_FAILURE';
export const fetchDepartmentFailure = createAction(FETCH_DEPARTMENT_FAILURE);

export const FETCH_DEPARTMENT_REQUESTED = 'FETCH_DEPARTMENT_REQUESTED';
export const fetchDepartmentRequested = createAction(FETCH_DEPARTMENT_REQUESTED);

export const FETCH_DEPARTMENT_SUCCESS = 'FETCH_DEPARTMENT_SUCCESS';
export const fetchDepartmentSuccess = createAction(FETCH_DEPARTMENT_SUCCESS);

export const EDIT_DEPARTMENT = 'EDIT_DEPARTMENT';
export const editDepartment = createAction(EDIT_DEPARTMENT);

export const EDIT_DEPARTMENT_FAILURE = 'EDIT_DEPARTMENT_FAILURE';
export const editDepartmentFailure = createAction(EDIT_DEPARTMENT_FAILURE);

export const EDIT_DEPARTMENT_REQUESTED = 'EDIT_DEPARTMENT_REQUESTED';
export const editDepartmentRequested = createAction(EDIT_DEPARTMENT_REQUESTED);

export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS';
export const editDepartmentSuccess = createAction(EDIT_DEPARTMENT_SUCCESS);

export const FETCH_SINGLE_DEPARTMENT = 'FETCH_SINGLE_DEPARTMENT';
export const fetchSingleDepartment = createAction(FETCH_SINGLE_DEPARTMENT);

export const FETCH_SINGLE_DEPARTMENT_FAILURE = 'FETCH_SINGLE_DEPARTMENT_FAILURE';
export const fetchSingleDepartmentFailure = createAction(FETCH_SINGLE_DEPARTMENT_FAILURE);

export const FETCH_SINGLE_DEPARTMENT_REQUESTED = 'FETCH_SINGLE_DEPARTMENT_REQUESTED';
export const fetchSingleDepartmentRequested = createAction(FETCH_SINGLE_DEPARTMENT_REQUESTED);

export const FETCH_SINGLE_DEPARTMENT_SUCCESS = 'FETCH_SINGLE_DEPARTMENT_SUCCESS';
export const fetchSingleDepartmentSuccess = createAction(FETCH_SINGLE_DEPARTMENT_SUCCESS);

export const UPDATE_DEPARTMENT_FORM = 'UPDATE_DEPARTMENT_FORM';
export const updateDepartmentForm = createAction(UPDATE_DEPARTMENT_FORM);

import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_QUALIFICATION,
  addQualificationSuccess,
  DELETE_QUALIFICATION,
  deleteQualificationSuccess,
  FETCH_QUALIFICATION,
  fetchQualificationSuccess,
  FETCH_SINGLE_QUALIFICATION,
  EDIT_QUALIFICATION,
  editQualificationSuccess,
  fetchSingleQualificationSuccess
} from '../actions/qualification-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import moment from 'moment';
import i18next from "i18next";

export function* addQualificationHandler({ payload }) {
  const body = new FormData();

  body.append('qualification_name', payload.qualification_name);
  body.append('school_year_id', payload.school_year_id);
  body.append('status', payload.status.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-qualification',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    // yield put(addWeekNumberFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.goBack();
      },
      icon: 'success',
      message: i18next.t('skullman:setting.qualification.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addQualificationSuccess());
  }
}

export function* editQualificationHandler({ payload }) {
  const body = new FormData();

  body.append('qualification_name', payload.qualification_name);
  body.append('school_year_id', payload.school_year_id);
  body.append('status', payload.status.value);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-qualification/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    // yield put(editWeekNumberFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.goBack();
      },

      icon: 'success',
      message: i18next.t('skullman:setting.qualification.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editQualificationSuccess());
  }
}

export function* deleteQualificationHandler({ payload }) {
  const request = {
    method: 'DELETE',
    url: `delete-qualification/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    // yield put(deleteWeekNumberFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        window.location.reload();
      },
      icon: 'success',
      message: i18next.t('skullman:setting.qualification.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteQualificationSuccess(payload));
  }
}

export function* fetchSingleQualificationHandler({ payload }) {
  //   yield put(fetchSingleWeekNumberRequested());
  const request = {
    method: 'GET',
    url: `get-qualification/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    // yield put(fetchSingleWeekNumberFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      qualification_name: result.qualification_name,
      status: result.status,
    };

    yield put(fetchSingleQualificationSuccess(res));
  }
}

export function* fetchQualificationHandler({ payload }) {
  const request = {
    method: 'GET',
    params: { school_year_id: payload.school_year_id },
    url: 'qualifications',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    //   yield put(fetchWeekNumberFailure(error));
  } else {
    yield put(fetchQualificationSuccess(data.data.result || []));
  }
}

function* Qualification() {
  yield all([
    takeLatest(ADD_QUALIFICATION, addQualificationHandler),
    takeLatest(DELETE_QUALIFICATION, deleteQualificationHandler),
    takeLatest(FETCH_QUALIFICATION, fetchQualificationHandler),
    takeLatest(FETCH_SINGLE_QUALIFICATION, fetchSingleQualificationHandler),
    takeLatest(EDIT_QUALIFICATION, editQualificationHandler),
  ]);
}

export default Qualification;

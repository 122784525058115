import { createAction,handleAction } from 'redux-actions';

export const FETCH_PROGRESSION = 'FETCH_PROGRESSION';
export const fetchProgression = createAction(FETCH_PROGRESSION);

export const FETCH_PROGRESSION_SUCCESS = 'FETCH_PROGRESSION_SUCCESS';
export const fetchProgressionSuccess = createAction(FETCH_PROGRESSION_SUCCESS);

export const FETCH_SINGLE_PROGRESSION = 'FETCH_SINGLE_PROGRESSION';
export const fetchSingleProgression = createAction(FETCH_SINGLE_PROGRESSION,payload=>payload);

export const FETCH_SINGLE_PROGRESSION_SUCCESS = 'FETCH_SINGLE_PROGRESSION_SUCCESS';
export const fetchSingleProgressionSuccess = createAction(FETCH_SINGLE_PROGRESSION_SUCCESS);

export const EDIT_PROGRESSION_SUCCESS = 'EDIT_PROGRESSION_SUCCESS';
export const editProgressionSuccess = createAction(EDIT_PROGRESSION_SUCCESS);

export const EDIT_PROGRESSION = 'EDIT_PROGRESSION';
export const editProgression = createAction(EDIT_PROGRESSION);

export const DELETE_PROGRESSION = 'DELETE_PROGRESSION';
export const deleteProgression = createAction(DELETE_PROGRESSION);

export const DELETE_PROGRESSION_SUCCESS = 'DELETE_PROGRESSION_SUCCESS';
export const deleteProgressionSuccess = createAction(DELETE_PROGRESSION_SUCCESS);

export const ADD_PROGRESSION_SUCCESS = 'ADD_PROGRESSION_SUCCESS';
export const addProgressionSuccess = createAction(ADD_PROGRESSION_SUCCESS);

export const ADD_PROGRESSION = 'ADD_PROGRESSION';
export const addProgression = createAction(ADD_PROGRESSION);

export const UPDATE_PROGRESSION_FORM = 'UPDATE_PROGRESSION_FORM';
export const updateProgressionForm = createAction(UPDATE_PROGRESSION_FORM);
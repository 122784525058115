import { string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { downloadFile } from '../../actions/file-downloader-action-types';

const PaymentHistoryCellRenderer = ({ className }) => {
  const dispatch = useDispatch();
  const params = useParams(); 
   const location = useLocation();

  const paymentHistoryExport = () => {
    const student_id = params.publicId;

    const request = {
      filename: 'payment-receipt.pdf',
      params: student_id,
      url: `payment-receipt/${student_id}`,
    };

    dispatch(downloadFile(request));
  };

  return (
    <>
      <div>
        <button className={`${className}`} type="button" onClick={paymentHistoryExport}>
          <i className="pe-7s-print" data-toggle="tooltip" data-placement="top" title=" " data-original-title="" />
          <span> Payment History</span>
        </button>
      </div>
    </>
  );
};

PaymentHistoryCellRenderer.propTypes = { className: string };

PaymentHistoryCellRenderer.defaultProps = { className: 'badge  badge-success' };

export default PaymentHistoryCellRenderer;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { DOWNLOAD_FILE, downloadFileFailure, downloadFileRequested, downloadFileSuccess } from '../actions/file-downloader-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import i18next from "i18next";

export function* downloadFileHandler({ payload }) {
  yield put(downloadFileRequested());

  const request = {
    method: payload?.url=='marks-report/create'?'POST':'GET',
    params: payload?.params,
    responseType: 'blob',
    url: payload?.url,
  };
 
console.log(request);
  const {
    data, error, 
  } = yield call(httpClient, request);

  if(error?.code == 404){
    const alertProps = {
      icon: 'info',
      message: i18next.t('skullman:common.noDataFoundMessage'),
      // title: 'Success',
    };
    Alert.alert(alertProps);
  }else
  if (error) {
    yield put(downloadFileFailure(error));
  }
  else {console.log(data);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.setAttribute('download', payload.filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    yield put(downloadFileSuccess());
  }
}

function* FileDownloader() {
  yield all([
    takeLatest(DOWNLOAD_FILE, downloadFileHandler),
  ]);
}

export default FileDownloader;

import { createAction } from 'redux-actions';


export const FETCH_MARK_PRIMARY = 'FETCH_MARK_PRIMARY';
export const fetchMarkPrimary = createAction(FETCH_MARK_PRIMARY);

export const FETCH_MARK_PRIMARY_SUCCESS = 'FETCH_MARK_PRIMARY_SUCCESS';
export const fetchMarkPrimarySuccess = createAction(FETCH_MARK_PRIMARY_SUCCESS);


export const ADD_MARK_PRIMARY = 'ADD_MARK_PRIMARY';
export const addMarkPrimary = createAction(ADD_MARK_PRIMARY);

export const DELETE_MARK_PRIMARY = 'DELETE_MARK_PRIMARY';
export const deleteMarkPrimary = createAction(DELETE_MARK_PRIMARY);

export const FETCH_EXAM = 'FETCH_EXAM';
export const fetchExam = createAction(FETCH_EXAM);

export const FETCH_EXAM_SUCCESS = 'FETCH_EXAM_SUCCESS';
export const fetchExamSuccess = createAction(FETCH_EXAM_SUCCESS);


import { FETCH_ALL_QUARTER_SUCCESS,
  FETCH_ALL_SEQUENCE_SUCCESS,
  FETCH_ALL_TYPE_OF_EXAM_SUCCESS,
  ADD_MARK_FAILURE,
  ADD_MARK_REQUESTED,
  ADD_MARK_SUCCESS,
  FETCH_MARK_LIST_SUCCESS,
  FETCH_CLASS_STUDENT_MARKS_SUCCESS,
  FETCH_MARK_FILTER_REQUESTED,
  FETCH_MARK_FILTER_FAILURE,
  FETCH_MARK_FILTER_SUCCESS,
  FETCH_AVERAGE_MARK_FAILURE,
  FETCH_AVERAGE_MARK_REQUESTED,
  FETCH_AVERAGE_MARK_SUCCESS,
  FETCH_MARK_GENERAL_FILTER_REQUESTED,
  FETCH_MARK_GENERAL_FILTER_SUCCESS,
  FETCH_MARK_GENERAL_SUCCESS,
  FETCH_MARK_GENERAL_FILTER_FAILURE,
  FETCH_GENERAL_MARK_LIST_SUCCESS,
 } from '../actions/mark-action-types';

const initialState = {
  addMarkStatus: 'pending',
  average_mark_list: [],
  class_student_marks: [],
  class_subject_id: null,
  class_subject_info: {},
  error: null,
  fetchMarkStatus: 'pending',
  fetchGeneralMarkStatus: 'pending',
  mark_list: [],
  quarter: [],
  sequence: [],
  type_of_exam: [],
  Marks_history:null,
  general_mark_list: [],
};

export default function marks(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case FETCH_ALL_QUARTER_SUCCESS:
      return {
        ...state,
        quarter: payload,
      };

    case FETCH_ALL_SEQUENCE_SUCCESS:
      return {
        ...state,
        sequence: payload,
      };

    case FETCH_ALL_TYPE_OF_EXAM_SUCCESS:
      return {
        ...state,
        type_of_exam: payload,
      };
    case ADD_MARK_FAILURE:
      return {
        ...state,
        addMarkStatus: 'failure',
      };

    case ADD_MARK_REQUESTED:
      return {
        ...state,
        addMarkStatus: 'creating',
      };

    case ADD_MARK_SUCCESS:
      return {
        ...state,
        addMarkStatus: 'success',
        class_subject_id: payload,
      };
    case FETCH_MARK_FILTER_FAILURE:
      return {
        ...state,
        fetchMarkStatus: 'failure',
      };

    case FETCH_MARK_FILTER_REQUESTED:
      return {
        ...state,
        fetchMarkStatus: 'fetching',
      };

    case FETCH_MARK_FILTER_SUCCESS:
      return {
        ...state,
        fetchMarkStatus: 'success',
        mark_list: payload,
      };
    case FETCH_MARK_LIST_SUCCESS:
      return {
        ...state,
        mark_list: payload,
      };

      case FETCH_MARK_FILTER_REQUESTED:
        return {
          ...state,
          fetchMarkStatus: 'fetching',
        };
  
      case FETCH_MARK_FILTER_SUCCESS:
        return {
          ...state,
          fetchMarkStatus: 'success',
          mark_list: payload,
        };
      case FETCH_MARK_LIST_SUCCESS:
        return {
          ...state,
          mark_list: payload,
        };
  
    case FETCH_AVERAGE_MARK_FAILURE:
      return {
        ...state,
        fetchMarkStatus: 'failure',
      };

    case FETCH_AVERAGE_MARK_REQUESTED:
      return {
        ...state,
        fetchMarkStatus: 'fetching',
      };

    case FETCH_AVERAGE_MARK_SUCCESS:
      return {
        ...state,
        average_mark_list: payload,
        fetchMarkStatus: 'success',
      };
    case FETCH_CLASS_STUDENT_MARKS_SUCCESS:
      return {
        ...state,
        class_student_marks: payload?.studentMarks,
        class_subject_info: payload?.classSubjectInfo,
        Marks_history: payload?.marksHitory,
      };

      case FETCH_MARK_GENERAL_FILTER_FAILURE:
        return {
          ...state,
          fetchGeneralMarkStatus: 'failure',
          general_mark_list:[]
        };
      case FETCH_MARK_GENERAL_FILTER_REQUESTED:
        return {
          ...state,
          fetchGeneralMarkStatus: 'fetching',
        };
  
      case FETCH_MARK_GENERAL_FILTER_SUCCESS:
        return {
          ...state,
          fetchGeneralMarkStatus: 'success',
          general_mark_list: payload,
        };
      case FETCH_MARK_GENERAL_SUCCESS:
        return {
          ...state,
          general_mark_list: payload,
        };
        case FETCH_GENERAL_MARK_LIST_SUCCESS:
      return {
        ...state,
        general_mark_list: payload,
      };

    default:
      return state;
  }
}

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_DISCOUNT,
  addDiscountFailure,
  addDiscountRequested,
  addDiscountSuccess,
  DELETE_DISCOUNT,
  deleteDiscountFailure,
  deleteDiscountRequested,
  deleteDiscountSuccess,
  FETCH_DISCOUNT,
  fetchDiscountFailure,
  fetchDiscountRequested,
  fetchDiscountSuccess,
  FETCH_SINGLE_DISCOUNT,
  fetchSingleDiscountFailure,
  fetchSingleDiscountRequested,
  fetchSingleDiscountSuccess,
  EDIT_DISCOUNT,
  editDiscountFailure,
  editDiscountRequested,
  editDiscountSuccess } from '../actions/discount-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

export function* addDiscountHandler({ payload }) {
  yield put(addDiscountRequested());
  const body = new FormData();

  body.append('discount', payload.discount);
  body.append('discount_type', payload.discount_type.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'save-discount-setting',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addDiscountFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/discount');
      },
      icon: 'success',
      message: i18next.t('skullman:setting.discount.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addDiscountSuccess());
  }
}

export function* editDiscountHandler({ payload }) {
  yield put(editDiscountRequested());

  const body = new FormData();

  body.append('discount', payload.discount);
  body.append('discount_type', payload.discount_type.value);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-discount-setting/${payload.discount_setting_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editDiscountFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname:'/discount'});
    //   },
    //   icon: 'success',
    //   message: 'Discount updated successfully.',
    //   title: 'Success',
    // };

    // Alert.alert(alertProps);
    yield put(editDiscountSuccess());
  }
}

export function* deleteDiscountHandler({ payload }) {
  yield put(deleteDiscountRequested());

  const request = {
    method: 'DELETE',
    url: `delete-discount-setting/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteDiscountFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:setting.discount.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteDiscountSuccess(payload));
  }
}

export function* fetchDiscountHandler({ payload }) {
  yield put(fetchDiscountRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'list-discount-setting',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchDiscountFailure(error));
  } else {
    yield put(fetchDiscountSuccess(data.data.result));
  }
}

export function* fetchSingleDiscountHandler({ payload }) {
  yield put(fetchSingleDiscountRequested());

  const request = {
    method: 'GET',
    url: `view-discount-setting/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleDiscountFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      discount_type: {
        label: result.discount_type,
        value: result.discount_type,
      },
      id: result.discount_setting_id,
    };

    yield put(fetchSingleDiscountSuccess(res));
  }
}

function* Discount() {
  yield all([
    takeLatest(ADD_DISCOUNT, addDiscountHandler),
    takeLatest(DELETE_DISCOUNT, deleteDiscountHandler),
    takeLatest(FETCH_DISCOUNT, fetchDiscountHandler),
    takeLatest(FETCH_SINGLE_DISCOUNT, fetchSingleDiscountHandler),
    takeLatest(EDIT_DISCOUNT, editDiscountHandler),
  ]);
}

export default Discount;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_LEVEL, DELETE_LEVEL, EDIT_LEVEL, FETCH_LEVEL, FETCH_SINGLE_LEVEL, fetchLevel, fetchLevelSuccess, fetchSingleLevelSuccess } from '../actions/level-action-types';
import HttpClient from './http-client';
import Alert from '../utility/alert';
import i18next from 'i18next';
import { history } from '../index';

export function* fetchLevelHandler({ payload }) {
    const request = {
      method: 'GET',
      params: { school_year_id: payload?.school_year_id },
      url: 'levels',
    };
  
    const { data, error } = yield call(HttpClient, request);

    if (error) {
    } else {
      yield put(fetchLevelSuccess(data.data || []));
    }
  }

  export function* fetchSingleLevelHandler({ payload }) {

    const request = {
      method: 'GET',
      url: `levels/${payload}/edit`,
    };
  
    const { data, error } = yield call(HttpClient, request);
  
    if (error) {
    } else {
      const res = data.data;
  
      yield put(fetchSingleLevelSuccess(res));
    }
  }

  export function* addLevelHandler({ payload }) {
    const body = new FormData();
  
    body.append('school_id', payload.school_id);
    body.append('school_year_id', payload.school_year_id);
    body.append('code', payload.code);
    body.append('label_fr', payload.label_fr);
    body.append('label_en', payload.label_en);
  
    const request = {
      data: body,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: 'levels',
    };
    const { error } = yield call(HttpClient, request);
  
    if (error) {
    } else {
      const alertProps = {
        callback: () => {
          history.push('/level');
        },
        icon: 'success',
        message: i18next.t('skullman:setting.level.createMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchLevel({school_year_id:payload.school_year_id}));
    }
  }

  export function* editLevelHandler({ payload }) {

    let dataTosend = {
      school_id:payload.school_id,
      school_year_id:payload.school_year_id,
      code:payload.code,
      label_fr:payload.label_fr,
      label_en:payload.label_en,
    }
    // const body = new FormData();
  
    // body.append('school_id', payload.school_id);
    // body.append('school_year_id', payload.school_year_id);
    // body.append('code', payload.code);
    // body.append('label_fr', payload.label_fr);
    // body.append('label_en', payload.label_en);
    const request = {
      data: dataTosend,
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      url: `levels/${payload.id}`,
    };
  
    const { error } = yield call(HttpClient, request);
  
    if (error) {
      // yield put(editWeekNumberFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/level');
        },
  
        icon: 'success',
        message: i18next.t('skullman:setting.level.updateMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchLevel({school_year_id:payload.school_year_id}));
    }
  }

  export function* deleteLevelHandler({ payload }) {
    const request = {
      method: 'DELETE',
      url: `levels/${payload}`,
    };
  
    const { error } = yield call(HttpClient, request);
  
    if (error) {
      // yield put(deleteWeekNumberFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/level');
        },
        icon: 'success',
        message: i18next.t('skullman:setting.level.deleteMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchLevel({school_year_id:payload.school_year_id}));
    }
  }

function* Levels() {
    yield all([
      takeLatest(FETCH_LEVEL, fetchLevelHandler),
      takeLatest(FETCH_SINGLE_LEVEL, fetchSingleLevelHandler),
      takeLatest(ADD_LEVEL, addLevelHandler),
      takeLatest(EDIT_LEVEL, editLevelHandler),
      takeLatest(DELETE_LEVEL, deleteLevelHandler)
    ]);
  }

  export default Levels;
import { createAction } from 'redux-actions';


export const FETCH_ALL_QUARTER = 'FETCH_ALL_QUARTER';
export const fetchQuarter = createAction(FETCH_ALL_QUARTER);

export const FETCH_ALL_QUARTER_SUCCESS = 'FETCH_ALL_QUARTER_SUCCESS';
export const fetchQuarterSuccess = createAction(FETCH_ALL_QUARTER_SUCCESS);

export const FETCH_ALL_SEQUENCE = 'FETCH_ALL_SEQUENCE';
export const fetchSequence = createAction(FETCH_ALL_SEQUENCE);

export const FETCH_ALL_SEQUENCE_SUCCESS = 'FETCH_ALL_SEQUENCE_SUCCESS';
export const fetchSequenceSuccess = createAction(FETCH_ALL_SEQUENCE_SUCCESS);

export const FETCH_ALL_TYPE_OF_EXAM = 'FETCH_ALL_TYPE_OF_EXAM';
export const fetchTypeOfExam = createAction(FETCH_ALL_TYPE_OF_EXAM);

export const FETCH_ALL_TYPE_OF_EXAM_SUCCESS = 'FETCH_ALL_TYPE_OF_EXAM_SUCCESS';
export const fetchAllTypeExamSuccess = createAction(FETCH_ALL_TYPE_OF_EXAM_SUCCESS);

export const ADD_MARK = 'ADD_MARK';
export const addMark = createAction(ADD_MARK);

export const ADD_MARK_REQUESTED = 'ADD_MARK_REQUESTED';
export const addMarkRequested = createAction(ADD_MARK_REQUESTED);

export const ADD_MARK_FAILURE = 'ADD_MARK_FAILURE';
export const addMarkFailure = createAction(ADD_MARK_FAILURE);

export const ADD_MARK_SUCCESS = 'ADD_MARK_SUCCESS';
export const addMarkSuccess = createAction(ADD_MARK_SUCCESS);

export const FETCH_MARK_LIST = 'FETCH_MARK_LIST';
export const fetchMarkList = createAction(FETCH_MARK_LIST);

export const FETCH_MARK_LIST_SUCCESS = 'FETCH_MARK_LIST_SUCCESS';
export const fetchMarkListSuccess = createAction(FETCH_MARK_LIST_SUCCESS);

export const FETCH_CLASS_STUDENT_MARKS = 'FETCH_CLASS_STUDENT_MARKS';
export const fetchClassStudentMark = createAction(FETCH_CLASS_STUDENT_MARKS);

export const FETCH_CLASS_STUDENT_MARKS_SUCCESS = 'FETCH_CLASS_STUDENT_MARKS_SUCCESS';
export const fetchClassStudentMarksSuccess = createAction(FETCH_CLASS_STUDENT_MARKS_SUCCESS);

export const SET_CLASS_STUDENT_MARKS = 'SET_CLASS_STUDENT_MARKS';
export const setClassStudentMarks = createAction(SET_CLASS_STUDENT_MARKS);

export const SET_UPDATE_VALIDATION = 'SET_UPDATE_VALIDATION';
export const setUpdatevalidation = createAction(SET_UPDATE_VALIDATION);

export const SET_MARKS_VALIDATION = 'SET_MARKS_VALIDATION';
export const setMarksValidatetion = createAction(SET_MARKS_VALIDATION);

export const UPDATE_CLASS_STUDENT_MARKS = 'UPDATE_CLASS_STUDENT_MARKS';
export const updateClassStudentMarks = createAction(UPDATE_CLASS_STUDENT_MARKS);

export const FETCH_MARK_FILTER = 'FETCH_MARK_FILTER';
export const fetchMarkFilter = createAction(FETCH_MARK_FILTER);

export const FETCH_MARK_FILTER_REQUESTED = 'FETCH_MARK_FILTER_REQUESTED';FETCH_MARK_GENERAL_SUCCESS
export const fetchMarkFilterRequested = createAction(FETCH_MARK_FILTER_REQUESTED);

export const FETCH_MARK_FILTER_FAILURE = 'FETCH_MARK_FILTER_FAILURE';
export const fetchMarkFilterFailure = createAction(FETCH_MARK_FILTER_FAILURE);

export const FETCH_MARK_FILTER_SUCCESS = 'FETCH_MARK_FILTER_SUCCESS';
export const fetchMarkFilterSuccess = createAction(FETCH_MARK_FILTER_SUCCESS);

export const FETCH_AVERAGE_MARK = 'FETCH_AVERAGE_MARK';
export const fetchAverageMark = createAction(FETCH_AVERAGE_MARK);

export const FETCH_AVERAGE_MARK_REQUESTED = 'FETCH_MARK_FILTER_REQUESTED';
export const fetchAverageMarkRequested = createAction(FETCH_MARK_FILTER_REQUESTED);

export const FETCH_AVERAGE_MARK_FAILURE = 'FETCH_AVERAGE_MARK_FAILURE';
export const fetchAverageMarkFailure = createAction(FETCH_AVERAGE_MARK_FAILURE);

export const FETCH_AVERAGE_MARK_SUCCESS = 'FETCH_AVERAGE_MARK_SUCCESS';
export const fetchAverageMarkSuccess = createAction(FETCH_AVERAGE_MARK_SUCCESS);

export const FETCH_MARK_REPORT = 'FETCH_MARK_REPORT';
export const fetchMarkReport = createAction(FETCH_MARK_REPORT);

export const FETCH_MARK_REPORT_REQUESTED = 'FETCH_MARK_REPORT_REQUESTED';
export const fetchMarkReportRequested = createAction(FETCH_MARK_REPORT_REQUESTED);

export const FETCH_MARK_REPORT_FAILURE = 'FETCH_MARK_REPORT_FAILURE';
export const fetchMarkReportFailure = createAction(FETCH_MARK_REPORT_FAILURE);

export const FETCH_MARK_REPORT_SUCCESS = 'FETCH_MARK_REPORT_SUCCESS';
export const fetchMarkReportSuccess = createAction(FETCH_MARK_REPORT_SUCCESS);

export const FETCH_HONOR_ROLL = 'FETCH_HONOR_ROLL';
export const fetchHonorRoll = createAction(FETCH_HONOR_ROLL);

export const FETCH_HONOR_ROLL_REQUESTED = 'FETCH_HONOR_ROLL_REQUESTED';
export const fetchHonorRollRequested = createAction(FETCH_HONOR_ROLL_REQUESTED);

export const FETCH_HONOR_ROLL_FAILURE = 'FETCH_HONOR_ROLL_FAILURE';
export const fetchHonorRollFailure = createAction(FETCH_HONOR_ROLL_FAILURE);

export const FETCH_HONOR_ROLL_SUCCESS = 'FETCH_HONOR_ROLL_SUCCESS';
export const fetchHonorRollSuccess = createAction(FETCH_HONOR_ROLL_SUCCESS);

export const FETCH_MARK_GENERAL_FILTER_REQUESTED = 'FETCH_MARK_GENERAL_FILTER_REQUESTED';
export const fetchGeneralMarkFilterRequested = createAction(FETCH_MARK_GENERAL_FILTER_REQUESTED);

export const FETCH_MARK_GENERAL_FILTER_FAILURE = 'FETCH_MARK_GENERAL_FILTER_FAILURE';
export const fetchGeneralMarkFilterFailure = createAction(FETCH_MARK_GENERAL_FILTER_FAILURE);

export const FETCH_MARK_GENERAL_SUCCESS = 'FETCH_MARK_GENERAL_SUCCESS';
export const fetchGeneralMarkSuccess = createAction(FETCH_MARK_GENERAL_SUCCESS);

export const FETCH_MARK_GENERAL_FILTER_SUCCESS = 'FETCH_MARK_GENERAL_FILTER_SUCCESS';
export const fetchGeneralMarkFilterSuccess = createAction(FETCH_MARK_GENERAL_FILTER_SUCCESS);

export const FETCH_GENERAL_MARK_LIST = 'FETCH_GENERAL_MARK_LIST';
export const fetchGeneralMarkList = createAction(FETCH_GENERAL_MARK_LIST);

export const FETCH_GENERAL_MARK_FILTER = 'FETCH_GENERAL_MARK_FILTER';
export const fetchGeneralMarkFilter = createAction(FETCH_GENERAL_MARK_FILTER);

export const FETCH_GENERAL_MARK_LIST_SUCCESS = 'FETCH_GENERAL_MARK_LIST_SUCCESS';
export const fetchGeneralMarkListSuccess = createAction(FETCH_GENERAL_MARK_LIST_SUCCESS);
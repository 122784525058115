import { createAction } from 'redux-actions';

export const CREATE_SUBJECT = 'CREATE_SUBJECT';
export const createSubject = createAction(CREATE_SUBJECT);

export const CREATE_SUBJECT_FAILURE = 'CREATE_SUBJECT_FAILURE';
export const createSubjectFailure = createAction(CREATE_SUBJECT_FAILURE);

export const CREATE_SUBJECT_REQUESTED = 'CREATE_SUBJECT_REQUESTED';
export const createSubjectRequested = createAction(CREATE_SUBJECT_REQUESTED);

export const CREATE_SUBJECT_SUCCESS = 'CREATE_SUBJECT_SUCCESS';
export const createSubjectSuccess = createAction(CREATE_SUBJECT_SUCCESS);

export const DELETE_SUBJECT = 'DELETE_SUBJECT';
export const deleteSubject = createAction(DELETE_SUBJECT);

export const DELETE_SUBJECT_FAILURE = 'DELETE_SUBJECT_FAILURE';
export const deleteSubjectFailure = createAction(DELETE_SUBJECT_FAILURE);

export const DELETE_SUBJECT_REQUESTED = 'DELETE_SUBJECT_REQUESTED';
export const deleteSubjectRequested = createAction(DELETE_SUBJECT_REQUESTED);

export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const deleteSubjectSuccess = createAction(DELETE_SUBJECT_SUCCESS);

export const EDIT_SUBJECT = 'EDIT_SUBJECT';
export const editSubject = createAction(EDIT_SUBJECT, (post, subject_id) => ({
    post,
    subject_id,
  }));

export const EDIT_SUBJECT_FAILURE = 'EDIT_SUBJECT_FAILURE';
export const editSubjectFailure = createAction(EDIT_SUBJECT_FAILURE);

export const EDIT_SUBJECT_REQUESTED = 'EDIT_SUBJECT_REQUESTED';
export const editSubjectRequested = createAction(EDIT_SUBJECT_REQUESTED);

export const EDIT_SUBJECT_SUCCESS = 'EDIT_SUBJECT_SUCCESS';
export const editSubjectSuccess = createAction(EDIT_SUBJECT_SUCCESS);

export const FETCH_SINGLE_SUBJECT = 'FETCH_SINGLE_SUBJECT';
export const fetchSingleSubject = createAction(FETCH_SINGLE_SUBJECT);

export const FETCH_SINGLE_SUBJECT_FAILURE = 'FETCH_SINGLE_SUBJECT_FAILURE';
export const fetchSingleSubjectFailure = createAction(FETCH_SINGLE_SUBJECT_FAILURE);

export const FETCH_SINGLE_SUBJECT_REQUESTED = 'FETCH_SINGLE_SUBJECT_REQUESTED';
export const fetchSingleSubjectRequested = createAction(FETCH_SINGLE_SUBJECT_REQUESTED);

export const FETCH_SINGLE_SUBJECT_SUCCESS = 'FETCH_SINGLE_SUBJECT_SUCCESS';
export const fetchSingleSubjectSuccess = createAction(FETCH_SINGLE_SUBJECT_SUCCESS);

export const FETCH_SUBJECT = 'FETCH_SUBJECT';
export const fetchSubject = createAction(FETCH_SUBJECT);

export const FETCH_SUBJECT_TYPES = 'FETCH_SUBJECT_TYPES';
export const fetchSubjectTypes = createAction(FETCH_SUBJECT_TYPES);

export const FETCH_SUBJECT_FAILURE = 'FETCH_SUBJECT_FAILURE';
export const fetchSubjectFailure = createAction(FETCH_SUBJECT_FAILURE);

export const FETCH_SUBJECT_REQUESTED = 'FETCH_SUBJECT_REQUESTED';
export const fetchSubjectRequested = createAction(FETCH_SUBJECT_REQUESTED);

export const FETCH_SUBJECT_SUCCESS = 'FETCH_SUBJECT_SUCCESS';
export const fetchSubjectSuccess = createAction(FETCH_SUBJECT_SUCCESS);

export const FETCH_SUBJECT_TYPES_SUCCESS = 'FETCH_SUBJECT_TYPES_SUCCESS';
export const fetchSubjectTypesSuccess = createAction(FETCH_SUBJECT_TYPES_SUCCESS);

export const UPDATE_SUBJECT_FORM = 'UPDATE_SUBJECT_FORM';
export const updateSubjectForm = createAction(UPDATE_SUBJECT_FORM);

export const DELETE_SINGLE_SUBJECT = 'DELETE_SINGLE_SUBJECT';
export const deleteSingleSubjectGrade = createAction(DELETE_SINGLE_SUBJECT);




export const FETCH_SUBJECT_FILTER = 'FETCH_SUBJECT_FILTER';
export const fetchSubjectFilter = createAction(FETCH_SUBJECT_FILTER);

export const FETCH_SUBJECT_FILTER_REQUESTED = 'FETCH_SUBJECT_FILTER_REQUESTED';
export const fetchSubjectFilterRequested = createAction(FETCH_SUBJECT_FILTER_REQUESTED);

export const FETCH_SUBJECT_FILTER_FAILURE = 'FETCH_SUBJECT_FILTER_FAILURE';
export const fetchSubjectFilterFailure = createAction(FETCH_SUBJECT_FILTER_FAILURE);

export const FETCH_SUBJECT_FILTER_SUCCESS = 'FETCH_SUBJECT_FILTER_SUCCESS';
export const fetchSubjectFilterSuccess = createAction(FETCH_SUBJECT_FILTER_SUCCESS);


import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_LEVEL, DELETE_LEVEL, EDIT_LEVEL, FETCH_LEVEL, FETCH_SINGLE_LEVEL, fetchLevel, fetchLevelSuccess, fetchSingleLevelSuccess } from '../actions/level-action-types';
import HttpClient from './http-client';
import Alert from '../utility/alert';
import i18next from 'i18next';
import { history } from '../index';
import { ADD_SKILLS, DELETE_SKILLS, EDIT_SKILLS, FETCH_SINGLE_SKILLS, FETCH_SKILLS, fetchSingleSkillsSuccess, fetchSkill, fetchSkillsSuccess } from '../actions/skill-action-types';
import { DOWNLOAD_PRIMARY_REPORT, FETCH_PRIMARY_REPORT, GENERATE_PRIMARY_REPORT, fetchPrimaryReportSuccess } from '../actions/primary-mark-report-action-type';

export function* fetchmarkPrimaryGetReportHandler({ payload }) {
    const request = {
      method: 'GET',
      url: 'primary-marks-report',
      params:{
        school_year_id:payload.school_year_id
      }
    };
  
    const { data, error } = yield call(HttpClient, request);

    if (error) {
    } else {
      yield put(fetchPrimaryReportSuccess(data.data || []));
    }
  }

  export function* fetchmarkPrimaryGenerateReportHandler({ payload }) {


    let dataTosend = {
      school_year_id: payload.school_year_id,
      class_id: payload.class_id,
      exam_id: payload.exam_id,
      type: payload.type,
      term_id: payload.term_id, 
    }

    const request = {
      data: dataTosend,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      url: `primary-marks-report`,
    };
  
    const { data,error } = yield call(HttpClient, request);
  
    if (error) {
      // yield put(editWeekNumberFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/marks-primary-report');
        },
  
        icon: 'success',
        message: data.message,
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
    }
  }

  export function* fetchmarkPrimaryDownloadReportHandler({ payload }){
    
    const request = {
        url: `/download-primary-marks-report/${payload.id}`,
        // params: payload,
        responseType: 'blob',
        method: 'GET'
    };

    const {data, error} = yield call(HttpClient, request);

    if (error) {
        
      }
      else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.setAttribute('download', 'Marks-reports.pdf');
        link.href = url;
        document.body.appendChild(link);
        link.click();
      }
}











 

function* MarkPrimaryGeneratedReport() {
    yield all([
      takeLatest(FETCH_PRIMARY_REPORT, fetchmarkPrimaryGetReportHandler),
      takeLatest(GENERATE_PRIMARY_REPORT, fetchmarkPrimaryGenerateReportHandler),
      takeLatest(DOWNLOAD_PRIMARY_REPORT, fetchmarkPrimaryDownloadReportHandler),  
    ]);
  }

  export default MarkPrimaryGeneratedReport;
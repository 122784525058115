import { createAction } from 'redux-actions';


export const FETCH_SUBJECT_TYPE = 'FETCH_SUBJECT_TYPE';
export const fetchSubjectType = createAction(FETCH_SUBJECT_TYPE);

export const FETCH_SUBJECT_TYPE_SUCCESS = 'FETCH_SUBJECT_TYPE_SUCCESS';
export const fetchSubjectTypeSuccess = createAction(FETCH_SUBJECT_TYPE_SUCCESS);

export const FETCH_SINGLE_SUBJECT_TYPE = 'FETCH_SINGLE_SUBJECT_TYPE';
export const fetchSingleSubjectType = createAction(FETCH_SINGLE_SUBJECT_TYPE);

export const FETCH_SINGLE_SUBJECT_TYPE_SUCCESS = 'FETCH_SINGLE_SUBJECT_TYPE_SUCCESS';
export const fetchSingleSubjectTypeSuccess = createAction(FETCH_SINGLE_SUBJECT_TYPE_SUCCESS);

export const ADD_SUBJECT_TYPE = 'ADD_SUBJECT_TYPE';
export const addSubjectType = createAction(ADD_SUBJECT_TYPE);

export const EDIT_SUBJECT_TYPE = 'EDIT_SUBJECT_TYPE';
export const editSubjectType = createAction(EDIT_SUBJECT_TYPE);

export const DELETE_SUBJECT_TYPE = 'DELETE_SUBJECT_TYPE';
export const deleteSubjectType = createAction(DELETE_SUBJECT_TYPE);

export const FETCH_SUBJECT_TYPE_BY_LEVEL_ID = 'FETCH_SUBJECT_TYPE_BY_LEVEL_ID';
export const fetchSubjectTypeById = createAction(FETCH_SUBJECT_TYPE_BY_LEVEL_ID);

export const FETCH_SUBJECT_TYPE_BY_LEVEL_ID_SUCCESS = 'FETCH_SUBJECT_TYPE_BY_LEVEL_ID_SUCCESS';
export const fetchSubjectTypeSuccessById = createAction(FETCH_SUBJECT_TYPE_BY_LEVEL_ID_SUCCESS);
import {LOCATION_CHANGE} from "connected-react-router";

import {
    ADD_QUARTERS_REQUESTED,
    ADD_QUARTERS_SUCCESS,
    ADD_QUARTERS_FAILURE,
    FETCH_QUARTERS_REQUESTED,
    FETCH_QUARTERS_SUCCESS,
    FETCH_QUARTERS_FAILURE
} from "../actions/quarters-action-types";

const initialState = {
    addUpdateQuarterStutus: 'pending',
    fetchQuarterStatus: 'pending',
    quarters: [],
    form: {
        quarters: []
    }
};

export default function Quarters(state = initialState, {
    payload, type
}) {
    switch (type) {
        case ADD_QUARTERS_REQUESTED:
            return {
                ...state,
                addUpdateQuarterStutus: 'creating'
            }
        case ADD_QUARTERS_SUCCESS:
            return {
                ...state,
                addUpdateQuarterStutus: 'success'
            }
        case ADD_QUARTERS_FAILURE:
            return {
                ...state,
                addUpdateQuarterStutus: 'failure'
            }
        case FETCH_QUARTERS_REQUESTED:
            return {
                ...state,
                fetchQuarterStatus: 'fetching'
            }
        case FETCH_QUARTERS_SUCCESS:
            return {
                ...state,
                quarters: payload,
                fetchQuarterStatus: 'success'
            }
        case FETCH_QUARTERS_FAILURE:
            return {
                ...state,
                fetchQuarterStatus: 'failure'
            }
        case LOCATION_CHANGE:
            return {...initialState}
        default:
            return state;
    }
};
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_BANK,
  addBankFailure,
  addBankRequested,
  addBankSuccess,
  DELETE_BANK,
  deleteBankFailure,
  deleteBankRequested,
  deleteBankSuccess,
  EDIT_BANK,
  editBankFailure,
  editBankRequested,
  editBankSuccess,
  FETCH_SINGLE_BANK,
  fetchSingleBankFailure,
  fetchSingleBankRequested,
  fetchSingleBankSuccess,
  FETCH_BANK,
  fetchBankFailure,
  fetchBankRequested,
  fetchBankSuccess,
} from '../actions/bank-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import i18next from "i18next";


export function* addBankHandler({ payload }) {
  yield put(addBankRequested());
  const body = new FormData();
  body.append('name', payload.form.name);
  body.append('account_no', payload.form.account_no);
  body.append('ifsc', payload.form.ifsc);
  body.append('pan_no', payload.form.pan_no);
  body.append('employee_id', payload.employee_id);
  console.log(body);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-bank',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addBankFailure(error));
  } else {
    yield put(addBankSuccess());
    const alertProps = {
      /*callback: () => {
        history.push('/employee');
      },*/
      icon: 'success',
      message: 'Bank created successfully.',
      title: 'Success',
    };

    Alert.alert(alertProps);
  }
}

export function* editBankHandler({ payload }) {
  yield put(editBankRequested());
  const request = {
    method: 'PUT',
    params: {
      name: payload.form.name,
      account_no: payload.form.account_no,
      ifsc: payload.form.ifsc,
      pan_no: payload.form.pan_no,
    },
    url: `update-bank/${payload.bankId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(editBankFailure(error));
  } else {
    const alertProps = {
      /*callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/employee'});
      },*/
      icon: 'success',
      message: 'Bank updated successfully.',
      title: 'Success',
    };

    Alert.alert(alertProps);
    yield put(editBankSuccess());

  }
}

export function* deleteBankHandler({ payload }) {
  console.log(payload);
  yield put(deleteBankRequested());
  const request = {
    method: 'PUT',
    url: `delete-employee/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteBankFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:schoolStaff.employees.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteBankSuccess(payload));
  }
}

export function* fetchBankHandler({ payload }) {
  yield put(fetchBankRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'employee-bank',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchBankFailure(error));
  } else {
    yield put(fetchBankSuccess(data.data.result));
  }
}

export function* fetchSingleBankHandler({ payload }) {
  yield put(fetchSingleBankRequested());
  const request = {
    method: 'GET',
    url: `get-employee/${payload.publicId}`,
  };
  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleBankFailure(error));
  } else {
    yield put(fetchBankSuccess(data.data.result));
    if (payload?.result) {
      payload.result(data.data.result)
    }
  }

  /*if (error) {
    yield put(fetchSingleBankFailure(error));
  } else {
    const { result } = data.data;

    const department_id = result.department_id?.split(',');
    const department_name = result.department_name?.split(',');

    let department_arr = [];
    for (let index = 0; index < department_id.length; index++) {
      let value = department_id[index];
      let label = department_name[index];

      let obj = {};
      obj.label = label;
      obj.value = value;
      department_arr.push(obj);
    }

    const res = {
      ...data.data.result,
      department_id: department_arr,
      highest_qualification: {
        label: result.qualification_name,
        value: result.highest_qualification,
      },
      id: result.id,

      status:
        result.status === 1
          ? {
              label: 'Active',
              value: 1,
            }
          : {
              label: 'Inactive',
              value: 0,
            },
    };

    yield put(fetchSingleBankSuccess(res));
  }*/
}

export function* deleteBankClass({ payload }) {
  const request = {
    method: 'DELETE',
    url: `delete-employee-class/${payload}`,
  };

  yield call(httpClient, request);
}

function* bank() {
  yield all([
    takeLatest(ADD_BANK, addBankHandler),
    takeLatest(DELETE_BANK, deleteBankHandler),
    takeLatest(EDIT_BANK, editBankHandler),
    takeLatest(FETCH_BANK, fetchBankHandler),
    takeLatest(FETCH_SINGLE_BANK, fetchSingleBankHandler),
  ]);
}

export default bank;

import { LOCATION_CHANGE } from 'connected-react-router';
import { FETCH_SCHEDULE_SUCCESS, ADD_SCHEDULE_SUCCESS, FETCH_SINGLE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_SUCCESS, UPDATE_SCHEDULE_FORM, FETCH_TEACHER_SCHEDULE_SUCCESS } from '../actions/schedule-action-types';

const initialState = {
  addScheduleStatus: 'pending',
  allSchedules: [],
  deleteScheduleStatus: 'pending',
  error: false,
  fetchScheduleStatus: 'pending',
  fetchSingleScheduleStatus: 'pending',
  form: {
    class_id: '',
    fromScheduleTime: '',
    school_year_id: '',
    subject_id: '',
    teacher_id: '',
    toScheduleTime: '',
    weekDay: '',
  },
  schedule: [],
  schedules: [],
};

export default function schedule(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case FETCH_TEACHER_SCHEDULE_SUCCESS:
      return {
        ...state, schedule: payload,
      };
    case FETCH_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedules: payload,
      };

    case ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        addScheduleStatus: 'success',
      };

    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        allSchedules: [...state.allSchedules].filter((Schedule) => !(payload.toString().includes(Schedule.id.toString()))),

      };
    case FETCH_SINGLE_SCHEDULE_SUCCESS:
      return {
        ...state,
        fetchSingleScheduleStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_SCHEDULE_FORM:
      console.log('payload',payload)
      return {
        ...state,
        form: payload,
      };
    case LOCATION_CHANGE:
      return { ...initialState };
    default:
      return state;
  }
}

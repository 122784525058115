import { createAction } from 'redux-actions';

export const SET_USERTYPE = 'SET_USERTYPE';
export const setUserType = createAction(SET_USERTYPE);

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const setInitialState = createAction(SET_INITIAL_STATE);

export const FETCH_USERTYPE_FAILURE = 'FETCH_USERTYPE_FAILURE';
export const fetchUserTypeFailure = createAction(FETCH_USERTYPE_FAILURE);

export const FETCH_USERTYPE_REQUESTED = 'FETCH_USERTYPE_REQUESTED';
export const fetchUserTypeRequested = createAction(FETCH_USERTYPE_REQUESTED);

export const FETCH_USERTYPE_SUCCESS = 'FETCH_USERTYPE_SUCCESS';
export const fetchUserTypeSuccess = createAction(FETCH_USERTYPE_SUCCESS);

export const FETCH_USERTYPE = 'FETCH_USERTYPE';
export const fetchUserType = createAction(FETCH_USERTYPE);

export const FETCH_EMPLOYEE_USERTYPE = 'FETCH_EMPLOYEE_USERTYPE';
export const fetchEmployeeUserType = createAction(FETCH_EMPLOYEE_USERTYPE);

export const FETCH_EMPLOYEE_USERTYPE_REQUESTED = 'FETCH_EMPLOYEE_USERTYPE_REQUESTED';
export const fetchEmployeeUserTypeRequested = createAction(FETCH_EMPLOYEE_USERTYPE_REQUESTED);
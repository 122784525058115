import { createAction } from "redux-actions";


export const MARKS_REPORT_REQUEST = 'MARKS_REPORT_REQUEST';
export const marksReportRequest = createAction(MARKS_REPORT_REQUEST);

export const MARKS_REPORT_REQUEST_CREATED = 'MARKS_REPORT_REQUEST_CREATED';
export const marksReportRequestCreated = createAction(MARKS_REPORT_REQUEST_CREATED);

export const MARKS_REPORT_REQUEST_SUCCESS = 'MARKS_REPORT_REQUEST_SUCCESS';
export const marksReportRequestSuccess = createAction(MARKS_REPORT_REQUEST_SUCCESS)

export const MARKS_REPORT_REQUEST_FAILURE = 'MARKS_REPORT_REQUEST_FAILURE';
export const marksReportRequestFailure = createAction(MARKS_REPORT_REQUEST_FAILURE);


export const FETCH_MARKS_REPORT_REQUEST = "FETCH_MARKS_REPORT_REQUEST";
export const fetchMarksReportRequest = createAction(FETCH_MARKS_REPORT_REQUEST);

export const FETCH_MARKS_REPORT_REQUEST_CREATED = "FETCH_MARKS_REPORT_REQUEST_CREATED";
export const fetchMarksReportRequestCreated = createAction(FETCH_MARKS_REPORT_REQUEST_CREATED);

export const FETCH_MARKS_REPORT_REQUEST_SUCCESS = "FETCH_MARKS_REPORT_REQUEST_SUCCESS";
export const fetchMarksReportRequestSuccess = createAction(FETCH_MARKS_REPORT_REQUEST_SUCCESS);

export const FETCH_MARKS_REPORT_REQUEST_FAILURE = "FETCH_MARKS_REPORT_REQUEST_FAILURE";
export const fetchMarksReportRequestFailure = createAction(FETCH_MARKS_REPORT_REQUEST_FAILURE);


export const DOWNLOAD_MARKS_REPORT = "DOWNLOAD_MARKS_REPORT";
export const downloadMarksReport = createAction(DOWNLOAD_MARKS_REPORT);

export const DOWNLOAD_MARKS_REPORT_REQUESTED = "DOWNLOAD_MARKS_REPORT_REQUESTED";
export const downloadMarksReportRequested = createAction(DOWNLOAD_MARKS_REPORT_REQUESTED);

export const DOWNLOAD_MARKS_REPORT_SUCCESS = "DOWNLOAD_MARKS_REPORT_SUCCESS";
export const downloadMarksReportSuccess = createAction(DOWNLOAD_MARKS_REPORT_SUCCESS);

export const DOWNLOAD_MARKS_REPORT_FAILURE = "DOWNLOAD_MARKS_REPORT_FAILURE";
export const downloadMarksReportFailure = createAction(DOWNLOAD_MARKS_REPORT_FAILURE);
import { createAction } from 'redux-actions';

export const ADD_TOTAL_USERS = 'ADD_TOTAL_USERS';
export const addTotalUsers = createAction(ADD_TOTAL_USERS);

export const ADD_TOTAL_USERS_FAILURE = 'ADD_TOTAL_USERS_FAILURE';
export const addTotalUsersFailure = createAction(ADD_TOTAL_USERS_FAILURE);

export const ADD_TOTAL_USERS_REQUESTED = 'ADD_TOTAL_USERS_REQUESTED';
export const addTotalUsersRequested = createAction(ADD_TOTAL_USERS_REQUESTED);

export const ADD_TOTAL_USERS_SUCCESS = 'ADD_TOTAL_USERS_SUCCESS';
export const addTotalUsersSuccess = createAction(ADD_TOTAL_USERS_SUCCESS);

export const DELETE_TOTAL_USERS = 'DELETE_TOTAL_USERS';
export const deleteTotalUsers = createAction(DELETE_TOTAL_USERS);

export const DELETE_TOTAL_USERS_FAILURE = 'DELETE_TOTAL_USERS_FAILURE';
export const deleteTotalUsersFailure = createAction(DELETE_TOTAL_USERS_FAILURE);

export const DELETE_TOTAL_USERS_REQUESTED = 'DELETE_TOTAL_USERS_REQUESTED';
export const deleteTotalUsersRequested = createAction(DELETE_TOTAL_USERS_REQUESTED);

export const DELETE_TOTAL_USERS_SUCCESS = 'DELETE_TOTAL_USERS_SUCCESS';
export const deleteTotalUsersSuccess = createAction(DELETE_TOTAL_USERS_SUCCESS);

export const FETCH_TOTAL_USERS = 'FETCH_TOTAL_USERS';
export const fetchTotalUsers = createAction(FETCH_TOTAL_USERS);

export const FETCH_TOTAL_USERS_FAILURE = 'FETCH_TOTAL_USERS_FAILURE';
export const fetchTotalUsersFailure = createAction(FETCH_TOTAL_USERS_FAILURE);

export const FETCH_TOTAL_USERS_REQUESTED = 'FETCH_TOTAL_USERS_REQUESTED';
export const fetchTotalUsersRequested = createAction(FETCH_TOTAL_USERS_REQUESTED);

export const FETCH_TOTAL_USERS_SUCCESS = 'FETCH_TOTAL_USERS_SUCCESS';
export const fetchTotalUsersSuccess = createAction(FETCH_TOTAL_USERS_SUCCESS);

export const EDIT_TOTAL_USERS = 'EDIT_TOTAL_USERS';
export const editTotalUsers = createAction(EDIT_TOTAL_USERS);

export const EDIT_TOTAL_USERS_FAILURE = 'EDIT_TOTAL_USERS_FAILURE';
export const editTotalUsersFailure = createAction(EDIT_TOTAL_USERS_FAILURE);

export const EDIT_TOTAL_USERS_REQUESTED = 'EDIT_TOTAL_USERS_REQUESTED';
export const editTotalUsersRequested = createAction(EDIT_TOTAL_USERS_REQUESTED);

export const EDIT_TOTAL_USERS_SUCCESS = 'EDIT_TOTAL_USERS_SUCCESS';
export const editTotalUsersSuccess = createAction(EDIT_TOTAL_USERS_SUCCESS);

export const FETCH_SINGLE_TOTAL_USERS = 'FETCH_SINGLE_TOTAL_USERS';
export const fetchSingleTotalUsers = createAction(FETCH_SINGLE_TOTAL_USERS);

export const FETCH_SINGLE_TOTAL_USERS_FAILURE = 'FETCH_SINGLE_TOTAL_USERS_FAILURE';
export const fetchSingleTotalUsersFailure = createAction(FETCH_SINGLE_TOTAL_USERS_FAILURE);

export const FETCH_SINGLE_TOTAL_USERS_REQUESTED = 'FETCH_SINGLE_TOTAL_USERS_REQUESTED';
export const fetchSingleTotalUsersRequested = createAction(FETCH_SINGLE_TOTAL_USERS_REQUESTED);

export const FETCH_SINGLE_TOTAL_USERS_SUCCESS = 'FETCH_SINGLE_TOTAL_USERS_SUCCESS';
export const fetchSingleTotalUsersSuccess = createAction(FETCH_SINGLE_TOTAL_USERS_SUCCESS);

export const UPDATE_TOTAL_USERS_FORM = 'UPDATE_TOTAL_USERS_FORM';
export const updateTotalUsersForm = createAction(UPDATE_TOTAL_USERS_FORM);

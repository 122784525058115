import { createAction } from 'redux-actions';

export const ADD_APPRECIATION = 'ADD_APPRECIATION';
export const addAppreciation = createAction(ADD_APPRECIATION);

export const ADD_APPRECIATION_FAILURE = 'ADD_APPRECIATION_FAILURE';
export const addAppreciationFailure = createAction(ADD_APPRECIATION_FAILURE);

export const ADD_APPRECIATION_REQUESTED = 'ADD_APPRECIATION_REQUESTED';
export const addAppreciationRequested = createAction(ADD_APPRECIATION_REQUESTED);

export const ADD_APPRECIATION_SUCCESS = 'ADD_APPRECIATION_SUCCESS';
export const addAppreciationSuccess = createAction(ADD_APPRECIATION_SUCCESS);

export const DELETE_APPRECIATION = 'DELETE_APPRECIATION';
export const deleteAppreciation = createAction(DELETE_APPRECIATION);

export const DELETE_APPRECIATION_FAILURE = 'DELETE_APPRECIATION_FAILURE';
export const deleteAppreciationFailure = createAction(DELETE_APPRECIATION_FAILURE);

export const DELETE_APPRECIATION_REQUESTED = 'DELETE_APPRECIATION_REQUESTED';
export const deleteAppreciationRequested = createAction(DELETE_APPRECIATION_REQUESTED);

export const DELETE_APPRECIATION_SUCCESS = 'DELETE_APPRECIATION_SUCCESS';
export const deleteAppreciationSuccess = createAction(DELETE_APPRECIATION_SUCCESS);

export const FETCH_APPRECIATION = 'FETCH_APPRECIATION';
export const fetchAppreciation = createAction(FETCH_APPRECIATION);

export const FETCH_APPRECIATION_FAILURE = 'FETCH_APPRECIATION_FAILURE';
export const fetchAppreciationFailure = createAction(FETCH_APPRECIATION_FAILURE);

export const FETCH_APPRECIATION_REQUESTED = 'FETCH_APPRECIATION_REQUESTED';
export const fetchAppreciationRequested = createAction(FETCH_APPRECIATION_REQUESTED);

export const FETCH_APPRECIATION_SUCCESS = 'FETCH_APPRECIATION_SUCCESS';
export const fetchAppreciationSuccess = createAction(FETCH_APPRECIATION_SUCCESS);

export const EDIT_APPRECIATION = 'EDIT_APPRECIATION';
export const editAppreciation = createAction(EDIT_APPRECIATION);

export const EDIT_APPRECIATION_FAILURE = 'EDIT_APPRECIATION_FAILURE';
export const editAppreciationFailure = createAction(EDIT_APPRECIATION_FAILURE);

export const EDIT_APPRECIATION_REQUESTED = 'EDIT_APPRECIATION_REQUESTED';
export const editAppreciationRequested = createAction(EDIT_APPRECIATION_REQUESTED);

export const EDIT_APPRECIATION_SUCCESS = 'EDIT_APPRECIATION_SUCCESS';
export const editAppreciationSuccess = createAction(EDIT_APPRECIATION_SUCCESS);

export const FETCH_SINGLE_APPRECIATION = 'FETCH_SINGLE_APPRECIATION';
export const fetchSingleAppreciation = createAction(FETCH_SINGLE_APPRECIATION);

export const FETCH_SINGLE_APPRECIATION_FAILURE = 'FETCH_SINGLE_APPRECIATION_FAILURE';
export const fetchSingleAppreciationFailure = createAction(FETCH_SINGLE_APPRECIATION_FAILURE);

export const FETCH_SINGLE_APPRECIATION_REQUESTED = 'FETCH_SINGLE_APPRECIATION_REQUESTED';
export const fetchSingleAppreciationRequested = createAction(FETCH_SINGLE_APPRECIATION_REQUESTED);

export const FETCH_SINGLE_APPRECIATION_SUCCESS = 'FETCH_SINGLE_APPRECIATION_SUCCESS';
export const fetchSingleAppreciationSuccess = createAction(FETCH_SINGLE_APPRECIATION_SUCCESS);

export const UPDATE_APPRECIATION_FORM = 'UPDATE_APPRECIATION_FORM';
export const updateAppreciationForm = createAction(UPDATE_APPRECIATION_FORM);

export const FETCH_TEACHER_APPRECIATION = 'FETCH_TEACHER_APPRECIATION';
export const fetchTeacherAppreciation = createAction(FETCH_TEACHER_APPRECIATION);

export const FETCH_TEACHER_APPRECIATION_FAILURE = 'FETCH_TEACHER_APPRECIATION_FAILURE';
export const fetchTeacherAppreciationFailure = createAction(FETCH_TEACHER_APPRECIATION_FAILURE);

export const FETCH_TEACHER_APPRECIATION_REQUESTED = 'FETCH_TEACHER_APPRECIATION_REQUESTED';
export const fetchTeacherAppreciationRequested = createAction(FETCH_TEACHER_APPRECIATION_REQUESTED);

export const FETCH_TEACHER_APPRECIATION_SUCCESS = 'FETCH_TEACHER_APPRECIATION_SUCCESS';
export const fetchTeacherAppreciationSuccess = createAction(FETCH_TEACHER_APPRECIATION_SUCCESS);

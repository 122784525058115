import { all, call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_TOTAL_USERS,
  fetchTotalUsersFailure,
  fetchTotalUsersRequested,
  fetchTotalUsersSuccess } from '../actions/total-users-action-type';
import httpClient from './http-client';

export function* fetchTotalUsersHandler({ payload }) {
  yield put(fetchTotalUsersRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'total-users',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchTotalUsersFailure(error));
  } else {
    yield put(fetchTotalUsersSuccess(data.student));
  }
}

function* TotalUsers() {
  yield all([
    takeLatest(FETCH_TOTAL_USERS, fetchTotalUsersHandler),
  ]);
}

export default TotalUsers;

import {bool, string} from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import Utils from "../../utility";


const LinkableImageTextCellRenderer = ({
    text, subText, image, href, className
}) => {
    const newTo = {
        pathname: href,
        param1: localStorage.getItem('pagination') || 1,
    };

    if(href && text){
        const studentImage = (image && image.length)? (<img width="42px" height="42px" className={"rounded-circle"} src={encodeURI(image)} alt="" />):
            (<img width="42px" height="42px" className={"rounded-circle"} src={Utils.getImage('user-placeholder.png')} alt="Profile Pic" />);

        const columnContent = (text, subText, studentImage) => {
            if(subText) {
                return {studentImage} + <div className={"enrollment-student-name"}>{text} <span>{subText}</span></div>;
            }else {
                return {studentImage} + <div className={"enrollment-student-name"}>{text}</div>;
            }
        }

        return (
            <Link to={newTo} className={`${className}`+"-container"}>
                {studentImage} <div className={"enrollStd"}><p>{text}</p> <span>{subText}</span></div>
            </Link>
        );
    }

    return <></>;
};

LinkableImageTextCellRenderer.propTypes = {
    text: string.isRequired,
    subText: string,
    href: string.isRequired,
    image: string,
    className: string
};

LinkableImageTextCellRenderer.defaultProps = {
    className: 'enrollment-student-image',
    image: '',
    subText: ''
};

export default LinkableImageTextCellRenderer;
import { createAction } from 'redux-actions';

export const CREATE_ENROLLMENT = 'CREATE_ENROLLMENT';
export const createEnrollment = createAction(CREATE_ENROLLMENT);

export const CREATE_ENROLLMENT_FAILURE = 'CREATE_ENROLLMENT_FAILURE';
export const createEnrollmentFailure = createAction(CREATE_ENROLLMENT_FAILURE);

export const CREATE_ENROLLMENT_REQUESTED = 'CREATE_ENROLLMENT_REQUESTED';
export const createEnrollmentRequested = createAction(CREATE_ENROLLMENT_REQUESTED);

export const CREATE_ENROLLMENT_SUCCESS = 'CREATE_ENROLLMENT_SUCCESS';
export const createEnrollmentSuccess = createAction(CREATE_ENROLLMENT_SUCCESS);

export const DELETE_ENROLLMENT = 'DELETE_ENROLLMENT';
export const deleteEnrollment = createAction(DELETE_ENROLLMENT);

export const DELETE_ENROLLMENT_FAILURE = 'DELETE_ENROLLMENT_FAILURE';
export const deleteEnrollmentFailure = createAction(DELETE_ENROLLMENT_FAILURE);

export const DELETE_ENROLLMENT_REQUESTED = 'DELETE_ENROLLMENT_REQUESTED';
export const deleteEnrollmentRequested = createAction(DELETE_ENROLLMENT_REQUESTED);

export const DELETE_ENROLLMENT_SUCCESS = 'DELETE_ENROLLMENT_SUCCESS';
export const deleteEnrollmentSuccess = createAction(DELETE_ENROLLMENT_SUCCESS);

export const EDIT_ENROLLMENT = 'EDIT_ENROLLMENT';
export const editEnrollment = createAction(EDIT_ENROLLMENT);

export const EDIT_ENROLLMENT_FAILURE = 'EDIT_ENROLLMENT_FAILURE';
export const editEnrollmentFailure = createAction(EDIT_ENROLLMENT_FAILURE);

export const EDIT_ENROLLMENT_REQUESTED = 'EDIT_ENROLLMENT_REQUESTED';
export const editEnrollmentRequested = createAction(EDIT_ENROLLMENT_REQUESTED);

export const EDIT_ENROLLMENT_SUCCESS = 'EDIT_ENROLLMENT_SUCCESS';
export const editEnrollmentSuccess = createAction(EDIT_ENROLLMENT_SUCCESS);

export const FETCH_SINGLE_ENROLLMENT = 'FETCH_SINGLE_ENROLLMENT';
export const fetchSingleEnrollment = createAction(FETCH_SINGLE_ENROLLMENT, (student_id, school_year_id) => ({
  school_year_id,
  student_id,

}));

export const FETCH_SINGLE_ENROLLMENT_FAILURE = 'FETCH_SINGLE_ENROLLMENT_FAILURE';
export const fetchSingleEnrollmentFailure = createAction(FETCH_SINGLE_ENROLLMENT_FAILURE);

export const FETCH_SINGLE_ENROLLMENT_REQUESTED = 'FETCH_SINGLE_ENROLLMENT_REQUESTED';
export const fetchSingleEnrollmentRequested = createAction(FETCH_SINGLE_ENROLLMENT_REQUESTED);

export const FETCH_SINGLE_ENROLLMENT_SUCCESS = 'FETCH_SINGLE_ENROLLMENT_SUCCESS';
export const fetchSingleEnrollmentSuccess = createAction(FETCH_SINGLE_ENROLLMENT_SUCCESS);

export const FETCH_ENROLLMENT = 'FETCH_ENROLLMENT';
export const fetchEnrollment = createAction(FETCH_ENROLLMENT);

export const FETCH_ENROLLMENT_FAILURE = 'FETCH_ENROLLMENT_FAILURE';
export const fetchEnrollmentFailure = createAction(FETCH_ENROLLMENT_FAILURE);

export const FETCH_ENROLLMENT_REQUESTED = 'FETCH_ENROLLMENT_REQUESTED';
export const fetchEnrollmentRequested = createAction(FETCH_ENROLLMENT_REQUESTED);

export const FETCH_ENROLLMENT_SUCCESS = 'FETCH_ENROLLMENT_SUCCESS';
export const fetchEnrollmentSuccess = createAction(FETCH_ENROLLMENT_SUCCESS);

export const UPDATE_ENROLLMENT_FORM = 'UPDATE_ENROLLMENT_FORM';
export const updateEnrollmentForm = createAction(UPDATE_ENROLLMENT_FORM);

export const FETCH_ENROLLED_STUDENTS = 'FETCH_ENROLLED_STUDENTS';
export const fetchEnrolledStudents = createAction(FETCH_ENROLLED_STUDENTS);

export const FETCH_ENROLLED_STUDENTS_FAILURE = 'FETCH_ENROLLED_STUDENTS_FAILURE';
export const fetchEnrolledStudentsFailure = createAction(FETCH_ENROLLED_STUDENTS_FAILURE);

export const FETCH_ENROLLED_STUDENTS_REQUESTED = 'FETCH_ENROLLED_STUDENTS_REQUESTED';
export const fetchEnrolledStudentsRequested = createAction(FETCH_ENROLLED_STUDENTS_REQUESTED);

export const FETCH_ENROLLED_STUDENTS_SUCCESS = 'FETCH_ENROLLED_STUDENTS_SUCCESS';
export const fetchEnrolledStudentsSuccess = createAction(FETCH_ENROLLED_STUDENTS_SUCCESS);

export const FETCH_STUDENTS_INSTALLMENT = 'FETCH_STUDENTS_INSTALLMENT';
export const fetchStudentsInstallment = createAction(FETCH_STUDENTS_INSTALLMENT);

export const FETCH_STUDENTS_INSTALLMENT_FAILURE = 'FETCH_STUDENTS_INSTALLMENT_FAILURE';
export const fetchStudentsInstallmentFailure = createAction(FETCH_STUDENTS_INSTALLMENT_FAILURE);

export const FETCH_STUDENTS_INSTALLMENT_REQUESTED = 'FETCH_STUDENTS_INSTALLMENT_REQUESTED';
export const fetchStudentsInstallmentRequested = createAction(FETCH_STUDENTS_INSTALLMENT_REQUESTED);

export const FETCH_STUDENTS_INSTALLMENT_SUCCESS = 'FETCH_STUDENTS_INSTALLMENT_SUCCESS';
export const fetchStudentsInstallmentSuccess = createAction(FETCH_STUDENTS_INSTALLMENT_SUCCESS);

export const FETCH_STUDENTS_INSTALLMENT_SEQ_FAILURE = 'FETCH_STUDENTS_INSTALLMENT_SEQ_FAILURE';
export const fetchStudentsInstallmentSeqFailure = createAction(FETCH_STUDENTS_INSTALLMENT_SEQ_FAILURE);

export const FETCH_STUDENTS_INSTALLMENT_SEQ_REQUESTED = 'FETCH_STUDENTS_INSTALLMENT_SEQ_REQUESTED';
export const fetchStudentsInstallmentSeqRequested = createAction(FETCH_STUDENTS_INSTALLMENT_SEQ_REQUESTED);

export const FETCH_STUDENTS_INSTALLMENT_SEQ_SUCCESS = 'FETCH_STUDENTS_INSTALLMENT_SEQ_SUCCESS';
export const fetchStudentsInstallmentSeqSuccess = createAction(FETCH_STUDENTS_INSTALLMENT_SEQ_SUCCESS);

// export const FETCH_TRANSPORT = 'FETCH_TRANSPORT';
// export const fetchTransport = createAction(FETCH_TRANSPORT);

// export const FETCH_TRANSPORT_FAILURE = 'FETCH_TRANSPORT_FAILURE';
// export const fetchTransportFailure = createAction(FETCH_TRANSPORT_FAILURE);

// export const FETCH_TRANSPORT_REQUESTED = 'FETCH_TRANSPORT_REQUESTED';
// export const fetchTransportRequested = createAction(FETCH_TRANSPORT_REQUESTED);

// export const FETCH_TRANSPORT_SUCCESS = 'FETCH_TRANSPORT_SUCCESS';
// export const fetchTransportSuccess = createAction(FETCH_TRANSPORT_SUCCESS);

export const FETCH_STUDENT_PROFILE = 'FETCH_STUDENT_PROFILE';
export const fetchStudentProfile = createAction(FETCH_STUDENT_PROFILE);

export const FETCH_STUDENT_PROFILE_FAILURE = 'FETCH_STUDENT_PROFILE_FAILURE';
export const fetchStudentProfileFailure = createAction(FETCH_STUDENT_PROFILE_FAILURE);

export const FETCH_STUDENT_PROFILE_REQUESTED = 'FETCH_STUDENT_PROFILE_REQUESTED';
export const fetchStudentProfileRequested = createAction(FETCH_STUDENT_PROFILE_REQUESTED);

export const FETCH_STUDENT_PROFILE_SUCCESS = 'FETCH_STUDENT_PROFILE_SUCCESS';
export const fetchStudentProfileSuccess = createAction(FETCH_STUDENT_PROFILE_SUCCESS);

export const FETCH_INSTALLMENT_RECAP = 'FETCH_INSTALLMENT_RECAP';
export const fetchInstallmentRecap = createAction(FETCH_INSTALLMENT_RECAP);

export const FETCH_INSTALLMENT_RECAP_FAILURE = 'FETCH_INSTALLMENT_RECAP_FAILURE';
export const fetchInstallmentRecapFailure = createAction(FETCH_INSTALLMENT_RECAP_FAILURE);

export const FETCH_INSTALLMENT_RECAP_REQUESTED = 'FETCH_INSTALLMENT_RECAP_REQUESTED';
export const fetchInstallmentRecapRequested = createAction(FETCH_INSTALLMENT_RECAP_REQUESTED);

export const FETCH_INSTALLMENT_RECAP_SUCCESS = 'FETCH_INSTALLMENT_RECAP_SUCCESS';
export const fetchInstallmentRecapSuccess = createAction(FETCH_INSTALLMENT_RECAP_SUCCESS);

export const FETCH_INSTALLMENT_HISTORY = 'FETCH_INSTALLMENT_HISTORY';
export const fetchInstallmentHistory = createAction(FETCH_INSTALLMENT_HISTORY);

export const FETCH_INSTALLMENT_HISTORY_FAILURE = 'FETCH_INSTALLMENT_HISTORY_FAILURE';
export const fetchInstallmentHistoryFailure = createAction(FETCH_INSTALLMENT_HISTORY_FAILURE);

export const FETCH_INSTALLMENT_HISTORY_REQUESTED = 'FETCH_INSTALLMENT_HISTORY_REQUESTED';
export const fetchInstallmentHistoryRequested = createAction(FETCH_INSTALLMENT_HISTORY_REQUESTED);

export const FETCH_INSTALLMENT_HISTORY_SUCCESS = 'FETCH_INSTALLMENT_HISTORY_SUCCESS';
export const fetchInstallmentHistorySuccess = createAction(FETCH_INSTALLMENT_HISTORY_SUCCESS);

export const FETCH_TRANSPORT_RECAP = 'FETCH_TRANSPORT_RECAP';
export const fetchTransportRecap = createAction(FETCH_TRANSPORT_RECAP);

export const FETCH_TRANSPORT_RECAP_FAILURE = 'FETCH_TRANSPORT_RECAP_FAILURE';
export const fetchTransportRecapFailure = createAction(FETCH_TRANSPORT_RECAP_FAILURE);

export const FETCH_TRANSPORT_RECAP_REQUESTED = 'FETCH_TRANSPORT_RECAP_REQUESTED';
export const fetchTransportRecapRequested = createAction(FETCH_TRANSPORT_RECAP_REQUESTED);

export const FETCH_TRANSPORT_RECAP_SUCCESS = 'FETCH_TRANSPORT_RECAP_SUCCESS';
export const fetchTransportRecapSuccess = createAction(FETCH_TRANSPORT_RECAP_SUCCESS);

export const FETCH_TRANSPORT_HISTORY = 'FETCH_TRANSPORT_HISTORY';
export const fetchTransportHistory = createAction(FETCH_TRANSPORT_HISTORY);

export const FETCH_TRANSPORT_HISTORY_FAILURE = 'FETCH_TRANSPORT_HISTORY_FAILURE';
export const fetchTransportHistoryFailure = createAction(FETCH_TRANSPORT_HISTORY_FAILURE);

export const FETCH_TRANSPORT_HISTORY_REQUESTED = 'FETCH_TRANSPORT_HISTORY_REQUESTED';
export const fetchTransportHistoryRequested = createAction(FETCH_TRANSPORT_HISTORY_REQUESTED);

export const FETCH_TRANSPORT_HISTORY_SUCCESS = 'FETCH_TRANSPORT_HISTORY_SUCCESS';
export const fetchTransportHistorySuccess = createAction(FETCH_TRANSPORT_HISTORY_SUCCESS);

export const EXISTING_REGISTRATION_NUMBER = 'EXISTING_REGISTRATION_NUMBER';
export const existingRegistrationNumber = createAction(EXISTING_REGISTRATION_NUMBER);

export const EXISTING_REGISTRATION_NUMBER_FAILURE = 'EXISTING_REGISTRATION_NUMBER_FAILURE';
export const existingRegistrationNumberFailure = createAction(EXISTING_REGISTRATION_NUMBER_FAILURE);

export const EXISTING_REGISTRATION_NUMBER_REQUESTED = 'EXISTING_REGISTRATION_NUMBER_REQUESTED';
export const existingRegistrationNumberRequested = createAction(EXISTING_REGISTRATION_NUMBER_REQUESTED);

export const EXISTING_REGISTRATION_NUMBER_SUCCESS = 'EXISTING_REGISTRATION_NUMBER_SUCCESS';
export const existingRegistrationNumberSuccess = createAction(EXISTING_REGISTRATION_NUMBER_SUCCESS);

export const DELETE_PAYMENT_HISTORY = 'DELETE_PAYMENT_HISTORY';
export const deletePaymentHistory = createAction(DELETE_PAYMENT_HISTORY);

export const DELETE_PAYMENT_HISTORY_FAILURE = 'DELETE_PAYMENT_HISTORY_FAILURE';
export const deletePaymentHistoryFailure = createAction(DELETE_PAYMENT_HISTORY_FAILURE);

export const DELETE_PAYMENT_HISTORY_REQUESTED = 'DELETE_PAYMENT_HISTORY_REQUESTED';
export const deletePaymentHistoryRequested = createAction(DELETE_PAYMENT_HISTORY_REQUESTED);

export const DELETE_PAYMENT_HISTORY_SUCCESS = 'DELETE_PAYMENT_HISTORY_SUCCESS';
export const deletePaymentHistorySuccess = createAction(DELETE_PAYMENT_HISTORY_SUCCESS);

export const DELETE_TRANSPORT_HISTORY = 'DELETE_TRANSPORT_HISTORY';
export const deleteTransportHistory = createAction(DELETE_TRANSPORT_HISTORY);

export const DELETE_TRANSPORT_HISTORY_FAILURE = 'DELETE_TRANSPORT_HISTORY_FAILURE';
export const deleteTransportHistoryFailure = createAction(DELETE_TRANSPORT_HISTORY_FAILURE);

export const DELETE_TRANSPORT_HISTORY_REQUESTED = 'DELETE_TRANSPORT_HISTORY_REQUESTED';
export const deleteTransportHistoryRequested = createAction(DELETE_TRANSPORT_HISTORY_REQUESTED);

export const DELETE_TRANSPORT_HISTORY_SUCCESS = 'DELETE_TRANSPORT_HISTORY_SUCCESS';
export const deleteTransportHistorySuccess = createAction(DELETE_TRANSPORT_HISTORY_SUCCESS);
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUESTED,
  FETCH_REPORT_SUCCESS,
   } from '../actions/report-action-types';

const initialState = { 
  fetchReportStatus: 'pending',
  
  reports: [],
};

export default function Report(state = initialState, {
  payload, type,
}) {
  switch (type) {
     
 

    case FETCH_REPORT_FAILURE:
      return {
        ...state,
        fetchReportStatus: 'failure',
      };

    case FETCH_REPORT_REQUESTED:
      return {
        ...state,
        fetchReportStatus: 'fetching',
      };

    case FETCH_REPORT_SUCCESS:
      return {
        ...state,
        fetchReportStatus: 'success',
        reports: payload,
      };

     
   
    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { string } from 'prop-types';
import React from 'react';

const MarksInputRenderer = ({
  data, onChangeText,name
}) => {
  const ref = React.useRef(null);

  const handleFocus = (event) => event.target.select();

  return (
    <>
      <input
        ref={ref}
        name={name}
        className="line form-control col-12 px-2 text-center"
        style={{ borderBottom: '2px solid #e9ecef' }}
        defaultValue={data}
        onChange={onChangeText}
        onFocus={handleFocus}

      />
    </>
  );
};

MarksInputRenderer.propTypes = { className: string };

export default MarksInputRenderer;

import { createAction } from 'redux-actions';

export const FETCH_DAILY_ENTRIES = 'FETCH_DAILY_ENTRIES';
export const fetchDailyEntries = createAction(FETCH_DAILY_ENTRIES);

export const FETCH_DAILY_ENTRIES_FAILURE = 'FETCH_DAILY_ENTRIES_FAILURE';
export const fetchDailyEntriesFailure = createAction(FETCH_DAILY_ENTRIES_FAILURE);

export const FETCH_DAILY_ENTRIES_REQUESTED = 'FETCH_DAILY_ENTRIES_REQUESTED';
export const fetchDailyEntriesRequested = createAction(FETCH_DAILY_ENTRIES_REQUESTED);

export const FETCH_DAILY_ENTRIES_SUCCESS = 'FETCH_DAILY_ENTRIES_SUCCESS';
export const fetchDailyEntriesSuccess = createAction(FETCH_DAILY_ENTRIES_SUCCESS);

export const FETCH_DAILY_ENTRY_USERS = 'FETCH_DAILY_ENTRY_USERS';
export const fetchDailyEntriesUsers = createAction(FETCH_DAILY_ENTRY_USERS);

export const FETCH_DAILY_ENTRY_USERS_FAILURE = 'FETCH_DAILY_ENTRY_USERS_FAILURE';
export const fetchDailyEntriesUsersFailure = createAction(FETCH_DAILY_ENTRY_USERS_FAILURE);

export const FETCH_DAILY_ENTRY_USERS_REQUESTED = 'FETCH_DAILY_ENTRIES_REQUESTED';
export const fetchDailyEntriesUsersRequested = createAction(FETCH_DAILY_ENTRIES_REQUESTED);

export const FETCH_DAILY_ENTRY_USERS_SUCCESS = 'FETCH_DAILY_ENTRY_USERS_SUCCESS';
export const fetchDailyEntriesUsersSuccess = createAction(FETCH_DAILY_ENTRY_USERS_SUCCESS);

export const FETCH_GENERAL_RECAP = 'FETCH_GENERAL_RECAP';
export const fetchGeneralRecap = createAction(FETCH_GENERAL_RECAP);

export const FETCH_GENERAL_RECAP_FAILURE = 'FETCH_GENERAL_RECAP_FAILURE';
export const fetchGeneralRecapFailure = createAction(FETCH_GENERAL_RECAP_FAILURE);

export const FETCH_GENERAL_RECAP_REQUESTED = 'FETCH_DAILY_ENTRIES_REQUESTED';
export const fetchGeneralRecapRequested = createAction(FETCH_DAILY_ENTRIES_REQUESTED);

export const FETCH_GENERAL_RECAP_SUCCESS = 'FETCH_GENERAL_RECAP_SUCCESS';
export const fetchGeneralRecapSuccess = createAction(FETCH_GENERAL_RECAP_SUCCESS);

export const FETCH_CASHIER_DATA = 'FETCH_CASHIER_DATA';
export const fetchCashierData = createAction(FETCH_CASHIER_DATA);

export const FETCH_CASHIER_DATA_FAILURE = 'FETCH_CASHIER_DATA_FAILURE';
export const fetchCashierDataFailure = createAction(FETCH_CASHIER_DATA_FAILURE);

export const FETCH_CASHIER_DATA_REQUESTED = 'FETCH_CASHIER_DATA_REQUESTED';
export const fetchCashierDataRequested = createAction(FETCH_CASHIER_DATA_REQUESTED);

export const FETCH_CASHIER_DATA_SUCCESS = 'FETCH_CASHIER_DATA_SUCCESS';
export const fetchCashierDataSuccess = createAction(FETCH_CASHIER_DATA_SUCCESS);

export const FETCH_PERIOD_DATA = 'FETCH_PERIOD_DATA';
export const fetchPeriodData = createAction(FETCH_PERIOD_DATA);

export const FETCH_PERIOD_DATA_FAILURE = 'FETCH_PERIOD_DATA_FAILURE';
export const fetchPeriodDataFailure = createAction(FETCH_PERIOD_DATA_FAILURE);

export const FETCH_PERIOD_DATA_REQUESTED = 'FETCH_PERIOD_DATA_REQUESTED';
export const fetchPeriodDataRequested = createAction(FETCH_PERIOD_DATA_REQUESTED);

export const FETCH_PERIOD_DATA_SUCCESS = 'FETCH_PERIOD_DATA_SUCCESS';
export const fetchPeriodDataSuccess = createAction(FETCH_CASHIER_DATA_SUCCESS);

export const UPDATE_ENTRY_FORM = 'UPDATE_ENTRY_FORM';
export const updateEntryForm = createAction(UPDATE_ENTRY_FORM);

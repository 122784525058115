import {all, call, put, takeLatest} from 'redux-saga/effects';

import { ADD_QUARTERS,
    addQuartersRequested,
    addQuartersSuccess,
    addQuartersFailure,
    FETCH_QUARTERS,
    fetchQuartersRequested,
    fetchQuartersSuccess,
    fetchQuartersFailure,
} from '../actions/quarters-action-types';
import httpClient from "./http-client";

export function* addQuartersHandler({payload}){
    yield put(addQuartersRequested());

    const body = new FormData();

    body.append('quarters', JSON.stringify(payload.quarters));

    const request = {
        url: 'add-update-quarters',
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: body
    };

    const {error} = yield call(httpClient, request);

    if(error){
        yield put(addQuartersFailure(error));
    }else{
        yield put(addQuartersSuccess());
    }
}


export function* fetchQuartersHandler({payload}){
    yield put(fetchQuartersRequested());

    const request = {
        url: 'get-quarters',
        params: payload,
        method: 'GET'
    };

    const {data, error} = yield call(httpClient, request);

    if(error){
        yield put(fetchQuartersFailure(error));
    }
    else{
        yield put(fetchQuartersSuccess((data.data.result.length > 0)?data.data.result:[
            {
                id: 0,
                quater_name: '',
                percentage: '',
                editable: true,
                removed: false
            }
        ]));
    }
}


function* Quarter(){
    yield all([
        takeLatest(ADD_QUARTERS, addQuartersHandler),
        takeLatest(FETCH_QUARTERS, fetchQuartersHandler)
    ]);
}

export default Quarter;
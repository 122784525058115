import React from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database"
import "firebase/compat/messaging";
import "firebase/compat/storage";

import { getMessaging, getToken,onMessage } from 'firebase/messaging';
import { initializeApp} from "firebase/app";
//import { doc, setDoc } from "firebase/firestore";
/*import {
   // createUserWithEmailAndPassword,
    updateProfile
} from "firebase/auth";*/
/* +v9
import { getMessaging } from "firebase/messaging";
import { ref,getStorage } from "firebase/storage";
import { initializeApp} from "firebase/app";
import { getFirestore,serverTimestamp,Timestamp,arrayUnion,increment,collection,doc, setDoc,query, orderBy,getDocs    } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAuth,GoogleAuthProvider   } from "firebase/auth";
*/
//import { getMessaging,isSupported } from "firebase/messaging";
const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
/*
    apiKey: "AIzaSyDcRBszQ-q9cboL9D4w4sS2HY-3-lJdPg8",
    authDomain: "skulman-447e2.firebaseapp.com",
    projectId: "skulman-447e2",
    storageBucket: "skulman-447e2.appspot.com",
    messagingSenderId: "363855118322",
    appId: "1:363855118322:web:50d2bf38147c22995bb56b",
    measurementId: "G-M6NR9EHJ60"
    */
};/*use your own configuration*/
/*
const firebaseApp = initializeApp(firebaseConfig);
//you can enable persistence to allow the user to see previous data when he's offline but it will make your queries very slow which leads
//to bad user experience so I suggest you implement your own offline support by caching the data and retrieving them when the user is offline
//firebaseApp.firestore().enablePersistence();
const db = getFirestore(firebaseApp);
const db2 = getDatabase();
const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
const createTimestamp = serverTimestamp();
const createTimestamp2 = Timestamp ;//Timestamp.now()
const messaging1 = getMessaging(firebaseApp);
const messaging = "serviceWorker" in navigator && "PushManager" in window ?  getMessaging(firebaseApp) : null;
const fieldIncrement = increment;
//const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const storage = ref(getStorage(firebaseApp), "images");
const audioStorage = ref(getStorage(firebaseApp),"audios");
 */
const firebaseApp = firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
//const app = initializeApp(firebaseConfig);
/*you can enable persistence to allow the user to see previous data when he's offline but it will make your queries very slow which leads
to bad user experience so I suggest you implement your own offline support by caching the data and retrieving them when the user is offline*/
//firebaseApp.firestore().enablePersistence();
const db = firebaseApp.firestore();
const db2 = firebaseApp.database();
const auth = firebaseApp.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const createTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const createTimestamp2 = firebase.database.ServerValue.TIMESTAMP;
const messaging ="serviceWorker" in navigator && "PushManager" in window ?  firebase.messaging() : null;
const messaging1 = getMessaging(app);
//const messaging = async () => isSupported() && getMessaging(firebaseApp);
const fieldIncrement = firebase.firestore.FieldValue.increment;
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const storage = firebase.storage().ref("images");
const audioStorage = firebase.storage().ref("audios");

const VAPID_KEY=`${process.env.REACT_APP_FIREBASE_VAPID_KEY}`;
//db.disableNetwork();


// Method to sign in the user
/*const signIn = (email, password) =>{
    // const email = "xyz12@gmail.com"; // Arbitrary email
    // const password = "12345678";
        * auth
         .signInWithEmailAndPassword(email, password)
         .then((userCredential) => {
             // Signed in
             var user = userCredential.user;
   
             // ...
         })
         .catch((error) => {
             var errorCode = error.code;
             var errorMessage = error.message;
         });*
         signInWithEmailAndPassword(auth, email, password)
   .then((userCredential) => {
     // Signed in 
     const user = userCredential.user;
     console.log(user);
     // ...
   })
   .catch((error) => {
     const errorCode = error.code;
     const errorMessage = error.message;
     if (errorCode == 'user-not-found') {
         createUserWithEmailAndPassword(auth, email, password)
         .then((userCredential) => {
           // Signed in 
           const user = userCredential.user;
           console.log(user);
           // ...
         })
         .catch((error) => {
           const errorCode = error.code;
           const errorMessage = error.message;
           // ..
         });
       }
   });
 }*/
//db.disableNetwork();
// Method to sign out the user
const signOut=()=> {
    signOut(auth).then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
}

const  reqNotifPermissions =async (uid)=>{
/*const permission=await Notification.requestPermission();
if(permission ==='granted'){
  saveMessDeviceToken(uid);
console.log("get Notif");
  }else{
console.log('unable to get permission to notify');
db.collection("users").doc(uid).set({
  token: ""
}, { merge: true });
 }*/
 //const mess = firebase.messaging();
 /*messaging1
    .requestPermission()
    .then(() => {
       console.log("Have Permission");
       return messaging.getToken();
     })
    .then(token => {
       console.log("FCM Token:", token);
       db.collection("users").doc(uid).set({
        token: token
      }, { merge: true });
       //you probably want to send your new found FCM token to the
       //application server so that they can send any push
       //notification to you.
     })
    .catch(error => {
       if (error.code === "messaging/permission-blocked") {
          console.log("Please Unblock Notification Request Manually");
       } else {
          console.log("Error Occurred", error);
       }
       db.collection("users").doc(uid).set({
        token: ""
      }, { merge: true });
      });*/

      // Request the push notification permission from browser
      const status = await Notification.requestPermission();
      if (status && status === "granted") {
      // Get new token from Firebase
        const fcm_token = await getToken(messaging1, { vapidKey: VAPID_KEY });

        // Set token in our local storage
        if (fcm_token) {
          console.log("FCM Token:", fcm_token);
          db.collection("users").doc(uid).set({
           token: fcm_token
         }, { merge: true });
         // return fcm_token;
        }else {
          console.log("Error Occurred", status);
          if(uid){
          db.collection("users").doc(uid).set({
            token: ""
          }, { merge: true });
       }}
    
      }else {
        console.log("Error Occurred", status);
        if(uid){
        db.collection("users").doc(uid).set({
          token: ""
        }, { merge: true });
      }
     }
     
    
      

}

const  saveMessDeviceToken =async (uid)=>{
  
   // const msg=await messaging();
   // const fcmToken= getToken(msg, { vapidKey: VAPID_KEY });
   const fcmToken= firebase.messaging().getToken({ vapidKey: VAPID_KEY });
    if(fcmToken){
      db.collection("users").doc(uid).set({
        token: fcmToken
      }, { merge: true });
    
    }else{
      reqNotifPermissions(uid);
    }
  }
  

// Check the user sign in or not
const check =() =>{
    auth.onAuthStateChanged((user) => {
        if (user) {
            // User Signed In
            console.log("User Signed In!!");
        } else {
            // User is signed out
            console.log("User Signed out!!");
            // ...
        }
    });
}

const getLoggedInUser =() =>{
    var user = auth.currentUser;        
    return user;
}

const signIn = async (userConnected) =>{
    // const email = "xyz12@gmail.com"; // Arbitrary email
    // const password = "12345678";
    
    const password="P@ssw0rd02";
    const email = userConnected?.email;

    console.log(email);
    console.log(userConnected);
    const username0=email?.split("@")[0]; //((String)email0?.split("@")[0]).replace(".", " ");
    const username=username0?.replace(".", " ");

         auth
         .signInWithEmailAndPassword(email, password)
         .then(async (userCredential) => {
             // Signed in
             //var user = userCredential.user;
   
          /*   const update = await updateProfile(auth.currentUser, {
                displayName: username,
            });*/
            const update = {
                displayName: username,
               // photoURL: 'https://my-cdn.com/assets/user/123.png',
              };
              
             /* db.collection("users").doc(user.uid)
              .update({
                photoURL:url
              });*/
             
            const user = userCredential.user;
            localStorage.setItem(
                "FIREBASE_USER_CONNECTED",
                JSON.stringify(user),
              );
            await user.updateProfile(update);
           // await user.updatePhotoURL(userConnected.profile_picture);
          /* if(userConnected.profile_picture){
            const  avatarStgRef = firebase.storage().ref("profilePictures/" + user.uid + "/avatar.jpg");

// imgFile the Photo File.
avatarStgRef.put(userConnected.profile_picture).then(function(snapshot){
 snapshot.ref.getDownloadURL().then(function(url){  // Now I can use url
     user.updateProfile({
         photoURL: url       // <- URL from uploaded photo.
     }).then(function(){
         firebase.database().ref("profilePictures/" + user.uid).set({
           "photoUri": url   // <- URL from uploaded photo.
         });
     });
 });
});
           }*/
 console.log(user)
 db.collection("users").doc(user.uid).set({
    timestamp: createTimestamp(),
    photoURL: userConnected.profile_picture?  userConnected.profile_picture:user.photoURL ? user.photoURL : null,
    //photoURL: user.photoURL?  user.photoURL:userConnected.profile_picture ? userConnected.profile_picture : null,
    name: userConnected?.name,
    userID: user.uid,
    username: userConnected?.name,
   // email: userConnected?.email,
    
    school_id : userConnected?.school_id,
    user_id: userConnected?.userId,
    phoneNumber: userConnected?.phoneNumber?userConnected?.phoneNumber:null,
    numberType: 'MOBILE',
    user_type_name:userConnected?.user_type_name,
    department_name:userConnected?.department_name,
   // profile_picture: userConnected?.profile_picture,

}, { merge: true });
            /*setDoc(doc(db, "users", user.uid), {
                username: username,
                email: email,
                userId: user.uid,
                timestamp: new Date(),
                name: username,
            });*/
         })
         .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            if (errorCode == 'auth/user-not-found') {
                auth.createUserWithEmailAndPassword(email, password)
                .then(async (userCredential) => {
                  // Signed in 
                  const user = userCredential.user;
                  localStorage.setItem(
                    "FIREBASE_USER_CONNECTED",
                    JSON.stringify(user),
                  );
                 /* db.collection("users").doc(user.uid)
                  .update({
                    photoURL:url
                  });*/

                  await user.updateProfile(update);
                 // await user.updatePhotoURL(userConnected.profile_picture);
               /*  if(userConnected.profile_picture){
               const  avatarStgRef = firebase.storage().ref("profilePictures/" + user.uid + "/avatar.jpg");

// imgFile the Photo File.
avatarStgRef.put(userConnected.profile_picture).then(function(snapshot){
    snapshot.ref.getDownloadURL().then(function(url){  // Now I can use url
        user.updateProfile({
            photoURL: url       // <- URL from uploaded photo.
        }).then(function(){
            firebase.database().ref("profilePictures/" + user.uid).set({
              "photoUri": url   // <- URL from uploaded photo.
            });
        });
    });
});
                 }*/
                  console.log(user)
                  db.collection("users").doc(user.uid).set({
                     timestamp: createTimestamp(),
                     photoURL: userConnected.profile_picture?  userConnected.profile_picture:user.photoURL ? user.photoURL : null,
                     //photoURL: user.photoURL?  user.photoURL:userConnected.profile_picture ? userConnected.profile_picture : null,
                     name: userConnected?.name,
                     userID: user.uid,
                     username: userConnected?.name,
                    // email: userConnected?.email,
                     
                     school_id : userConnected?.school_id,
                     user_id: userConnected?.userId,
                     phoneNumber: userConnected?.phoneNumber?userConnected?.phoneNumber:null,
                     numberType: 'MOBILE',
                     user_type_name:userConnected?.user_type_name,
                     department_name:userConnected?.department_name,
                    // profile_picture: userConnected?.profile_picture,
                 
                 }, { merge: true });
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  // ..
                });
              }
         });
       
  
 }
 /*messaging.onMessage((payload) => {
  console.log('Message received. ', payload);
  // ...
});
 export const onMessager = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});*/

const onMessager = () =>
  new Promise((resolve) => {
    messaging.onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

 const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging1, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });


const requestForToken = async () => {
 
 /* return await getToken(messaging1, { vapidKey: VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
       // const f_user= localStorage.getItem("FIREBASE_USER_CONNECTED");
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
       /* if(f_user){
        db.collection("users").doc(f_user.uid).set({
          token: currentToken
        }, { merge: true });
      }*
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
       /* db.collection("users").doc(uid).set({
          token: ""
        }, { merge: true });*
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });*/

  return await firebase.messaging().getToken({ vapidKey:  VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        const f_user= localStorage.getItem("FIREBASE_USER_CONNECTED");
        if(f_user){
          db.collection("users").doc(f_user.uid).set({
            token: currentToken
          }, { merge: true });
        }
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// register service worker
/*if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
      const registration = await navigator.serviceWorker.register('../firebase-messaging-sw.js', {
          updateViaCache: 'none'
      });
      messaging1.useServiceWorker(registration);
      reqNotifPermissions();
  });
}*/

// loadFirebase, 
/*export {collection,doc, setDoc,query, orderBy ,getDocs ,auth , provider, createTimestamp, messaging, fieldIncrement, arrayUnion, storage, audioStorage, db2, createTimestamp2,signIn,getLoggedInUser,signOut,check};
export default db;*/

export {getLoggedInUser,onMessageListener,saveMessDeviceToken, reqNotifPermissions,requestForToken,onMessager,signIn,auth , provider, createTimestamp, messaging, fieldIncrement, arrayUnion, storage, audioStorage, db2, createTimestamp2};
export default db;
import { LOCATION_CHANGE } from 'connected-react-router';
import { CREATE_INSTALLMENT_FAILURE,
  CREATE_INSTALLMENT_REQUESTED,
  CREATE_INSTALLMENT_SUCCESS,
  DELETE_INSTALLMENT_FAILURE,
  DELETE_INSTALLMENT_REQUESTED,
  DELETE_INSTALLMENT_SUCCESS,
  EDIT_INSTALLMENT_FAILURE,
  EDIT_INSTALLMENT_REQUESTED,
  EDIT_INSTALLMENT_SUCCESS,
  FETCH_SINGLE_INSTALLMENT_FAILURE,
  FETCH_SINGLE_INSTALLMENT_REQUESTED,
  FETCH_SINGLE_INSTALLMENT_SUCCESS,
  FETCH_INSTALLMENT_FAILURE,
  FETCH_INSTALLMENT_REQUESTED,
  FETCH_INSTALLMENT_SUCCESS,
  UPDATE_INSTALLMENT_FORM,
  FETCH_INSTALLMENT_SEQ_FAILURE,
  FETCH_INSTALLMENT_SEQ_REQUESTED,
  FETCH_INSTALLMENT_SEQ_SUCCESS, } from '../actions/installment-action-type';

const initialState = {
  createInstallmentStatus: 'pending',
  deleteInstallmentStatus: 'pending',
  fetchInstallmentsStatus: 'pending',
  fetchInstallmentsSeqStatus: 'pending',
  fetchSingleInstallmentStatus: 'pending',
  form: {
    amount_to_pay: '',
    deadline_installment: null,
    description: '',
    grade_id: '',
    installment: '',
    payble_in_kind: false,
    school_year_id: '',
    sequence_no: '',
    status: null,
  },
  installments: [],
  installments_seq: [],
  status: '',
};

export default function installments(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case CREATE_INSTALLMENT_FAILURE:
      return {
        ...state,
        createInstallmentStatus: 'failure',
      };

    case CREATE_INSTALLMENT_REQUESTED:
      return {
        ...state,
        createInstallmentStatus: 'creating',
      };

    case CREATE_INSTALLMENT_SUCCESS:
      return {
        ...state,
        createInstallmentStatus: 'success',
      };

    case DELETE_INSTALLMENT_FAILURE:
      return {
        ...state,
        deleteInstallmentStatus: 'failure',
      };

    case DELETE_INSTALLMENT_REQUESTED:
      return {
        ...state,
        deleteInstallmentStatus: 'deleting',
      };

    case DELETE_INSTALLMENT_SUCCESS:
      return {
        ...state,
        deleteInstallmentStatus: 'success',
        installments: [...state.installments].filter((installment) => !(payload.toString().includes(installment.installment_id.toString()))),

      };

      case EDIT_INSTALLMENT_FAILURE:
        return {
          ...state,
          createInstallmentStatus: 'failure',
          status : payload,
        };
  
      case EDIT_INSTALLMENT_REQUESTED:
        return {
          ...state,
          createInstallmentStatus: 'creating',
        };
  
      case EDIT_INSTALLMENT_SUCCESS:
        return {
          ...state,
          createInstallmentStatus: 'success',
        };
  
    case FETCH_INSTALLMENT_FAILURE:
      return {
        ...state,
        fetchInstallmentsStatus: 'failure',
      };

    case FETCH_INSTALLMENT_REQUESTED:
      return {
        ...state,
        fetchInstallmentsStatus: 'fetching',
      };

    case FETCH_INSTALLMENT_SUCCESS:
      return {
        ...state,
        fetchInstallmentsStatus: 'success',
        installments: payload,
      };

      case FETCH_INSTALLMENT_SEQ_FAILURE:
        return {
          ...state,
          fetchInstallmentsSeqStatus: 'failure',
        };
  
      case FETCH_INSTALLMENT_SEQ_REQUESTED:
        return {
          ...state,
          fetchInstallmentsSeqStatus: 'fetching',
        };
  
      case FETCH_INSTALLMENT_SEQ_SUCCESS:
        return {
          ...state,
          fetchInstallmentsSeqStatus: 'success',
          installments_seq: payload,
        };

    case FETCH_SINGLE_INSTALLMENT_FAILURE:
      return {
        ...state,
        fetchSingleInstallmentStatus: 'failure',
      };

    case FETCH_SINGLE_INSTALLMENT_REQUESTED:
      return {
        ...state,
        fetchSingleInstallmentStatus: 'fetching',
      };

    case FETCH_SINGLE_INSTALLMENT_SUCCESS:
      return {
        ...state,
        fetchSingleInstallmentStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };

    case UPDATE_INSTALLMENT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

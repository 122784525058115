import { string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { downloadFile } from '../../actions/file-downloader-action-types';

const TransportHistoryCellRenderer = ({ className }) => {
  const dispatch = useDispatch();
  const params = useParams(); 
   const location = useLocation();

  const transportHistoryExport = () => {
    const student_id = params.publicId;

    const request = {
      filename: 'transport-receipt.pdf',
      params: student_id,
      url: `transport-receipt/${student_id}`,
    };

    dispatch(downloadFile(request));
  };

  return (
    <>
      <div>
        <button className={`${className}`} type="button" onClick={transportHistoryExport}>
          <i className="pe-7s-print" data-toggle="tooltip" data-placement="top" title=" " data-original-title="" />
          <span> Transport Report</span>
        </button>
      </div>
    </>
  );
};

TransportHistoryCellRenderer.propTypes = { className: string };

TransportHistoryCellRenderer.defaultProps = { className: 'badge  badge-success' };

export default TransportHistoryCellRenderer;

import { createAction } from 'redux-actions';

 export const ADD_EXAM_ATTENDANCE = 'ADD_EXAM_ATTENDANCE';
 export const addExamAttendance = createAction(ADD_EXAM_ATTENDANCE);

export const ADD_EXAM_ATTENDANCE_FAILURE = 'ADD_EXAM_ATTENDANCE_FAILURE';
export const addExamAttendanceFailure = createAction(ADD_EXAM_ATTENDANCE_FAILURE);

export const ADD_EXAM_ATTENDANCE_REQUESTED = 'ADD_EXAM_ATTENDANCE_REQUESTED';
export const addExamAttendanceRequested = createAction(ADD_EXAM_ATTENDANCE_REQUESTED);

export const ADD_EXAM_ATTENDANCE_SUCCESS = 'ADD_EXAM_ATTENDANCE_SUCCESS';
export const addExamAttendanceSuccess = createAction(ADD_EXAM_ATTENDANCE_SUCCESS);

export const DELETE_EXAM_ATTENDANCE = 'DELETE_EXAM_ATTENDANCE';
export const deleteAttendance = createAction(DELETE_EXAM_ATTENDANCE);

export const DELETE_EXAM_ATTENDANCE_FAILURE = 'DELETE_EXAM_ATTENDANCE_FAILURE';
export const deleteExamAttendanceFailure = createAction(DELETE_EXAM_ATTENDANCE_FAILURE);

export const DELETE_EXAM_ATTENDANCE_REQUESTED = 'DELETE_EXAM_ATTENDANCE_REQUESTED';
export const deleteExamAttendanceRequested = createAction(DELETE_EXAM_ATTENDANCE_REQUESTED);

export const DELETE_EXAM_ATTENDANCE_SUCCESS = 'DELETE_EXAM_ATTENDANCE_SUCCESS';
export const deleteExamAttendanceSuccess = createAction(DELETE_EXAM_ATTENDANCE_SUCCESS);

export const FETCH_EXAM_ATTENDANCE = 'FETCH_EXAM_ATTENDANCE';
export const fetchExamAttendance = createAction(FETCH_EXAM_ATTENDANCE);

export const FETCH_EXAM_ATTENDANCE_FAILURE = 'FETCH_EXAM_ATTENDANCE_FAILURE';
export const fetchExamAttendanceFailure = createAction(FETCH_EXAM_ATTENDANCE_FAILURE);

export const FETCH_EXAM_ATTENDANCE_REQUESTED = 'FETCH_EXAM_ATTENDANCE_REQUESTED';
export const fetchExamAttendanceRequested = createAction(FETCH_EXAM_ATTENDANCE_REQUESTED);

export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS';
export const fetchExamAttendanceSuccess = createAction(FETCH_ATTENDANCE_SUCCESS);

export const EDIT_EXAM_ATTENDANCE = 'EDIT_EXAM_ATTENDANCE';
export const editExamAttendance = createAction(EDIT_EXAM_ATTENDANCE);

export const EDIT_ATTENDANCE_FAILURE = 'EDIT_ATTENDANCE_FAILURE';
export const editAttendanceFailure = createAction(EDIT_ATTENDANCE_FAILURE);

export const EDIT_EXAM_ATTENDANCE_REQUESTED = 'EDIT_EXAM_ATTENDANCE_REQUESTED';
export const editExamAttendanceRequested = createAction(EDIT_EXAM_ATTENDANCE_REQUESTED);

export const EDIT_ATTENDANCE_SUCCESS = 'EDIT_ATTENDANCE_SUCCESS';
export const editAttendanceSuccess = createAction(EDIT_ATTENDANCE_SUCCESS);

export const FETCH_SINGLE_EXAM_ATTENDANCE = 'FETCH_SINGLE_EXAM_ATTENDANCE';
export const fetchSingleExamAttendance = createAction(FETCH_SINGLE_EXAM_ATTENDANCE);

export const FETCH_SINGLE_ATTENDANCE_FAILURE = 'FETCH_SINGLE_ATTENDANCE_FAILURE';
export const fetchSingleAttendanceFailure = createAction(FETCH_SINGLE_ATTENDANCE_FAILURE);

export const FETCH_SINGLE_EXAM_ATTENDANCE_REQUESTED = 'FETCH_SINGLE_EXAM_ATTENDANCE_REQUESTED';
export const fetchSingleExamAttendanceRequested = createAction(FETCH_SINGLE_EXAM_ATTENDANCE_REQUESTED);

export const FETCH_SINGLE_ATTENDANCE_SUCCESS = 'FETCH_SINGLE_ATTENDANCE_SUCCESS';
export const fetchSingleAttendanceSuccess = createAction(FETCH_SINGLE_ATTENDANCE_SUCCESS);

export const UPDATE_EXAM_ATTENDANCE_FORM = 'UPDATE_EXAM_ATTENDANCE_FORM';
export const updateExamAttendanceForm = createAction(UPDATE_EXAM_ATTENDANCE_FORM);

export const FETCH_TEACHER_ATTENDANCE = 'FETCH_TEACHER_ATTENDANCE';
export const fetchTeacherAttendance = createAction(FETCH_TEACHER_ATTENDANCE);

export const FETCH_TEACHER_ATTENDANCE_FAILURE = 'FETCH_TEACHER_ATTENDANCE_FAILURE';
export const fetchTeacherAttendanceFailure = createAction(FETCH_TEACHER_ATTENDANCE_FAILURE);

export const FETCH_TEACHER_ATTENDANCE_REQUESTED = 'FETCH_TEACHER_ATTENDANCE_REQUESTED';
export const fetchTeacherAttendanceRequested = createAction(FETCH_TEACHER_ATTENDANCE_REQUESTED);

export const FETCH_TEACHER_ATTENDANCE_SUCCESS = 'FETCH_TEACHER_ATTENDANCE_SUCCESS';
export const fetchTeacherAttendanceSuccess = createAction(FETCH_TEACHER_ATTENDANCE_SUCCESS);

export const SET_INIT_STATE = 'SET_INIT_STATE';
export const setInitState = createAction(SET_INIT_STATE);

export const FETCH_TEACHER_ATTENDANCE_FILTER = 'FETCH_TEACHER_ATTENDANCE_FILTER';
export const fetchTeacherAttendanceFilter = createAction(FETCH_TEACHER_ATTENDANCE_FILTER);

export const FETCH_TEACHER_ATTENDANCE_FILTER_SUCCESS = 'FETCH_TEACHER_ATTENDANCE_FILTER_SUCCESS';
export const fetchTeacherAttendanceFilterSuccess = createAction(FETCH_TEACHER_ATTENDANCE_FILTER_SUCCESS);


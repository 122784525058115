import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_CHAPTER,
  COMBINE_CHAPTER,
  EDIT_COMBINE_CHAPTER,
  addChapterFailure,
  addChapterRequested,
  addChapterSuccess,
  DELETE_CHAPTER,
  deleteChapterFailure,
  deleteChapterRequested,
  deleteChapterSuccess,
  FETCH_CHAPTER,
  fetchChapterFailure,
  fetchChapterRequested,
  fetchChapterSuccess,
  FETCH_SINGLE_CHAPTER,
  fetchSingleChapterFailure,
  fetchSingleChapterRequested,
  fetchSingleChapterSuccess,
  EDIT_CHAPTER,
  editChapterFailure,
  editChapterRequested,
  editChapterSuccess,
  FETCH_CHAPTER_FILTER,
  fetchChapterFilterFailure,
  fetchChapterFilterRequested,
  fetchChapterFilterSuccess,
  DELETE_CHAPTER_FORCAST,
  EDIT_COMBINE_PART,
  ADD_SINGLE_PART_FORCAST,
  UPDATE_SINGLE_PART_FORCAST,
} from '../actions/chapter-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import i18next from 'i18next';
import { addForecastFailure, addForecastRequested, addForecastSuccess, deleteForecastFailure, deleteForecastRequested, deleteForecastSuccess, editForecastFailure, editForecastSuccess, fetchSingleForecastFailure, fetchSingleForecastRequested } from '../actions/forecast-action-types';
import { addPartFailure, addPartRequested, deletePartFailure, deletePartRequested, deletePartSuccess, editPartFailure, editPartRequested, fetchPartFailure } from '../actions/part-action-types';

export function* addChapterHandler({ payload }) {
  yield put(addChapterRequested());
  const body = new FormData();

  body.append('chapter_number', payload.chapter_number);
  body.append('created_date', payload.created_date);
  body.append('chapter_title', payload.chapter_title);
  body.append('school_year_id', payload.school_year_id);
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-chapter',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addChapterFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-chapters');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.chapters.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addChapterSuccess());
  }
}



export function* combineChapterHandler({ payload }) {
  console.log("payloaddddd",payload)
  yield put(addChapterRequested());
  const body = new FormData();

  body.append('chapter_number', payload.chapter_number);
  body.append('created_date', payload.created_date);
  body.append('chapter_title', payload.chapter_title);
  body.append('school_year_id', payload.school_year_id);
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-chapter',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addChapterFailure(error));
  } else {
    const request = {
      method: 'GET',
      params: { school_year_id: payload.school_year_id },
      url: 'chapters',
    };
  
    const { data, error } = yield call(httpClient, request);
  
    if (error) {
      yield put(fetchChapterFailure(error));
    } else {
      let selectedData = data?.data?.result?.filter((item)=> {return item.chapter_number==payload.chapter_number&&item.subject_id==payload.subject_id.value})
      console.log("selectedData",selectedData)
      let chapter_id = selectedData[0].id
      
      for (var i=0; i<payload.part.length; i++) {
        const partbody = new FormData();
      partbody.append('grade_id', payload.grade_id.value);
      partbody.append('subject_id', payload.subject_id.value);
      partbody.append('chapter_id', chapter_id);
      partbody.append('part_number', payload.part[i].partNumber);
      partbody.append('created_date', payload.created_date);
      partbody.append('part_name', payload.part[i].partTitle);
      partbody.append('school_year_id', payload.school_year_id);

  const request = {
    data: partbody,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-part',
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(addPartFailure(error));
  }
    }

  if (error) {
    yield put(addPartFailure(error));
  } else {
    console.log("we are in part")
    const request = {
      method: 'GET',
      params: {
        grade_id: payload.grade_id.value,
        subject_id: payload.subject_id.value,
       // chapter_id: chapter_id,
        chapter_id: chapter_id ,
        school_year_id: payload.school_year_id,
      },
      url: `/part-filter`,
    };
  
    const { data, error } = yield call(httpClient, request);
  
    if (error) {
      yield put(fetchPartFailure(error));
    } else {
      console.log("partDatatata",data.data)
      let newPart = []
      for (var i=0; i<payload?.part?.length; i++){
        console.log("first loop")
        for (var j=0; j<data?.data?.result?.length; j++){
          console.log("second loop")
          if(payload?.part[i].partNumber===data?.data?.result[j].part_number){
            let obj = {
             ...payload?.part[i],
             part_id:data?.data?.result[j].id
            }
            newPart.push(obj)
           }
        }
      }
      // payload.part.map((item)=>{
      //   data.data.map((childItem)=>{
      //     if(item.partNumber===childItem.part_number){
      //      let obj = {
      //       ...item,
      //       part_id:childItem.id
      //      }
      //      newPart.push(obj)
      //     }

      //   })

      // })
console.log("newParttttttttttttttt",newPart)

      for (var i=0; i<newPart.length; i++) {
        console.log("inside add forcast",newPart)
        const forcastbody = new FormData();
        forcastbody.append('grade_id', payload.grade_id.value);
        forcastbody.append('week_number_id', newPart[i].weekState.value);
        forcastbody.append('comment', payload.comment);
        forcastbody.append('part_id',newPart[i].part_id);
        forcastbody.append('school_year_id', payload.school_year_id);
        forcastbody.append('quater_id', newPart[i].quarter.value);
        forcastbody.append('subject_id', payload.subject_id.value);
        forcastbody.append('chapter_id', chapter_id);

        const request = {
          data: forcastbody,
          headers: { 'Content-Type': 'multiForecast/form-data' },
          method: 'POST',
          url: 'add-forecast',
        };
      
        const { error } = yield call(httpClient, request);
      
        if (error) {
          yield put(addForecastFailure(error));
        }
    }

      // const forcastbody = new FormData();
      // forcastbody.append('grade_id', payload.grade_id.value);
      // forcastbody.append('week_number_id', payload.week_number_id.value);
      // forcastbody.append('comment', payload.comment);
      // forcastbody.append('part_id', part_id);
      // forcastbody.append('grade_id', payload.grade_id.value);
      // forcastbody.append('school_year_id', payload.school_year_id);
      // forcastbody.append('quater_id', payload.quater_id.value);
      // forcastbody.append('subject_id', payload.subject_id.value);
      // forcastbody.append('chapter_id', chapter_id);

  // const request = {
  //   data: body,
  //   headers: { 'Content-Type': 'multiForecast/form-data' },
  //   method: 'POST',
  //   url: 'add-forecast',
  // };

  // const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addForecastFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-forecast');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.forecast.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addForecastSuccess());
  }
    }
  }
    }
  }
}




export function* EditcombineChapterHandler({ payload }) {
  console.log("payloaddddd",payload)
  yield put(editChapterRequested());
  const body = new FormData();

  body.append('chapter_number', payload.chapter_number);
  body.append('created_date', payload.created_date);
  body.append('chapter_title', payload.chapter_title);
  body.append('school_year_id', payload.school_year_id);
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-chapter/${payload.chapter_id}`,
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editChapterFailure(error));
  } else {
    const request = {
      method: 'GET',
      params: { school_year_id: payload.school_year_id },
      url: 'chapters',
    };
  
    const { data, error } = yield call(httpClient, request);
  
    if (error) {
      yield put(fetchChapterFailure(error));
    } else {
      let selectedData = data?.data?.result?.filter((item)=> {return item.chapter_number==payload.chapter_number})
      console.log("selectedData",selectedData)
      let chapter_id = selectedData[0].id
      
      for (var i=0; i<payload.part.length; i++) {
        const partbody = new FormData();
      partbody.append('grade_id', payload.grade_id.value);
      partbody.append('subject_id', payload.subject_id.value);
      partbody.append('chapter_id', chapter_id);
      partbody.append('part_number', payload.part[i].partNumber);
      partbody.append('created_date', payload.created_date);
      partbody.append('part_name', payload.part[i].partTitle);
      partbody.append('school_year_id', payload.school_year_id);
      if(payload.part[i].part_id.length===0){
        console.log("innn iff")
        const request = {
          data: partbody,
          headers: { 'Content-Type': 'multipart/form-data' },
          method: 'POST',
          url: 'add-part',
        };
        const { error } = yield call(httpClient, request);
        if (error) {
          yield put(editPartFailure(error));
        }
      }else{
        const request = {
          data: partbody,
          headers: { 'Content-Type': 'multipart/form-data' },
          method: 'POST',
          url: `update-part/${payload.part[i].part_id}`,
        };
        const { error } = yield call(httpClient, request);
        if (error) {
          yield put(editPartFailure(error));
        }
      }

 
  
    }

  if (error) {
    yield put(editPartFailure(error));
  } else {
    console.log("we are in part")
    const request = {
      method: 'GET',
      params: {
        grade_id: payload.grade_id.value,
        subject_id: payload.subject_id.value,
       // chapter_id: chapter_id,
        chapter_id: chapter_id ,
        school_year_id: payload.school_year_id,
      },
      url: `/part-filter`,
    };
  
    const { data, error } = yield call(httpClient, request);
    console.log("filterPartData",data.data.result)
   let filterPartData = data.data.result;
    let ForcastIdArray = [];
    for (var i=0; i<payload?.forcastId?.length; i++) {
      yield put(fetchSingleForecastRequested());
      const request = {
        method: 'GET',
        url: `get-forecast/${payload?.forcastId[i]?.id}`,
      };
    
      const { data, error } = yield call(httpClient, request);
      console.log("forcastData",data)
      if (error) {
        yield put(fetchSingleForecastFailure(error));
      } else {
        let obj ={
          part_number:data?.data?.result?.part_number,
          forcastId:data?.data?.result?.id,
          part_id:data?.data?.result?.part_id
        }
        ForcastIdArray.push(obj)
      }
    }
    console.log("ForcastIdArray",ForcastIdArray)
  
    if (ForcastIdArray?.length===0 ) {
      yield put(fetchSingleForecastFailure(error));
    } else {
      console.log("partDatatata",data?.result?.part_id,data?.result?.part_id)
      let newPart = []
      for (var i=0; i<filterPartData?.length; i++){
        let found = null;
        console.log("first loop")
        for (var j=0; j<ForcastIdArray?.length; j++){
          console.log("second loop")
          if(filterPartData[i]?.part_number===ForcastIdArray[j]?.part_number){
            found = ForcastIdArray[j].forcastId
            // let obj = {
            //  ...filterPartData[i],
            // //  part_id:filterPartData[j].part_id,
            //  forcastId:ForcastIdArray[j].forcastId,
            // }
            // newPart.push(obj)
           }
          //  else{
          //   let obj = {
          //     ...filterPartData[i],
          //     part_id:filterPartData[j].part_id,
          //     forcastId:null,
          //    }
          //    newPart.push(obj)
          //  }
        }
        if(found!==null){
          let obj = {
             ...filterPartData[i],
            //  part_id:filterPartData[j].part_id,
             forcastId:found,
            }
            newPart.push(obj)
        }
        else{
          let obj = {
                ...filterPartData[i],
                // part_id:filterPartData[j].part_id,
                forcastId:null,
               }
               newPart.push(obj)
        }
      }

      let NewForcastData = [];

      for (var i=0; i<payload?.part?.length; i++){
        let newFound = []
        for (var j=0; j<newPart?.length; j++){
          if(payload?.part[i]?.partNumber===newPart[j]?.part_number){
            let obj = {
                  ...payload?.part[i],
                  part_id:newPart[j].id,
                  forcastId:newPart[j].forcastId,
                 }
                 NewForcastData.push(obj)


            // if(newPart[j].forcastId!==null){
            //   let obj = {
            //     ...payload?.part[i],
            //     part_id:newPart[j].part_id,
            //     forcastId:newPart[j].forcastId,
            //    }
            //    NewForcastData.push(obj)
            // }else{
            //   let obj = {
            //     ...payload?.part[i],
            //    part_id:newPart[j].part_id,
            //     forcastId:null,
            //    }
            //    NewForcastData.push(obj)
            // }
            
           }
        }
      }
      // payload.part.map((item)=>{
      //   data.data.map((childItem)=>{
      //     if(item.partNumber===childItem.part_number){
      //      let obj = {
      //       ...item,
      //       part_id:childItem.id
      //      }
      //      newPart.push(obj)
      //     }

      //   })

      // })
console.log("newParttttttttttttttt",newPart)
console.log("newForcasttttttttt",NewForcastData)

      for (var i=0; i<NewForcastData.length; i++) {
        console.log("inside add forcast",NewForcastData)
        const forcastbody = new FormData();
        forcastbody.append('grade_id', payload?.grade_id?.value);
        forcastbody.append('week_number_id', NewForcastData[i]?.weekState?.value);
        forcastbody.append('comment', payload?.comment);
        forcastbody.append('part_id',NewForcastData[i]?.part_id);
        forcastbody.append('school_year_id', payload?.school_year_id);
        forcastbody.append('quater_id', NewForcastData[i]?.quarter?.value);
        forcastbody.append('subject_id', payload?.subject_id?.value);
        forcastbody.append('chapter_id', chapter_id);

console.log("NewForcastData?.forcastId",NewForcastData[i]?.forcastId,NewForcastData[i]?.forcastId!==null)
if(NewForcastData[i]?.forcastId!==null){
  const request = {
    data: forcastbody,
    headers: { 'Content-Type': 'multiForecast/form-data' },
    method: 'POST',
    url: `update-forecast/${NewForcastData[i]?.forcastId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    console.log("i am in error")
    yield put(editForecastFailure(error));
  }
}
else{
  console.log("add Fotrcast if")
  const request = {
    data: forcastbody,
    headers: { 'Content-Type': 'multiForecast/form-data' },
    method: 'POST',
    url: 'add-forecast',
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    console.log("i am in error")
    yield put(editForecastFailure(error));
  }
}
        

       
      
        
    }

      // const forcastbody = new FormData();
      // forcastbody.append('grade_id', payload.grade_id.value);
      // forcastbody.append('week_number_id', payload.week_number_id.value);
      // forcastbody.append('comment', payload.comment);
      // forcastbody.append('part_id', part_id);
      // forcastbody.append('grade_id', payload.grade_id.value);
      // forcastbody.append('school_year_id', payload.school_year_id);
      // forcastbody.append('quater_id', payload.quater_id.value);
      // forcastbody.append('subject_id', payload.subject_id.value);
      // forcastbody.append('chapter_id', chapter_id);

  // const request = {
  //   data: body,
  //   headers: { 'Content-Type': 'multiForecast/form-data' },
  //   method: 'POST',
  //   url: 'add-forecast',
  // };

  // const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editForecastFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-forecast');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.forecast.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editForecastSuccess());
  }
    }
  }
    }
  }
}


export function* SingleaddPartHandler({ payload }) {
  console.log("in addddd apii part",payload)
  yield put(addPartRequested());
  const body = new FormData();
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('chapter_id', payload.id);
  body.append('part_number', payload.part_number);
  body.append('created_date', payload.created_date);
  body.append('part_name', payload.part_name);
  body.append('school_year_id', payload.school_year_id);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-part',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addPartFailure(error));
  } else {
    const request = {
      method: 'GET',
      params: {
        grade_id: payload.grade_id.value,
        subject_id: payload.subject_id.value,
       // chapter_id: chapter_id,
        chapter_id: payload.id ,
        school_year_id: payload.school_year_id,
      },
      url: `/part-filter`,
    };
  
    const { data, error } = yield call(httpClient, request);
  
    if (error) {
      yield put(fetchPartFailure(error));
    } else {
      console.log("partDatatata",data.data)
      let newPart ;
      for (var i=0; i<data?.data?.result?.length; i++){
        if(data?.data?.result[i].part_number==payload.part_number){
          newPart=data?.data?.result[i]
        }
        // console.log("first loop")
        // for (var j=0; j<data?.data?.result?.length; j++){
        //   console.log("second loop")
        //   if(payload?.part[i].partNumber===data?.data?.result[j].part_number){
        //     let obj = {
        //      ...payload?.part[i],
        //      part_id:data?.data?.result[j].id
        //     }
        //     newPart.push(obj)
        //    }
        // }
      }
      console.log("newwwpartttt",newPart)
    yield put(addForecastRequested());
    const body = new FormData();
    body.append('grade_id', payload.grade_id.value);
    body.append('week_number_id', payload.week_number_id);
    body.append('comment', payload.comment);
    body.append('part_id', newPart.id);
    body.append('grade_id', payload.grade_id.value);
    body.append('school_year_id', payload.school_year_id);
    body.append('quater_id', payload.quater_id);
    body.append('subject_id', payload.subject_id.value);
    body.append('chapter_id', payload.id);
  
    const request = {
      data: body,
      headers: { 'Content-Type': 'multiForecast/form-data' },
      method: 'POST',
      url: 'add-forecast',
    };
  
    const { error } = yield call(httpClient, request);
  
    if (error) {
      yield put(addForecastFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/pedagogical-forecast');
        },
        icon: 'success',
        message: i18next.t('skullman:pedagogicalFollowUp.forecast.createMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(addForecastSuccess());
    }
  }
}
}
export function* SingleeditPartHandler({ payload }) {
  console.log("in editttt apii part",payload)
  yield put(editPartRequested());

  const body = new FormData();
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('chapter_id', payload.id);
  body.append('part_number', payload.part_number);
  body.append('created_date', payload.created_date);
  body.append('part_name', payload.part_name);
  body.append('school_year_id', payload.school_year_id);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-part/${payload.part_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editPartFailure(error));
  } else {
    const body = new FormData();
    body.append('grade_id', payload.grade_id.value);
    body.append('week_number_id', payload.week_number_id);
    body.append('comment', payload.comment);
    body.append('part_id', payload.part_id);
    body.append('grade_id', payload.grade_id.value);
    body.append('school_year_id', payload.school_year_id);
    body.append('quater_id', payload.quater_id);
    body.append('subject_id', payload.subject_id.value);
    body.append('chapter_id', payload.id);
    const request = {
      data: body,
      headers: { 'Content-Type': 'multiForecast/form-data' },
      method: 'POST',
      url: `update-forecast/${payload.forcastId}`,
    };
  
    const { error } = yield call(httpClient, request);
  
    if (error) {
      yield put(editForecastFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/pedagogical-forecast');
        },
        icon: 'success',
        message: i18next.t('skullman:pedagogicalFollowUp.forecast.updateMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(editForecastSuccess());
    }
  }
}


export function* EditPartCombineHandler({ payload }) {
      console.log("in apiiii",payload)
        const partbody = new FormData();
      partbody.append('grade_id', payload.grade_id.value);
      partbody.append('subject_id', payload.subject_id.value);
      partbody.append('chapter_id', payload.chapter_id);
      partbody.append('part_number', payload.part_number);
      partbody.append('created_date', payload.created_date);
      partbody.append('part_name', payload.part_name);
      partbody.append('school_year_id', payload.school_year_id);
      if(payload.part_id.length===0){
        console.log("innn iff")
        const request = {
          data: partbody,
          headers: { 'Content-Type': 'multipart/form-data' },
          method: 'POST',
          url: 'add-part',
        };
        const { error } = yield call(httpClient, request);
        if (error) {
          yield put(editPartFailure(error));
        }
      }
        
      

  else {

    const request = {
      data: partbody,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `update-part/${payload.part_id}`,
    };
    const { error } = yield call(httpClient, request);
    if (error) {
      yield put(editPartFailure(error));
    }
   
    let ForcastIdArray = [];
    for (var i=0; i<payload?.forcastId?.length; i++) {
      yield put(fetchSingleForecastRequested());
      const request = {
        method: 'GET',
        url: `get-forecast/${payload?.forcastId[i]?.id}`,
      };
    
      const { data, error } = yield call(httpClient, request);
      console.log("forcastData",data)
      if (error) {
        yield put(fetchSingleForecastFailure(error));
      } else {
        let obj ={
          ...data?.data?.result,
          forcastId:data?.data?.result?.id,
        }
        ForcastIdArray.push(obj)
      }
    }
 
  console.log("ForcastIdArray",ForcastIdArray)
    if (ForcastIdArray?.length===0 ) {
      yield put(fetchSingleForecastFailure(error));
    } else {
      let newPart ;
      ForcastIdArray.map((item)=>{
        console.log("item.part_id",item.part_id,payload.part_id)
        if(item.part_id==payload.part_id){
          newPart = item
        }
      })


console.log("newPartParttttt",newPart)

        const forcastbody = new FormData();
        forcastbody.append('grade_id', newPart?.grade_id);
        forcastbody.append('week_number_id', newPart?.week_number_id);
        forcastbody.append('comment', newPart?.comment);
        forcastbody.append('part_id',payload?.part_id);
        forcastbody.append('school_year_id', newPart?.school_year_id);
        forcastbody.append('quater_id', newPart?.quater_id);
        forcastbody.append('subject_id', newPart?.subject_id);
        forcastbody.append('chapter_id', newPart?.chapter_id);

if(newPart!==null){
  const request = {
    data: forcastbody,
    headers: { 'Content-Type': 'multiForecast/form-data' },
    method: 'POST',
    url: `update-forecast/${newPart?.forcastId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    console.log("i am in error")
    yield put(editForecastFailure(error));
  }
}
else{
  console.log("add Fotrcast if")
  const request = {
    data: forcastbody,
    headers: { 'Content-Type': 'multiForecast/form-data' },
    method: 'POST',
    url: 'add-forecast',
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    console.log("i am in error")
    yield put(editForecastFailure(error));
  }
}

  if (error) {
    yield put(editForecastFailure(error));
  } else {
    const alertProps = {
      // callback: () => {
      //   history.push('/pedagogical-forecast');
      // },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.forecast.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editForecastSuccess());
  }
    }
  }
    
  
}











export function* deleteChapterAndForecast({ payload }) {

  yield put(deletePartRequested());

  const request = {
    method: 'DELETE',
    url: `delete-part/${payload.part_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deletePartFailure(error));
  }else{
    const request = {
      method: 'GET',
      params: {
        grade_id: payload.grade_id.value,
        subject_id: payload.subject_id.value,
       // chapter_id: chapter_id,
        chapter_id: payload.chapter_id.value ,
        school_year_id: payload.school_year_id,
      },
      url: `/part-filter`,
    };
  
    const { data, error } = yield call(httpClient, request);
    console.log("filterPartData",data.data.result)
   let filterPartData = data.data.result;
if(filterPartData.length > 0){
  console.log("part deleted")
  const alertProps = {
    icon: 'success',
    message: i18next.t('skullman:pedagogicalFollowUp.part.deleteMessage'),
    title: i18next.t('skullman:common.successMessage'),
  };

  Alert.alert(alertProps);
  yield put(deletePartSuccess(payload.part_id));
}
else{
  console.log("chapter deleted")
  yield put(deleteChapterRequested());

  const request = {
    method: 'DELETE',
    url: `delete-chapter/${payload?.chapter_id?.value}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteChapterFailure(error));

  }
  else{
      const alertProps = {
        callback: () => {
          history.push('/pedagogical-forecast');
        },
        icon: 'success',
        message: i18next.t('skullman:pedagogicalFollowUp.forecast.deleteMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
  
      Alert.alert(alertProps);
      yield put(deleteForecastSuccess(payload));
  }
}

  }

 

  
}





export function* editChapterHandler({ payload }) {
  yield put(editChapterRequested());

  const body = new FormData();

  body.append('chapter_number', payload.chapter_number);
  body.append('created_date', payload.created_date);
  body.append('chapter_title', payload.chapter_title);
  body.append('school_year_id', payload.school_year_id);
  body.append('grade_id', payload.grade_id.value);
  body.append('subject_id', payload.subject_id.value);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-chapter/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editChapterFailure(error));
  } else {
    // const alertProps = {
    //   callback: () => {
    //     history.push({
    //       params: location?.param1,
    //       pathname: '/pedagogical-chapters',
    //     });
    //   },

    //   icon: 'success',
    //   message: i18next.t('skullman:pedagogicalFollowUp.chapters.updateMessage'),
    //   title: i18next.t('skullman:common.successMessage'),
    // };

    // Alert.alert(alertProps);
    // yield put(editChapterSuccess());
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-forecast');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.forecast.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editForecastSuccess());
  }
}

export function* deleteChapterHandler({ payload }) {
  yield put(deleteChapterRequested());

  const request = {
    method: 'DELETE',
    url: `delete-chapter/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteChapterFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.chapters.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteChapterSuccess(payload));
  }
}

export function* fetchChapterHandler({ payload }) {
  yield put(fetchChapterRequested());

  const request = {
    method: 'GET',
    params: { school_year_id: payload.school_year_id },
    url: 'chapters',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchChapterFailure(error));
  } else {
    yield put(fetchChapterSuccess(data.data.result || []));
  }
}

export function* fetchChapterFilterHandler({ payload }) {
  yield put(fetchChapterFilterRequested());
  console.log(payload);
  const grade_id = payload?.grade_id?.value;
  const subject_id = payload?.subject_id?.value;
  const class_id = payload?.class_id?.value;

  const request = {
    method: 'GET',
    params: {
      grade_id: grade_id,
      school_year_id: payload.school_year_id,
      subject_id: subject_id,
      class_id:class_id
    },
    url: `/chapter-filter`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchChapterFilterFailure(error));
  } else {
    yield put(fetchChapterFilterSuccess(data.data.result || []));
    if(payload.result)
     payload.result(data.data.result);
    
  }
}

export function* fetchSingleChapterHandler({ payload }) {
  yield put(fetchSingleChapterRequested());
  const request = {
    method: 'GET',
    url: `get-chapter/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleChapterFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      // chapter_number: {
      //   label: result.chapter_number,
      //   value: result.chapter_number,
      // },
      grade_id: {
        label: result.grade_name,
        value: result.grade_id,
      },
      subject_id: {
        label: result.subject_name,
        value: result.subject_id,
      },
      id: result.id,
    };

    yield put(fetchSingleChapterSuccess(res));
  }
}

function* Chapter() {
  yield all([
    takeLatest(ADD_CHAPTER, addChapterHandler),
    takeLatest(COMBINE_CHAPTER, combineChapterHandler),
    takeLatest(EDIT_COMBINE_CHAPTER, EditcombineChapterHandler),
    takeLatest(EDIT_COMBINE_PART, EditPartCombineHandler),
    takeLatest(DELETE_CHAPTER_FORCAST, deleteChapterAndForecast),
    takeLatest(DELETE_CHAPTER, deleteChapterHandler),
    takeLatest(FETCH_CHAPTER, fetchChapterHandler),
    takeLatest(FETCH_CHAPTER_FILTER, fetchChapterFilterHandler),
    takeLatest(FETCH_SINGLE_CHAPTER, fetchSingleChapterHandler),
    takeLatest(EDIT_CHAPTER, editChapterHandler),
    takeLatest(ADD_SINGLE_PART_FORCAST, SingleaddPartHandler),
    takeLatest(UPDATE_SINGLE_PART_FORCAST, SingleeditPartHandler),
  ]);
}

export default Chapter;

import React, { Fragment } from "react";
import MegaMenuFooter from "./Components/FooterMegaMenu";
//import FooterDots from "./Components/FooterDots";
import FooterMobile from "./Components/FooterMobile";

//class AppFooter extends React.Component {
 const AppFooter = ({user,activeDrawerMenu }) => {
 // render() {
    return (
      <Fragment>
        <div className="app-footer" style={{height:'71px'}}>
          <div className="app-footer__inner" style={{paddingLeft:'20px',paddingRight:'20px',paddingTop:'10px',paddingBottom:'10px',height:'100%'}}>
            <div className="app-footer-left" style={{height:'100%'}}>
              {/*<FooterDots />*/}
              <FooterMobile user={user} activeDrawerMenu={activeDrawerMenu}/>
            </div>
            <div className="app-footer-right">
             {/* <MegaMenuFooter />*/}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
//}

export default AppFooter;

import { LOCATION_CHANGE } from 'connected-react-router';
import { CREATE_OTHER_PARAMETERS_FAILURE,
  CREATE_OTHER_PARAMETERS_REQUESTED,
  CREATE_OTHER_PARAMETERS_SUCCESS,
  FETCH_SINGLE_OTHER_PARAMETERS_FAILURE,
  FETCH_SINGLE_OTHER_PARAMETERS_REQUESTED,
  FETCH_SINGLE_OTHER_PARAMETERS_SUCCESS,
  UPDATE_OTHER_PARAMETERS_FORM } from '../actions/other-parameters-action-types';

const initialState = {
  createOtherParameterStatus: 'pending',
  fetchSingleInstallmentStatus: 'pending',
  form: { no_of_days_before_being_almost_late_for_payment: '' },
  otherParameters: [],
};

export default function otherParameters(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case CREATE_OTHER_PARAMETERS_FAILURE:
      return {
        ...state,
        createOtherParameterStatus: 'failure',
      };

    case CREATE_OTHER_PARAMETERS_REQUESTED:
      return {
        ...state,
        createOtherParameterStatus: 'creating',
      };

    case CREATE_OTHER_PARAMETERS_SUCCESS:
      return {
        ...state,
        createOtherParameterStatus: 'success',
      };
    case FETCH_SINGLE_OTHER_PARAMETERS_FAILURE:
      return {
        ...state,
        fetchSingleInstallmentStatus: 'failure',
      };

    case FETCH_SINGLE_OTHER_PARAMETERS_REQUESTED:
      return {
        ...state,
        fetchSingleInstallmentStatus: 'fetching',
      };

    case FETCH_SINGLE_OTHER_PARAMETERS_SUCCESS:
      return {
        ...state,
        fetchSingleInstallmentStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };

    case UPDATE_OTHER_PARAMETERS_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

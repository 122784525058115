import {LOCATION_CHANGE} from "connected-react-router";
import {
    ADD_EXAMTYPE_REQUESTED,
    ADD_EXAMTYPE_SUCCESS,
    ADD_EXAMTYPE_FAILURE,
    FETCH_EXAMTYPE_REQUESTED,
    FETCH_EXAMTYPE_SUCCESS,
    FETCH_EXAMTYPE_FAILURE, ADD_EXAMTYPE
} from "../actions/typeofexam-action-types";

const initialState = {
    addUpdateExamTypeStatus: 'pending',
    fetchExamTypeStatus: 'pending',
    examTypes: [],
    form: {
        examTypes: []
    }
};

export default function ExamType(state = initialState, {
    payload, type
}){
    switch (type){
        case ADD_EXAMTYPE_REQUESTED:
            return {
                ...state,
                addUpdateExamTypeStatus: 'creating'
            }
        case ADD_EXAMTYPE_FAILURE:
            return {
                ...state,
                addUpdateExamTypeStatus: 'failure'
            }
        case ADD_EXAMTYPE_SUCCESS:
            return {
                ...state,
                addUpdateExamTypeStatus: 'success'
            }
        case FETCH_EXAMTYPE_REQUESTED:
            return {
                ...state,
                fetchExamTypeStatus: 'fetching'
            }
        case FETCH_EXAMTYPE_SUCCESS:
            return {
                ...state,
                examTypes: payload,
                fetchExamTypeStatus: 'success'
            }
        case FETCH_EXAMTYPE_FAILURE:
            return {
                ...state,
                fetchExamTypeStatus: 'failure'
            }
        case LOCATION_CHANGE:
            return {...initialState}
        default:
            return state;
    }
};
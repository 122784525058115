import { createAction } from 'redux-actions';

export const CREATE_OTHER_PARAMETERS = 'CREATE_OTHER_PARAMETERS';
export const createOtherParameters = createAction(CREATE_OTHER_PARAMETERS);

export const CREATE_OTHER_PARAMETERS_FAILURE = 'CREATE_OTHER_PARAMETERS_FAILURE';
export const createOtherParametersFailure = createAction(CREATE_OTHER_PARAMETERS_FAILURE);

export const CREATE_OTHER_PARAMETERS_REQUESTED = 'CREATE_OTHER_PARAMETERS_REQUESTED';
export const createOtherParametersRequested = createAction(CREATE_OTHER_PARAMETERS_REQUESTED);

export const CREATE_OTHER_PARAMETERS_SUCCESS = 'CREATE_OTHER_PARAMETERS_SUCCESS';
export const createOtherParametersSuccess = createAction(CREATE_OTHER_PARAMETERS_SUCCESS);

export const DELETE_OTHER_PARAMETERS = 'DELETE_OTHER_PARAMETERS';
export const deleteOtherParameters = createAction(DELETE_OTHER_PARAMETERS);

export const DELETE_OTHER_PARAMETERS_FAILURE = 'DELETE_OTHER_PARAMETERS_FAILURE';
export const deleteOtherParametersFailure = createAction(DELETE_OTHER_PARAMETERS_FAILURE);

export const DELETE_OTHER_PARAMETERS_REQUESTED = 'DELETE_OTHER_PARAMETERS_REQUESTED';
export const deleteOtherParametersRequested = createAction(DELETE_OTHER_PARAMETERS_REQUESTED);

export const DELETE_OTHER_PARAMETERS_SUCCESS = 'DELETE_OTHER_PARAMETERS_SUCCESS';
export const deleteOtherParametersSuccess = createAction(DELETE_OTHER_PARAMETERS_SUCCESS);

export const EDIT_OTHER_PARAMETERS = 'EDIT_OTHER_PARAMETERS';
export const editOtherParameters = createAction(EDIT_OTHER_PARAMETERS);

export const EDIT_OTHER_PARAMETERS_FAILURE = 'EDIT_OTHER_PARAMETERS_FAILURE';
export const editOtherParametersFailure = createAction(EDIT_OTHER_PARAMETERS_FAILURE);

export const EDIT_OTHER_PARAMETERS_REQUESTED = 'EDIT_OTHER_PARAMETERS_REQUESTED';
export const editOtherParametersRequested = createAction(EDIT_OTHER_PARAMETERS_REQUESTED);

export const EDIT_OTHER_PARAMETERS_SUCCESS = 'EDIT_OTHER_PARAMETERS_SUCCESS';
export const editOtherParametersSuccess = createAction(EDIT_OTHER_PARAMETERS_SUCCESS);

export const FETCH_SINGLE_OTHER_PARAMETERS = 'FETCH_SINGLE_OTHER_PARAMETERS';
export const fetchSingleOtherParameters = createAction(FETCH_SINGLE_OTHER_PARAMETERS);

export const FETCH_SINGLE_OTHER_PARAMETERS_FAILURE = 'FETCH_SINGLE_OTHER_PARAMETERS_FAILURE';
export const fetchSingleOtherParametersFailure = createAction(FETCH_SINGLE_OTHER_PARAMETERS_FAILURE);

export const FETCH_SINGLE_OTHER_PARAMETERS_REQUESTED = 'FETCH_SINGLE_OTHER_PARAMETERS_REQUESTED';
export const fetchSingleOtherParametersRequested = createAction(FETCH_SINGLE_OTHER_PARAMETERS_REQUESTED);

export const FETCH_SINGLE_OTHER_PARAMETERS_SUCCESS = 'FETCH_SINGLE_OTHER_PARAMETERS_SUCCESS';
export const fetchSingleOtherParametersSuccess = createAction(FETCH_SINGLE_OTHER_PARAMETERS_SUCCESS);

export const FETCH_OTHER_PARAMETERS = 'FETCH_OTHER_PARAMETERS';
export const fetchOtherParameters = createAction(FETCH_OTHER_PARAMETERS);

export const FETCH_OTHER_PARAMETERS_FAILURE = 'FETCH_OTHER_PARAMETERS_FAILURE';
export const fetchOtherParametersFailure = createAction(FETCH_OTHER_PARAMETERS_FAILURE);

export const FETCH_OTHER_PARAMETERS_REQUESTED = 'FETCH_OTHER_PARAMETERS_REQUESTED';
export const fetchOtherParametersRequested = createAction(FETCH_OTHER_PARAMETERS_REQUESTED);

export const FETCH_OTHER_PARAMETERS_SUCCESS = 'FETCH_OTHER_PARAMETERS_SUCCESS';
export const fetchOtherParametersSuccess = createAction(FETCH_OTHER_PARAMETERS_SUCCESS);

export const UPDATE_OTHER_PARAMETERS_FORM = 'UPDATE_OTHER_PARAMETERS_FORM';
export const updateOtherParametersForm = createAction(UPDATE_OTHER_PARAMETERS_FORM);

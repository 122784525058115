import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_BANK_FAILURE,
  ADD_BANK_REQUESTED,
  ADD_BANK_SUCCESS,
  DELETE_BANK_FAILURE,
  DELETE_BANK_REQUESTED,
  DELETE_BANK_SUCCESS,
  FETCH_BANK_FAILURE,
  FETCH_BANK_REQUESTED,
  FETCH_BANK_SUCCESS,
  FETCH_SINGLE_BANK_FAILURE,
  FETCH_SINGLE_BANK_REQUESTED,
  FETCH_SINGLE_BANK_SUCCESS,
  UPDATE_BANK_FORM
} from '../actions/bank-action-type';

const initialState = {
  createBankStatus: 'pending',
  deleteBankStatus: 'pending',
  fetchSingleBankStatus: 'pending',
  fetchBanksStatus: 'pending',
  form: {
    name: '',
    account_no: '',
    ifsc: '',
    pan_no: '',
  },
  banks: [],
};

export default function Banks(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_BANK_FAILURE:
      return {
        ...state,
        createBankStatus: 'failure',
      };

    case ADD_BANK_REQUESTED:
      return {
        ...state,
        createBankStatus: 'creating',
      };

    case ADD_BANK_SUCCESS:
      return {
        ...state,
        // class_list: payload.class_list,
        createBankStatus: 'success',
       // file: encodeURI(payload.image),
       // profile_picture: encodeURI(payload.image),
      };

    case DELETE_BANK_FAILURE:
      return {
        ...state,
        deleteBankStatus: 'failure',
      };

    case DELETE_BANK_REQUESTED:
      return {
        ...state,
        deleteBankStatus: 'deleting',
      };

    case DELETE_BANK_SUCCESS:
      return {
        ...state,
        deleteBankStatus: 'success',
        Banks: [...state.Banks].filter((bankItem) => !(payload.toString().includes(bankItem.id.toString()))),
      };

    case FETCH_BANK_FAILURE:
      return {
        ...state,
        fetchBanksStatus: 'failure',
      };

    case FETCH_BANK_REQUESTED:
      return {
        ...state,
        fetchBanksStatus: 'fetching',
      };

    case FETCH_BANK_SUCCESS:
      return {
        ...state,
        fetchBanksStatus: 'success',
        Banks: payload,
      };

    case FETCH_SINGLE_BANK_FAILURE:
      return {
        ...state,
        fetchSingleBankStatus: 'failure',
      };

    case FETCH_SINGLE_BANK_REQUESTED:
      return {
        ...state,
        fetchSingleBankStatus: 'fetching',
      };

    case FETCH_SINGLE_BANK_SUCCESS:
      return {
        ...state,
        fetchSingleBankStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.image),
          profile_picture: encodeURI(payload.image),
        },
      };

    case UPDATE_BANK_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

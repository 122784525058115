import React, { Fragment, useEffect, useState } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import HeaderLogo from "../AppLogo";

import SearchBox from "./Components/SearchBox";
import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";

import HeaderDots from "./Components/HeaderDots";
import { bool, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Select from 'react-select';
import LanguageSwitcher from "../../components/language-switcher";

import { toggleDrawer, toggleDrawerMenu } from '../../actions/app-action-types';
import { logoutSuccess } from '../../actions/user-action-types';
import {  setInitialState, setCurrentYearOnDb } from '../../actions/school-year-action-type';
import{ updateStudentTutorForm} from '../../actions/user-action-types';
import ProfileDropDown from '../../components/header/profile-dropdown';
import Utils from '../../utility';
import { isMobile } from 'react-device-detect';
import { Row, Col,Container,Button,Input,Label } from "reactstrap";
import Card from 'react-bootstrap/Card';
import { fetchStudentDetails } from '../../actions/students-action-types';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import noavatar from "../../assets/img/user.jpg";
import { useParams,useLocation } from "react-router-dom";
import { fetchSingleAttendance } from "../../actions/attendance-action-types";
import costReasonColDefs from "../../pages/cost-reason/columns";
import { fetchClasses } from "../../actions/classes-action-types";
import { fetchSingleExamAttendance } from "../../actions/attendance-exam-action-types";
import "react-toastify/ReactToastify.min.css";
import { NotificationCenter } from "../../components/Notification/NotificationCenter";
const Header = ({ isDrawerOpen, title, user, }) => {
  const dispatch = useDispatch();
  const {
    schoolYears, form,dtutorStudents
  } = useSelector((store) => (
    {
      form: store?.schoolYears?.form,
      schoolYears: store?.schoolYears?.schoolYears,
      dtutorStudents: store?.user?.userDetail?.studentsTutor,
    }));

  //const [selected, setSelected] = useState(tutorStudents[0].value);

  /*console.log('store')
  console.log(user)*/
  const { headerBackgroundColor, enableHeaderShadow, closedSmallerSidebar, enableMobileMenuSmall, } = useSelector((state) => (
    {
      enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
      closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
      headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
      enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    }));
    const {school_year_id}=form;
  

  //const [tutorStudents, setTutorStudents] = React.useState();
  const [isStudentList, setIsStudentList] = React.useState(false);
  const tutorStudents = JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID)) ? JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID)) : []
  const [isStudentIdSelected, setIsStudentIdSelected] = React.useState(false);
  const tutorStudentSelected = JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) ? JSON.parse(localStorage.getItem(process.env.MOBILE_STUDENT_ID_SELECTED)) : []
  const [defaultStudent, setDefaultStudent] = React.useState([]);
  const [classNameHeader,setClassNameHeader] = useState(null)
  const [headerName,setHeaderName] = useState(null)

  const [profile, setProfileVisibility] = React.useState(false);
  const iconClass = isDrawerOpen ? '' : 'is-active';
  const [t] = useTranslation('skullman');

  const { attendance, classes, examsubjectId} = useSelector((store) => ({
    attendance: store.Attendance.studentAttendance.attendanceSubject,
    classes: store.classes.classes,
    examsubjectId: store.Attendance.studentAttendance.attendanceExam,
  }));


  const location = useLocation()
  const params = useParams()
const headTitle = `headerTitle.${location.pathname}`
  console.log("params",params)
  const subjectId = params.subjectId
  const classId = params.classId
  const publicId = params.publicId
  // useEffect(() => {
  //   if(params.subjectId){
  //     dispatch(fetchSingleAttendance(id))
  //   }
  // }, [params])

  useEffect(() => {
    let url = location.pathname.replace(Object.values(params)[0],"").replace(Object.values(params)[1],"")
    setHeaderName(url)
  }, [location])

  useEffect(() => {
    if(attendance){
      setClassNameHeader(attendance.class_name)
    }
    else if(classId){
      const singleClass = classes?.filter((type) => type.class_id == classId);
      console.log("headerSingle",singleClass)
      setClassNameHeader(singleClass[0]?.class_name)
    }
    else if(subjectId){
      if(location.pathname.includes('exam')){
        const singleClass = classes?.filter((type) => type.class_id == examsubjectId?.class_id);
        console.log("headerSingleSubjectid",singleClass)
        setClassNameHeader(singleClass[0]?.class_name)
      }else{
        setClassNameHeader(attendance?.class_name)
      }
      
    }
    else if(publicId){
      const singleClass = classes?.filter((type) => type.class_id == publicId);
      console.log("headerSinglePublic",singleClass)
      setClassNameHeader(singleClass[0]?.class_name)
    }
  }, [attendance, classes,params])

  React.useEffect(() => {
    if (school_year_id && subjectId) {
      if(location.pathname.includes('exam')){
        dispatch(fetchSingleExamAttendance(subjectId));
      }
      dispatch(fetchSingleAttendance(subjectId));
    }
    dispatch(fetchClasses());
  }, [school_year_id]);
  

  console.log("classNameHeader",classNameHeader)
  console.log("headerName",headerName)
  console.log("attendance",attendance)

  React.useEffect(() => {
    if (dtutorStudents && dtutorStudents.length > 0) {
     // if(!tutorStudentSelected){
      if(!defaultStudent){
      localStorage.setItem(
        process.env.MOBILE_STUDENT_ID_SELECTED,
        JSON.stringify({
          label: dtutorStudents[0].sFirstName +' '+ dtutorStudents[0].sLastName +' '+ dtutorStudents[0].cClassName,
          value: dtutorStudents[0].sId,
        }),
      );

      setDefaultStudent({
        label: dtutorStudents[0].sFirstName +' '+ dtutorStudents[0].sLastName +' '+ dtutorStudents[0].cClassName,
        value: dtutorStudents[0].sId,
      });

      }
    }
     
  //  }
  }, []);


  const [form1, setForm1] = React.useState({
    //student_filter: '' 
  });
  const { student_filter } = form1;

  const onSelectChange = (name, value) => {
    var student_id = value.value;
    const updates = {
      ...form1,
      student_filter: value,
    };
   // console.log(tutorStudents)
//console.log(tutorStudents?.filter(element => element?.sId ==student_id))
    setForm1(updates);
    
    localStorage.setItem(
      process.env.MOBILE_STUDENT_ID_SELECTED,
      JSON.stringify(value),
    );

    const param = {
      student_id,
     // school_year_id,
    };
   /* console.log('param');
    console.log(param);*/

    const updates1 = {
      ...form1,
      studentTutor: tutorStudents?.filter(element => element?.sId ==student_id),
    };

   dispatch(updateStudentTutorForm(updates1));
  };

  React.useEffect(() => {
    if (tutorStudents && tutorStudents.length > 0) {
      var student_id = tutorStudents[0].sId;
      const updates = {
        ...form1,
        student_filter: {
          label: tutorStudents[0].sFirstName +' '+ tutorStudents[0].sLastName +' '+ tutorStudents[0].cClassName,
          value: tutorStudents[0].sId,
        },
       // school_year_id,
      };
      setForm1(updates);
     console.log({
      label: tutorStudents[0].sFirstName +' '+ tutorStudents[0].sLastName +' '+ tutorStudents[0].cClassName,
      value: tutorStudents[0].sId,
    })
    
     /* localStorage.setItem(
        process.env.MOBILE_STUDENT_ID_SELECTED,
        JSON.stringify({
          label: tutorStudents[0].sFirstName +' '+ tutorStudents[0].sLastName +' '+ tutorStudents[0].cClassName,
          value: tutorStudents[0].sId,
        }),
      );*/
     
      setIsStudentIdSelected(true);

      const param = {
        student_id,
        school_year_id,
      };

      const updates1 = {
        ...form1,
        studentTutor: tutorStudents[0],
        school_year_id,
        student_filter: student_filter

      };

     /* console.log('param');
      console.log(param);
      console.log(student_filter)*/
      setIsStudentList(true)
      //dispatch(fetchStudentDetails(param));
      dispatch(updateStudentTutorForm(updates1));
    }
  }, [isStudentList]);

  const onClick = () => dispatch(toggleDrawer(isDrawerOpen ? 'hidden' : 'visible'));

  const onLogout = () => {
    dispatch(setInitialState());
    dispatch(logoutSuccess());
    dispatch(push('/login'));
  };//console.log(user)
  return (
    <div>
      {!isMobile ? (
        <Fragment>
          <TransitionGroup>
            <CSSTransition component="div"
              className={cx("app-header", headerBackgroundColor, {
                "header-shadow": enableHeaderShadow,
              })}
              appear={true} timeout={1500} enter={false} exit={false}>
              <div>
                <HeaderLogo />

                <div className={cx("app-header__content", {
                  "header-mobile-open": enableMobileMenuSmall,
                })}>
                  <div className="app-header-left">
                  
                    <SearchBox />
                    {/* <MegaMenu />*/}

                  </div>

                  <div className="app-header-right">
                    {/* <HeaderDots />
                  <UserBox />*/}
                     {/* <NotificationCenter />*/}
                 
                    <LanguageSwitcher />
                    <ProfileDropDown
                      handleDrawerMenu={() => dispatch(toggleDrawerMenu(null))}
                      open={profile}
                      onClick={(payload) => setProfileVisibility(payload)}
                      onLogout={onLogout}
                      user={user}
                    />
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Fragment>
      ) :
        (
                  <Fragment>
          <TransitionGroup>
            <CSSTransition component="div"
              className={ !isMobile ? cx("app-header", headerBackgroundColor, {
                "header-shadow": enableHeaderShadow,
              }) : cx("app-header", headerBackgroundColor,"custom-mobile-header-height")}
              appear={true} timeout={1500} enter={false} exit={false}>
              <div>
                <HeaderLogo />

                <div className={!isMobile ? cx("app-header__content", {
                  "header-mobile-open": enableMobileMenuSmall,
                }) : cx("app-header__content", {
                  "header-mobile-open": enableMobileMenuSmall,
                },"custom-mobile-header-height")}>
                  <div className="app-header-left">
                  <div className="screen-title">
                  <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                  <div className="head-title">
                    <p style={{marginBottom:'0px',marginLeft:'10px',fontWeight:'bold',fontSize:'15px',color:'#000'}}>{headerName ? t([headerName],{name: classNameHeader}) : null}</p>
                  </div>
                    {/* <SearchBox /> */}
                    {/* <MegaMenu />*/}

                  </div>

                  <div className="app-header-right">
                    {/* <HeaderDots />
                  <UserBox />*/}
                 {/* <NotificationCenter />*/}
                    <LanguageSwitcher />
                    <ProfileDropDown
                      handleDrawerMenu={() => dispatch(toggleDrawerMenu(null))}
                      open={profile}
                      onClick={(payload) => setProfileVisibility(payload)}
                      onLogout={onLogout}
                      user={user}
                    />
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Fragment>
    //       <Fragment>
    //         <TransitionGroup>
    //           <CSSTransition component="div"
    //             className={cx("app-header", headerBackgroundColor, {
    //               "header-shadow": enableHeaderShadow,
    //             })}
    //             appear={true} timeout={1500} enter={false} exit={false}>
    //               {(user?.user_type_id === 5 || user?.user_type_id === 6 || user?.user_type_id === 7) ?(
    //             <Card>
    //               <Container style={{ 
    
    // marginTop: "10px"}}>
    //               <Row >
    //               <Col >
    //                   <img
    //                     width={40}
    //                     height={40}
    //                     className="rounded-circle"
    //                     src={encodeURI(tutorStudents?.sProfilePicture) || Utils.getImage('user-placeholder.png')}
    //                     alt="Profile"
    //                     onError={(e) => {e.target.src = noavatar}}
    //                     onClick={()=> dispatch(push('/my-profile'))}
    //                   />
    //                 </Col>
    //                 <Col xs={8}>
    //                   {defaultStudent && tutorStudents?.length > 0 ?
    //                     <Select
    //                       id="student_filter"
    //                       name="student_filter"
    //                       placeholder='Select Student'
    //                       className="student_input"
    //                       style={{control: (base) => ({
    //                         ...base,
    //                         minHeight: 'initial',
    //                         fontSize:'10px'
    //                       }),
    //                       valueContainer: (base) => ({
    //                         ...base,
    //                         fontSize:'xx-small'
    //                       }),
    //                       clearIndicator: (base) => ({
    //                         ...base,
    //                         padding: `${(30 - 20 - 1 - 1) / 2}px`,
    //                         fontSize:'10px'
    //                       }),
    //                       dropdownIndicator: (base) => ({
    //                         ...base,
    //                         padding: `${(30 - 20 - 1 - 1) / 2}px`,
    //                         fontSize:'10px'
    //                       })}}
    //                       onChange={(value) => onSelectChange('student_filter', value)}
    //                       options={
    //                         tutorStudents?.map((reason) => ({
    //                           label: `${reason.sFirstName}` + ' ' + `${reason.sLastName}` + ' '
    //                            + `${reason.cClassName}`,
    //                           value: reason.sId,
    //                         }))}
    //                       value={tutorStudentSelected || defaultStudent || student_filter}
    //                       width="100px"
    //                     />
    //                     : ''}
    //                 </Col>
    //                 <Col xs={2}>
    //                   <div className="pe-7s-power pe-2x icon-gradient bg-danger" onClick={onLogout} ></div>
    //                 </Col>
                    
    //               </Row>
    //               </Container>
    //             </Card>
    //               ):
    //               <Card>
    //               <Container style={{ 
    
    // marginTop: "10px"}}>
    //               <Row >
                  
    //               <Col >
    //                   <img
    //                     width={40}
    //                     height={40}
    //                     className="rounded-circle"
    //                     src={encodeURI(user?.profile_picture) || Utils.getImage('user-placeholder.png')}
    //                     alt="Profile"
    //                     onClick={()=> dispatch(push('/my-profile'))}
    //                     onError={(e) => {e.target.src = noavatar}}
    //                   />
    //                 </Col>
    //                 <Col xs={8}>
                      
    //                 <Label bsSize="sm" placeholder="sm">{user?.name } - {user?.user_type_name }</Label>
    //                {/* <Label bsSize="sm" placeholder="sm">{user?.user_type_name }</Label>  */} 
    //                 </Col>
                   
    //                 <Col xs={2}>
    //                   <div className="pe-7s-power pe-2x icon-gradient bg-danger" onClick={onLogout} ></div>
    //                 </Col>
                    
    //               </Row>
    //               </Container>
    //             </Card>
    //               }
    //           </CSSTransition>
    //         </TransitionGroup>
    //       </Fragment>
        )}
    </div>
  );
}

export default Header;

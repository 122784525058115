import React from "react";
import { string, number } from 'prop-types';
import { useDispatch } from "react-redux";
import { downloadMarksReport } from "../../actions/marks-report-request-action-types";

const DownloadReport = ({
    reportId,
    text,
    className,
    status
}) => {

    const dispatch = new useDispatch();

    const onDownloadReport = (e, reportId) => {

        const params = {
            'id': reportId
        };

        dispatch(downloadMarksReport(params));
    };

    if(status == "completed"){
        return (
            <button 
                className={`${className}`} 
                type="button"
                onClick={ (e) => onDownloadReport(e, reportId)}
                style={{ cursor: "pointer" }}
            >
                <i class="fa fa-download mr-2"></i> {text}
            </button>
        )
    }

    return (
        <span className={`${className}`}>{text}</span>
    );
}

DownloadReport.propTypes = {
    'reportId': number.isRequired,
    'className': string,
    'text': string,
    'status': string.isRequired
  };

DownloadReport.defaultProps = {
    'actionUrl': '',
    'className': 'btn btn-primary btn-sm',
    'text': 'Download'
};

export default DownloadReport;
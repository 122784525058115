import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_PROFESSIONAL_EXPERIENCE,
  addProfessionalExperienceFailure,
  addProfessionalExperienceRequested,
  addProfessionalExperienceSuccess,
  DELETE_PROFESSIONAL_EXPERIENCE,
  deleteProfessionalExperienceFailure,
  deleteProfessionalExperienceRequested,
  deleteProfessionalExperienceSuccess,
  EDIT_PROFESSIONAL_EXPERIENCE,
  editProfessionalExperienceFailure,
  editProfessionalExperienceRequested,
  editProfessionalExperienceSuccess,
  FETCH_SINGLE_PROFESSIONAL_EXPERIENCE,
  fetchSingleProfessionalExperienceFailure,
  fetchSingleProfessionalExperienceRequested,
  fetchSingleProfessionalExperienceSuccess,
  FETCH_PROFESSIONAL_EXPERIENCE,
  fetchProfessionalExperienceFailure,
  fetchProfessionalExperienceRequested,
  fetchProfessionalExperienceSuccess,
} from '../actions/professional-experience-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import i18next from "i18next";

export function* addProfessionalExperienceHandler({ payload }) {
  yield put(addProfessionalExperienceRequested());
  const body = new FormData();
  body.append('company_name', payload.form.company_name);
  body.append('location', payload.form.location);
  body.append('job_name', payload.form.job_name);
  body.append('from_date', payload.form.from_date);
  body.append('to_date', payload.form.to_date);
  body.append('employee_id', payload.form.employee_id);
  console.log(body);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-professional-experience',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addProfessionalExperienceFailure(error));
  } else {
    yield put(addProfessionalExperienceSuccess());
    const alertProps = {
      /*callback: () => {
        history.push('/employee');
      },*/
      icon: 'success',
      message: 'ProfessionalExperience created successfully.',
      title: 'Success',
    };

    Alert.alert(alertProps);
  }
}

export function* editProfessionalExperienceHandler({ payload }) {
  yield put(editProfessionalExperienceRequested());
  const request = {
    method: 'PUT',
    params: {
      company_name: payload.form.company_name,
      location: payload.form.location,
      job_name: payload.form.job_name,
      from_date: payload.form.from_date,
      to_date: payload.form.to_date,
      employee_id: payload.form.employee_id,
    },
    url: `update-professional-experience/${payload.experienceId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(editProfessionalExperienceFailure(error));
  } else {
    const alertProps = {
      /*callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/employee'});
      },*/
      icon: 'success',
      message: 'ProfessionalExperience updated successfully.',
      title: 'Success',
    };
    Alert.alert(alertProps);
    yield put(editProfessionalExperienceSuccess());

  }
}

export function* deleteProfessionalExperienceHandler({ payload }) {
  yield put(deleteProfessionalExperienceRequested());
  const request = {
    method: 'DELETE',
    url: `delete-professional-experience/${payload.idToDelete}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(deleteProfessionalExperienceFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:schoolStaff.employees.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };
    Alert.alert(alertProps);
    yield put(deleteProfessionalExperienceSuccess(payload.idToDelete));
  }
}

export function* fetchProfessionalExperienceHandler({ payload }) {
  yield put(fetchProfessionalExperienceRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'professional-experiences',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchProfessionalExperienceFailure(error));
  } else {
    yield put(fetchProfessionalExperienceSuccess(data.data.result));
  }
}

export function* fetchSingleProfessionalExperienceHandler({ payload }) {
  yield put(fetchSingleProfessionalExperienceRequested());
  const request = {
    method: 'GET',
    url: `get-professional-experience/${payload.publicId}`,
  };
  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleProfessionalExperienceFailure(error));
  } else {
    yield put(fetchProfessionalExperienceSuccess(data.data.result));
    if (payload?.result) {
      payload.result(data.data.result)
    }
  }
}

function* professionalExperience() {
  yield all([
    takeLatest(ADD_PROFESSIONAL_EXPERIENCE, addProfessionalExperienceHandler),
    takeLatest(DELETE_PROFESSIONAL_EXPERIENCE, deleteProfessionalExperienceHandler),
    takeLatest(EDIT_PROFESSIONAL_EXPERIENCE, editProfessionalExperienceHandler),
    takeLatest(FETCH_PROFESSIONAL_EXPERIENCE, fetchProfessionalExperienceHandler),
    takeLatest(FETCH_SINGLE_PROFESSIONAL_EXPERIENCE, fetchSingleProfessionalExperienceHandler),
  ]);
}

export default professionalExperience;

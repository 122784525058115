import { LOCATION_CHANGE } from 'connected-react-router';
import { FETCH_DAILY_ENTRIES_FAILURE,
  FETCH_DAILY_ENTRIES_REQUESTED,
  FETCH_DAILY_ENTRIES_SUCCESS,
  FETCH_DAILY_ENTRY_USERS_FAILURE,
  FETCH_DAILY_ENTRY_USERS_REQUESTED,
  FETCH_DAILY_ENTRY_USERS_SUCCESS,
  FETCH_GENERAL_RECAP_FAILURE,
  FETCH_GENERAL_RECAP_REQUESTED,
  FETCH_GENERAL_RECAP_SUCCESS,
  FETCH_CASHIER_DATA_FAILURE,
  FETCH_CASHIER_DATA_REQUESTED,
  FETCH_CASHIER_DATA_SUCCESS,
  FETCH_PERIOD_DATA_FAILURE,
  FETCH_PERIOD_DATA_REQUESTED,
  FETCH_PERIOD_DATA_SUCCESS,
  UPDATE_ENTRY_FORM } from '../actions/daily_entry_action_types';

const initialState = {
  cashierData: [],
  dailyEntryUsers: [],
  entries: [],
  fetchCashierDataStatus: 'pending',
  fetchDailyEntriesStatus: 'pending',
  fetchGeneralRecapStatus: 'pending',
  fetchPeriodDataStatus: 'pending',
  fetchUsersStatus: 'pending',
  form: {
    from: null,
    id: null,
    income_reason_id: null,
    school_year_id: null,
    to: null,
  },
  generalRecap: [],
  periodData: [],
};

export default function dailyEntries(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case FETCH_DAILY_ENTRIES_FAILURE:
      return {
        ...state,
        fetchDailyEntriesStatus: 'failure',
      };

    case FETCH_DAILY_ENTRIES_REQUESTED:
      return {
        ...state,
        fetchDailyEntriesStatus: 'fetching',
      };

    case FETCH_DAILY_ENTRIES_SUCCESS:
      return {
        ...state,
        fetchDailyEntriesStatus: 'success',
        generalRecap: payload,

      };

    case FETCH_GENERAL_RECAP_FAILURE:
      return {
        ...state,
        fetchGeneralRecapStatus: 'failure',
      };

    case FETCH_GENERAL_RECAP_REQUESTED:
      return {
        ...state,
        fetchGeneralRecapStatus: 'fetching',
      };

    case FETCH_GENERAL_RECAP_SUCCESS:
      return {
        ...state,
        entries: payload,
        fetchGeneralRecapStatus: 'success',
        // form: payload,

      };
    case FETCH_CASHIER_DATA_FAILURE:
      return {
        ...state,
        fetchCashierDataStatus: 'failure',
      };

    case FETCH_CASHIER_DATA_REQUESTED:
      return {
        ...state,
        fetchCashierDataStatus: 'fetching',
      };

    case FETCH_CASHIER_DATA_SUCCESS:
      return {
        ...state,
        cashierData: payload,
        fetchCashierDataStatus: 'success',
        // form: payload,

      };
    case FETCH_PERIOD_DATA_FAILURE:
      return {
        ...state,
        fetchPeriodDataStatus: 'failure',
      };

    case FETCH_PERIOD_DATA_REQUESTED:
      return {
        ...state,
        fetchPeriodDataStatus: 'fetching',
      };

    case FETCH_PERIOD_DATA_SUCCESS:
      return {
        ...state,
        fetchPeriodDataStatus: 'success',
        periodData: [...state.cashierData].map((item) => item.periodData),
        // form: payload,
      };
    case FETCH_DAILY_ENTRY_USERS_FAILURE:
      return {
        ...state,
        fetchUsersStatus: 'failure',
      };

    case FETCH_DAILY_ENTRY_USERS_REQUESTED:
      return {
        ...state,
        fetchUsersStatus: 'fetching',
      };

    case FETCH_DAILY_ENTRY_USERS_SUCCESS:
      return {
        ...state,
        dailyEntryUsers: payload,
        fetchUsersStatus: 'success',

      };

    case UPDATE_ENTRY_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { createAction } from 'redux-actions';

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const addEmployee = createAction(ADD_EMPLOYEE);

export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';
export const addEmployeeFailure = createAction(ADD_EMPLOYEE_FAILURE);

export const ADD_EMPLOYEE_REQUESTED = 'ADD_EMPLOYEE_REQUESTED';
export const addEmployeeRequested = createAction(ADD_EMPLOYEE_REQUESTED);

export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const addEmployeeSuccess = createAction(ADD_EMPLOYEE_SUCCESS);

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const deleteEmployee = createAction(DELETE_EMPLOYEE);

export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';
export const deleteEmployeeFailure = createAction(DELETE_EMPLOYEE_FAILURE);

export const DELETE_EMPLOYEE_REQUESTED = 'DELETE_EMPLOYEE_REQUESTED';
export const deleteEmployeeRequested = createAction(DELETE_EMPLOYEE_REQUESTED);

export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const deleteEmployeeSuccess = createAction(DELETE_EMPLOYEE_SUCCESS);

export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE';
export const fetchEmployee = createAction(FETCH_EMPLOYEE);

export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';
export const fetchEmployeeFailure = createAction(FETCH_EMPLOYEE_FAILURE);

export const FETCH_EMPLOYEE_REQUESTED = 'FETCH_EMPLOYEE_REQUESTED';
export const fetchEmployeeRequested = createAction(FETCH_EMPLOYEE_REQUESTED);

export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const fetchEmployeeSuccess = createAction(FETCH_EMPLOYEE_SUCCESS);

export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const editEmployee = createAction(EDIT_EMPLOYEE);

export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE';
export const editEmployeeFailure = createAction(EDIT_EMPLOYEE_FAILURE);

export const EDIT_EMPLOYEE_REQUESTED = 'EDIT_EMPLOYEE_REQUESTED';
export const editEmployeeRequested = createAction(EDIT_EMPLOYEE_REQUESTED);

export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const editEmployeeSuccess = createAction(EDIT_EMPLOYEE_SUCCESS);

export const FETCH_SINGLE_EMPLOYEE = 'FETCH_SINGLE_EMPLOYEE';
export const fetchSingleEmployee = createAction(FETCH_SINGLE_EMPLOYEE);

export const FETCH_SINGLE_EMPLOYEE_FAILURE = 'FETCH_SINGLE_EMPLOYEE_FAILURE';
export const fetchSingleEmployeeFailure = createAction(FETCH_SINGLE_EMPLOYEE_FAILURE);

export const FETCH_SINGLE_EMPLOYEE_REQUESTED = 'FETCH_SINGLE_EMPLOYEE_REQUESTED';
export const fetchSingleEmployeeRequested = createAction(FETCH_SINGLE_EMPLOYEE_REQUESTED);

export const FETCH_SINGLE_EMPLOYEE_SUCCESS = 'FETCH_SINGLE_EMPLOYEE_SUCCESS';
export const fetchSingleEmployeeSuccess = createAction(FETCH_SINGLE_EMPLOYEE_SUCCESS);

export const UPDATE_EMPLOYEE_FORM = 'UPDATE_EMPLOYEE_FORM';
export const updateEmployeeForm = createAction(UPDATE_EMPLOYEE_FORM);

export const DELETE_EMPLOYEE_CLASS = 'DELETE_EMPLOYEE_CLASS';
export const deleteEmployeeClass = createAction(DELETE_EMPLOYEE_CLASS);


import { createAction } from 'redux-actions';

export const ADD_EDUCATION = 'ADD_EDUCATION';
export const addEducation = createAction(ADD_EDUCATION);

export const ADD_EDUCATION_FAILURE = 'ADD_EDUCATION_FAILURE';
export const addEducationFailure = createAction(ADD_EDUCATION_FAILURE);

export const ADD_EDUCATION_REQUESTED = 'ADD_EDUCATION_REQUESTED';
export const addEducationRequested = createAction(ADD_EDUCATION_REQUESTED);

export const ADD_EDUCATION_SUCCESS = 'ADD_EDUCATION_SUCCESS';
export const addEducationSuccess = createAction(ADD_EDUCATION_SUCCESS);

export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const deleteEducation = createAction(DELETE_EDUCATION);

export const DELETE_EDUCATION_FAILURE = 'DELETE_EDUCATION_FAILURE';
export const deleteEducationFailure = createAction(DELETE_EDUCATION_FAILURE);

export const DELETE_EDUCATION_REQUESTED = 'DELETE_EDUCATION_REQUESTED';
export const deleteEducationRequested = createAction(DELETE_EDUCATION_REQUESTED);

export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const deleteEducationSuccess = createAction(DELETE_EDUCATION_SUCCESS);

export const FETCH_EDUCATION = 'FETCH_EDUCATION';
export const fetchEducation = createAction(FETCH_EDUCATION);

export const FETCH_EDUCATION_FAILURE = 'FETCH_EDUCATION_FAILURE';
export const fetchEducationFailure = createAction(FETCH_EDUCATION_FAILURE);

export const FETCH_EDUCATION_REQUESTED = 'FETCH_EDUCATION_REQUESTED';
export const fetchEducationRequested = createAction(FETCH_EDUCATION_REQUESTED);

export const FETCH_EDUCATION_SUCCESS = 'FETCH_EDUCATION_SUCCESS';
export const fetchEducationSuccess = createAction(FETCH_EDUCATION_SUCCESS);

export const EDIT_EDUCATION = 'EDIT_EDUCATION';
export const editEducation = createAction(EDIT_EDUCATION);

export const EDIT_EDUCATION_FAILURE = 'EDIT_EDUCATION_FAILURE';
export const editEducationFailure = createAction(EDIT_EDUCATION_FAILURE);

export const EDIT_EDUCATION_REQUESTED = 'EDIT_EDUCATION_REQUESTED';
export const editEducationRequested = createAction(EDIT_EDUCATION_REQUESTED);

export const EDIT_EDUCATION_SUCCESS = 'EDIT_EDUCATION_SUCCESS';
export const editEducationSuccess = createAction(EDIT_EDUCATION_SUCCESS);

export const FETCH_SINGLE_EDUCATION = 'FETCH_SINGLE_EDUCATION';
export const fetchSingleEducation = createAction(FETCH_SINGLE_EDUCATION);

export const FETCH_SINGLE_EDUCATION_FAILURE = 'FETCH_SINGLE_EDUCATION_FAILURE';
export const fetchSingleEducationFailure = createAction(FETCH_SINGLE_EDUCATION_FAILURE);

export const FETCH_SINGLE_EDUCATION_REQUESTED = 'FETCH_SINGLE_EDUCATION_REQUESTED';
export const fetchSingleEducationRequested = createAction(FETCH_SINGLE_EDUCATION_REQUESTED);

export const FETCH_SINGLE_EDUCATION_SUCCESS = 'FETCH_SINGLE_EDUCATION_SUCCESS';
export const fetchSingleEducationSuccess = createAction(FETCH_SINGLE_EDUCATION_SUCCESS);

export const UPDATE_EDUCATION_FORM = 'UPDATE_EDUCATION_FORM';
export const updateEducationForm = createAction(UPDATE_EDUCATION_FORM);

export const DELETE_EDUCATION_CLASS = 'DELETE_EDUCATION_CLASS';
export const deleteEducationClass = createAction(DELETE_EDUCATION_CLASS);


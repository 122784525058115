import React from 'react';
import { instanceOf, string, func, bool } from 'prop-types';

const DateCalenderInput = ({
  name, value, onClick, title, disabled, manual,
}, ref) =>

  // console.log('name',name)
  // console.log(value)
  (
    <div role='presentation' className="calendar-icon" ref={ref} onClick={onClick}>
      <span className="btn-icon-wrapper pr-1 icon">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:'8px'}}>
<path d="M17.4167 3.66668H15.5834V2.75001C15.5834 2.5069 15.4868 2.27374 15.3149 2.10183C15.143 1.92992 14.9098 1.83334 14.6667 1.83334C14.4236 1.83334 14.1904 1.92992 14.0185 2.10183C13.8466 2.27374 13.75 2.5069 13.75 2.75001V3.66668H8.25004V2.75001C8.25004 2.5069 8.15346 2.27374 7.98155 2.10183C7.80965 1.92992 7.57649 1.83334 7.33337 1.83334C7.09026 1.83334 6.8571 1.92992 6.68519 2.10183C6.51328 2.27374 6.41671 2.5069 6.41671 2.75001V3.66668H4.58337C3.85403 3.66668 3.15456 3.95641 2.63883 4.47213C2.12311 4.98786 1.83337 5.68733 1.83337 6.41668V17.4167C1.83337 18.146 2.12311 18.8455 2.63883 19.3612C3.15456 19.8769 3.85403 20.1667 4.58337 20.1667H17.4167C18.1461 20.1667 18.8455 19.8769 19.3612 19.3612C19.877 18.8455 20.1667 18.146 20.1667 17.4167V6.41668C20.1667 5.68733 19.877 4.98786 19.3612 4.47213C18.8455 3.95641 18.1461 3.66668 17.4167 3.66668ZM18.3334 17.4167C18.3334 17.6598 18.2368 17.893 18.0649 18.0649C17.893 18.2368 17.6598 18.3333 17.4167 18.3333H4.58337C4.34026 18.3333 4.1071 18.2368 3.93519 18.0649C3.76328 17.893 3.66671 17.6598 3.66671 17.4167V11H18.3334V17.4167ZM18.3334 9.16668H3.66671V6.41668C3.66671 6.17356 3.76328 5.9404 3.93519 5.7685C4.1071 5.59659 4.34026 5.50001 4.58337 5.50001H6.41671V6.41668C6.41671 6.65979 6.51328 6.89295 6.68519 7.06486C6.8571 7.23677 7.09026 7.33334 7.33337 7.33334C7.57649 7.33334 7.80965 7.23677 7.98155 7.06486C8.15346 6.89295 8.25004 6.65979 8.25004 6.41668V5.50001H13.75V6.41668C13.75 6.65979 13.8466 6.89295 14.0185 7.06486C14.1904 7.23677 14.4236 7.33334 14.6667 7.33334C14.9098 7.33334 15.143 7.23677 15.3149 7.06486C15.4868 6.89295 15.5834 6.65979 15.5834 6.41668V5.50001H17.4167C17.6598 5.50001 17.893 5.59659 18.0649 5.7685C18.2368 5.9404 18.3334 6.17356 18.3334 6.41668V9.16668Z" fill="black" fill-opacity="0.58"/>
</svg>
      </span>
      <input
        type="text"
        name={name}
        className="form-control datePicker datepickerStyle"
        placeholder={title}
        defaultValue={value}
        disabled={disabled}
        onKeyPress={(e) => {
          if (!manual) e.preventDefault();

          return false;
        }}
      />
    </div>
  );

DateCalenderInput.propTypes = {
  disabled: bool,
  name: string.isRequired,
  onClick: func.isRequired,
  title: string.isRequired,
  value: instanceOf(Date),
};

DateCalenderInput.defaultProps = {
  disabled: false,
  value: null,
};

export default React.forwardRef(DateCalenderInput);

import { LOCATION_CHANGE } from 'connected-react-router';
import { CREATE_ENROLLMENT_FAILURE,
  CREATE_ENROLLMENT_REQUESTED,
  CREATE_ENROLLMENT_SUCCESS,
  DELETE_ENROLLMENT_FAILURE,
  DELETE_ENROLLMENT_REQUESTED,
  DELETE_ENROLLMENT_SUCCESS,
  EDIT_ENROLLMENT_FAILURE,
  EDIT_ENROLLMENT_REQUESTED,
  EDIT_ENROLLMENT_SUCCESS,
  EXISTING_REGISTRATION_NUMBER_FAILURE,
  EXISTING_REGISTRATION_NUMBER_REQUESTED,
  EXISTING_REGISTRATION_NUMBER_SUCCESS,
  FETCH_SINGLE_ENROLLMENT_FAILURE,
  FETCH_SINGLE_ENROLLMENT_REQUESTED,
  FETCH_SINGLE_ENROLLMENT_SUCCESS,
  FETCH_ENROLLMENT_FAILURE,
  FETCH_ENROLLMENT_REQUESTED,
  FETCH_ENROLLMENT_SUCCESS,
  FETCH_ENROLLED_STUDENTS_FAILURE,
  FETCH_ENROLLED_STUDENTS_REQUESTED,
  FETCH_ENROLLED_STUDENTS_SUCCESS,
  FETCH_STUDENTS_INSTALLMENT_FAILURE,
  FETCH_STUDENTS_INSTALLMENT_REQUESTED,
  FETCH_STUDENTS_INSTALLMENT_SUCCESS,
  UPDATE_ENROLLMENT_FORM,
  // FETCH_TRANSPORT_FAILURE,
  // FETCH_TRANSPORT_REQUESTED,
  // FETCH_TRANSPORT_SUCCESS,
  DELETE_PAYMENT_HISTORY_FAILURE,
  DELETE_PAYMENT_HISTORY_REQUESTED,
  DELETE_PAYMENT_HISTORY_SUCCESS,
  FETCH_STUDENT_PROFILE_FAILURE,
  FETCH_STUDENT_PROFILE_REQUESTED,
  FETCH_STUDENT_PROFILE_SUCCESS,
  FETCH_INSTALLMENT_RECAP_FAILURE,
  FETCH_INSTALLMENT_RECAP_REQUESTED,
  FETCH_INSTALLMENT_RECAP_SUCCESS,
  FETCH_INSTALLMENT_HISTORY_FAILURE,
  FETCH_INSTALLMENT_HISTORY_REQUESTED,
  FETCH_INSTALLMENT_HISTORY_SUCCESS,
  FETCH_TRANSPORT_RECAP_FAILURE,
  FETCH_TRANSPORT_RECAP_REQUESTED,
  FETCH_TRANSPORT_RECAP_SUCCESS,
  FETCH_TRANSPORT_HISTORY_FAILURE,
  FETCH_TRANSPORT_HISTORY_REQUESTED,
  FETCH_TRANSPORT_HISTORY_SUCCESS ,
  //DELETE_TRANSPORT_HISTORY,
  DELETE_TRANSPORT_HISTORY_FAILURE,
  DELETE_TRANSPORT_HISTORY_REQUESTED,
  DELETE_TRANSPORT_HISTORY_SUCCESS,
  FETCH_STUDENTS_INSTALLMENT_SEQ_FAILURE,
  FETCH_STUDENTS_INSTALLMENT_SEQ_REQUESTED,
  FETCH_STUDENTS_INSTALLMENT_SEQ_SUCCESS} from '../actions/enrollment-action-type';

const initialState = {
  createEnrollmentStatus: 'pending',
  deleteEnrollmentStatus: 'pending',
  enrollments: [],
  fetchEnrolledStudentsStatus: 'pending',
  fetchEnrollmentStatus: 'pending',
  fetchEnrollmentSeqStatus: 'pending',
  fetchExistingStudentsStatus: 'pending',
  fetchInstallmentHistoryStatus: 'pending',
  fetchInstallmentRecapStatus: 'pending',
  fetchSingleEnrollmentStatus: 'pending',
  fetchStudentProfileStatus: 'pending',
  fetchStudentsInstallmentStatus: 'pending',
  fetchTransportHistoryStatus: 'pending',
  fetchTransportRecapStatus: 'pending',
  fetchTransportStatus: 'pending',
  form: {
    allergy: '',
    amount_paid: '',
    amount_to_pay: '',
    balance: '',
    class_id: '',
    country_id: '',
    date_of_birth: null,
    deadline: null,
    director_name: '',
    discount: 0,
    enrollment_date: null,
    father_email_address: '',
    father_first_name: '',
    father_last_name: '',
    father_phone_no: '',
    file: null,
    filter: null,
    first_enrollment_date: null,
    gender: null,
    grade_id: '',
    id: null,
    moreinfo: '',
    mother_email_address: '',
    mother_first_name: '',
    mother_last_name: '',
    mother_phone_no: '',
    neighborhood: '',
    payment_already_amount_paid: '',
    payment_amount_paid: '',
    payment_amount_to_pay: '',
    payment_balance: '',
    payment_date: '',
    payment_status: null,
    place_of_birth: '',
    quater_name: '',
    reference_hospital: '',
    reference_hospital_address: '',
    school_year: '',
    section: '',
    seniority: '',
    status: '',
    street: '',
    student_address: '',
    student_email: '',
    student_first_name: '',
    student_id: null,
    student_last_name: '',
    student_phone_no: '',
    student_profile_picture: '',
    student_registration_no: '',
    tutor_date_of_birth: null,
    tutor_email_address: '',
    tutor_first_name: '',
    tutor_gender: null,
    tutor_last_name: '',
    tutor_phone_no: '',
    type_of_student: '',
  },
  installmentHistory: [],
  installmentRecap: [],
  installmentsList: [],
  installmentsListSeq: [],
  key: {},
  student_profile: {},
  students: [],
  transport: [],
  transportHistory: [],
  transportRecap: [],
};

export default function enrollment(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case CREATE_ENROLLMENT_FAILURE:
      return {
        ...state,
        createEnrollmentStatus: 'failure',
      };

    case CREATE_ENROLLMENT_REQUESTED:
      return {
        ...state,
        createEnrollmentStatus: 'creating',
      };

    case CREATE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        createEnrollmentStatus: 'success',
        form: {
          ...state.form,
          student_id: payload.student_id || state.form.student_id,
          student_registration_no: payload.student_registration_no || state.form.student_registration_no,
        },
      };
    case EDIT_ENROLLMENT_FAILURE:
      return {
        ...state,
        createEnrollmentStatus: 'failure',
      };

    case EDIT_ENROLLMENT_REQUESTED:
      return {
        ...state,
        createEnrollmentStatus: 'creating',
      };

    case EDIT_ENROLLMENT_SUCCESS:
      return {
        ...state,
        createEnrollmentStatus: 'success',
        form: {
          ...state.form,
          student_id: payload.student_id || state.form.student_id,
          student_registration_no: payload.student_registration_no || state.form.student_registration_no,
        },
      };

      //
      case DELETE_TRANSPORT_HISTORY_FAILURE:
        return {
          ...state,
          deleteTransportTypeStatus: 'failure',
        };
  
      case DELETE_TRANSPORT_HISTORY_REQUESTED:
        return {
          ...state,
          deleteTransportTypeStatus: 'deleting',
        };
  
      case DELETE_TRANSPORT_HISTORY_SUCCESS:
        return {
          ...state,
          deleteTransportTypeStatus: 'success',
          transportHistory: [...state.transportHistory].filter((pType) => !(payload.toString().includes(pType.receipt_id.toString()))),
        };
      //
    case DELETE_PAYMENT_HISTORY_FAILURE:
      return {
        ...state,
        deletePaymentTypeStatus: 'failure',
      };

    case DELETE_PAYMENT_HISTORY_REQUESTED:
      return {
        ...state,
        deletePaymentTypeStatus: 'deleting',
      };

    case DELETE_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        deletePaymentTypeStatus: 'success',
        installmentHistory: [...state.installmentHistory].filter((pType) => !(payload.toString().includes(pType.receipt_id.toString()))),
      };
    case DELETE_ENROLLMENT_FAILURE:
      return {
        ...state,
        deleteEnrollmentStatus: 'failure',
      };

    case DELETE_ENROLLMENT_REQUESTED:
      return {
        ...state,
        deleteEnrollmentStatus: 'deleting',
      };

    case DELETE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        deleteEnrollmentStatus: 'success',
        enrollments: [...state.enrollments].filter((singleEnrollment) => !(payload.toString().includes(singleEnrollment.enrollment_id.toString()))),

      };

    case FETCH_ENROLLMENT_FAILURE:
      return {
        ...state,
        fetchEnrollmentStatus: 'failure',
      };

    case FETCH_ENROLLMENT_REQUESTED:
      return {
        ...state,
        fetchEnrollmentStatus: 'fetching',
      };

    case FETCH_ENROLLMENT_SUCCESS:
      return {
        ...state,
        enrollments: payload,
        fetchEnrollmentStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };

    case FETCH_STUDENT_PROFILE_FAILURE:
      return {
        ...state,
        fetchStudentProfileStatus: 'failure',
      };

    case FETCH_STUDENT_PROFILE_REQUESTED:
      return {
        ...state,
        fetchStudentProfileStatus: 'fetching',
      };

    case FETCH_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        fetchStudentProfileStatus: 'success',
        student_profile: payload,
      };

    case FETCH_INSTALLMENT_RECAP_FAILURE:
      return {
        ...state,
        fetchInstallmentRecapStatus: 'failure',
      };

    case FETCH_INSTALLMENT_RECAP_REQUESTED:
      return {
        ...state,
        fetchInstallmentRecapStatus: 'fetching',
      };

    case FETCH_INSTALLMENT_RECAP_SUCCESS:
      return {
        ...state,
        fetchInstallmentRecapStatus: 'success',
        installmentRecap: payload,
      };

    case FETCH_INSTALLMENT_HISTORY_FAILURE:
      return {
        ...state,
        fetchInstallmentHistoryStatus: 'failure',
      };

    case FETCH_INSTALLMENT_HISTORY_REQUESTED:
      return {
        ...state,
        fetchInstallmentHistoryStatus: 'fetching',
      };

    case FETCH_INSTALLMENT_HISTORY_SUCCESS:
      return {
        ...state,
        fetchInstallmentHistoryStatus: 'success',
        installmentHistory: payload,
      };

    case FETCH_TRANSPORT_RECAP_FAILURE:
      return {
        ...state,
        fetchTransportRecapStatus: 'failure',
      };

    case FETCH_TRANSPORT_RECAP_REQUESTED:
      return {
        ...state,
        fetchTransportRecapStatus: 'fetching',
      };

    case FETCH_TRANSPORT_RECAP_SUCCESS:
      return {
        ...state,
        fetchTransportRecapStatus: 'success',
        transportRecap: payload,
      };
    case FETCH_TRANSPORT_HISTORY_FAILURE:
      return {
        ...state,
        fetchTransportHistoryStatus: 'failure',
      };

    case FETCH_TRANSPORT_HISTORY_REQUESTED:
      return {
        ...state,
        fetchTransportHistoryStatus: 'fetching',
      };

    case FETCH_TRANSPORT_HISTORY_SUCCESS:
      return {
        ...state,
        fetchTransportHistoryStatus: 'success',
        transportHistory: payload,
      };
      // case FETCH_TRANSPORT_FAILURE:
      //   return {
      //     ...state,
      //     fetchTransportStatus: 'failure',
      //   };

      // case FETCH_TRANSPORT_REQUESTED:
      //   return {
      //     ...state,
      //     fetchTransportStatus: 'fetching',
      //   };

    // case FETCH_TRANSPORT_SUCCESS:
    //   return {
    //     ...state,
    //     fetchTransportStatus: 'success',
    //     transport: payload,
    //   };
    case FETCH_STUDENTS_INSTALLMENT_FAILURE:
      return {
        ...state,
        fetchEnrollmentStatus: 'failure',
      };

    case FETCH_STUDENTS_INSTALLMENT_REQUESTED:
      return {
        ...state,
        fetchEnrollmentStatus: 'fetching',
      };

    case FETCH_STUDENTS_INSTALLMENT_SUCCESS:

      return {
        ...state,
        fetchEnrollmentStatus: 'success',
        installmentsList: payload,
      };

      case FETCH_STUDENTS_INSTALLMENT_SEQ_FAILURE:
        return {
          ...state,
          fetchEnrollmentSeqStatus: 'failure',
        };
  
      case FETCH_STUDENTS_INSTALLMENT_SEQ_REQUESTED:
        return {
          ...state,
          fetchEnrollmentSeqStatus: 'fetching',
        };
  
      case FETCH_STUDENTS_INSTALLMENT_SEQ_SUCCESS:
  
        return {
          ...state,
          fetchEnrollmentSeqStatus: 'success',
          installmentsListSeq: payload,
        };

    case FETCH_ENROLLED_STUDENTS_FAILURE:
      return {
        ...state,
        fetchEnrolledStudentsStatus: 'failure',
      };

    case FETCH_ENROLLED_STUDENTS_REQUESTED:
      return {
        ...state,
        fetchEnrolledStudentsStatus: 'fetching',
      };

    case FETCH_ENROLLED_STUDENTS_SUCCESS:
      return {
        ...state,
        fetchEnrolledStudentsStatus: 'success',
        students: payload,
      };

    case EXISTING_REGISTRATION_NUMBER_FAILURE:
      return {
        ...state,
        fetchExistingStudentsStatus: 'failure',
      };

    case EXISTING_REGISTRATION_NUMBER_REQUESTED:
      return {
        ...state,
        fetchExistingStudentsStatus: 'fetching',
      };

    case EXISTING_REGISTRATION_NUMBER_SUCCESS:

      return {
        ...state,
        fetchExistingStudentsStatus: 'success',
        key: payload,
      };

    case FETCH_SINGLE_ENROLLMENT_FAILURE:
      return {
        ...state,
        fetchSingleEnrollmentStatus: 'failure',
      };

    case FETCH_SINGLE_ENROLLMENT_REQUESTED:
      return {
        ...state,
        fetchSingleEnrollmentStatus: 'fetching',
      };

    case FETCH_SINGLE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        fetchSingleEnrollmentStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.student_profile_picture),
          // student_profile_picture: null,
        },
      };

    case UPDATE_ENROLLMENT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { 
  FETCH_RESSOURCES,
  EDIT_RESSOURCES,
  fetchRessourceFailure,
  fetchRessourceRequested,
  fetchRessourceSuccess,
  editRessourcesSuccess,
  editRessourcesFailure,
  editRessourcesRequested
} from '../actions/ressources-action-type';
import httpClient from './http-client';

export function* fetchRessourceHandler({ payload }) {
  yield put(fetchRessourceRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'ressources-list',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchRessourceFailure(error));
  } else {
 
  //  console.log('RESSOURCES');
//console.log(data.result.data);
const ressources=data.result.data;
localStorage.setItem('roleUser', JSON.stringify(JSON.parse(JSON.stringify(ressources).substring(1, JSON.stringify(ressources).length-1)))); //stringify object and store
  yield put(fetchRessourceSuccess(ressources));
  }
}
export function* editRessourcesHandler({ payload }) {
    yield put(editRessourcesRequested());
    const body = new FormData();
  
    body.append('ressources', payload.ressources);
  console.log(payload);
    const request = {
      data: payload,
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      method: 'POST',
      url: `edit-ressources`,
    };
  
    const { error } = yield call(httpClient, request);
  
    if (error) {
      yield put(editRessourcesFailure(error));
    } else {
     
  
      yield put(editRessourcesSuccess());
    }
  }

function* Ressources() {
  yield all([
    takeLatest(FETCH_RESSOURCES, fetchRessourceHandler),
    takeLatest(EDIT_RESSOURCES, editRessourcesHandler)
  ]);
}

export default Ressources;

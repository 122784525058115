import { LOCATION_CHANGE } from 'connected-react-router';

import { ADD_TRANSPORT_FAILURE,
  ADD_TRANSPORT_REQUESTED,
  ADD_TRANSPORT_SUCCESS,
  DELETE_TRANSPORT_FAILURE,
  DELETE_TRANSPORT_REQUESTED,
  DELETE_TRANSPORT_SUCCESS,
  FETCH_SINGLE_TRANSPORT_FAILURE,
  FETCH_SINGLE_TRANSPORT_REQUESTED,
  FETCH_SINGLE_TRANSPORT_SUCCESS,
  FETCH_TRANSPORT_FAILURE,
  FETCH_TRANSPORT_REQUESTED,
  FETCH_TRANSPORT_SUCCESS,
  UPDATE_TRANSPORT_FORM,
  EDIT_TRANSPORT_FAILURE,
  EDIT_TRANSPORT_REQUESTED,
  EDIT_TRANSPORT_SUCCESS } from '../actions/transport-action-types';

const initialState = {
  addTransportStatus: 'pending',
  deleteTransportStatus: 'pending',
  editTransportStatus: 'pending',
  fetchSingleTransportStatus: 'pending',
  fetchTransportStatus: 'pending',
  form: {
    amount_to_pay: '',
    deadline: null,
    quater_name: null,
    student_id: null,
  },
  responseCode: null,
  statusCode: null,
  transport: [],
};

export default function transport(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_TRANSPORT_FAILURE:
      return {
        ...state,
        addTransportStatus: 'failure',
      };

    case ADD_TRANSPORT_REQUESTED:
      return {
        ...state,
        addTransportStatus: 'creating',
      };

    case ADD_TRANSPORT_SUCCESS:
      return {
        ...state,
        addTransportStatus: 'success',
        responseCode: payload,
      };
    case EDIT_TRANSPORT_FAILURE:
      return {
        ...state,
        editTransportStatus: 'failure',
      };

    case EDIT_TRANSPORT_REQUESTED:
      return {
        ...state,
        editTransportStatus: 'creating',
      };

    case EDIT_TRANSPORT_SUCCESS:
      return {
        ...state,
        editTransportStatus: 'success',
        statusCode: payload,
      };
    case FETCH_TRANSPORT_FAILURE:
      return {
        ...state,
        fetchTransportStatus: 'failure',
      };

    case FETCH_TRANSPORT_REQUESTED:
      return {
        ...state,
        fetchTransportStatus: 'fetching',
      };

    case FETCH_TRANSPORT_SUCCESS:

      return {
        ...state,
        fetchTransportStatus: 'success',
        transport: payload,
      };
    case DELETE_TRANSPORT_FAILURE:
      return {
        ...state,
        deleteTransportStatus: 'failure',
      };

    case DELETE_TRANSPORT_REQUESTED:
      return {
        ...state,
        deleteTransportStatus: 'deleting',
      };

    case DELETE_TRANSPORT_SUCCESS:
      return {
        ...state,
        deleteTransportStatus: 'success',
        transport: [...state.transport].filter((transports) => !(payload.toString().includes(transports.id.toString()))),
      };

    case FETCH_SINGLE_TRANSPORT_FAILURE:
      return {
        ...state,
        fetchSingleTransportStatus: 'failure',
      };

    case FETCH_SINGLE_TRANSPORT_REQUESTED:
      return {
        ...state,
        fetchSingleTransportStatus: 'fetching',
      };

    case FETCH_SINGLE_TRANSPORT_SUCCESS:
      return {
        ...state,
        fetchSingleTransportStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };

    case UPDATE_TRANSPORT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_PROGRESSION,
  fetchProgressionSuccess,
  ADD_PROGRESSION,
  addProgressionSuccess,
  EDIT_PROGRESSION,
  editProgressionSuccess,
  DELETE_PROGRESSION,
  deleteProgressionSuccess,
  FETCH_SINGLE_PROGRESSION,
  fetchSingleProgressionSuccess,
} from '../actions/progression-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import moment from 'moment';
import i18next from 'i18next';

export function* fetchProgression({ payload }) {
  let arr=[];
  const quarter_id=payload?.quater_id;
  var isArr = Array.isArray(quarter_id);
  console.log(payload?.quater_id);
  if (typeof quarter_id !== 'undefined') {
      // do something
      if(!isArr){
        console.log(payload?.quater_id);
        arr.push(quarter_id?.value);
      }else{
      if(quarter_id?.length==1){
        console.log(payload?.quater_id);
        arr.push(quarter_id[0]?.value);
      }else{
      for (var i = 0; i < quarter_id?.length; i++) {
        arr.push(quarter_id[i]?.value);
        console.log("PAIR " + i + ": " + quarter_id[i]?.value);
    }
      }
    }
  
  }
  const request = {
    method: 'GET',
    // params: { school_year_id: payload.school_year_id },
    url: `progression-filter?school_year_id=${payload.school_year_id}&subject_id=${payload?.subject_id?.value ?? ""}&class_id=${payload?.class_id?.value ?? ""}&quater_id=${ arr.join(',') ?? ""}&teacher_id=${payload?.teacher_id?.value ?? ""} `,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    console.log(error);
  } else {
    yield put(fetchProgressionSuccess(data.data.result));
  }
}

export function* addProgressionHandler({ payload }) {
  const body = new FormData();
  body.append('school_year_id', payload.school_year_id);
  body.append('class_id', payload.class_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('teacher_id', payload.teacher_id.value);
  body.append('chapter_id', payload.chapter_id.value);
  body.append('created_date', payload?.created_date);
  // body.append('quater_id', payload?.quarter_id.value);
  body.append('part_id', payload.part_id.value);
  // body.append('week_number_id', payload.week_number_id.value);
  body.append('comment', payload?.comment);
  body.append('created_date', moment(payload.created_date).format('yyyy-MM-DD HH:mm').toString());
  body.append('progression_status', payload?.progression_status?.value);
  body.append('progression_perc', payload?.progression_perc?.value);

  console.log(payload?.progression_status);
  console.log(body);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-progression',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    console.log(error);
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-progression');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.progression.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addProgressionSuccess());
  }
}

export function* deleteProgressionHandler({ payload }) {
  const request = {
    method: 'DELETE',
    url: `delete-progression/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    console.log(error);
  } else {
    const alertProps = {
      callback: () => {
       window.location.reload()
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.progression.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteProgressionSuccess(payload));
  }
}
export function* editProgressionHandler({ payload }) {
  const body = new FormData();
  body.append('school_year_id', payload.school_year_id);
  body.append('class_id', payload.class_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('teacher_id', payload.teacher_id.value);

  body.append('chapter_id', payload.chapter_id.value);
  body.append('part_id', payload.part_id.value);
  // body.append('week_number_id', 1);

  body.append('comment', payload?.comment);
  body.append('created_date', moment(payload.created_date).format('yyyy-MM-DD HH:mm').toString());

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-progression/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    console.log(error);
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-progression');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.progression.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editProgressionSuccess());
  }
}

export function* fetchSingleProgressionHandler({payload}) {
  const request = {
    method: 'GET',
    url: `get-progression/${payload.id}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    console.log(error);
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,

      chapter_id: {
        label: result.chapter_title,
        value: result.chapter_id,
      },
      created_date: new Date(result.created_date),
      class_id: {
        label: result.class_name,
        value: result.class_id,
      },
      quarter_id: {
        label: result.quater_name,
        value: result.quater_id,
      },

      grade_id: {
        label: result.grade_name,
        value: result.grade_id,
      },
      id: result.id,

      part_id: {
        label: result.part_name,
        value: result.part_id,
      },

      subject_id: {
        label: result.subject_name,
        value: result.subject_id,
      },
      teacher_id: {
        label: result.teacher_name,
        value: result.teacher_id,
      },
      week_number_id: {
        label: result.week_number,
        value: result.week_number_id,
      },
    };
    payload.cb(res)
    yield put(fetchSingleProgressionSuccess(res));
  }
}
function* progression() {
  yield all([
    takeLatest(FETCH_PROGRESSION, fetchProgression),
    takeLatest(ADD_PROGRESSION, addProgressionHandler),
    takeLatest(EDIT_PROGRESSION, editProgressionHandler),
    takeLatest(DELETE_PROGRESSION, deleteProgressionHandler),
    takeLatest(FETCH_SINGLE_PROGRESSION, fetchSingleProgressionHandler),
  ]);
}

export default progression;

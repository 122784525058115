import React, { Fragment,useState,useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { IoIosCalendar } from "react-icons/io";
import Select from 'react-select';
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchSchoolYear, updateSchoolYearForm, setInitialState, setCurrentYearOnDb } from '../../actions/school-year-action-type';


import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { bool, func, shape, string } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
//import Button from '../button';
import Utils from '../../utility';

import { toast, Bounce } from "react-toastify";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import 'react-toastify/dist/ReactToastify.css';

import city3 from "../../assets/utils/images/dropdown-header/city3.jpg";

import {isMobile} from 'react-device-detect';
//import { NotificationCenter } from "../Notification/NotificationCenter";
import StudentMenu from "../../Layout/AppHeader/Components/StudentMenu";

function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`} styles="color:red">
      {props.children}
    </a>
  );
}


const ProfileDropDown = ({
  handleDrawerMenu, open, onClick, onLogout, user,
}) => {
  const location = useLocation();
  const dropdownRef = React.useRef(null);
//const userConnected = useSelector((store) => store.user.userDetail);
/*const socket =getSocket();
useEffect(() => {
   
   
    //Listens for the notification from the server
    socket.on("notification-recieve", (data1) => {
      const data=data1?.message;
      console.log(data)
      const msg=data?.msg;
      const sentToall=data?.sentToall ? data?.sentToall :false;
      const toUser=data?.toUser ? data?.toUser:null;
      const notifTitle=data?.notifTitle ? data?.notifTitle:"Notification from Skulman";
      const redirectPage=data?.redirectPage ? data?.redirectPage:"";
      const userConnected=data?.user ? data?.user: user?.userId;
      if(userConnected !== getLocalData(webConstants.USER_CONNECTED)?.userId 
      || sentToall && (userConnected !== getLocalData(webConstants.USER_CONNECTED)?.userId ) 
      || toUser !== getLocalData(webConstants.USER_CONNECTED)?.userId ) {
      notify(msg);
      SendNotificationToBrowser(msg, userConnected,redirectPage,notifTitle,sentToall,toUser);
      }
    });
}, []);*/

  const handleBodyClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      onClick(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleBodyClick);

    return () => {
      document.removeEventListener('mousedown', handleBodyClick);
    };
  }, []);

  const onActive = () => {
    onClick(true);
    handleDrawerMenu();
  };

  const notify2 = () => {
    toast("You don't have any new items in your calendar for today! Go out and play!",
    {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "success",
    });
  };
  

    //school year selection
    const dispatch = useDispatch();
    const {
      schoolYears, form,
    } = useSelector((store) => (
      {
        form: store?.schoolYears?.form,
        schoolYears: store?.schoolYears?.schoolYears,
      }));
      const {school_year_id}=form;
     /* const school_year_id = schoolYears.length ? (form?.school_year_id ? form?.school_year_id : {
        label: `${schoolYears[0]?.school_start_year} / ${schoolYears[0]?.school_end_year}`,
        value: schoolYears[0]?.school_year_id,
      }) : '';*/
      
      React.useEffect(() => {
        const request = { filter: 'active' };
    
        dispatch(fetchSchoolYear(request));
      }, []);
    
      React.useEffect(() => {
    
        const updates = {
          ...form,
          school_year_id,
        };
    
        dispatch(updateSchoolYearForm(updates));
      }, []);
    
      const onSelectChange = (name, value) => {
    
        const updates = {
          ...form,
          [name]: value,
        };
    
        dispatch(updateSchoolYearForm(updates));
        setTimeout(() => {
          window.location.reload(); 
        }, 100);
    
      };
      
  return (   
                <Fragment>
                <div className="header-btn-lg pe-0">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <UncontrolledButtonDropdown>
                          <DropdownToggle color="link" className="p-0" >
                            <div style={{padding:'5px',padding: '5px',borderRadius: '50%',width: '45px',height: '45px',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                               <img
                  width={'100%'}
                  height={'100%'}
                  className="rounded-circle"
                  src={encodeURI(user?.profile_picture) || Utils.getImage('user-placeholder.png')}
                  alt="Profile"
                />
                            </div>
                          {/* <img
                  width={42}
                  height={42}
                  className="rounded-circle"
                  src={encodeURI(user?.profile_picture) || Utils.getImage('user-placeholder.png')}
                  alt="Profile"
                /> */}
                            {!isMobile ? <FontAwesomeIcon
                              className="ms-2 opacity-8"
                              icon={faAngleDown}
                            /> : null}
                          </DropdownToggle>
                          <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                            <div className="dropdown-menu-header">
                              <div className="dropdown-menu-header-inner bg-info">
                                <div className="menu-header-image opacity-2"
                                  style={{
                                    backgroundImage: "url(" + city3 + ")",
                                  }}/>
                                <div className="menu-header-content text-start">
                                  <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                      <div className="widget-content-left me-3">
                                      <img
                  width={42}
                  height={42}
                  className="rounded-circle"
                  src={encodeURI(user?.profile_picture) || Utils.getImage('user-placeholder.png')}
                  alt="Profile"
                />
                                      </div>
                                      <div className="widget-content-left">
                                        <div className="widget-heading">
                                        {user ? user.name.capitalizeEachLetter() : ''}
                                        </div>
                                        <div className="widget-subheading opacity-8">
                                        {user ? user.user_type_name : ''}
                                        </div>
                                      </div>
                                      <div className="widget-content-right me-2">
                                        <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={onLogout}>
                                          Logout
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="scroll-area-xs"
                              style={{
                                height: "180px",
                              }}>
                              <PerfectScrollbar>


                                <Nav vertical>
                                <NavItem className="nav-item-header">
                                  Selected year
                                <Select
          isSearchable
          name="school_year_id"
          id={"school_year_id"}
          loadingMessage="Please wait loading"
          placeholder="Select School  Year"
          onChange={(value) => onSelectChange('school_year_id', value)}
          options={schoolYears?.map((schoolYear) => ({
            label: `${schoolYear?.school_start_year} / ${schoolYear?.school_end_year}`,
            value: schoolYear?.school_year_id,
          }))}
          value={school_year_id}
          />
                                  </NavItem>
                                  <NavItem className="nav-item-header">
                                   
            <StudentMenu user={user}/>
                                  </NavItem>

                                 {/* <NavItem className="nav-item-header">
                                    Activity
                                  </NavItem>
                                  <NavItem>
                                    <NavLink href="#">
                                      Chat
                                      <div className="ms-auto badge rounded-pill bg-info">
                                        0
                                      </div>
                                    </NavLink>
        </NavItem>
                                  <NavItem>
                                    <NavLink href="/change-password">Recover Password</NavLink>
                                  </NavItem>*/}
                                  <NavItem className="nav-item-header">
                                    My Account
                                  </NavItem>
                                  <NavItem>
                                    <NavLink href="/my-profile">
                                      Settings
                                      <div className="ms-auto badge bg-success">
                                        New
                                      </div>
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink href="/change-password">Recover Password</NavLink>
                                  </NavItem>
                                 {/* <NavItem>
                                    <NavLink href="#">
                                      Messages
                                      <div className="ms-auto badge bg-warning">
                                        512
                                      </div>
                            </NavLink>
                                  </NavItem>*/}
                                </Nav>
                              </PerfectScrollbar>
                            </div>
                            <Nav vertical>
                              <NavItem className="nav-item-divider mb-0" />
                            </Nav>
                           {/* <div className="grid-menu grid-menu-2col">
                              <Row className="g-0">
                                <Col sm="6">
                                <Link styles={{color: 'yellow'}}
                      to="/chat"
                    >
                                  <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                    outline color="warning">
                                    <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> {" "} </i>
                                
                    Message Inbox
                                
                                  </Button>
                                  </Link>
                          </Col>
                                <Col sm="6">
                                <Mailto email="contact@skulman.com" subject={`Support for the skulman app for user: ${user ? user.name.capitalizeEachLetter() : ''} and school id: ${user ? user.school_id : ''}`} body="Hi, I need some help.">
                                  <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                    outline color="danger">
                                    <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> {" "} </i>
                                    
                                    <b>Support Tickets</b>
                                   
                                    
                                  </Button>
                                  </Mailto>
                                </Col>
                              </Row>
                            </div>*/}
                           {/* <Nav vertical>
                              <NavItem className="nav-item-divider" />
                              <NavItem className="nav-item-btn text-center">
                                <Button size="sm" className="btn-wide" color="primary">
                                  Open Messages
                                </Button>
                              </NavItem>
                          </Nav>*/}
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </div>
                      <div className="widget-content-left  ms-3 header-user-info">
                        <div className="widget-heading">{user ? user.name.capitalizeEachLetter() : ''}</div>
                        <div className="widget-subheading">{user ? user.user_type_name : ''}</div>
                      </div>
                      <div className="widget-content-right header-user-info ms-3">
                     
                        <Button className="btn-shadow p-1" size="sm" onClick={notify2} color="info" id="Tooltip-1">
                          <IoIosCalendar color="#ffffff" fontSize="20px" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={"Tooltip-1"}>
                          Click for Toastify Notifications!
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
  );
};

ProfileDropDown.propTypes = {
  handleDrawerMenu: func.isRequired,
  onClick: func.isRequired,
  onLogout: func.isRequired,
  open: bool.isRequired,
  user: shape({ name: string.isRequired }),
};

ProfileDropDown.defaultProps = { user: null };

export default ProfileDropDown;

class Utility {
  static getImage = (image) => {
    const path = process.env.NODE_ENV === 'production' ? '/images' : '/public/images';

    return `${path}/${image}`;
  };

  static getImageURL = (image) => {
    const url = process.env.API_SERVER_URL;
    const path = url?.split('/api/')[0];
    return `${path}/${image}`;
  };
}

module.exports = Utility;


import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_GRADE,
  addGradeFailure,
  addGradeRequested,
  addGradeSuccess,
  DELETE_GRADE,
  deleteGradeFailure,
  deleteGradeRequested,
  deleteGradeSuccess,
  FETCH_GRADE,
  fetchGradeFailure,
  fetchGradeRequested,
  fetchGradeSuccess,
  FETCH_SINGLE_GRADE,
  fetchSingleGradeFailure,
  fetchSingleGradeRequested,
  fetchSingleGradeSuccess,
  EDIT_GRADE,
  editGradeFailure,
  editGradeRequested,
  editGradeSuccess, 
  FETCH_CYCLE_TYPE,
  fetchCycleTypeSuccess} from '../actions/grades-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

export function* addGradeHandler({ payload }) {
  console.log("Grades ADD");
  yield put(addGradeRequested());
  const body = new FormData();

  body.append('grade_name', payload.grade_name);
  body.append('grade_code', payload.grade_code);
  body.append('section', payload.section.value);
  body.append('status', payload.status.value);

  body.append('simple', payload?.simple);
  body.append('credits', payload?.credits);
  body.append('distinctions', payload?.distinctions);
  body.append('school_year_id', payload?.school_year_id);
  body.append('level_id', payload?.level_id);
  body.append('cycle_type_id', payload?.cycle_type_id);


  const request = {
    data: body,
    method: 'POST',
    url: 'add-grade',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addGradeFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/grades');
      },
      icon: 'success',
      message: i18next.t('skullman:setting.grade.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(addGradeSuccess());
  }
}

export function* fetchGradeHandler({ payload }) {
  yield put(fetchGradeRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'list-grade',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchGradeFailure(error));
  } else {
    yield put(fetchGradeSuccess(data.data.result));
    if(payload?.result)
     payload?.result(data.data.result);

  }
}

export function* editGradeHandler({ payload }) {
  yield put(editGradeRequested());

  const request = {
    method: 'POST',
    params: {
      grade_code: payload.grade_code,
      grade_name: payload.grade_name,
      section: payload.section.value,
      status: payload.status.value,
      
      simple: payload?.simple,
      credits:payload?.credits,
      distinctions:payload?.distinctions,
      school_year_id:payload?.school_year_id,
      level_id: payload?.level_id,
      cycle_type_id: payload?.cycle_type_id
    },
    url: `update-grade/${payload.grade_id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editGradeFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/grades'});
      },
      icon: 'success',
      message: i18next.t('skullman:setting.grade.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(editGradeSuccess());
  }
}

export function* fetchSingleGradeHandler({ payload }) {
  yield put(fetchSingleGradeRequested());

  const request = {
    method: 'GET',
    url: `view-grade/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleGradeFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      grade_id: result.grade_id,
      section: {
        label: result.section,
        value: result.section,
      },
      status: result.status === 1 ? {
        label: 'Active',
        value: 1,
      } : {
        label: 'Inactive',
        value: 0,
      },
    };

    yield put(fetchSingleGradeSuccess(res));
  }
}

export function* deleteGradeHandler({ payload }) {
  yield put(deleteGradeRequested());

  const request = {
    method: 'DELETE',
    url: `delete-grade/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteGradeFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:setting.grade.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(deleteGradeSuccess(payload));
  }
}

export function* fetchCycleHandler({ payload }) {
  const request = {
    method: 'GET',
    url: 'cycle-types',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
  } else {
    yield put(fetchCycleTypeSuccess(data.data || []));
  }
}

function* user() {
  yield all([
    takeLatest(ADD_GRADE, addGradeHandler),
    takeLatest(DELETE_GRADE, deleteGradeHandler),
    takeLatest(FETCH_GRADE, fetchGradeHandler),
    takeLatest(FETCH_SINGLE_GRADE, fetchSingleGradeHandler),
    takeLatest(EDIT_GRADE, editGradeHandler),
    takeLatest(FETCH_CYCLE_TYPE, fetchCycleHandler),
  ]);
}

export default user;

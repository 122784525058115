import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_FORECAST,
  addForecastFailure,
  addForecastRequested,
  addForecastSuccess,
  DELETE_FORECAST,
  deleteForecastFailure,
  deleteForecastRequested,
  deleteForecastSuccess,
  FETCH_FORECAST,
  fetchForecastFailure,
  fetchForecastRequested,
  fetchForecastSuccess,
  FETCH_SINGLE_FORECAST,
  fetchSingleForecastFailure,
  fetchSingleForecastRequested,
  fetchSingleForecastSuccess,
  EDIT_FORECAST,
  editForecastFailure,
  editForecastRequested,
  editForecastSuccess,
  FETCH_WEEK_NUMBER,
  fetchWeekNumberFailure,
  fetchWeekNumberRequested,
  fetchWeekNumberSuccess,
} from '../actions/forecast-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import { fetchChapterFilter } from '../actions/chapter-action-types';
import { fetchSubject } from '../actions/subject-action-types';
import { fetchPartFilter } from '../actions/part-action-types';
import i18next from "i18next";

export function* addForecastHandler({ payload }) {
  yield put(addForecastRequested());
  const body = new FormData();
  body.append('grade_id', payload.grade_id.value);
  body.append('week_number_id', payload.week_number_id.value);
  body.append('comment', payload.comment);
  body.append('part_id', payload.part_id.value);
  body.append('grade_id', payload.grade_id.value);
  body.append('school_year_id', payload.school_year_id);
  body.append('quater_id', payload.quater_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('chapter_id', payload.chapter_id.value);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multiForecast/form-data' },
    method: 'POST',
    url: 'add-forecast',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addForecastFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-forecast');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.forecast.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addForecastSuccess());
  }
}

export function* editForecastHandler({ payload }) {
  console.log("Forcastpayload",payload)
  yield put(editForecastRequested());

  const body = new FormData();
  body.append('grade_id', payload.grade_id.value);
  body.append('week_number_id', payload.week_number_id.value);
  body.append('comment', payload.comment);
  body.append('part_id', payload.part_id.value);
  body.append('grade_id', payload.grade_id.value);
  body.append('school_year_id', payload.school_year_id);
  body.append('quater_id', payload.quater_id.value);
  body.append('subject_id', payload.subject_id.value);
  body.append('chapter_id', payload.chapter_id.value);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multiForecast/form-data' },
    method: 'POST',
    url: `update-forecast/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editForecastFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push('/pedagogical-forecast');
      },
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.forecast.updateMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editForecastSuccess());
  }
}

export function* deleteForecastHandler({ payload }) {
  yield put(deleteForecastRequested());

  const request = {
    method: 'DELETE',
    url: `delete-forecast/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteForecastFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:pedagogicalFollowUp.forecast.deleteMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };
    setTimeout(() => {
      window.location.reload();
    }, 2000);

    Alert.alert(alertProps);
    yield put(deleteForecastSuccess(payload));
  }
}

export function* fetchForecastHandler({ payload }) {
  // yield put(fetchForecastRequested());
  let arr=[];
  const quarter_id=payload?.quater_id;
  if (typeof quarter_id !== 'undefined') {
      // do something
      if(quarter_id.length==1){
        arr.push(quarter_id[0].value);
      }else{
      for (var i = 0; i < quarter_id.length; i++) {
        arr.push(quarter_id[i].value);
        console.log("PAIR " + i + ": " + quarter_id[i].value);
    }
      }
   
  
  }


  const grade_id = payload?.grade_id?.value;
  const subject_id = payload?.subject_id?.value;
  const chapter_id = payload?.chapter_id;
  //const quater_id = payload?.quater_id?.value;

  const request = {
    method: 'GET',
    params: {
      // grade_id,
      // school_year_id: payload.school_year_id,
      // subject_id,
      // quater_id
    },
    url: `/forecast-filter?school_year_id=${payload.school_year_id}&chapter_id=${chapter_id!=undefined?chapter_id:""}&subject_id=${subject_id!=undefined?subject_id:""}&grade_id=${grade_id}&quater_id=${ arr.join(',')  ?? ""}`,
  };

  // const request = {
  //   method: 'GET',
  //   params: payload,
  //   url: 'forecasts',
  // };

  // const grade_id = payload?.grade_id.value;
  // const quater_id = payload?.quater_id.value;
  // const subject_id = payload?.subject_id.value;

  // const request = {
  //   method: 'GET',
  //   params: {
  //     grade_id: grade_id,
  //     quater_id: quater_id,
  //     subject_id: subject_id,
  //     school_year_id: payload?.school_year_id,
  //   },
  //   url: `forecast-filter?school_year_id=27&subject_id=32&class_id=86&quater_id=1`,
  // };
  console.log('jjjjj--- ', payload);
  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchForecastFailure(error));
  } else {
    yield put(fetchForecastSuccess(data.data.result || []));
  }
}

export function* fetchWeekNumberHandler({ payload }) {
  yield put(fetchWeekNumberRequested());

  const request = {
    method: 'GET',
    params: { school_year_id: payload.school_year_id },
    url: 'all-week-number',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchWeekNumberFailure(error));
  } else {
    yield put(fetchWeekNumberSuccess(data.data.result || []));
  }
}

export function* fetchSingleForecastHandler({ payload }) {
  yield put(fetchSingleForecastRequested());
  const request = {
    method: 'GET',
    url: `get-forecast/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleForecastFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,

      chapter_id: {
        label: result.chapter_title,
        value: result.chapter_id,
      },
      class_id: {
        label: result.class_name,
        value: result.class_id,
      },

      grade_id: {
        label: result.grade_name,
        value: result.grade_id,
      },
      id: result.id,

      part_id: {
        label: result.part_name,
        value: result.part_id,
      },

      quater_id: {
        label: result.quater_name,
        value: result.quater_id,
      },

      subject_id: {
        label: result.subject_name,
        value: result.subject_id,
      },
      teacher_id: {
        label: result.teacher_name,
        value: result.teacher_id,
      },
      week_number_id: {
        label: result.week_number,
        value: result.week_number_id,
      },
    };

    if (result.grade_id && result.subject_id) {
      let post = {
        subject_id: {
          label: result.subject_name,
          value: result.subject_id,
        },
        grade_id: {
          label: result.grade_name,
          value: result.grade_id,
        },
        school_year_id: result.school_year_id,
      };
      yield put(fetchChapterFilter(post));
    }

    if (result.grade_id) {
      let param = {
        school_year_id: result.school_year_id,
        grade_id: result.grade_id,
      };
      yield put(fetchSubject(param));
    }
    

    let param = { school_year_id: result.school_year_id, 
      chapter_id:  {
        label: result.chapter_title,
        value: result.chapter_id,
      },
       grade_id: {
        label: result.grade_name,
        value: result.grade_id,
      },
       subject_id: {
        label: result.subject_name,
        value: result.subject_id,
      } };
       yield put(fetchPartFilter(param));

    yield put(fetchSingleForecastSuccess(res));
  }
}

function* Forecast() {
  yield all([
    takeLatest(ADD_FORECAST, addForecastHandler),
    takeLatest(DELETE_FORECAST, deleteForecastHandler),
    takeLatest(FETCH_FORECAST, fetchForecastHandler),
    takeLatest(FETCH_SINGLE_FORECAST, fetchSingleForecastHandler),
    takeLatest(EDIT_FORECAST, editForecastHandler),
    takeLatest(FETCH_WEEK_NUMBER, fetchWeekNumberHandler),
  ]);
}

export default Forecast;

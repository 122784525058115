import {LOCATION_CHANGE} from "connected-react-router";
import {
    ADD_SEQUENCE_FAILURE,
    ADD_SEQUENCE_SUCCESS,
    ADD_SEQUENCE_REQUESTED,
    DELETE_SEQUENCE_FAILURE,
    DELETE_SEQUENCE_SUCCESS,
    DELETE_SEQUENCE_REQUESTED,
    UPDATE_SEQUENCE_FORM,
    FETCH_SEQUENCE_FAILURE,
    FETCH_SEQUENCE_SUCCESS,
    FETCH_SEQUENCE_REQUESTED
} from "../actions/sequence-action-type";

const initialState = {
    addSequenceStatus: 'pending',
    deleteSequenceStatus: 'pending',
    fetchSequenceStatus: 'pending',
    sequence: [],
    form: {
        sequences: []
    }
};

export default function Sequence(state = initialState, {
    payload, type,
}) {
    switch (type) {
        case ADD_SEQUENCE_FAILURE:
            return {
                ...state,
                addSequenceStatus: 'failure'
            }
        case ADD_SEQUENCE_SUCCESS:
            return {
                ...state,
                addSequenceStatus: 'success'
            }
        case ADD_SEQUENCE_REQUESTED:
            return {
                ...state,
                addSequenceStatus: 'creating'
            }
        case DELETE_SEQUENCE_FAILURE:
            return {
                ...state,
                deleteSequenceStatus: 'failure'
            }
        case DELETE_SEQUENCE_SUCCESS:
            return {
                ...state,
                deleteSequenceStatus: 'success'
            }
        case DELETE_SEQUENCE_REQUESTED:
            return {
                ...state,
                deleteSequenceStatus: 'deleting'
            }
        case FETCH_SEQUENCE_FAILURE:
            return {
                ...state,
                fetchSequenceStatus: 'failure'
            }
        case FETCH_SEQUENCE_SUCCESS:
            return {
                ...state,
                sequence: payload,
                fetchSequenceStatus: 'success'
            }
        case FETCH_SEQUENCE_REQUESTED:
            return {
                ...state,
                fetchSequenceStatus: 'fetching'
            }
        case UPDATE_SEQUENCE_FORM:
            return {
                ...state,
                form: payload
            }
        case LOCATION_CHANGE:
            return { ...initialState}
        default:
            return state;
    }
}
import { object, string, number } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AddMoratoriumDate } from '../../pages/students/moratoriaum-date';
import { downloadFile } from '../../actions/file-downloader-action-types';
import Utils from '../../utility';

const MoratoriumCellRenderer = ({
  className, data, student_id,
}) => {
  const [visible, setVisibility] = React.useState(false);

  return (
    <>
      <div>
        <button onClick={() => setVisibility(true)} className={`${className}`} type="button">
          <i className="lnr-calendar-full" data-toggle="tooltip" data-placement="top" title="New Moratorium" data-original-title="New Moratorium" />
          <span> Moratorium Date</span>
        </button>
      </div>
      {visible && <AddMoratoriumDate data={data} id={student_id} onClose={() => setVisibility(false)} />}
    </>
  );
};

MoratoriumCellRenderer.propTypes = {
  className: string,
  data: object.isRequired,
  student_id: number.isRequired,
};

MoratoriumCellRenderer.defaultProps = { className: 'badge  badge-success' };

export default MoratoriumCellRenderer;

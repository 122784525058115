import { createAction } from 'redux-actions';

export const ADD_WEEKNUMBER = 'ADD_WEEKNUMBER';
export const addWeekNumber = createAction(ADD_WEEKNUMBER);

export const ADD_WEEKNUMBER_FAILURE = 'ADD_WEEKNUMBER_FAILURE';
export const addWeekNumberFailure = createAction(ADD_WEEKNUMBER_FAILURE);

export const ADD_WEEKNUMBER_REQUESTED = 'ADD_WEEKNUMBER_REQUESTED';
export const addWeekNumberRequested = createAction(ADD_WEEKNUMBER_REQUESTED);

export const ADD_WEEKNUMBER_SUCCESS = 'ADD_WEEKNUMBER_SUCCESS';
export const addWeekNumberSuccess = createAction(ADD_WEEKNUMBER_SUCCESS);

export const DELETE_WEEKNUMBER = 'DELETE_WEEKNUMBER';
export const deleteWeekNumber = createAction(DELETE_WEEKNUMBER);

export const DELETE_WEEKNUMBER_FAILURE = 'DELETE_WEEKNUMBER_FAILURE';
export const deleteWeekNumberFailure = createAction(DELETE_WEEKNUMBER_FAILURE);

export const DELETE_WEEKNUMBER_REQUESTED = 'DELETE_WEEKNUMBER_REQUESTED';
export const deleteWeekNumberRequested = createAction(DELETE_WEEKNUMBER_REQUESTED);

export const DELETE_WEEKNUMBER_SUCCESS = 'DELETE_WEEKNUMBER_SUCCESS';
export const deleteWeekNumberSuccess = createAction(DELETE_WEEKNUMBER_SUCCESS);

export const FETCH_WEEKNUMBER = 'FETCH_WEEKNUMBER';
export const fetchWeekNumber = createAction(FETCH_WEEKNUMBER);

export const FETCH_WEEKNUMBER_FAILURE = 'FETCH_WEEKNUMBER_FAILURE';
export const fetchWeekNumberFailure = createAction(FETCH_WEEKNUMBER_FAILURE);

export const FETCH_WEEKNUMBER_REQUESTED = 'FETCH_WEEKNUMBER_REQUESTED';
export const fetchWeekNumberRequested = createAction(FETCH_WEEKNUMBER_REQUESTED);

export const FETCH_WEEKNUMBER_SUCCESS = 'FETCH_WEEKNUMBER_SUCCESS';
export const fetchWeekNumberSuccess = createAction(FETCH_WEEKNUMBER_SUCCESS);

export const EDIT_WEEKNUMBER = 'EDIT_WEEKNUMBER';
export const editWeekNumber = createAction(EDIT_WEEKNUMBER);

export const EDIT_WEEKNUMBER_FAILURE = 'EDIT_WEEKNUMBER_FAILURE';
export const editWeekNumberFailure = createAction(EDIT_WEEKNUMBER_FAILURE);

export const EDIT_WEEKNUMBER_REQUESTED = 'EDIT_WEEKNUMBER_REQUESTED';
export const editWeekNumberRequested = createAction(EDIT_WEEKNUMBER_REQUESTED);

export const EDIT_WEEKNUMBER_SUCCESS = 'EDIT_WEEKNUMBER_SUCCESS';
export const editWeekNumberSuccess = createAction(EDIT_WEEKNUMBER_SUCCESS);

export const FETCH_SINGLE_WEEKNUMBER = 'FETCH_SINGLE_WEEKNUMBER';
export const fetchSingleWeekNumber = createAction(FETCH_SINGLE_WEEKNUMBER);

export const FETCH_SINGLE_WEEKNUMBER_FAILURE = 'FETCH_SINGLE_WEEKNUMBER_FAILURE';
export const fetchSingleWeekNumberFailure = createAction(FETCH_SINGLE_WEEKNUMBER_FAILURE);

export const FETCH_SINGLE_WEEKNUMBER_REQUESTED = 'FETCH_SINGLE_WEEKNUMBER_REQUESTED';
export const fetchSingleWeekNumberRequested = createAction(FETCH_SINGLE_WEEKNUMBER_REQUESTED);

export const FETCH_SINGLE_WEEKNUMBER_SUCCESS = 'FETCH_SINGLE_WEEKNUMBER_SUCCESS';
export const fetchSingleWeekNumberSuccess = createAction(FETCH_SINGLE_WEEKNUMBER_SUCCESS);

export const UPDATE_WEEKNUMBER_FORM = 'UPDATE_WEEKNUMBER_FORM';
export const updateWeekNumberForm = createAction(UPDATE_WEEKNUMBER_FORM);

export const FETCH_WEEKNUMBER_FILTER = 'FETCH_WEEKNUMBER_FILTER';
export const fetchWeekNumberFilter = createAction(FETCH_WEEKNUMBER_FILTER);

export const FETCH_WEEKNUMBER_FILTER_REQUESTED = 'FETCH_WEEKNUMBER_FILTER_REQUESTED';
export const fetchWeekNumberFilterRequested = createAction(FETCH_WEEKNUMBER_FILTER_REQUESTED);

export const FETCH_WEEKNUMBER_FILTER_FAILURE = 'FETCH_WEEKNUMBER_FILTER_FAILURE';
export const fetchWeekNumberFilterFailure = createAction(FETCH_WEEKNUMBER_FILTER_FAILURE);

export const FETCH_WEEKNUMBER_FILTER_SUCCESS = 'FETCH_WEEKNUMBER_FILTER_SUCCESS';
export const fetchWeekNumberFilterSuccess = createAction(FETCH_WEEKNUMBER_FILTER_SUCCESS);
import { LOCATION_CHANGE } from 'connected-react-router';

import { ADD_TEACHER_FAILURE,
  ADD_TEACHER_REQUESTED,
  ADD_TEACHER_SUCCESS,
  DELETE_TEACHER_FAILURE,
  DELETE_TEACHER_REQUESTED,
  DELETE_TEACHER_SUCCESS,
  FETCH_TEACHER_FAILURE,
  FETCH_TEACHER_REQUESTED,
  FETCH_TEACHER_SUCCESS,
  FETCH_SINGLE_TEACHER_FAILURE,
  FETCH_SINGLE_TEACHER_REQUESTED,
  FETCH_SINGLE_TEACHER_SUCCESS,
  UPDATE_TEACHER_FORM } from '../actions/teacher-action-type';

const initialState = {
  createTeacherStatus: 'pending',
  deleteTeacherStatus: 'pending',
  fetchSingleTeacherStatus: 'pending',
  fetchTeachersStatus: 'pending',
  form: {
    address: '',
    class_list: [],
    department_id: '',
    email: '',
    file: null,
    first_name: '',
    highest_qualification: '',
    last_name: '',
    phone_no: '',
    profile_picture: null,
    status: null,
  },
  teachers: [],
};

export default function teachers(state = initialState, {
  payload, type,
}) {
  console.log('redux',type)
  switch (type) {
    case ADD_TEACHER_FAILURE:
      return {
        ...state,
        createTeacherStatus: 'failure',
      };

    case ADD_TEACHER_REQUESTED:
      return {
        ...state,
        createTeacherStatus: 'creating',
      };

    case ADD_TEACHER_SUCCESS:
      return {
        ...state,
        // class_list: payload.class_list,
        createTeacherStatus: 'success',
        file: encodeURI(payload.image),
        profile_picture: encodeURI(payload.image),
      };

    case DELETE_TEACHER_FAILURE:
      return {
        ...state,
        deleteTeacherStatus: 'failure',
      };

    case DELETE_TEACHER_REQUESTED:
      return {
        ...state,
        deleteTeacherStatus: 'deleting',
      };

    case DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        deleteTeacherStatus: 'success',
        teachers: [...state.teachers].filter((teacherItem) => !(payload.toString().includes(teacherItem.id.toString()))),
      };

    case FETCH_TEACHER_FAILURE:
      return {
        ...state,
        fetchTeachersStatus: 'failure',
      };

    case FETCH_TEACHER_REQUESTED:
      return {
        ...state,
        fetchTeachersStatus: 'fetching',
      };

    case FETCH_TEACHER_SUCCESS:
      return {
        ...state,
        fetchTeachersStatus: 'success',
        teachers: payload,
      };

    case FETCH_SINGLE_TEACHER_FAILURE:
      return {
        ...state,
        fetchSingleTeacherStatus: 'failure',
      };

    case FETCH_SINGLE_TEACHER_REQUESTED:
      return {
        ...state,
        fetchSingleTeacherStatus: 'fetching',
      };

    case FETCH_SINGLE_TEACHER_SUCCESS:
      return {
        ...state,
        fetchSingleTeacherStatus: 'success',
        form: {
          ...state.form,
          ...payload,
          file: encodeURI(payload.image),
          profile_picture: encodeURI(payload.image),
        },
      };

    case UPDATE_TEACHER_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { createAction } from 'redux-actions';

export const FETCH_TEACHER_SCHEDULE = 'FETCH_TEACHER_SCHEDULE';
export const fetchTeacherSchedule = createAction(FETCH_TEACHER_SCHEDULE);

export const FETCH_TEACHER_SCHEDULE_SUCCESS = 'FETCH_TEACHER_SCHEDULE_SUCCESS';
export const fetchTeacherScheduleSuccess = createAction(FETCH_TEACHER_SCHEDULE_SUCCESS);

export const FETCH_SCHEDULE = 'FETCH_SCHEDULE';
export const fetchSchedule = createAction(FETCH_SCHEDULE);

export const FETCH_SCHEDULE_SUCCESS = 'FETCH_SCHEDULE_SUCCESS';
export const fetchScheduleSuccess = createAction(FETCH_SCHEDULE_SUCCESS);

export const FETCH_SINGLE_SCHEDULE = 'FETCH_SINGLE_SCHEDULE';
export const fetchSingleSchedule = createAction(FETCH_SINGLE_SCHEDULE);

export const FETCH_SINGLE_SCHEDULE_SUCCESS = 'FETCH_SINGLE_SCHEDULE_SUCCESS';
export const fetchSingleScheduleSuccess = createAction(FETCH_SINGLE_SCHEDULE_SUCCESS);

export const EDIT_SCHEDULE_SUCCESS = 'EDIT_SCHEDULE_SUCCESS';
export const editScheduleSuccess = createAction(EDIT_SCHEDULE_SUCCESS);

export const EDIT_SCHEDULE = 'EDIT_SCHEDULE';
export const editSchedule = createAction(EDIT_SCHEDULE);

export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const deleteSchedule = createAction(DELETE_SCHEDULE);

export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const deleteScheduleSuccess = createAction(DELETE_SCHEDULE_SUCCESS);

export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const addScheduleSuccess = createAction(ADD_SCHEDULE_SUCCESS);

export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const addSchedule = createAction(ADD_SCHEDULE);

export const UPDATE_SCHEDULE_FORM = 'UPDATE_SCHEDULE_FORM';
export const updateScheduleForm = createAction(UPDATE_SCHEDULE_FORM);

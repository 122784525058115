import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { FETCH_PAYMENT,
  fetchPaymentFailure,
  fetchPaymentRequested,
  fetchPaymentSuccess,
  CREATE_PAYMENT,
  createPaymentFailure,
  createPaymentRequested,
  CREATE_TRANSPORT_PAYMENT,
  createTransportPaymentFailure,
  createTransportPaymentRequested,
  createTransportPaymentSuccess,
  CREATE_APPLY_DISCOUNT,
  createApplyDiscountFailure,
  createApplyDiscountRequested,
  createApplyDiscountSuccess,
  FETCH_APPLY_DISCOUNT,
  fetchApplyDiscountFailure,
  fetchApplyDiscountRequested,
  fetchApplyDiscountSuccess,
  DELETE_APPLY_DISCOUNT,
  deleteApplyDiscountFailure,
  deleteApplyDiscountRequested,
  deleteApplyDiscountSuccess } from '../actions/payment-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import i18next from 'i18next';
import SweetAlert from 'sweetalert2';

export function* createPaymentHandler({ payload }) {
  yield put(createPaymentRequested());
  const body = new FormData();

  body.append('comment', payload.comment);
  body.append('displaydatetime', moment(payload.payment_date).format('DD/MM/yyyy HH:mm').toString());
  body.append('payment_date', moment(payload.payment_date).format('yyyy-MM-DD HH:mm:ss').toString());
  body.append('grade_id', payload?.grade_id);
  // body.append('kind', payload.kind?.value);
  body.append('payment_type_id', payload?.payment_type_id);
  body.append('received_by', payload.received_by);
  body.append('school_year_id', payload.school_year_id);
  body.append('student_id', payload?.student_id);
 body.append('remaining_balance', payload.remaining_balance);
  // body.append('discount_percentage_amount', payload.discount_percentage_amount);
  if (payload.installment_id) {
    body.append('installment_id', payload.installment_id?.value);
  }
  if (payload.amount) {
    body.append('amount', payload.amount);
  }
  if (payload.discount) {
    body.append('discount', payload.discount);
  }
  if (payload.discount_setting_id) {
    body.append('discount_setting_id', payload.discount_setting_id?.value);
  }
  if (payload.discount_type) {
    body.append('discount_type', payload.discount_type?.value);
  }
  if (payload.derogation_status) {
    body.append('derogation_status', payload.derogation_status);
  }
  if (payload.derogation_type) {
    body.append('derogation_type', payload.derogation_type?.value);
  }console.log(payload);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'save-payment-installment',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createPaymentFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        window.location.reload();
      },
      icon: 'success',
      message: i18next.t('skullman:tution.payment.discount.createPaymentMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(createTransportPaymentSuccess());
  }
}

export function* createTransportPaymentHandler({ payload }) {
  yield put(createTransportPaymentRequested());
  const body = new FormData();

  body.append('amount', payload.amount);
  body.append('comment', payload.comment);
  body.append('displaydatetime', moment(payload.payment_date).format('DD/MM/yyyy HH:mm').toString());
  body.append('payment_date', moment(payload.payment_date).format('yyyy-MM-DD HH:mm:ss').toString());
  body.append('grade_id', payload.grade_id);
  body.append('payment_type_id', payload.payment_type_id);
  body.append('received_by', payload.received_by);
  body.append('school_year_id', payload.school_year_id);
  body.append('student_id', payload.student_id);
  if (payload.discount) {
    body.append('discount', payload.discount);
  }
  if (payload.discount_setting_id) {
    body.append('discount_setting_id', payload.discount_setting_id?.value);
  }
  if (payload.discount_type) {
    body.append('discount_type', payload.discount_type?.value);
  }
  if (payload.derogation_status) {
    body.append('derogation_status', payload.derogation_status?.value);
  }
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'save-payment-transport',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createTransportPaymentFailure(error));
  } else {
    
    SweetAlert.fire({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: i18next.t('skullman:tution.enrollment.confirmMessage'),
      icon: 'success',
      showCancelButton: false,
      text:  i18next.t('skullman:tution.payment.discount.createPaymentMessage'),
      title: '',
    }).then((result) => {
      put(createTransportPaymentSuccess(payload));
      if (result.isConfirmed) {
        window.location.reload();
      }
    });

  }
  
}

export function* fetchPaymentHandler({ payload }) {
  yield put(fetchPaymentRequested());
  const request = {
    method: 'GET',
    params: {
      filter: payload?.selectedOption, school_year_id: payload?.school_year_id,
    },
    url: 'pre-enrollment-filter',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchPaymentFailure(error));
  } else {
    yield put(fetchPaymentSuccess(data?.data?.result ||[]));
  }
}

export function* createApplyDiscountHandler({ payload }) {
  yield put(createApplyDiscountRequested());
  const body = new FormData();

  body.append('discount_setting_id', payload.discount_setting_id.value);
  body.append('discount_type', payload.discount_type.value);
  body.append('student_id', payload.student_id);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-discount',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(createApplyDiscountFailure(error));
  } else {
    const alertProps = {
      callback: () => {
      },
      icon: 'success',
      message: i18next.t('skullman:tution.payment.discount.createDiscountMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(createApplyDiscountSuccess());
  }
}
export function* fetchApplyDiscountHandler({ payload }) {
  yield put(fetchApplyDiscountRequested());

  const request = {
    method: 'GET',
    url: `view-discount/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchApplyDiscountFailure(error));
  } else {
    const { result } = data.data;
    const res = {
      ...data.data.result,

      discount_setting_id: {
        label: result?.discount,
        value: result?.discount.discount_setting_id,
      },

      discount_type: {
        label: result?.discount_type,
        value: result?.discount_type,
      },
    };

    yield put(fetchApplyDiscountSuccess(res));
  }
}

export function* deleteApplyDiscountHandler({ payload }) {
  yield put(deleteApplyDiscountRequested());

  const request = {
    method: 'DELETE',
    url: `delete-discount/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteApplyDiscountFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        window.location.reload();
      },
      icon: 'success',
      message: i18next.t('skullman:tution.payment.discount.deleteDiscountMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);

    yield put(deleteApplyDiscountSuccess(payload));
  }
}
function* Payments() {
  yield all([
    takeLatest(CREATE_APPLY_DISCOUNT, createApplyDiscountHandler),
    takeLatest(DELETE_APPLY_DISCOUNT, deleteApplyDiscountHandler),
    takeLatest(FETCH_PAYMENT, fetchPaymentHandler),
    takeLatest(FETCH_APPLY_DISCOUNT, fetchApplyDiscountHandler),
    takeLatest(CREATE_PAYMENT, createPaymentHandler),
    takeLatest(CREATE_TRANSPORT_PAYMENT, createTransportPaymentHandler),
  ]);
}

export default Payments;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_APPRECIATION,
  addAppreciationFailure,
  addAppreciationRequested,
  addAppreciationSuccess,
  DELETE_APPRECIATION,
  deleteAppreciationFailure,
  deleteAppreciationRequested,
  deleteAppreciationSuccess,
  FETCH_APPRECIATION,
  fetchAppreciationFailure,
  fetchAppreciationRequested,
  fetchAppreciationSuccess,
  FETCH_TEACHER_APPRECIATION,
  fetchTeacherAppreciationFailure,
  fetchTeacherAppreciationRequested,
  fetchTeacherAppreciationSuccess,
  FETCH_SINGLE_APPRECIATION,
  fetchSingleAppreciationFailure,
  fetchSingleAppreciationRequested,
  fetchSingleAppreciationSuccess,
  EDIT_APPRECIATION,
  editAppreciationFailure,
  editAppreciationRequested,
  editAppreciationSuccess,
  FETCH_TEACHER_APPRECIATION_SUCCESS } from '../actions/appreciation-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

export function* addAppreciationHandler({ payload }) {
  yield put(addAppreciationRequested());
  const body = new FormData();

  body.append('marks_from', payload.marks_from);
  body.append('marks_to', payload.marks_to);
  body.append('school_year_id', payload.school_year_id);
  body.append('type', payload.type);
  body.append('appreciation', payload.appreciation);
  body.append('color', payload.color);
  body.append('level_id', payload.level_id);
  body.append('long_appreciation', payload.long_appreciation);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-appreciation',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addAppreciationFailure(error));
  } else {
    const alertProps = {
      // callback: () => {
      //   history.push('/Appreciation');
      // },
      icon: 'success',
      message: i18next.t('skullman:setting.appreciation.createMessage'),
      title: i18next.t('skullman:setting.appreciation.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addAppreciationSuccess());
  }
}

export function* editAppreciationHandler({ payload }) {
  yield put(editAppreciationRequested());

  const body = new FormData();

  body.append('marks_from', payload.marks_from);
  body.append('marks_to', payload.marks_to);
  body.append('school_year_id', payload.school_year_id);
  body.append('type', payload.type);
  body.append('appreciation', payload.appreciation);
  body.append('color', payload.color);
  body.append('level_id', payload.level_id);
  body.append('long_appreciation', payload.long_appreciation);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: `update-appreciation/${payload.id}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editAppreciationFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        //history.push('/Appreciation');
      },
      icon: 'success',
      message: i18next.t('skullman:setting.appreciation.updateMessage'),
      title: i18next.t('skullman:setting.appreciation.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(editAppreciationSuccess());
  }
}

export function* deleteAppreciationHandler({ payload }) {
  yield put(deleteAppreciationRequested());

  const request = {
    method: 'DELETE',
    url: `delete-appreciation/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteAppreciationFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:setting.appreciation.updateMessage'),
      title: i18next.t('skullman:setting.appreciation.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteAppreciationSuccess(payload));
  }
}

export function* fetchAppreciationHandler({ payload }) {
  yield put(fetchAppreciationRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'all-student-appreciation',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchAppreciationFailure(error));
  } else {
    yield put(fetchAppreciationSuccess(data.data.result || []));
  }
}

export function* fetchTeacherAppreciationHandler({ payload }) {
  yield put(fetchTeacherAppreciationRequested());

  const request = {
    method: 'GET',
    params: payload,
    url: 'all-teacher-appreciation',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchTeacherAppreciationFailure(error));
  } else {
    yield put(fetchTeacherAppreciationSuccess(data.data.result || []));
  }
}

export function* fetchSingleAppreciationHandler({ payload }) {
  yield put(fetchSingleAppreciationRequested());
  const request = {
    method: 'GET',
    url: `get-appreciation/${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleAppreciationFailure(error));
  } else {
    const { result } = data.data;

    const res = {
      ...data.data.result,
      color: result.color,
      id: result.id,
    };

    yield put(fetchSingleAppreciationSuccess(res));
  }
}


function* Appreciation() {
  yield all([
    takeLatest(ADD_APPRECIATION, addAppreciationHandler),
    takeLatest(DELETE_APPRECIATION, deleteAppreciationHandler),
    takeLatest(FETCH_APPRECIATION, fetchAppreciationHandler),
    takeLatest(FETCH_TEACHER_APPRECIATION, fetchTeacherAppreciationHandler),
    takeLatest(FETCH_SINGLE_APPRECIATION, fetchSingleAppreciationHandler),
    takeLatest(EDIT_APPRECIATION, editAppreciationHandler),
  ]);
}

export default Appreciation;

import { LOCATION_CHANGE } from 'connected-react-router';
import { ADD_DISCOUNT_FAILURE,
  ADD_DISCOUNT_REQUESTED,
  ADD_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_FAILURE,
  DELETE_DISCOUNT_REQUESTED,
  DELETE_DISCOUNT_SUCCESS,
  FETCH_DISCOUNT_FAILURE,
  FETCH_DISCOUNT_REQUESTED,
  FETCH_DISCOUNT_SUCCESS,
  FETCH_SINGLE_DISCOUNT_FAILURE,
  FETCH_SINGLE_DISCOUNT_REQUESTED,
  FETCH_SINGLE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_FORM } from '../actions/discount-action-type';

const initialState = {
  addDiscountStatus: 'pending',
  deleteDiscountStatus: 'pending',
  discount: [],
  fetchDiscountStatus: 'pending',
  fetchSingleDiscountStatus: 'pending',
  form: {
    discount: '',
    discount_type: null,
  },
};

export default function discount(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_DISCOUNT_FAILURE:
      return {
        ...state,
        addDiscountStatus: 'failure',
      };

    case ADD_DISCOUNT_REQUESTED:
      return {
        ...state,
        addDiscountStatus: 'creating',
      };

    case ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        addDiscountStatus: 'success',
      };

    case DELETE_DISCOUNT_FAILURE:
      return {
        ...state,
        deleteDiscountStatus: 'failure',
      };

    case DELETE_DISCOUNT_REQUESTED:
      return {
        ...state,
        deleteDiscountStatus: 'deleting',
      };

    case DELETE_DISCOUNT_SUCCESS:
      return {
        ...state,
        deleteDiscountStatus: 'success',
        discount: [...state.discount].filter((discounts) =>  !(payload.toString().includes(discounts.discount_setting_id.toString()))),
  
      };

    case FETCH_DISCOUNT_FAILURE:
      return {
        ...state,
        fetchDiscountStatus: 'failure',
      };

    case FETCH_DISCOUNT_REQUESTED:
      return {
        ...state,
        fetchDiscountStatus: 'fetching',
      };

    case FETCH_DISCOUNT_SUCCESS:
      return {
        ...state,
        discount: payload,
        fetchDiscountStatus: 'success',
      };

    case FETCH_SINGLE_DISCOUNT_FAILURE:
      return {
        ...state,
        fetchSingleDiscountStatus: 'failure',
      };

    case FETCH_SINGLE_DISCOUNT_REQUESTED:
      return {
        ...state,
        fetchSingleDiscountStatus: 'fetching',
      };

    case FETCH_SINGLE_DISCOUNT_SUCCESS:
      return {
        ...state,
        fetchSingleDiscountStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_DISCOUNT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ADD_EXAM_ATTENDANCE_FAILURE,
  ADD_EXAM_ATTENDANCE_REQUESTED,
  ADD_EXAM_ATTENDANCE_SUCCESS,
  DELETE_EXAM_ATTENDANCE_FAILURE,
  DELETE_EXAM_ATTENDANCE_REQUESTED,
  DELETE_EXAM_ATTENDANCE_SUCCESS,
  FETCH_EXAM_ATTENDANCE_FAILURE,
  FETCH_EXAM_ATTENDANCE_REQUESTED,
  FETCH_ATTENDANCE_SUCCESS,
  FETCH_TEACHER_ATTENDANCE_FAILURE,
  FETCH_TEACHER_ATTENDANCE_REQUESTED,
  FETCH_TEACHER_ATTENDANCE_SUCCESS,
  FETCH_SINGLE_ATTENDANCE_FAILURE,
  FETCH_SINGLE_EXAM_ATTENDANCE_REQUESTED,
  FETCH_SINGLE_ATTENDANCE_SUCCESS,
  UPDATE_EXAM_ATTENDANCE_FORM,
  SET_INIT_STATE
} from '../actions/attendance-exam-action-types';
import { SET_INITIAL_STATE } from '../actions/school-year-action-type';

const initialState = {
  addExamAttendanceStatus: 'pending',
  attendance: [],
  studentAttendance: [],
  deleteExamAttendanceStatus: 'pending',
  fetchExamAttendanceStatus: 'pending',
  fetchSingleExamAttendanceStatus: 'pending',
  form: {
    class_id: '',
    created_date: '',
    end_date: null,
    school_year_id: '',
    start_date: null,
    // student_attandance_list: [],
    student_attendance_list: [],
    subject_id: null,
    skills:null,
    exam_id:null,
    isSchedule:false
  },

  teacherAttendance: [],
};

export default function ExamAttendance(state = initialState, { payload, type }) {

  switch (type) {
    case ADD_EXAM_ATTENDANCE_FAILURE:
      return {
        ...state,
        addExamAttendanceStatus: 'failure',
      };

    case ADD_EXAM_ATTENDANCE_REQUESTED:
      return {
        ...state,
        addExamAttendanceStatus: 'creating',
      };

    case ADD_EXAM_ATTENDANCE_SUCCESS:
      return {
        ...state,
        addExamAttendanceStatus: 'success',
        form: {
          ...state.form,
          attendance_subject_id: payload?.attendance_subject_id || state.form?.attendance_subject_id,
        },
      };

    case DELETE_EXAM_ATTENDANCE_FAILURE:
      return {
        ...state,
        deleteExamAttendanceStatus: 'failure',
      };

    case DELETE_EXAM_ATTENDANCE_REQUESTED:
      return {
        ...state,
        deleteExamAttendanceStatus: 'deleting',
      };

    case DELETE_EXAM_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendance: [...state.attendance].filter((Attendances) => !payload.toString().includes(Attendances.id.toString())),
        deleteExamAttendanceStatus: 'success',
      };

    case FETCH_EXAM_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchExamAttendanceStatus: 'failure',
      };

    case FETCH_EXAM_ATTENDANCE_REQUESTED:
      return {
        ...state,
        fetchExamAttendanceStatus: 'fetching',
      };

    case FETCH_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendance: payload,
        fetchExamAttendanceStatus: 'success',
      };

    case FETCH_TEACHER_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchExamAttendanceStatus: 'failure',
      };

    case FETCH_TEACHER_ATTENDANCE_REQUESTED:
      return {
        ...state,
        fetchExamAttendanceStatus: 'fetching',
      };

    case FETCH_TEACHER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        teacherAttendance: payload,
        fetchExamAttendanceStatus: 'success',
      };

    case FETCH_SINGLE_ATTENDANCE_FAILURE:
      return {
        ...state,
        fetchSingleExamAttendanceStatus: 'failure',
      };

    case FETCH_SINGLE_EXAM_ATTENDANCE_REQUESTED:
      return {
        ...state,
        fetchSingleExamAttendanceStatus: 'fetching',
      };

    case FETCH_SINGLE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        fetchSingleExamAttendanceStatus: 'success',
        studentAttendance: payload,
        
      };
    case UPDATE_EXAM_ATTENDANCE_FORM:
      
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:{
      if(!state.form.isSchedule){
        return { ...initialState };
      }
      else
      {
        return { ...state };
      }
    
    }
    

    case SET_INIT_STATE:
      return { ...initialState };

    default:
      return state;
  }
}

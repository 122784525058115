import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_EMPLOYEE,
  addEmployeeFailure,
  addEmployeeRequested,
  addEmployeeSuccess,
  DELETE_EMPLOYEE,
  deleteEmployeeFailure,
  deleteEmployeeRequested,
  deleteEmployeeSuccess,
  EDIT_EMPLOYEE,
  editEmployeeFailure,
  editEmployeeRequested,
  editEmployeeSuccess,
  FETCH_SINGLE_EMPLOYEE,
  fetchSingleEmployeeFailure,
  fetchSingleEmployeeRequested,
  fetchSingleEmployeeSuccess,
  FETCH_EMPLOYEE,
  fetchEmployeeFailure,
  fetchEmployeeRequested,
  fetchEmployeeSuccess,
} from '../actions/employee-action-type';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import i18next from "i18next";


export function* addEmployeeHandler({ payload }) {
  yield put(addEmployeeRequested());
  // const classValues = payload && payload.class_list && payload.class_list.length > 0 ? payload?.class_list?.map((u) => u.value) : [];
  //  const SubjectValues = payload && payload.Subject_id && payload.Subject_id.length > 0 ? payload?.Subject_id?.map((u) => u.value) : [];

  // const classId = payload?.class_list?.map((u) => u.class_id);
  // const subjectId = payload?.class_list?.map((u) => u.subject_id);
  // const classArray = [];
  //classArray.push(classId,subjectId);
  const body = new FormData();
  //console.log(JSON.stringify(payload.class_list));
  body.append('user_name', payload.form.user_name);
  body.append('first_name', payload.form.first_name);
  body.append('last_name', payload.form.last_name);
  body.append('password', payload.form.password);
  body.append('join_date', payload.form.join_date);
  body.append('service_id', payload.form.service_id);
  body.append('email', payload.form.email);
  body.append('school_year_id', payload.school_year_id);
  body.append('phone_no', payload.form.phone_no);
  body.append('status', payload.form.status);
  body.append('user_type_id', payload.form.user_type_id);

  /*if (payload.profile_picture) {
    body.append('profile_picture', payload.profile_picture);
  }*/
  //body.append('class_list', JSON.stringify(payload.class_list));

  console.log(body);
  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-employee',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addEmployeeFailure(error));
  } else {
    yield put(addEmployeeSuccess());
    const alertProps = {
      /*callback: () => {
        history.push('/employee');
      },*/
      icon: 'success',
      message: 'Employee created successfully.',
      title: 'Success',
    };

    Alert.alert(alertProps);
  }
}

export function* editEmployeeHandler({ payload }) {
  console.log('payload');
  console.log(payload);
  yield put(editEmployeeRequested());
  var paramData, userPic = null;

  //Employee main info
  var mainInfo = {
    first_name: payload?.form?.first_name,
    last_name: payload?.form?.last_name,
    join_date: payload?.form?.join_date,
    school_year_id: payload?.school_year_id,
    phone_no: payload.form.phone_no,
    status: payload.form.status,
    user_type_id: payload.form.user_type_id,
    service_id: payload?.form?.service_id,
  };

  if (payload?.form?.user_profile_picture) {
    userPic = payload?.form?.user_profile_picture;
  }

  //Employee personal info
  var persInfo = {
    first_name: payload?.form?.first_name,
    user_type_id: payload?.form?.user_type_id,
    status: payload?.form?.status,
    passport_no: payload?.form?.passport_no,
    passport_exp_no: payload?.form?.passport_exp_no,
    nationality: payload?.form?.nationality,
    religion: payload?.form?.religion,
    marital_status: payload?.form?.marital_status,
    spouse_job: payload?.form?.spouse_job,
    children_no: payload?.form?.children_no,
  };

  //Employee profil info
  var persProfil = {
    first_name: payload?.form?.first_name,
    last_name: payload?.form?.last_name,
    date_of_birth: payload?.form?.date_of_birth,
    gender: payload?.form?.gender,
    address: payload?.form?.address,
    state: payload?.form?.state,
    country: payload?.form?.country,
    pin_code: payload?.form?.pin_code,
    phone_no: payload?.form?.phone_no,
    service_id: payload?.form?.service_id,
    user_type_id: payload?.form?.user_type_id,
    school_year_id: payload?.school_year_id,
    status: payload?.form?.status,
    profile_picture: userPic
  };
  console.log('payload');
  console.log(payload);
  if (payload?.personalInfo) {
    paramData = persInfo;
  } else if (payload?.profilEdit) {
    paramData = persProfil;
  } else {
    paramData = mainInfo;
  }

  const request = {
    method: 'PUT',
    params: paramData,
    url: `update-employee/${payload.employeeId}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(editEmployeeFailure(error));
  } else {
    const alertProps = {
      /*callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/employee'});
      },*/
      icon: 'success',
      message: 'Employee updated successfully.',
      title: 'Success',
    };

    Alert.alert(alertProps);
    yield put(editEmployeeSuccess());

  }
}

export function* deleteEmployeeHandler({ payload }) {
  console.log(payload);
  yield put(editEmployeeRequested());
  const request = {
    method: 'PUT',
    params: {
      status: payload.status,
    },
    url: `activate-deactivate-employee/${payload.employeeId}`,
  };
  const { error } = yield call(httpClient, request);
  if (error) {
    yield put(editEmployeeFailure(error));
  } else {
    const alertProps = {
      /*callback: () => {
        history.push({
          params: location?.param1,
          pathname:'/employee'});
      },*/
      icon: 'success',
      message: 'Employee updated successfully.',
      title: 'Success',
    };

    //Alert.alert(alertProps);
    yield put(editEmployeeSuccess());

  }
}

export function* fetchEmployeeHandler({ payload }) {
  yield put(fetchEmployeeRequested());
  const request = {
    method: 'GET',
    params: payload,
    url: 'employees',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchEmployeeFailure(error));
  } else {
    yield put(fetchEmployeeSuccess(data.data.result));
    /*if(payload?.result)
    {
      payload.result(data.data.result)
    }*/
  }
}

export function* fetchSingleEmployeeHandler({ payload }) {
  yield put(fetchSingleEmployeeRequested());
  const request = {
    method: 'GET',
    url: `get-employee/${payload.publicId}`,
  };
  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleEmployeeFailure(error));
  } else {
    yield put(fetchEmployeeSuccess(data.data.result));
    if (payload?.result) {
      payload.result(data.data.result)
    }
  }

  /*if (error) {
    yield put(fetchSingleEmployeeFailure(error));
  } else {
    const { result } = data.data;

    const department_id = result.department_id?.split(',');
    const department_name = result.department_name?.split(',');

    let department_arr = [];
    for (let index = 0; index < department_id.length; index++) {
      let value = department_id[index];
      let label = department_name[index];

      let obj = {};
      obj.label = label;
      obj.value = value;
      department_arr.push(obj);
    }

    const res = {
      ...data.data.result,
      department_id: department_arr,
      highest_qualification: {
        label: result.qualification_name,
        value: result.highest_qualification,
      },
      id: result.id,

      status:
        result.status === 1
          ? {
              label: 'Active',
              value: 1,
            }
          : {
              label: 'Inactive',
              value: 0,
            },
    };

    yield put(fetchSingleEmployeeSuccess(res));
  }*/
}

function* employee() {
  yield all([
    takeLatest(ADD_EMPLOYEE, addEmployeeHandler),
    takeLatest(DELETE_EMPLOYEE, deleteEmployeeHandler),
    takeLatest(EDIT_EMPLOYEE, editEmployeeHandler),
    takeLatest(FETCH_EMPLOYEE, fetchEmployeeHandler),
    takeLatest(FETCH_SINGLE_EMPLOYEE, fetchSingleEmployeeHandler),
  ]);
}

export default employee;

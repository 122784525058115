import { createAction } from 'redux-actions';

export const ADD_BANK = 'ADD_BANK';
export const addBank = createAction(ADD_BANK);

export const ADD_BANK_FAILURE = 'ADD_BANK_FAILURE';
export const addBankFailure = createAction(ADD_BANK_FAILURE);

export const ADD_BANK_REQUESTED = 'ADD_BANK_REQUESTED';
export const addBankRequested = createAction(ADD_BANK_REQUESTED);

export const ADD_BANK_SUCCESS = 'ADD_BANK_SUCCESS';
export const addBankSuccess = createAction(ADD_BANK_SUCCESS);

export const DELETE_BANK = 'DELETE_BANK';
export const deleteBank = createAction(DELETE_BANK);

export const DELETE_BANK_FAILURE = 'DELETE_BANK_FAILURE';
export const deleteBankFailure = createAction(DELETE_BANK_FAILURE);

export const DELETE_BANK_REQUESTED = 'DELETE_BANK_REQUESTED';
export const deleteBankRequested = createAction(DELETE_BANK_REQUESTED);

export const DELETE_BANK_SUCCESS = 'DELETE_BANK_SUCCESS';
export const deleteBankSuccess = createAction(DELETE_BANK_SUCCESS);

export const FETCH_BANK = 'FETCH_BANK';
export const fetchBank = createAction(FETCH_BANK);

export const FETCH_BANK_FAILURE = 'FETCH_BANK_FAILURE';
export const fetchBankFailure = createAction(FETCH_BANK_FAILURE);

export const FETCH_BANK_REQUESTED = 'FETCH_BANK_REQUESTED';
export const fetchBankRequested = createAction(FETCH_BANK_REQUESTED);

export const FETCH_BANK_SUCCESS = 'FETCH_BANK_SUCCESS';
export const fetchBankSuccess = createAction(FETCH_BANK_SUCCESS);

export const EDIT_BANK = 'EDIT_BANK';
export const editBank = createAction(EDIT_BANK);

export const EDIT_BANK_FAILURE = 'EDIT_BANK_FAILURE';
export const editBankFailure = createAction(EDIT_BANK_FAILURE);

export const EDIT_BANK_REQUESTED = 'EDIT_BANK_REQUESTED';
export const editBankRequested = createAction(EDIT_BANK_REQUESTED);

export const EDIT_BANK_SUCCESS = 'EDIT_BANK_SUCCESS';
export const editBankSuccess = createAction(EDIT_BANK_SUCCESS);

export const FETCH_SINGLE_BANK = 'FETCH_SINGLE_BANK';
export const fetchSingleBank = createAction(FETCH_SINGLE_BANK);

export const FETCH_SINGLE_BANK_FAILURE = 'FETCH_SINGLE_BANK_FAILURE';
export const fetchSingleBankFailure = createAction(FETCH_SINGLE_BANK_FAILURE);

export const FETCH_SINGLE_BANK_REQUESTED = 'FETCH_SINGLE_BANK_REQUESTED';
export const fetchSingleBankRequested = createAction(FETCH_SINGLE_BANK_REQUESTED);

export const FETCH_SINGLE_BANK_SUCCESS = 'FETCH_SINGLE_BANK_SUCCESS';
export const fetchSingleBankSuccess = createAction(FETCH_SINGLE_BANK_SUCCESS);

export const UPDATE_BANK_FORM = 'UPDATE_BANK_FORM';
export const updateBankForm = createAction(UPDATE_BANK_FORM);

export const DELETE_BANK_CLASS = 'DELETE_BANK_CLASS';
export const deleteBankClass = createAction(DELETE_BANK_CLASS);


import { LOCATION_CHANGE } from 'connected-react-router';
import { ADD_SERVICE_FAILURE,
  ADD_SERVICE_REQUESTED,
  ADD_SERVICE_SUCCESS,
  DELETE_SERVICE_FAILURE,
  DELETE_SERVICE_REQUESTED,
  DELETE_SERVICE_SUCCESS,
  FETCH_SERVICE_FAILURE,
  FETCH_SERVICE_REQUESTED,
  FETCH_SERVICE_SUCCESS,
  FETCH_SINGLE_SERVICE_FAILURE,
  FETCH_SINGLE_SERVICE_REQUESTED,
  FETCH_SINGLE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FORM } from '../actions/service-action-type';

const initialState = {
  addServiceStatus: 'pending',
  deleteServiceStatus: 'pending',
  service: [],
  fetchServiceStatus: 'pending',
  fetchSingleServiceStatus: 'pending',
  form: {
    code_service: '',
    name: '',
    status: null,
  },
};

export default function service(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_SERVICE_FAILURE:
      return {
        ...state,
        addServiceStatus: 'failure',
      };

    case ADD_SERVICE_REQUESTED:
      return {
        ...state,
        addServiceStatus: 'creating',
      };

    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        addServiceStatus: 'success',
      };

    case DELETE_SERVICE_FAILURE:
      return {
        ...state,
        deleteServiceStatus: 'failure',
      };

    case DELETE_SERVICE_REQUESTED:
      return {
        ...state,
        deleteServiceStatus: 'deleting',
      };

    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        deleteServiceStatus: 'success',
        service: [...state.service].filter((services) => !(payload.toString().includes(services.id.toString()))),

      };

    case FETCH_SERVICE_FAILURE:
      return {
        ...state,
        fetchServiceStatus: 'failure',
      };

    case FETCH_SERVICE_REQUESTED:
      return {
        ...state,
        fetchServiceStatus: 'fetching',
      };

    case FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        service: payload,
        fetchServiceStatus: 'success',
      };

    case FETCH_SINGLE_SERVICE_FAILURE:
      return {
        ...state,
        fetchSingleServiceStatus: 'failure',
      };

    case FETCH_SINGLE_SERVICE_REQUESTED:
      return {
        ...state,
        fetchSingleServiceStatus: 'fetching',
      };

    case FETCH_SINGLE_SERVICE_SUCCESS:
      return {
        ...state,
        fetchSingleServiceStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_SERVICE_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { push } from 'connected-react-router';
import { ADD_MARK,
  addMarkFailure,
  addMarkRequested,
  addMarkSuccess,
  FETCH_ALL_QUARTER,
  FETCH_ALL_SEQUENCE,
  FETCH_ALL_TYPE_OF_EXAM,
  fetchAllTypeExamSuccess,
  fetchQuarterSuccess,
  fetchSequenceSuccess,
  fetchMarkListSuccess,
  FETCH_MARK_LIST,
  fetchClassStudentMarksSuccess,
  FETCH_CLASS_STUDENT_MARKS,
  SET_CLASS_STUDENT_MARKS,
  FETCH_MARK_FILTER,
  fetchMarkFilterFailure,
  fetchMarkFilterRequested,
  fetchMarkFilterSuccess,
 FETCH_AVERAGE_MARK,
fetchAverageMarkRequested,
fetchAverageMarkFailure,
fetchAverageMarkSuccess, 
SET_MARKS_VALIDATION,
SET_UPDATE_VALIDATION,
FETCH_GENERAL_MARK_FILTER,
fetchGeneralMarkFilterRequested,
fetchGeneralMarkFilterFailure,
fetchGeneralMarkFilterSuccess,
} from '../actions/mark-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';       
import { useLocation } from 'react-router-dom';

export function* fetchAllQuarterHandler({payload}) {
  const request = {
    method: 'GET',
    params: { school_year_id:payload?.school_year_id},
    url: 'quaters',
  };
  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: '',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    yield put(fetchQuarterSuccess(data.data.result));
    if(payload.result)
    payload.result(data.data.result)
  }
}

export function* fetchAllSequenceHandler({}) {
  const request = {
    method: 'GET',
    url: 'sequences',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: '',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    yield put(fetchSequenceSuccess(data.data.result));
  }
}

export function* fetchAllMarkListHandler({ payload }) {
  const request = {
    method: 'GET',
    url: `allclassSubjectForMarks?school_year_id=${payload?.school_year_id}&class_id=${payload.id}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: '',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    yield put(fetchMarkListSuccess(data.data.result));
  }
}

export function* fetchAllTypeExamHandler({ payload }) {
  const request = {
    method: 'GET',
    url: 'get-examtypes',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: '',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    yield put(fetchAllTypeExamSuccess(data.data.result));
  }
}

export function* addMarkHandler({ payload }) {
  yield put(addMarkRequested());
  const body = new FormData();

  body.append('school_year_id', payload.school_year_id);
  body.append('subject_id', payload.subject_id.value);
  body.append('class_id', payload.class_id);
  body.append('quater_id', payload.quater_id.value);
  body.append('sequence_id', payload.sequence_id.value);
  body.append('exam_type_id', payload.exam_type_id.value);
  body.append('created_date', moment(payload.created_date).format('yyyy-MM-DD HH:mm:ss').toString());

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'add-classSubjectMarks',
  };
  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(addMarkFailure(error));
  } else {
    const response = { class_subject_id: data.data.result.class_subject_id };

    yield put(addMarkSuccess(response));

    yield put(
      push({
        params: {},
        pathname: `/marks-update/${payload?.class_id}/${data.data.result.class_subject_id}`,
      }),
    );
  }
}

export function* fetchAverageMarkHandler({ payload }) {
  yield put(fetchAverageMarkRequested());
  const request = {
    method: 'GET',
    params: {
      class_id: payload.id,
      school_year_id: payload.school_year_id,
    },
    url: 'average-list',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchAverageMarkFailure(error));
  } else {
    yield put(fetchAverageMarkSuccess(data.data.result));
  }
}

export function* fetchClassMarkListHandler({ payload }) {
  const request = {
    method: 'GET',
    url: `listStudentMarks?class_subject_id=${payload}`,
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: 'Marks update failed',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    yield put(fetchClassStudentMarksSuccess(data.data.result));
  }
}

export function* setClassStudentMarksHandler({ payload }) {
  const request = {
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    url: 'update-studentMarks',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: 'Marks updating failed',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    const alertProps = {
      callback: () => {
        history.goBack();
      },
      icon: 'success',
      message: 'Class Student Wise Marks updated successfully',
      title: 'Success',
    };

    Alert.alert(alertProps);
  }
}
export function* fetchGeneralMarkFilterHandler({ payload }) {
  yield put(fetchGeneralMarkFilterRequested());

 // console.log('final', moment(payload.creates_after).startOf('day').format('yyyy-MM-DD').toString());
 const request = {method: 'GET',
 params: {
   school_year_id: payload?.school_year_id,
   sequence_id: payload?.sequence_id,
   class_id: payload?.class_id,
   quarter_id: payload?.quarter_id,
   school_id: payload?.quarter_id,
   report_id:payload?.report_id,
 
 },
 url: 'all-student-report',
};

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
   // yield put(fetchGeneralMarkFilterFailure(error));
   yield put(fetchGeneralMarkFilterSuccess( []));
  } else {
    yield put(fetchGeneralMarkFilterSuccess(data?.data?.student_recap || []));
   // window.location.reload();
  }
}

export function* fetchMarkFilterHandler({ payload }) {
  yield put(fetchMarkFilterRequested());

 // console.log('final', moment(payload.creates_after).startOf('day').format('yyyy-MM-DD').toString());
  const request = {
    method: 'GET',
    params: {
      class_id: payload.class_id,
      creates_after: (payload?.creates_after ? moment(payload.creates_after).format('yyyy-MM-DD').toString() : null),
      creates_before: (payload?.creates_before ? moment(payload.creates_before).format('yyyy-MM-DD').toString() : null),
      // income_reason_id: (incomeArray || '').toString(),
      // to: (payload?.to ? moment(payload.to).format('yyyy-MM-DD hh:mm:ss').toString() : ''),
      school_year_id: payload?.school_year_id,
      updated_after: (payload?.updated_after ? moment(payload.updated_after).format('yyyy-MM-DD').toString() : null),
      updated_before: (payload?.updated_before ? moment(payload.updated_before).format('yyyy-MM-DD').toString() : null),
    },
    url: 'dateWiseClassSubject',
  };
  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchMarkFilterFailure(error));
  } else {
    yield put(fetchMarkFilterSuccess(data?.data?.result || []));
  }
}

//Marks validation
export function* setMarksvalidationHandler({ payload }) {
  const request = {
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    url: 'requestMarksValidation',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: 'Marks updating failed',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    const alertProps = {
      callback: () => {
        //history.goForward();//.goBack();
        history.goBack();
      },
      icon: 'success',
      message: 'Class Student Wise Marks updated successfully',
      title: 'Success',
    };

    Alert.alert(alertProps);
  }
}

//Update Validation 

export function* setUpdatevalidationHandler({ payload }) {
  const request = {
    data: payload,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    url: 'validateMarks',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    const alertProps = {
      callback: () => {},
      icon: 'error',
      message: 'Validation updating failed',
      title: 'Error',
    };

    Alert.alert(alertProps);
  } else {
    const alertProps = {
      callback: () => {
        history.goBack();
      },
      icon: 'success',
      message: 'Validation updated successfully',
      title: 'Success',
    };

    Alert.alert(alertProps);
  }
}

 

function* mark() {
  yield all([
    takeLatest(ADD_MARK, addMarkHandler),
    takeLatest(FETCH_ALL_QUARTER, fetchAllQuarterHandler),
    takeLatest(FETCH_ALL_SEQUENCE, fetchAllSequenceHandler),
    takeLatest(FETCH_ALL_TYPE_OF_EXAM, fetchAllTypeExamHandler),
    takeLatest(FETCH_GENERAL_MARK_FILTER, fetchGeneralMarkFilterHandler),
    takeLatest(FETCH_MARK_FILTER, fetchMarkFilterHandler),
    takeLatest(FETCH_MARK_LIST, fetchAllMarkListHandler),
    takeLatest(FETCH_CLASS_STUDENT_MARKS, fetchClassMarkListHandler),
    takeLatest(SET_CLASS_STUDENT_MARKS, setClassStudentMarksHandler),
    takeLatest(FETCH_AVERAGE_MARK, fetchAverageMarkHandler), 
    takeLatest(SET_MARKS_VALIDATION, setMarksvalidationHandler),
    takeLatest(SET_UPDATE_VALIDATION, setUpdatevalidationHandler),
  
  ]);
}

export default mark;

import { createAction } from 'redux-actions';

export const ADD_DISCOUNT = 'ADD_DISCOUNT';
export const addDiscount = createAction(ADD_DISCOUNT);

export const ADD_DISCOUNT_FAILURE = 'ADD_DISCOUNT_FAILURE';
export const addDiscountFailure = createAction(ADD_DISCOUNT_FAILURE);

export const ADD_DISCOUNT_REQUESTED = 'ADD_DISCOUNT_REQUESTED';
export const addDiscountRequested = createAction(ADD_DISCOUNT_REQUESTED);

export const ADD_DISCOUNT_SUCCESS = 'ADD_DISCOUNT_SUCCESS';
export const addDiscountSuccess = createAction(ADD_DISCOUNT_SUCCESS);

export const DELETE_DISCOUNT = 'DELETE_DISCOUNT';
export const deleteDiscount = createAction(DELETE_DISCOUNT);

export const DELETE_DISCOUNT_FAILURE = 'DELETE_DISCOUNT_FAILURE';
export const deleteDiscountFailure = createAction(DELETE_DISCOUNT_FAILURE);

export const DELETE_DISCOUNT_REQUESTED = 'DELETE_DISCOUNT_REQUESTED';
export const deleteDiscountRequested = createAction(DELETE_DISCOUNT_REQUESTED);

export const DELETE_DISCOUNT_SUCCESS = 'DELETE_DISCOUNT_SUCCESS';
export const deleteDiscountSuccess = createAction(DELETE_DISCOUNT_SUCCESS);

export const FETCH_DISCOUNT = 'FETCH_DISCOUNT';
export const fetchDiscount = createAction(FETCH_DISCOUNT);

export const FETCH_DISCOUNT_FAILURE = 'FETCH_DISCOUNT_FAILURE';
export const fetchDiscountFailure = createAction(FETCH_DISCOUNT_FAILURE);

export const FETCH_DISCOUNT_REQUESTED = 'FETCH_DISCOUNT_REQUESTED';
export const fetchDiscountRequested = createAction(FETCH_DISCOUNT_REQUESTED);

export const FETCH_DISCOUNT_SUCCESS = 'FETCH_DISCOUNT_SUCCESS';
export const fetchDiscountSuccess = createAction(FETCH_DISCOUNT_SUCCESS);

export const EDIT_DISCOUNT = 'EDIT_DISCOUNT';
export const editDiscount = createAction(EDIT_DISCOUNT);

export const EDIT_DISCOUNT_FAILURE = 'EDIT_DISCOUNT_FAILURE';
export const editDiscountFailure = createAction(EDIT_DISCOUNT_FAILURE);

export const EDIT_DISCOUNT_REQUESTED = 'EDIT_DISCOUNT_REQUESTED';
export const editDiscountRequested = createAction(EDIT_DISCOUNT_REQUESTED);

export const EDIT_DISCOUNT_SUCCESS = 'EDIT_DISCOUNT_SUCCESS';
export const editDiscountSuccess = createAction(EDIT_DISCOUNT_SUCCESS);

export const FETCH_SINGLE_DISCOUNT = 'FETCH_SINGLE_DISCOUNT';
export const fetchSingleDiscount = createAction(FETCH_SINGLE_DISCOUNT);

export const FETCH_SINGLE_DISCOUNT_FAILURE = 'FETCH_SINGLE_DISCOUNT_FAILURE';
export const fetchSingleDiscountFailure = createAction(FETCH_SINGLE_DISCOUNT_FAILURE);

export const FETCH_SINGLE_DISCOUNT_REQUESTED = 'FETCH_SINGLE_DISCOUNT_REQUESTED';
export const fetchSingleDiscountRequested = createAction(FETCH_SINGLE_DISCOUNT_REQUESTED);

export const FETCH_SINGLE_DISCOUNT_SUCCESS = 'FETCH_SINGLE_DISCOUNT_SUCCESS';
export const fetchSingleDiscountSuccess = createAction(FETCH_SINGLE_DISCOUNT_SUCCESS);

export const UPDATE_DISCOUNT_FORM = 'UPDATE_DISCOUNT_FORM';
export const updateDiscountForm = createAction(UPDATE_DISCOUNT_FORM);

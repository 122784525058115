import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_ATTENDANCE,
  addAttendanceFailure,
  addAttendanceRequested,
  addAttendanceSuccess,
  DELETE_ATTENDANCE,
  deleteAttendanceFailure,
  deleteAttendanceRequested,
  deleteAttendanceSuccess,
  FETCH_ATTENDANCE,
  fetchAttendanceFailure,
  fetchAttendanceRequested,
  fetchAttendanceSuccess,
  FETCH_TEACHER_ATTENDANCE,
  fetchTeacherAttendanceFailure,
  fetchTeacherAttendanceRequested,
  fetchTeacherAttendanceSuccess,
  FETCH_SINGLE_ATTENDANCE,
  fetchSingleAttendanceFailure,
  fetchSingleAttendanceRequested,
  fetchSingleAttendanceSuccess,
  EDIT_ATTENDANCE,
  editAttendanceFailure,
  editAttendanceRequested,
  FETCH_TEACHER_ATTENDANCE_FILTER,
} from '../actions/attendance-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import i18next from 'i18next';

export function* addAttendanceHandler({ payload }) {
  yield put(addAttendanceRequested());
  const body = new FormData();
  
  body.append('start_date', payload?.start_date);
  body.append('end_date', payload?.end_date);
  body.append('marks_from', payload.marks_from);
  body.append('marks_to', payload.marks_to);
  body.append('school_year_id', payload.school_year_id);
  body.append('type', payload.type);
  body.append('Attendance', payload.Attendance);
  body.append('color', payload.color);
  body.append('student_attendance_list', payload.student_attendance_list);
  console.log('This is the data of', payload);
  const request = {
    data: payload,
    method: 'POST',
    url: 'add-attendance',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addAttendanceFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push(`/attendance-at-lesson-list/${payload.class_id}`);
      },
      icon: 'success',
      message: i18next.t('skullman:grades.attendance.createMessage'),
      title: i18next.t('skullman:grades.attendance.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addAttendanceSuccess());
  }
}

export function* editAttendanceHandler({ payload }) {
  yield put(editAttendanceRequested());

  const body = new FormData();

  body.append('marks_from', payload.marks_from);
  body.append('marks_to', payload.marks_to);
  body.append('school_year_id', payload.school_year_id);
  body.append('type', payload.type);
  body.append('Attendance', payload.Attendance);
  body.append('color', payload.color);
  body.append('student_attendance_list', payload.student_attendance_list);

  let id = payload.attendanceId;
  delete payload.attendanceId;
  const request = {
    data: payload,
    method: 'POST',
    url: `update-attendance/${id}`,
  };

  const { data, error } = yield call(httpClient, request);
  if (error) {
    yield put(editAttendanceFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push(`/attendance-at-lesson-list/${payload.class_id}`);
      },
      icon: 'success',
      message: i18next.t('skullman:grades.attendance.updateMessage'),
      title: i18next.t('skullman:grades.attendance.successMessage'),
    };
    // const response = { student_id: data.data.attendance_subject_id };

    Alert.alert(alertProps);
    // yield put(editAttendanceSuccess(response));
  }
}

export function* deleteAttendanceHandler({ payload }) {
  yield put(deleteAttendanceRequested());

  const request = {
    method: 'DELETE',
    url: `delete-attendance/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteAttendanceFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:grades.attendance.deleteMessage'),
      title: i18next.t('skullman:grades.attendance.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteAttendanceSuccess(payload));
  }
}

export function* fetchAttendanceHandler({ payload }) {
  yield put(fetchAttendanceRequested());

  const request = {
    method: 'GET',
    params: {
      class_id: payload.id,
      school_year_id: payload.school_year_id,
    },
    url: 'list-subject-attendance',
  };

  // list-subject-attendance?school_year_id=27&class_id=86;

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchAttendanceFailure(error));
  } else {
    yield put(fetchAttendanceSuccess(data.data.resulst || []));
  }
}

export function* fetchAttendanceFilterHandler({ payload }) {
  yield put(fetchAttendanceRequested());

  const request = {
    method: 'GET',
    params: {
      class_id: payload.class_id,
      school_year_id: payload.school_year_id,
      creates_before: payload.create_before_date &&  moment(payload.create_before_date).format('yyyy-MM-DD').toString(),
      creates_after: payload.create_after_date && moment(payload.create_after_date).format('yyyy-MM-DD').toString(),
      updates_before:payload.update_before_date && moment(payload.update_before_date).format('yyyy-MM-DD').toString(),
      updates_after:payload.update_after_date && moment(payload.update_after_date).format('yyyy-MM-DD').toString(),
    },
    url: 'attendance-filter',
  };

  // list-subject-attendance?school_year_id=27&class_id=86;

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchAttendanceFailure(error));
  } else {
    yield put(fetchAttendanceSuccess(data.data.result || []));
  }
}

export function* fetchTeacherAttendanceHandler({ payload }) {
  yield put(fetchTeacherAttendanceRequested());

  const request = {
    method: 'GET',
    params: { attendance_subject_id: payload },
    url: `all-teacher-Attendance/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchTeacherAttendanceFailure(error));
  } else {
    yield put(fetchTeacherAttendanceSuccess(data.data.result || []));
  }
}

export function* fetchSingleAttendanceHandler({ payload }) {
  yield put(fetchSingleAttendanceRequested());
  const request = {
    method: 'GET',
    params: { attendance_subject_id: payload },
    url: `list-student-attendance`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchSingleAttendanceFailure(error));
  } else {
    const { result } = data.data;
    yield put(fetchSingleAttendanceSuccess(data?.data?.result || []));
  }
}

function* Attendance() {
  yield all([
    takeLatest(ADD_ATTENDANCE, addAttendanceHandler),
    takeLatest(DELETE_ATTENDANCE, deleteAttendanceHandler),
    takeLatest(FETCH_ATTENDANCE, fetchAttendanceHandler),
    takeLatest(FETCH_TEACHER_ATTENDANCE, fetchTeacherAttendanceHandler),
    takeLatest(FETCH_SINGLE_ATTENDANCE, fetchSingleAttendanceHandler),
    takeLatest(EDIT_ATTENDANCE, editAttendanceHandler),
    takeLatest(FETCH_TEACHER_ATTENDANCE_FILTER, fetchAttendanceFilterHandler),
  ]);
}

export default Attendance;

import { string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
//import '/'

const MarksViewRenderer = ({
  page, classId, classSubjectId,examStatus
}) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    if (page == 'student-details') dispatch(push('/student-details/86'));
    else dispatch(push(`/marks-details/${classId}/${classSubjectId}`));
  };
  const onClickEdit = () =>{
    dispatch(push(`/marks-update/${classId}/${classSubjectId}`));
  }
  return (
    <>
      <div className="text-center">
        <button onClick={onClickHandler} className="btn btn-warning" type="button"> <i className="lnr lnr-license mr-1" /><span>Result</span></button>
        {examStatus != 'Validated' ? <button onClick={onClickEdit} className="btn btn-primary ml-1"><i className="lnr-pencil mr-1" /><span>Edit</span></button> : ''}        
      </div>
    </>
  );
};

MarksViewRenderer.propTypes = { className: string };

export default MarksViewRenderer;

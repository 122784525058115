import React from 'react';

const MarksAppreciationCellRenderer = ({
  className, text, color,
}) => {

  // const [bg, SetBg] = React.useState('');
  // const data = class_student_marks.map((color) => color.teacher_appreciation_color);

  // React.useEffect(() => {
  //   if (data == '#e20413') {
  //     SetBg('bg-red');
  //   } else if (data == '#f88c05') {
  //     SetBg('bg-orange');
  //   } else if (data == '#fce911') {
  //     SetBg('bg-yellow');
  //   } else if (data == '#34a925') {
  //     SetBg('bg-green');
  //   } else if (data == '#1f72b8') {
  //     SetBg('bg-blue');
  //   } else if (data == '#662382') {
  //     SetBg('bg-indigo');
  //   } else if (data == '#8c0f73') {
  //     SetBg('bg-violet');
  //   }
  // }, [data]);

  console.log(text,color,className)
  return (
    <>
    {
      text !=undefined && (
           <span className={`badge text-center`}  style={{backgroundColor:color,color:'white',color: 'white',
           fontSize: '10px',
           borderRadius: '4px',
           border: 'none',
           height: '26px',
           display: 'flex',
           alignItems: 'center'}}>
      {text ? text:""}
    </span>
      )
    }
 
  </>
  )
};




export default MarksAppreciationCellRenderer;

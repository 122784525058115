import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";

export default function Dropzone({ open }) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <DragZone {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <ZoneParagraph>Drop the files here ...</ZoneParagraph>
        ) : (
          <>
            <div>
              <img
                src="/images/file-upload.png"
                width="20px"
                height="16px"
                style={{ marginBottom: 8 }}
              />
            </div>
            <ZoneParagraph>
              Drag & drop file here or click to select file.
            </ZoneParagraph>
            <ZoneParagraph>Upload max 4 photos or also a video.</ZoneParagraph>
          </>
        )}
      </DragZone>
      <ThumbnailContainer>
        <Row>
          <ImageThumbnailCols>
            <ImageThumbnails src="/images/Rectangle 34625683.png" />
            <CancelIcon />
          </ImageThumbnailCols>
          <ImageThumbnailCols>
            <ImageThumbnails src="/images/Rectangle 34625683.png" />
            <CancelIcon />
          </ImageThumbnailCols>
          <ImageThumbnailCols>
            <ImageThumbnails src="/images/tower.png" />
            <CancelIcon />
            <VideoIcon src="/images/video.png" />
          </ImageThumbnailCols>
          <ImageThumbnailCols>
            <ImageThumbnails src="/images/Rectangle 34625683.png" />
            <CancelIcon />
          </ImageThumbnailCols>
        </Row>
      </ThumbnailContainer>
    </>
  );
}

const DragZone = styled.div`
  height: 88px;
  background: rgba(85, 91, 212, 0.13);
  border: 1px solid rgba(85, 91, 212, 0.11);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5px;
`;

const ZoneParagraph = styled.p`
  font-size: 10px;
  color: rgba(0, 0, 0, 0.42);
  margin-bottom: 5px;
`;

const ImageThumbnailCols = styled(Col)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ImageThumbnails = styled.img`
  border-radius: 13px;
  width: 100%;
`;

const ThumbnailContainer = styled.div`
  margin-top: 20px;
`;

const CancelIcon = styled(AiFillCloseCircle)`
  position: absolute;
  right: 18px;
  top: 6px;
  width: 9.17px;
  height: 9.19px;
`;

const VideoIcon = styled.img`
  position: absolute;
`;

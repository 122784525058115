import { createAction } from 'redux-actions';

export const ADD_CHAPTER = 'ADD_CHAPTER';
export const addChapter = createAction(ADD_CHAPTER);

export const COMBINE_CHAPTER = 'COMBINE_CHAPTER';
export const combineChapter = createAction(COMBINE_CHAPTER);

export const EDIT_COMBINE_CHAPTER = 'EDIT_COMBINE_CHAPTER';
export const editCombineChapter = createAction(EDIT_COMBINE_CHAPTER);

export const EDIT_COMBINE_PART = 'EDIT_COMBINE_PART';
export const editCombinePart = createAction(EDIT_COMBINE_PART);


export const DELETE_CHAPTER_FORCAST = 'DELETE_CHAPTER_FORCAST';
export const deleteChapterForcast = createAction(DELETE_CHAPTER_FORCAST);

export const ADD_CHAPTER_FAILURE = 'ADD_CHAPTER_FAILURE';
export const addChapterFailure = createAction(ADD_CHAPTER_FAILURE);

export const ADD_CHAPTER_REQUESTED = 'ADD_CHAPTER_REQUESTED';
export const addChapterRequested = createAction(ADD_CHAPTER_REQUESTED);

export const ADD_CHAPTER_SUCCESS = 'ADD_CHAPTER_SUCCESS';
export const addChapterSuccess = createAction(ADD_CHAPTER_SUCCESS);

export const DELETE_CHAPTER = 'DELETE_CHAPTER';
export const deleteChapter = createAction(DELETE_CHAPTER);

export const DELETE_CHAPTER_FAILURE = 'DELETE_CHAPTER_FAILURE';
export const deleteChapterFailure = createAction(DELETE_CHAPTER_FAILURE);

export const DELETE_CHAPTER_REQUESTED = 'DELETE_CHAPTER_REQUESTED';
export const deleteChapterRequested = createAction(DELETE_CHAPTER_REQUESTED);

export const DELETE_CHAPTER_SUCCESS = 'DELETE_CHAPTER_SUCCESS';
export const deleteChapterSuccess = createAction(DELETE_CHAPTER_SUCCESS);

export const FETCH_CHAPTER = 'FETCH_CHAPTER';
export const fetchChapter = createAction(FETCH_CHAPTER);

export const FETCH_CHAPTER_FAILURE = 'FETCH_CHAPTER_FAILURE';
export const fetchChapterFailure = createAction(FETCH_CHAPTER_FAILURE);

export const FETCH_CHAPTER_REQUESTED = 'FETCH_CHAPTER_REQUESTED';
export const fetchChapterRequested = createAction(FETCH_CHAPTER_REQUESTED);

export const FETCH_CHAPTER_SUCCESS = 'FETCH_CHAPTER_SUCCESS';
export const fetchChapterSuccess = createAction(FETCH_CHAPTER_SUCCESS);

export const EDIT_CHAPTER = 'EDIT_CHAPTER';
export const editChapter = createAction(EDIT_CHAPTER);

export const ADD_SINGLE_PART_FORCAST = 'ADD_SINGLE_PART_FORCAST';
export const addSinglePartForcast = createAction(ADD_SINGLE_PART_FORCAST);

export const UPDATE_SINGLE_PART_FORCAST = 'UPDATE_SINGLE_PART_FORCAST';
export const editSinglePartForcast = createAction(UPDATE_SINGLE_PART_FORCAST);

export const EDIT_CHAPTER_FAILURE = 'EDIT_CHAPTER_FAILURE';
export const editChapterFailure = createAction(EDIT_CHAPTER_FAILURE);

export const EDIT_CHAPTER_REQUESTED = 'EDIT_CHAPTER_REQUESTED';
export const editChapterRequested = createAction(EDIT_CHAPTER_REQUESTED);

export const EDIT_CHAPTER_SUCCESS = 'EDIT_CHAPTER_SUCCESS';
export const editChapterSuccess = createAction(EDIT_CHAPTER_SUCCESS);

export const FETCH_SINGLE_CHAPTER = 'FETCH_SINGLE_CHAPTER';
export const fetchSingleChapter = createAction(FETCH_SINGLE_CHAPTER);

export const FETCH_SINGLE_CHAPTER_FAILURE = 'FETCH_SINGLE_CHAPTER_FAILURE';
export const fetchSingleChapterFailure = createAction(FETCH_SINGLE_CHAPTER_FAILURE);

export const FETCH_SINGLE_CHAPTER_REQUESTED = 'FETCH_SINGLE_CHAPTER_REQUESTED';
export const fetchSingleChapterRequested = createAction(FETCH_SINGLE_CHAPTER_REQUESTED);

export const FETCH_SINGLE_CHAPTER_SUCCESS = 'FETCH_SINGLE_CHAPTER_SUCCESS';
export const fetchSingleChapterSuccess = createAction(FETCH_SINGLE_CHAPTER_SUCCESS);

export const UPDATE_CHAPTER_FORM = 'UPDATE_CHAPTER_FORM';
export const updateChapterForm = createAction(UPDATE_CHAPTER_FORM);

export const FETCH_CHAPTER_FILTER = 'FETCH_CHAPTER_FILTER';
export const fetchChapterFilter = createAction(FETCH_CHAPTER_FILTER);

export const FETCH_CHAPTER_FILTER_REQUESTED = 'FETCH_CHAPTER_FILTER_REQUESTED';
export const fetchChapterFilterRequested = createAction(FETCH_CHAPTER_FILTER_REQUESTED);

export const FETCH_CHAPTER_FILTER_FAILURE = 'FETCH_CHAPTER_FILTER_FAILURE';
export const fetchChapterFilterFailure = createAction(FETCH_CHAPTER_FILTER_FAILURE);

export const FETCH_CHAPTER_FILTER_SUCCESS = 'FETCH_CHAPTER_FILTER_SUCCESS';
export const fetchChapterFilterSuccess = createAction(FETCH_CHAPTER_FILTER_SUCCESS);
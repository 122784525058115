import React from 'react';
import { func, number, object } from 'prop-types';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useParams, useLocation } from 'react-router-dom';
import Button from '../../../components/button';
import DateCalenderInput from '../../../components/date-calender-input';
import Modal from '../../../components/modal';
import Alert from '../../../utility/alert';
import { addTransport, updateTransportForm, fetchSingleTransport, editTransport, fetchTransport } from '../../../actions/transport-action-types';
import Regex from '../../../utility/regex';
import { fetchSingleEnrollment } from '../../../actions/enrollment-action-type';
import {useTranslation} from "react-i18next";

export const AddEditTransport = ({
  onClose, data, stu_id, paramsId, school_year_id, isPromotion,
}) => {
  const dispatch = useDispatch();
  const student_id = isPromotion == 1 ? stu_id : (paramsId || stu_id);
  const transportId = data.id;
  const [isPause, setPause] = React.useState(false);
  const {
    form, responseCode, statusCode, transport,
  } = useSelector((store) => ({
    form: store.transport.form,
    responseCode: store.transport.responseCode,
    statusCode: store.transport.statusCode,
    transport: store.transport.transport,
  }));
  const [t] = useTranslation('skullman');
  const {
    amount_to_pay, deadline, quater_name,
  } = form;

  const checkQuarter = transport?.map((n) => n?.quater_name);

  console.log(transport, checkQuarter, 'transport stud');
  React.useEffect(() => {
    if (data && data.id) {
      dispatch(fetchSingleTransport(transportId));
      const updates = {
        ...form,
        student_id,
      };

      dispatch(updateTransportForm(updates));
    }
  }, []);
  // React.useEffect(() => {
  //   if ((responseCode && responseCode.response == 201) || (statusCode && statusCode.response == 202)) {
  //     const request = {
  //       school_year_id,
  //       student_id,
  //     };

  //     dispatch(fetchTransport(request));
  //   }
  // }, [responseCode, statusCode]);

  const onChange = (e) => {
    const updates = {
      ...form,
      [e.target.name]: e.target.value,
      student_id,

    };

    dispatch(updateTransportForm(updates));
  };

  const onSelectChange = (name, value) => {
    const updates = {
      ...form,
      [name]: value,
      student_id,
    };

    dispatch(updateTransportForm(updates));
  };
  const onValidate = async () => {
    if (!Regex.numbers(amount_to_pay)) {
      Alert.alert('Amount  is required');

      return;
    }
    if (!deadline) {
      Alert.alert('  Deadline  is required');

      return;
    }

    if (!quater_name) {
      Alert.alert('Quarter Name  is required');

      return;
    }

    if (!student_id && paramsId == undefined) {
      Alert.alert('Student Id   is required');

      return;
    }

    const updates = {
      ...form,
      school_year_id,
      student_id,
    };

    if (data && data.id) {
      dispatch(editTransport(updates));
      setPause(true);
    } else {
      if (checkQuarter?.includes('Quarter 1') && quater_name.value == 'Quarter 1') {
        Alert.alert('Quarter already exists');

        return;
      }

      if (checkQuarter?.includes('Quarter 2') && quater_name.value == 'Quarter 2') {
        Alert.alert('Quarter already exists');

        return;
      }

      if (checkQuarter?.includes('Quarter 3') && quater_name.value == 'Quarter 3') {
        Alert.alert('Quarter already exists');

        return;
      }
      dispatch(addTransport(updates));

      setPause(true);
    }
    setTimeout(() => {
      const request = {
        school_year_id,
        student_id,
      };

      // console.log(responseCode, 'RESPONSE', statusCode);
      // if (responseCode == 201 || statusCode == 202) {
      //   console.log(responseCode, 'RESPON-SE code', statusCode);
      //   dispatch(fetchTransport(request));
      // }
      onClose();
      const options = {
        ...form,
        amount_to_pay: '',
        deadline: null,
        quater_name: '',
      };

      dispatch(updateTransportForm(options));
    }, 500);
  };

  const handleModalClose = () => {
    const options = {
      ...form,
      amount_to_pay: '',
      deadline: null,
      quater_name: '',
    };

    dispatch(updateTransportForm(options));
    onClose();
  };

  return (
    <Modal>
      <div
        className="modal fade show"
        id="addingParentInfo"
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        style={{
          background: 'rgba(0, 0, 0, 0.5)', display: 'block', paddingRight: 15,
        }}
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <h4 className="text-center mt-4 mb-0">{t('tution.enrollment.addTransport')}</h4>
            <Button
              type="button"
              className="close topRight"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleModalClose}
            >
              <span aria-hidden="true">×</span>
            </Button>
            <div className="modal-body px-4 mx-2 mt-3 pb-5">

              <form noValidate autoComplete='off'>
                <div className="form-group row align-items-center">
                  <label className="control-label col-sm-5 my-0">
                    {t('tution.payment.installment.quarterName')}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-7">
                    <Select
                      id="quater_name"
                      name="quater_name"
                      placeholder={t('tution.payment.installment.enterquarterName')}
                      onChange={(value) => onSelectChange('quater_name', value)}
                      options={[
                        {
                          label: 'Quarter 1',
                          value: 'Quarter 1',
                        }, {
                          label: 'Quarter 2',
                          value: 'Quarter 2',
                        }, {
                          label: 'Quarter 3',
                          value: 'Quarter 3',
                        },
                      ]}
                      value={quater_name}
                    />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <label className="control-label col-sm-5 my-0">
                    {t('tution.payment.installment.amountToPay')}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-7">
                    <input
                      className="form-control"
                      id="amount_to_pay"
                      name="amount_to_pay"
                      placeholder={t('tution.payment.installment.enterAmount')}
                      onChange={onChange}
                      value={amount_to_pay}
                      type="number"
                    />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <label className="control-label col-sm-5 my-0">
                    {t('tution.payment.installment.deadline')}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-7">
                    <div className="calendar-icon">
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        id='date_of_birth'
                        name='date_of_birth'
                        customInput={<DateCalenderInput />}
                        dateFormat="dd/MM/yyyy"
                        title={t('tution.payment.installment.selectDateTitle')}
                        placeholderText={t('tution.payment.installment.selectDateTitle')}
                        className="form-control datePicker"
                        onChange={(date) => {
                          const payload = {
                            ...form,
                            deadline: date,
                          };

                          dispatch(updateTransportForm(payload));
                        }}
                        selected={deadline}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="button" className="btn btn-primary px-4" onClick={onValidate}>
                  {t('tution.payment.installment.add')}
                  </button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </div>
    </Modal>
  );
};

AddEditTransport.propTypes = {
  data: object.isRequired,
  onClose: func.isRequired,
  stu_id: number.isRequired,
};

export default AddEditTransport;

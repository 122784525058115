import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_EXAM_ATTENDANCE,
  addExamAttendanceFailure,
  addExamAttendanceRequested,
  addExamAttendanceSuccess,
  DELETE_EXAM_ATTENDANCE,
  deleteExamAttendanceFailure,
  deleteExamAttendanceRequested,
  deleteExamAttendanceSuccess,
  FETCH_EXAM_ATTENDANCE,
  fetchExamAttendanceFailure,
  fetchExamAttendanceRequested,
  fetchExamAttendanceSuccess,
  FETCH_TEACHER_ATTENDANCE,
  fetchTeacherAttendanceFailure,
  fetchTeacherAttendanceRequested,
  fetchTeacherAttendanceSuccess,
  FETCH_SINGLE_EXAM_ATTENDANCE,
  fetchSingleAttendanceFailure,
  fetchSingleExamAttendanceRequested,
  fetchSingleAttendanceSuccess,
  EDIT_EXAM_ATTENDANCE,
  editAttendanceFailure,
  editExamAttendanceRequested,
  FETCH_TEACHER_ATTENDANCE_FILTER,
} from '../actions/attendance-exam-action-types';
import httpClient from './http-client';
import Alert from '../utility/alert';
import { history } from '../index';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import i18next from 'i18next';

export function* addAttendanceHandler({ payload }) {
  yield put(addExamAttendanceRequested());
  const body = new FormData();
   
  body.append('start_date', payload?.start_date);
  body.append('end_date', payload?.end_date);
  body.append('marks_from', payload.marks_from);
  body.append('marks_to', payload.marks_to);
  body.append('school_year_id', payload.school_year_id);
  body.append('type', payload.type);
  body.append('Attendance', payload.Attendance);
  body.append('color', payload.color);
  body.append('student_attendance_list', payload.student_attendance_list);
  console.log('This is the data of', payload);
  const request = {
    data: payload,
    method: 'POST',
    url: 'add-attendance-at-exam',
  };
  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(addExamAttendanceFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push(`/attendance-at-exam-list/${payload.class_id}`);
      },
      icon: 'success',
      message: i18next.t('skullman:grades.attendance.createMessage'),
      title: i18next.t('skullman:grades.attendance.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(addExamAttendanceSuccess());
  }
}

export function* editExamAttendanceHandler({ payload }) {
  yield put(editExamAttendanceRequested());

  const body = new FormData();

  body.append('marks_from', payload.marks_from);
  body.append('marks_to', payload.marks_to);
  body.append('school_year_id', payload.school_year_id);
  body.append('type', payload.type);
  body.append('Attendance', payload.Attendance);
  body.append('color', payload.color);
  body.append('student_attendance_list', payload.student_attendance_list);

  let id = payload.attendanceId;
  delete payload.attendanceId;
  const request = {
    data: payload,
    method: 'POST',
    url: `update-attendance-at-exam/${id}`,
  };

  const { data, error } = yield call(httpClient, request);
  if (error) {
    yield put(editAttendanceFailure(error));
  } else {
    const alertProps = {
      callback: () => {
        history.push(`/attendance-at-exam-list/${payload.class_id}`);
      },
      icon: 'success',
      message: i18next.t('skullman:grades.attendance.updateMessage'),
      title: i18next.t('skullman:grades.attendance.successMessage'),
    };
    // const response = { student_id: data.data.attendance_subject_id };

    Alert.alert(alertProps);
    // yield put(editAttendanceSuccess(response));
  }
}

export function* deleteExamAttendanceHandler({ payload }) {
  yield put(deleteExamAttendanceRequested());

  const request = {
    method: 'DELETE',
    url: `delete-attendance/${payload}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(deleteExamAttendanceFailure(error));
  } else {
    const alertProps = {
      icon: 'success',
      message: i18next.t('skullman:grades.attendance.deleteMessage'),
      title: i18next.t('skullman:grades.attendance.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(deleteExamAttendanceSuccess(payload));
  }
}

export function* fetchAttendanceHandler({ payload }) {
  yield put(fetchExamAttendanceRequested());

  const request = {
    method: 'GET',
    params: {
      class_id: payload.id,
      school_year_id: payload.school_year_id,
    },
    url: 'list-exam-attendance',
  };

  // list-subject-attendance?school_year_id=27&class_id=86;

  const { data, error } = yield call(httpClient, request);  

  if (error) {
    yield put(fetchExamAttendanceFailure(error));
  } else {
    yield put(fetchExamAttendanceSuccess(data.data.result || []));
  }
}

export function* fetchAttendanceFilterHandler({ payload }) {
  yield put(fetchExamAttendanceRequested());

  const request = {
    method: 'GET',
    params: {
      class_id: payload.class_id,
      school_year_id: payload.school_year_id,
      creates_before: payload.create_before_date &&  moment(payload.create_before_date).format('yyyy-MM-DD').toString(),
      creates_after: payload.create_after_date && moment(payload.create_after_date).format('yyyy-MM-DD').toString(),
      updates_before:payload.update_before_date && moment(payload.update_before_date).format('yyyy-MM-DD').toString(),
      updates_after:payload.update_after_date && moment(payload.update_after_date).format('yyyy-MM-DD').toString(),
    },
    url: 'attendance-filter',
  };

  // list-subject-attendance?school_year_id=27&class_id=86;

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchExamAttendanceFailure(error));
  } else {
    yield put(fetchExamAttendanceSuccess(data.data.result || []));
  }
}

export function* fetchTeacherAttendanceHandler({ payload }) {
  yield put(fetchTeacherAttendanceRequested());

  const request = {
    method: 'GET',
    params: { attendance_exam_id: payload },
    url: `all-teacher-Attendance/${payload}`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchTeacherAttendanceFailure(error));
  } else {
    yield put(fetchTeacherAttendanceSuccess(data.data.result || []));
  }
}

export function* fetchSingleExamAttendanceHandler({ payload }) {
  yield put(fetchSingleExamAttendanceRequested());
  const request = {
    method: 'GET',
    params: { attendance_exam_id: payload },
    url: `list-student-attendance-at-exam`,
  };

  const { data, error } = yield call(httpClient, request);
 
  if (error) {
    yield put(fetchSingleAttendanceFailure(error));
  } else {
    const { result } = data.data;
    yield put(fetchSingleAttendanceSuccess(data?.data?.result || []));    
  }
}

function* ExamAttendance() {
  yield all([
    takeLatest(ADD_EXAM_ATTENDANCE, addAttendanceHandler),
    takeLatest(DELETE_EXAM_ATTENDANCE, deleteExamAttendanceHandler),
    takeLatest(FETCH_EXAM_ATTENDANCE, fetchAttendanceHandler),
    takeLatest(FETCH_TEACHER_ATTENDANCE, fetchTeacherAttendanceHandler),
    takeLatest(FETCH_SINGLE_EXAM_ATTENDANCE, fetchSingleExamAttendanceHandler),
    takeLatest(EDIT_EXAM_ATTENDANCE, editExamAttendanceHandler),
    takeLatest(FETCH_TEACHER_ATTENDANCE_FILTER, fetchAttendanceFilterHandler),
  ]);
}

export default ExamAttendance;

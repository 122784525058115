import { LOCATION_CHANGE } from 'connected-react-router';

import { CREATE_SUBJECT_FAILURE,
  CREATE_SUBJECT_REQUESTED,
  CREATE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAILURE,
  DELETE_SUBJECT_REQUESTED,
  DELETE_SUBJECT_SUCCESS,
  FETCH_SUBJECT_FAILURE,
  FETCH_SUBJECT_REQUESTED,
  FETCH_SUBJECT_SUCCESS,
  FETCH_SINGLE_SUBJECT_FAILURE,
  FETCH_SINGLE_SUBJECT_REQUESTED,
  FETCH_SINGLE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FORM,
  FETCH_SUBJECT_TYPES_SUCCESS } from '../actions/subject-action-types';

const initialState = {
  createClassStatus: 'pending',
  deleteClassStatus: 'pending',
  fetchClassesStatus: 'pending',
  fetchSingleClassStatus: 'pending',
  form: {
    subject_code: '',
    subject_name: '',
    grade_id: null,
    color: '',
    status: null,
  },
  subjectTypes: [],
  subjects: [],
};

export default function subjects(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case CREATE_SUBJECT_FAILURE:
      return {
        ...state,
        createClassStatus: 'failure',
      };

    case CREATE_SUBJECT_REQUESTED:
      return {
        ...state,
        createClassStatus: 'creating',
      };

    case CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        createClassStatus: 'success',
      };

    case DELETE_SUBJECT_FAILURE:
      return {
        ...state,
        deleteClassStatus: 'failure',
      };

    case DELETE_SUBJECT_REQUESTED:
      return {
        ...state,
        deleteClassStatus: 'deleting',
      };

    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        deleteClassStatus: 'success',
        subjects: [...state.subjects].filter((Item) => !(payload.toString().includes(Item.id.toString()))),
      };

    case FETCH_SUBJECT_FAILURE:
      return {
        ...state,
        fetchClassesStatus: 'failure',
      };

    case FETCH_SUBJECT_REQUESTED:
      return {
        ...state,
        fetchClassesStatus: 'fetching',
      };

    case FETCH_SUBJECT_SUCCESS:
      return {
        ...state,
        fetchClassesStatus: 'success',
        subjects: payload,
      };

    case FETCH_SINGLE_SUBJECT_FAILURE:
      return {
        ...state,
        fetchSingleClassStatus: 'failure',
      };

    case FETCH_SINGLE_SUBJECT_REQUESTED:
      return {
        ...state,
        fetchSingleClassStatus: 'fetching',
      };

    case FETCH_SINGLE_SUBJECT_SUCCESS:
      return {
        ...state,
        fetchSingleClassStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };

    case UPDATE_SUBJECT_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    case FETCH_SUBJECT_TYPES_SUCCESS:
      return {
        ...state,
        subjectTypes: payload,
      };

    default:
      return state;
  }
}

import { createAction } from 'redux-actions';

export const ADD_ATTENDANCE = 'ADD_ATTENDANCE';
export const addAttendance = createAction(ADD_ATTENDANCE);

export const ADD_ATTENDANCE_FAILURE = 'ADD_ATTENDANCE_FAILURE';
export const addAttendanceFailure = createAction(ADD_ATTENDANCE_FAILURE);

export const ADD_ATTENDANCE_REQUESTED = 'ADD_ATTENDANCE_REQUESTED';
export const addAttendanceRequested = createAction(ADD_ATTENDANCE_REQUESTED);

export const ADD_ATTENDANCE_SUCCESS = 'ADD_ATTENDANCE_SUCCESS';
export const addAttendanceSuccess = createAction(ADD_ATTENDANCE_SUCCESS);

export const DELETE_ATTENDANCE = 'DELETE_ATTENDANCE';
export const deleteAttendance = createAction(DELETE_ATTENDANCE);

export const DELETE_ATTENDANCE_FAILURE = 'DELETE_ATTENDANCE_FAILURE';
export const deleteAttendanceFailure = createAction(DELETE_ATTENDANCE_FAILURE);

export const DELETE_ATTENDANCE_REQUESTED = 'DELETE_ATTENDANCE_REQUESTED';
export const deleteAttendanceRequested = createAction(DELETE_ATTENDANCE_REQUESTED);

export const DELETE_ATTENDANCE_SUCCESS = 'DELETE_ATTENDANCE_SUCCESS';
export const deleteAttendanceSuccess = createAction(DELETE_ATTENDANCE_SUCCESS);

export const FETCH_ATTENDANCE = 'FETCH_ATTENDANCE';
export const fetchAttendance = createAction(FETCH_ATTENDANCE);

export const FETCH_ATTENDANCE_FAILURE = 'FETCH_ATTENDANCE_FAILURE';
export const fetchAttendanceFailure = createAction(FETCH_ATTENDANCE_FAILURE);

export const FETCH_ATTENDANCE_REQUESTED = 'FETCH_ATTENDANCE_REQUESTED';
export const fetchAttendanceRequested = createAction(FETCH_ATTENDANCE_REQUESTED);

export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS';
export const fetchAttendanceSuccess = createAction(FETCH_ATTENDANCE_SUCCESS);

export const EDIT_ATTENDANCE = 'EDIT_ATTENDANCE';
export const editAttendance = createAction(EDIT_ATTENDANCE);

export const EDIT_ATTENDANCE_FAILURE = 'EDIT_ATTENDANCE_FAILURE';
export const editAttendanceFailure = createAction(EDIT_ATTENDANCE_FAILURE);

export const EDIT_ATTENDANCE_REQUESTED = 'EDIT_ATTENDANCE_REQUESTED';
export const editAttendanceRequested = createAction(EDIT_ATTENDANCE_REQUESTED);

export const EDIT_ATTENDANCE_SUCCESS = 'EDIT_ATTENDANCE_SUCCESS';
export const editAttendanceSuccess = createAction(EDIT_ATTENDANCE_SUCCESS);

export const FETCH_SINGLE_ATTENDANCE = 'FETCH_SINGLE_ATTENDANCE';
export const fetchSingleAttendance = createAction(FETCH_SINGLE_ATTENDANCE);

export const FETCH_SINGLE_ATTENDANCE_FAILURE = 'FETCH_SINGLE_ATTENDANCE_FAILURE';
export const fetchSingleAttendanceFailure = createAction(FETCH_SINGLE_ATTENDANCE_FAILURE);

export const FETCH_SINGLE_ATTENDANCE_REQUESTED = 'FETCH_SINGLE_ATTENDANCE_REQUESTED';
export const fetchSingleAttendanceRequested = createAction(FETCH_SINGLE_ATTENDANCE_REQUESTED);

export const FETCH_SINGLE_ATTENDANCE_SUCCESS = 'FETCH_SINGLE_ATTENDANCE_SUCCESS';
export const fetchSingleAttendanceSuccess = createAction(FETCH_SINGLE_ATTENDANCE_SUCCESS);

export const UPDATE_ATTENDANCE_FORM = 'UPDATE_ATTENDANCE_FORM';
export const updateAttendanceForm = createAction(UPDATE_ATTENDANCE_FORM);

export const FETCH_TEACHER_ATTENDANCE = 'FETCH_TEACHER_ATTENDANCE';
export const fetchTeacherAttendance = createAction(FETCH_TEACHER_ATTENDANCE);

export const FETCH_TEACHER_ATTENDANCE_FAILURE = 'FETCH_TEACHER_ATTENDANCE_FAILURE';
export const fetchTeacherAttendanceFailure = createAction(FETCH_TEACHER_ATTENDANCE_FAILURE);

export const FETCH_TEACHER_ATTENDANCE_REQUESTED = 'FETCH_TEACHER_ATTENDANCE_REQUESTED';
export const fetchTeacherAttendanceRequested = createAction(FETCH_TEACHER_ATTENDANCE_REQUESTED);

export const FETCH_TEACHER_ATTENDANCE_SUCCESS = 'FETCH_TEACHER_ATTENDANCE_SUCCESS';
export const fetchTeacherAttendanceSuccess = createAction(FETCH_TEACHER_ATTENDANCE_SUCCESS);

export const SET_INIT_STATE = 'SET_INIT_STATE';
export const setInitState = createAction(SET_INIT_STATE);

export const FETCH_TEACHER_ATTENDANCE_FILTER = 'FETCH_TEACHER_ATTENDANCE_FILTER';
export const fetchTeacherAttendanceFilter = createAction(FETCH_TEACHER_ATTENDANCE_FILTER);

export const FETCH_TEACHER_ATTENDANCE_FILTER_SUCCESS = 'FETCH_TEACHER_ATTENDANCE_FILTER_SUCCESS';
export const fetchTeacherAttendanceFilterSuccess = createAction(FETCH_TEACHER_ATTENDANCE_FILTER_SUCCESS);


import {  } from "../actions/level-action-types";
import { FETCH_PRIMARY_REPORT_SUCCESS } from "../actions/primary-mark-report-action-type";
import { FETCH_SINGLE_SKILLS_SUCCESS, FETCH_SKILLS_SUCCESS } from "../actions/skill-action-types";
import { FETCH_SINGLE_SUBJECT_TYPE_SUCCESS, FETCH_SUBJECT_TYPE_BY_LEVEL_ID_SUCCESS, FETCH_SUBJECT_TYPE_SUCCESS } from "../actions/subject-type-action-types";

const intitalState = {
    markPrimaryReport: [],
};

const markPrimaryReportReducer = (state = intitalState, action) => {
    switch (action.type) {
        case FETCH_PRIMARY_REPORT_SUCCESS:
            return {
                ...state,
                markPrimaryReport: action.payload,
            }

        default:
            return { ...state };
    }
};
export default markPrimaryReportReducer;
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ADD_FORECAST_FAILURE,
  ADD_FORECAST_REQUESTED,
  ADD_FORECAST_SUCCESS,
  DELETE_FORECAST_FAILURE,
  DELETE_FORECAST_REQUESTED,
  DELETE_FORECAST_SUCCESS,
  FETCH_FORECAST_FAILURE,
  FETCH_FORECAST_REQUESTED,
  FETCH_FORECAST_SUCCESS,
  FETCH_SINGLE_FORECAST_FAILURE,
  FETCH_SINGLE_FORECAST_REQUESTED,
  FETCH_SINGLE_FORECAST_SUCCESS,
  FETCH_WEEK_NUMBER_FAILURE,
  FETCH_WEEK_NUMBER_REQUESTED,
  FETCH_WEEK_NUMBER_SUCCESS,
  UPDATE_FORECAST_FORM,
} from '../actions/forecast-action-types';

const initialState = {
  addForecastStatus: 'pending',
  deleteForecastStatus: 'pending',
  fetchForecastStatus: 'pending',
  fetchSingleForecastStatus: 'pending',
  fetchWeekNumberStatus: 'pending',
  forecasts: [],
  form: {
    chapter_id: '',
    comment: '',
    part_id: '',
    school_year_id: '',
    subject_id: '',
    week_number_id: '',
    grade_id: '',
    quater_id: '',
  },
  weekNumbers: [],
};

export default function Forecast(state = initialState, { payload, type }) {
  switch (type) {
    case ADD_FORECAST_FAILURE:
      return {
        ...state,
        addForecastStatus: 'failure',
      };

    case ADD_FORECAST_REQUESTED:
      return {
        ...state,
        addForecastStatus: 'creating',
      };

    case ADD_FORECAST_SUCCESS:
      return {
        ...state,
        addForecastStatus: 'success',
      };

    case DELETE_FORECAST_FAILURE:
      return {
        ...state,
        deleteForecastStatus: 'failure',
      };

    case DELETE_FORECAST_REQUESTED:
      return {
        ...state,
        deleteForecastStatus: 'deleting',
      };

    case DELETE_FORECAST_SUCCESS:
      return {
        ...state,
        deleteForecastStatus: 'success',
        forecasts: [...state.forecasts].filter((Forecasts) => !payload.toString().includes(Forecasts.id.toString())),
      };

    case FETCH_FORECAST_FAILURE:
      return {
        ...state,
        fetchForecastStatus: 'failure',
      };

    case FETCH_FORECAST_REQUESTED:
      return {
        ...state,
        fetchForecastStatus: 'fetching',
      };

    case FETCH_FORECAST_SUCCESS:
      return {
        ...state,
        fetchForecastStatus: 'success',
        forecasts: payload,
      };

    case FETCH_SINGLE_FORECAST_FAILURE:
      return {
        ...state,
        fetchSingleForecastStatus: 'failure',
      };

    case FETCH_SINGLE_FORECAST_REQUESTED:
      return {
        ...state,
        fetchSingleForecastStatus: 'fetching',
      };

    case FETCH_SINGLE_FORECAST_SUCCESS:
      return {
        ...state,
        fetchSingleForecastStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_FORECAST_FORM:
      return {
        ...state,
        form: payload,
      };

    case FETCH_WEEK_NUMBER_FAILURE:
      return {
        ...state,
        fetchForecastStatus: 'failure',
      };

    case FETCH_WEEK_NUMBER_REQUESTED:
      return {
        ...state,
        fetchForecastStatus: 'fetching',
      };

    case FETCH_WEEK_NUMBER_SUCCESS:
      return {
        ...state,
        fetchForecastStatus: 'success',
        weekNumbers: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}

import { string } from 'prop-types';
import React from 'react';

const MarksTextAreaRenderer = ({  data, onChangeText }) => {
  return (
    <>
      <textarea defaultValue={data} name="teacher_comment" onChange={onChangeText} className=" form-control" />
    </>
  );
};

MarksTextAreaRenderer.propTypes = {
  className: string,
};

export default MarksTextAreaRenderer;

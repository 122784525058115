import { createAction } from 'redux-actions';


export const FETCH_PRIMARY_REPORT = 'FETCH_PRIMARY_REPORT';
export const fetchPrimaryReport = createAction(FETCH_PRIMARY_REPORT);

export const FETCH_PRIMARY_REPORT_SUCCESS = 'FETCH_PRIMARY_REPORT_SUCCESS';
export const fetchPrimaryReportSuccess = createAction(FETCH_PRIMARY_REPORT_SUCCESS);

export const GENERATE_PRIMARY_REPORT = 'GENERATE_PRIMARY_REPORT';
export const generatePrimaryReport = createAction(GENERATE_PRIMARY_REPORT);

export const DOWNLOAD_PRIMARY_REPORT = 'DOWNLOAD_PRIMARY_REPORT';
export const downloadPrimaryReport = createAction(DOWNLOAD_PRIMARY_REPORT);

import {  } from "../actions/level-action-types";
import { FETCH_SINGLE_SUBJECT_TYPE_SUCCESS, FETCH_SUBJECT_TYPE_BY_LEVEL_ID_SUCCESS, FETCH_SUBJECT_TYPE_SUCCESS } from "../actions/subject-type-action-types";

const intitalState = {
    subjectTypes: [],
    singleSubjectType:[],
    subjectTypeByLevelId:[],
};

const subjectTypeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case FETCH_SUBJECT_TYPE_SUCCESS:
            return {
                ...state,
                subjectTypes: action.payload,
            }
        case FETCH_SINGLE_SUBJECT_TYPE_SUCCESS:
                return {
                    ...state,
                    singleSubjectType: action.payload,
                }

                case FETCH_SUBJECT_TYPE_BY_LEVEL_ID_SUCCESS:
                return {
                    ...state,
                    subjectTypeByLevelId: action.payload,
                }
        default:
            return { ...state };
    }
};
export default subjectTypeReducer;
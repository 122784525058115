import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import React, { useEffect, useRef, useReducer } from "react";
import styled from "styled-components";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const Wrapper = styled.div`
  color: #666;
`;



export const TimeTracker=({ createdAt })=> {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const intervalRef = useRef();
  
  // refresh value of `createdAt` every ~ 1 minute
  useEffect(() => {
    const id = setInterval(() => {
      forceUpdate();
    }, 1000);
    //intervalRef.current = id;
    //interval = id;
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <Wrapper>
      <span>{dayjs(createdAt).fromNow()}</span>
    </Wrapper>
  );
}

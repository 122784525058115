import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { fetchCashierDataFailure,
  fetchCashierDataRequested,
  fetchCashierDataSuccess,
  FETCH_DAILY_ENTRIES,
  fetchDailyEntriesFailure,
  fetchDailyEntriesRequested,
  fetchDailyEntriesSuccess,
  FETCH_DAILY_ENTRY_USERS,
  fetchDailyEntriesUsersFailure,
  fetchDailyEntriesUsersRequested,
  fetchDailyEntriesUsersSuccess,
  fetchGeneralRecapFailure,
  fetchGeneralRecapRequested,
  fetchGeneralRecapSuccess,
  fetchPeriodDataFailure,
  fetchPeriodDataRequested,
  fetchPeriodDataSuccess } from '../actions/daily_entry_action_types';
import httpClient from './http-client';

export function* fetchDailyEntriesUsersHandler({ payload }) {
  yield put(fetchDailyEntriesUsersRequested());
  yield put(fetchGeneralRecapRequested());
  yield put(fetchCashierDataRequested());
  yield put(fetchPeriodDataRequested());

  const request = {
    method: 'POST',
    params: {
      // from: (payload?.from ? moment(payload.from, 'X').startOf('day').toDate() : moment().startOf('day').toDate()),
      from: (payload?.from ? moment(payload.from, 'X').toDate() : moment().startOf('day').toDate()),
      school_year_id: payload?.school_year_id,
      to: (payload?.to ? moment(payload.to, 'X').endOf('day').toDate() : moment().endOf('day').toDate()),
    },
    url: 'daily-report-entries',
  };

  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchDailyEntriesUsersFailure(error));
    yield put(fetchGeneralRecapFailure(error));
    yield put(fetchCashierDataFailure(error));
    yield put(fetchPeriodDataFailure(error));
  } else {
    yield put(fetchDailyEntriesUsersSuccess(data.data));
    yield put(fetchGeneralRecapSuccess(data.GeneralRecap));
    yield put(fetchCashierDataSuccess(data.CashierData.cashier));
    yield put(fetchPeriodDataSuccess(data.CashierData.cashier));
  }
}

export function* fetchDailyEntriesHandler({ payload }) {
  yield put(fetchDailyEntriesRequested());

  // const values = payload?.school_year_id?.value;

  const incomeValues = payload?.income_reason_id?.map((u) => u.value);
  const incomeArray = [];

  incomeArray.push(incomeValues);
  const cashierValues = payload?.id?.map((u) => u.value);
  const cashierArray = [];

  cashierArray.push(cashierValues);

  const request = {
    method: 'POST',
    params: {
      // cashier: (cashierArray || '').toString(),
      from: (payload?.from ? moment(payload.from, 'X').toDate() : moment().startOf('day').toDate()),
      // income_reason_id: (incomeArray || '').toString(),
      // to: (payload?.to ? moment(payload.to).format('yyyy-MM-DD hh:mm:ss').toString() : ''),
      school_year_id: payload?.school_year_id,
      to: (payload?.to ? moment(payload.to, 'X').endOf('day').toDate() : moment().endOf('day').toDate()),
    },
    url: 'daily-report-entries',
  };
  const {
    data, error,
  } = yield call(httpClient, request);

  if (error) {
    yield put(fetchDailyEntriesFailure(error));
  } else {
    yield put(fetchDailyEntriesSuccess(data.GeneralRecap || []));
    yield put(fetchCashierDataSuccess(data.CashierData.cashier|| []));
  }
}

function* FinanceReport() {
  yield all([
    takeLatest(FETCH_DAILY_ENTRY_USERS, fetchDailyEntriesUsersHandler),
    takeLatest(FETCH_DAILY_ENTRIES, fetchDailyEntriesHandler),
  ]);
}

export default FinanceReport;

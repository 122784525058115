import { object, string, number } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import SweetAlert from 'sweetalert2';
import { AddEditTransport } from '../../pages/enrollment/add-edit-enrollment/transport-modal';
import { deleteTransport, fetchSingleTransport, fetchTransport } from '../../actions/transport-action-types';
import Alert from '../../utility/alert';
import { fetchSingleEnrollment } from '../../actions/enrollment-action-type';

const TransportButtonCellRenderer = ({
  className, data, id,
}) => {
  const [visible, setVisibility] = React.useState(false);
  const dispatch = useDispatch();
  const { school_year_id } = data;

  const onDelete = () => {
    if (!id) {
      Alert.alert('Please select the transport, you want to delete.');

      return;
    }
    SweetAlert.fire({
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      icon: 'warning',
      showCancelButton: true,
      text: 'Are you sure you want delete the selected Transport?',
      title: 'Delete Transport',
    }).then((result) => {
      if (result.isConfirmed) {
        // setTimeout(() => {
        const request = {
          id,
          school_year_id,
          student_id: data.student_id,
        };

        //   dispatch(fetchTransport(request));
        // }, 500);

        dispatch(deleteTransport(request));
      }
    });
  };
  const onEdit = () => {
    setVisibility(true);
    dispatch(fetchSingleTransport(id));
  };

  return (
    <>
      <div>
        <button onClick={onEdit} className={`${className}`} type="button">
          <i className="pe-7s-pen mr-2" data-toggle="tooltip" data-placement="top" title="New Moratorium" data-original-title="" />
          <span> Edit</span>

        </button>
        <button onClick={onDelete} className={`${className}`} type="button" data={data} id={id}>
          <i className="lnr-trash" data-toggle="tooltip" data-placement="top" title="Delete" data-original-title="Delete" />
          <span> Delete</span>
        </button>
      </div>
      {visible && <AddEditTransport data={data} id={id} school_year_id={data.school_year_id} paramsId={data.student_id} onClose={() => setVisibility(false)} />}
    </>
  );
};

TransportButtonCellRenderer.propTypes = {
  className: string,
  data: object.isRequired,
  id: number.isRequired,
};

TransportButtonCellRenderer.defaultProps = { className: 'badge  badge-success' };

export default TransportButtonCellRenderer;

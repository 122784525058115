import React, { Fragment } from "react";
import { connect } from "react-redux";
import {isMobile} from 'react-device-detect';
import { Slider } from "react-burgers";
import AppMobileMenu from "../AppMobileMenu";
import Utils from '../../utility';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    };
  }

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };

  

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 380,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    return (
      <Fragment>
        {!isMobile ?<> <div className="app-header__logo">
        <div className="logo-src" />
          <div className="header__pane ms-auto desktop">
            <div onClick={this.toggleEnableClosedSidebar} >
              <Slider width={26} lineHeight={2} lineSpacing={5} color="#6c757d" padding={'50px'}
                active={this.state.active} onClick={() => this.setState({ active: !this.state.active })}/>
            </div>
          </div>
        </div>
        
      </>
        : 
        null
        // <div className="app-header__logo">
        //   <div className="header__pane ms-auto mobile">
        //     <div onClick={this.toggleEnableClosedSidebar} >
        //       <Slider width={26} lineHeight={2} lineSpacing={5} color="#6c757d" padding={'50px'}
        //         active={this.state.active} onClick={() => this.setState({ active: !this.state.active })}/>
        //     </div>
        //   </div>
        // </div>
        }
        <AppMobileMenu />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);

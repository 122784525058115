import React, {useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {any, arrayOf, bool, func, shape, string} from 'prop-types';
import LinkableCellRenderer from './linkable-cell-renderer';
import ImageCellRenderer from './image-cell-renderer';
import BadgeCellRenderer from './badge-cell-render';
import ButtonCellRenderer from './button-cell-renderer';
import DailyEntryCellRenderer from './report-download';
import DownloadCellRenderer from './download-cell-renderer';
import TransportButtonCellRenderer from './transport-btn-cell-render';
import TransportHistoryButtonCellRenderer from './transport-history-btn-cell-renderer';
import PaymentHistoryCellRenderer from './payment-history-btn-cell-renderer';
import MoratoriumCellRenderer from './moratorium-date-cell-renderer';
import MarksViewRenderer from './marks-view-renderer';
import MarksViewStudentRenderer from './mark-view-student-custom';
import MarksInputRenderer from './marks-input-custom';
import MarksTextAreaRenderer from './marks-textarea-custom';
import LinkableImageCellRenderer from './link-image-cell-renderer';
import AttendanceCellRenderer from './attendance-cell-renderer';
import MarksAppreciationCellRenderer from './marks-appriciation-color';
import {hideLoader, showLoader, showToast} from '../../actions/app-action-types';
import {GridApi} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import "ag-grid-community/dist/styles/webfont/agGridMaterialFont.scss";

//Component By StageBit
import LinkableImageTextCellRenderer from "./link-image-text-cell-render";
import LinkableImageCellRendererText from "./link-image-cell-renderer-text";
import CommentTextAreaRenderer from "./comment-textarea-custom";
import DownloadReport from './download-report';
import DownloadGeneral from './download-pdf-general';

//new 
import QualificationbuttonCellRenderer from './qualificationbutton-cell-renderer';
import LevelbuttonCellRenderer from './LevelbuttonCellRenderer';
import SubjectTypebuttonCellRenderer from './SubjectTypebuttonCellRenderer';
import SkillsbuttonCellRenderer from './SkillsbuttonCellRenderer';
import DownloadPrimaryReport from './download-report-primary';

const data = null;

const icons = {
    sortAscending: '<i class="fa fa-sort-asc "/>',
    sortDescending: '<i class="fa fa-sort-desc"/>',
    sortUnSort: '<i class="fa fa-sort"></i>'
};


const defaultColDef = {
    enableFilter: true,
    enableColResize: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: {suppressFilterButton: true},
    pagination: true,
    resizable: true,
    sortable: true,
    suppressCellSelection: true,
    suppressFiltersToolPanel: true,
    suppressMenu: true,
    unSortIcon: true,
    showLoadingOverlay: true
};

const Table = ({
                   children,
                   data,
                   agGridConf,
                   onGridReady,
                   sizeColumnsToFit,
                   containerClass,
                   isManualPagination,
                   paginationChanged,
                   customSortChanged,
                   isCustomSorting,
                   ...rest
               }) => {
    const [currentPaginationIndex, setPaginationIndex] = React.useState(1);

    const onReady = (params) => {
        if (sizeColumnsToFit && params.api.sizeColumnsToFit) {
            params.api.sizeColumnsToFit();
        }

        window.onresize = () => {
            params.api.sizeColumnsToFit();
        };
        onGridReady(params);
    };

    const getRowHeight = (params) =>
        params?.data?.hasOwnProperty('teacher_appreciation') && params?.data?.hasOwnProperty('teacher_comment') ? 80 : 42;

    const onPaginationChanged = () => {
        if (isManualPagination) {
            paginationChanged();
        }
    };

    const onSortChanged = () => {
        if (isCustomSorting) {
            customSortChanged();
        }
    };

console.log("data",data)
    return (
        <div className={`ag-theme-alpine ag-grid-custom-height ${containerClass || ''}`}>
            <AgGridReact
                icons={icons}
                pagination
                getRowHeight={getRowHeight}
                paginationPageSize={10}
                paginationNumberFormatter={(params) => ` ${params.value.toLocaleString()} `}
                defaultColDef={{...defaultColDef}}
                {...agGridConf}
                rowData={data}
                onGridReady={onReady}
                sortingOrder={['desc', 'asc']}
                suppressRowTransform={true}
                onPaginationChanged={onPaginationChanged}
                onSortChanged={onSortChanged}

                frameworkComponents={{
                    attendanceCellRenderer: AttendanceCellRenderer,
                    badgeCellRenderer: BadgeCellRenderer,
                    buttonCellRenderer: ButtonCellRenderer,
                    dailyEntryCellRenderer: DailyEntryCellRenderer,
                    downloadCellRenderer: DownloadCellRenderer,
                    imageCellRenderer: ImageCellRenderer,
                    linkableCellRenderer: LinkableCellRenderer,
                    linkableImageCellRenderer: LinkableImageCellRenderer,
                    marksAppreciationCellRenderer: MarksAppreciationCellRenderer,
                    marksInputRenderer: MarksInputRenderer,
                    marksTextAreaRenderer: MarksTextAreaRenderer,
                    marksViewRenderer: MarksViewRenderer,
                    marksViewStudentRenderer: MarksViewStudentRenderer,
                    moratoriumCellRenderer: MoratoriumCellRenderer,
                    paymentHistoryCellRenderer: PaymentHistoryCellRenderer,
                    transportBtnCellRenderer: TransportButtonCellRenderer,
                    transportHistoryButtonCellRenderer: TransportHistoryButtonCellRenderer,
                    //By StageBit
                    LinkableImageTextCellRenderer: LinkableImageTextCellRenderer,
                    LinkableImageCellRendererText: LinkableImageCellRendererText,
                    CommentTextAreaRenderer:CommentTextAreaRenderer,
                    DownloadReport: DownloadReport,
                    DownloadPrimaryReport : DownloadPrimaryReport,
                    DownloadGeneral :DownloadGeneral,

                    //new missing action - 07/11/22 - Skulman
                    QualificationbuttonCellRenderer:QualificationbuttonCellRenderer,
                    LevelbuttonCellRenderer:LevelbuttonCellRenderer,
                    SubjectTypebuttonCellRenderer:SubjectTypebuttonCellRenderer,
                    SkillsbuttonCellRenderer:SkillsbuttonCellRenderer
                }}
                overlayLoadingTemplate={'<span className="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                overlayNoRowsTemplate={
                    data === null ? `<p>hhhhh</p>` : '<span style="padding: 10px; border: 2px solid #3f6ad8; color: #3f6ad8; background: #fff;">No Records Found</span>'
                }
                {...rest}
            >
                {children}
            </AgGridReact>
        </div>
    );
};

Table.propTypes = {
    agGridConf: shape({rowSelection: string}),
    children: any.isRequired,
    containerClass: string,
    data: arrayOf(shape).isRequired,
    onGridReady: func,
    sizeColumnsToFit: bool,
};

Table.defaultProps = {
    agGridConf: {},
    containerClass: null,
    onGridReady: () => {
    },
    sizeColumnsToFit: true,
};

export default Table;

import React from 'react';
import { Input } from "reactstrap";
import styled from "styled-components";
import AddPostModal from "../Add_Post_Modal";
import { BsSearch } from "react-icons/bs";

export default function SearchBar({ isAddPost,onChange }) {
  return (
    <SearchContainer>
      <SearchInputContainer>
        <SearchInput
          style={{ marginRight: isAddPost ? 10 : 0 ,fontSize: '1rem',
          fontWeight: '400'}}
          placeholder="Search here"
          onChange={onChange}
        />
        <BsSearchIcon />
      </SearchInputContainer>
      {isAddPost && <AddPostModal />}
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
`;
const SearchInputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justifycontent: center;
`;

const SearchInput = styled(Input)`
  height: 47px;
  border-radius: 53px;
  padding-left: 30px;
`;

const BsSearchIcon = styled(BsSearch)`
  color: #15399e;
  position: absolute;
  right: 30px;
  width: 24px;
  height: 24px;
`;

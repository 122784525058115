import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ADD_SUBJECT_TYPE, DELETE_SUBJECT_TYPE, EDIT_SUBJECT_TYPE, FETCH_SINGLE_SUBJECT_TYPE, FETCH_SUBJECT_TYPE, FETCH_SUBJECT_TYPE_BY_LEVEL_ID, fetchSingleSubjectTypeSuccess, fetchSubjectType, fetchSubjectTypeSuccess, fetchSubjectTypeSuccessById } from "../actions/subject-type-action-types";
import HttpClient from './http-client';
import Alert from '../utility/alert';
import i18next from 'i18next';
import { history } from '../index';


export function* fetchSubjectTypeHandler({ payload }) {
  const request = {
    method: 'GET',
    params: { school_year_id: payload.school_year_id },
    url: 'subject-type',
  };

  const { data, error } = yield call(HttpClient, request);

  if (error) {
  } else {
    yield put(fetchSubjectTypeSuccess(data.data || []));
  }
}

export function* fetchSingleSubjectTypeHandler({ payload }) {

  const request = {
    method: 'GET',
    url: `subject-type/${payload}/edit`,
  };

  const { data, error } = yield call(HttpClient, request);

  if (error) {
  } else {
    const res = data.data;

    yield put(fetchSingleSubjectTypeSuccess(res));
  }
}

export function* addSubjectTypeHandler({ payload }) {
  console.log("i am in save subjecttyupoe")
  const body = new FormData();
  
  body.append('school_id', payload.school_id);
  body.append('school_year_id', payload.school_year_id);
  body.append('code', payload.code);
  body.append('label_fr', payload.label_fr);
  body.append('label_en', payload.label_en);
  body.append('level_id', payload.level_id);
  body.append('order_appearance', payload.order_appearance);
  body.append('subject_type_name', payload.subject_type_name);

  const request = {
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: 'subject-type',
  };
  const { error } = yield call(HttpClient, request);

  if (error) {
  } else {
    const alertProps = {
      callback: () => {
        history.push('/subject-type');
      },
      icon: 'success',
      message: i18next.t('skullman:setting.subjectType.createMessage'),
      title: i18next.t('skullman:common.successMessage'),
    };

    Alert.alert(alertProps);
    yield put(fetchSubjectType({ school_year_id: payload.school_year_id }));
  }
  }

  export function* fetchEditSubjectTypeHandler({ payload }) {


    let dataTosend = {
      school_id:payload.school_id,
      school_year_id:payload.school_year_id,
      code:payload.code,
      label_fr:payload.label_fr,
      label_en:payload.label_en,
      level_id:payload.level_id,
      order_appearance:payload.order_appearance,
      subject_type_name:payload.subject_type_name
    }

    const request = {
      data: dataTosend,
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      url: `subject-type/${payload.id}`,
    };
  
    const { error } = yield call(HttpClient, request);
  
    if (error) {
      // yield put(editWeekNumberFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/subject-type');
        },
  
        icon: 'success',
        message: i18next.t('skullman:setting.subjectType.updateMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchSubjectType({ school_year_id: payload.school_year_id }));
    }
  }

  export function* deleteSubjectTypeHandler({ payload }) {
    const request = {
      method: 'DELETE',
      url: `subject-type/${payload}`,
    };
  
    const { error } = yield call(HttpClient, request);
  
    if (error) {
      // yield put(deleteWeekNumberFailure(error));
    } else {
      const alertProps = {
        callback: () => {
          history.push('/subject-type');
        },
        icon: 'success',
        message: i18next.t('skullman:setting.subjectType.deleteMessage'),
        title: i18next.t('skullman:common.successMessage'),
      };
  
      Alert.alert(alertProps);
      yield put(fetchSubjectType({ school_year_id: payload.school_year_id }));
    }
  }

  export function* SubjectTypeByIdHandler({ payload }) {
    console.log("paylaod",payload)
    const request = {
      method: 'GET',
      params: payload,
      url: 'subject-type-by-level',
    };
  
    const { data, error } = yield call(HttpClient, request);
  
    if (error) {
    } else {
      yield put(fetchSubjectTypeSuccessById(data.data || []));
    }
  }





function* SubjectType() {
    yield all([
      takeLatest(ADD_SUBJECT_TYPE, addSubjectTypeHandler),
      takeLatest(FETCH_SUBJECT_TYPE, fetchSubjectTypeHandler),
      takeLatest(FETCH_SINGLE_SUBJECT_TYPE, fetchSingleSubjectTypeHandler),
      takeLatest(EDIT_SUBJECT_TYPE, fetchEditSubjectTypeHandler),
      takeLatest(DELETE_SUBJECT_TYPE, deleteSubjectTypeHandler),
      takeLatest(FETCH_SUBJECT_TYPE_BY_LEVEL_ID, SubjectTypeByIdHandler),
      
      
    ]);
  }

  export default SubjectType;
import { string, number, bool } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAttendanceForm } from '../../actions/attendance-action-types';

const AttendanceCellRenderer = ({ className, class_id, student_id, isPresent, _onChangeAttendance, i }) => {
  const { form } = useSelector((store) => ({ form: store.Attendance.form }));

  const [checked, setChecked] = React.useState(null);

  React.useEffect(() => {
    setChecked(isPresent);
  }, [isPresent]);
  
  const handleChange = (e) => {
    setChecked(e.target.value);
    const update = {
      attendance: e.target.value ,
      class_id,
      student_id,
    };
    _onChangeAttendance(update);   
    //console.log('checked update',update ); 
  };

  //console.log('ispresent', isPresent);
  return (
    <>
      <fieldset id={`group${Math.floor(Math.random() * 100000000000000)}`}>
        <label className="success checkbox-n mx-2 my-1">
          <span className="badge badge-success" style={{width:'28px',height:'29px',borderRadius:'8px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>P</span>
          <div className="box" style={{width:'28px',height:'29px',borderRadius:'8px',display: 'flex',alignItems: 'center',justifyContent: 'center',position:'relative'}}>
            <input type="radio" Value='1' checked={checked == '1'} onChange={handleChange} />
            <em className='custom-em' style={{width:'100%',height:'100%',borderRadius:'8px',border:'1px solid rgba(0,0,0,0.37)'}}/>
          </div>
        </label>
        <label className="danger checkbox-n mx-2 my-1">
          <span className="badge badge-danger" style={{width:'28px',height:'29px',borderRadius:'8px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>A</span>
          <div className="box" style={{width:'28px',height:'29px',borderRadius:'8px',display: 'flex',alignItems: 'center',justifyContent: 'center',position:'relative'}}>
            <input type="radio" Value='0' checked={checked == '0'} onChange={handleChange} />
            <em className='custom-em' style={{width:'100%',height:'100%',borderRadius:'8px',border:'1px solid rgba(0,0,0,0.37)'}}/>
          </div>
        </label>
        <label className="danger checkbox-n mx-2 my-1">
          <span className="badge badgepurple" style={{width:'28px',height:'29px',borderRadius:'8px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>J</span>
          <div className="box" style={{width:'28px',height:'29px',borderRadius:'8px',display: 'flex',alignItems: 'center',justifyContent: 'center',position:'relative'}}>
            <input type="radio" Value='2' checked={checked == '2'} onChange={handleChange} />
            <em className='custom-em' style={{width:'100%',height:'100%',borderRadius:'8px',border:'1px solid rgba(0,0,0,0.37)'}}/>
          </div>
        </label>
      </fieldset>
    </>
  );
};

AttendanceCellRenderer.propTypes = {
  className: string,
  class_id: number.isRequired,
  isPresent: bool.isRequired,
  student_id: number.isRequired,
};

AttendanceCellRenderer.defaultProps = { 
  className: '',
};

export default AttendanceCellRenderer;

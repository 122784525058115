import { createAction } from 'redux-actions';

export const ADD_FAMILY = 'ADD_FAMILY';
export const addFamily = createAction(ADD_FAMILY);

export const ADD_FAMILY_FAILURE = 'ADD_FAMILY_FAILURE';
export const addFamilyFailure = createAction(ADD_FAMILY_FAILURE);

export const ADD_FAMILY_REQUESTED = 'ADD_FAMILY_REQUESTED';
export const addFamilyRequested = createAction(ADD_FAMILY_REQUESTED);

export const ADD_FAMILY_SUCCESS = 'ADD_FAMILY_SUCCESS';
export const addFamilySuccess = createAction(ADD_FAMILY_SUCCESS);

export const DELETE_FAMILY = 'DELETE_FAMILY';
export const deleteFamily = createAction(DELETE_FAMILY);

export const DELETE_FAMILY_FAILURE = 'DELETE_FAMILY_FAILURE';
export const deleteFamilyFailure = createAction(DELETE_FAMILY_FAILURE);

export const DELETE_FAMILY_REQUESTED = 'DELETE_FAMILY_REQUESTED';
export const deleteFamilyRequested = createAction(DELETE_FAMILY_REQUESTED);

export const DELETE_FAMILY_SUCCESS = 'DELETE_FAMILY_SUCCESS';
export const deleteFamilySuccess = createAction(DELETE_FAMILY_SUCCESS);

export const FETCH_FAMILY = 'FETCH_FAMILY';
export const fetchFamily = createAction(FETCH_FAMILY);

export const FETCH_FAMILY_FAILURE = 'FETCH_FAMILY_FAILURE';
export const fetchFamilyFailure = createAction(FETCH_FAMILY_FAILURE);

export const FETCH_FAMILY_REQUESTED = 'FETCH_FAMILY_REQUESTED';
export const fetchFamilyRequested = createAction(FETCH_FAMILY_REQUESTED);

export const FETCH_FAMILY_SUCCESS = 'FETCH_FAMILY_SUCCESS';
export const fetchFamilySuccess = createAction(FETCH_FAMILY_SUCCESS);

export const EDIT_FAMILY = 'EDIT_FAMILY';
export const editFamily = createAction(EDIT_FAMILY);

export const EDIT_FAMILY_FAILURE = 'EDIT_FAMILY_FAILURE';
export const editFamilyFailure = createAction(EDIT_FAMILY_FAILURE);

export const EDIT_FAMILY_REQUESTED = 'EDIT_FAMILY_REQUESTED';
export const editFamilyRequested = createAction(EDIT_FAMILY_REQUESTED);

export const EDIT_FAMILY_SUCCESS = 'EDIT_FAMILY_SUCCESS';
export const editFamilySuccess = createAction(EDIT_FAMILY_SUCCESS);

export const FETCH_SINGLE_FAMILY = 'FETCH_SINGLE_FAMILY';
export const fetchSingleFamily = createAction(FETCH_SINGLE_FAMILY);

export const FETCH_SINGLE_FAMILY_FAILURE = 'FETCH_SINGLE_FAMILY_FAILURE';
export const fetchSingleFamilyFailure = createAction(FETCH_SINGLE_FAMILY_FAILURE);

export const FETCH_SINGLE_FAMILY_REQUESTED = 'FETCH_SINGLE_FAMILY_REQUESTED';
export const fetchSingleFamilyRequested = createAction(FETCH_SINGLE_FAMILY_REQUESTED);

export const FETCH_SINGLE_FAMILY_SUCCESS = 'FETCH_SINGLE_FAMILY_SUCCESS';
export const fetchSingleFamilySuccess = createAction(FETCH_SINGLE_FAMILY_SUCCESS);

export const UPDATE_FAMILY_FORM = 'UPDATE_FAMILY_FORM';
export const updateFamilyForm = createAction(UPDATE_FAMILY_FORM);

export const DELETE_FAMILY_CLASS = 'DELETE_FAMILY_CLASS';
export const deleteFamilyClass = createAction(DELETE_FAMILY_CLASS);


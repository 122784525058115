import React from 'react';
import { func, object, number } from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Button from '../../components/button';
import DateCalenderInput from '../../components/date-calender-input';
import Modal from '../../components/modal';
import { editMoratoriumDate } from '../../actions/students-action-types';
import Alert from '../../utility/alert';
import { fetchInstallment } from '../../actions/installment-action-type';
import {useTranslation} from "react-i18next";

export const AddMoratoriumDate = ({
  data, onClose,
}) => {
    const [t] = useTranslation('skullman');
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({ moratorium_date: null });
  const { moratorium_date } = form;
  // const { installments } = useSelector((store) => ({ installments: store.enrollment.installmentsList }));
  const { id } = data;
  const { student_id } = data;

  React.useEffect(() => {
    dispatch(fetchInstallment());
  }, []);
  console.log(data, 'data');

  const onDateChange = (name, date) => {
    const updates = {
      ...form,
      id,
      [name]: date,
      student_id,
    };

    setForm(updates);
    console.log(updates);
  };

  const onValidate = () => {
    if (!moratorium_date) {
      Alert.alert(t('tution.payment.installment.moratoriumRequire'));

      return;
    }

    dispatch(editMoratoriumDate(form));
    onClose();
  };

  return (
    <Modal>
      <div
        className="modal fade show "
        id="addingParentInfo"
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        style={{
          background: 'rgba(0, 0, 0, 0.5)', display: 'block', paddingRight: 15,
        }}
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <h4 className="text-center mt-4 mb-0">{t('tution.payment.installment.updateMoratorium')}</h4>
            <Button
              type="button"
              className="close topRight"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">×</span>
            </Button>
            <div className="modal-body px-4 mx-2 mt-3 pb-5">

              <form noValidate autoComplete='off'>
                <table className="m-0 w-100">
                  <tbody>
                    <tr>
                      <td className="py-1"><b>{t('tution.payment.installment.moratoriumDate')}</b></td>
                      <td className="py-1" width="20">:</td>
                      <td className="py-1">
                        {data.moratorium_date ? moment(data.moratorium_date * 1000).format('DD/MM/yyyy').toString() : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1"><b>{t('tution.payment.installment.installmentTitle')}</b></td>
                      <td className="py-1" width="20">:</td>
                      <td className="py-1">
                        {data.installment}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1"><b>{t('tution.payment.installment.newMoratoriumDate')}</b></td>
                      <td className="py-1" width="20">:</td>
                      <td className="py-1">
                        <DatePicker
                          showTimeInput
                          showMonthDropdown
                          showYearDropdown
                          title={t('tution.payment.installment.newMoratoriumDate')}
                          id='moratorium_date'
                          name='moratorium_date'
                          dateFormat="MM/dd/yyyy"
                          minDate={data.moratorium_date || new Date()}
                          placeholderText={t('tution.payment.installment.selectMoratoriumDate')}
                          className="form-control dateTimePicker "
                          onChange={(date) => onDateChange('moratorium_date', date)}
                          selected={moratorium_date}
                          customInput={<DateCalenderInput />}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="text-center">
                  <Button onClick={onClose} type="button" className="btn btn-secondary btn-sm mt-4 px-4">
                    <div className="h6 mt-0 mb-1 mx-2">{t('tution.payment.installment.cancel')}</div>
                  </Button>
                  <Button onClick={onValidate} type="button" className="btn btn-primary btn-sm mt-4 ml-4 px-4">
                    <div className="h6 mt-0 mb-1 mx-2">{t('tution.payment.installment.submit')}</div>
                  </Button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </Modal>
  );
};

AddMoratoriumDate.propTypes = {
  data: object.isRequired,
  id: number.isRequired,
  onClose: func.isRequired,
};

export default AddMoratoriumDate;

import { FETCH_LEVEL, FETCH_LEVEL_SUCCESS, FETCH_SINGLE_LEVEL_SUCCESS } from "../actions/level-action-types";

const intitalState = {
    levels: [],
    singleLevel:[]
};

const levelReducer = (state = intitalState, action) => {
    switch (action.type) {
        case FETCH_LEVEL_SUCCESS:
            return {
                ...state,
                levels: action.payload,
            }
        case FETCH_SINGLE_LEVEL_SUCCESS:
                return {
                    ...state,
                    singleLevel: action.payload,
                }
        default:
            return { ...state };
    }
};
export default levelReducer;
import { LOCATION_CHANGE } from 'connected-react-router';
import { ADD_CHAPTER_FAILURE,
  ADD_CHAPTER_REQUESTED,
  ADD_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAILURE,
  DELETE_CHAPTER_REQUESTED,
  DELETE_CHAPTER_SUCCESS,
  FETCH_CHAPTER_FAILURE,
  FETCH_CHAPTER_REQUESTED,
  FETCH_CHAPTER_SUCCESS,
  FETCH_SINGLE_CHAPTER_FAILURE,
  FETCH_SINGLE_CHAPTER_REQUESTED,
  FETCH_SINGLE_CHAPTER_SUCCESS,
  FETCH_CHAPTER_FILTER_FAILURE,
  FETCH_CHAPTER_FILTER_REQUESTED,
  FETCH_CHAPTER_FILTER_SUCCESS,
  UPDATE_CHAPTER_FORM } from '../actions/chapter-action-types';

const initialState = {
  addChapterStatus: 'pending',
  chapters: [],
  chapterFilter: [],
  deleteChapterStatus: 'pending',
  fetchChapterStatus: 'pending',
  fetchSingleChapterStatus: 'pending',
  form: {
    chapter_number: '',
    chapter_title: '',
    created_date: null,
    grade_id: '',
    school_year_id: '',
    subject_id: '',
  },
};

export default function Chapter(state = initialState, {
  payload, type,
}) {
  switch (type) {
    case ADD_CHAPTER_FAILURE:
      return {
        ...state,
        addChapterStatus: 'failure',
      };

    case ADD_CHAPTER_REQUESTED:
      return {
        ...state,
        addChapterStatus: 'creating',
      };

    case ADD_CHAPTER_SUCCESS:
      return {
        ...state,
        addChapterStatus: 'success',
      };

    case DELETE_CHAPTER_FAILURE:
      return {
        ...state,
        deleteChapterStatus: 'failure',
      };

    case DELETE_CHAPTER_REQUESTED:
      return {
        ...state,
        deleteChapterStatus: 'deleting',
      };

    case DELETE_CHAPTER_SUCCESS:
      return {
        ...state,
        chapters: [...state.chapters].filter((Chapters) => !(payload.toString().includes(Chapters.id.toString()))),
        deleteChapterStatus: 'success',

      };

    case FETCH_CHAPTER_FAILURE:
      return {
        ...state,
        fetchChapterStatus: 'failure',
      };

    case FETCH_CHAPTER_REQUESTED:
      return {
        ...state,
        fetchChapterStatus: 'fetching',
      };

    case FETCH_CHAPTER_SUCCESS:
      return {
        ...state,
        chapters: payload,
        fetchChapterStatus: 'success',
      };

      case FETCH_CHAPTER_FILTER_FAILURE:
        return {
          ...state,
          fetchChapterStatus: 'failure',
        };
  
      case FETCH_CHAPTER_FILTER_REQUESTED:
        return {
          ...state,
          fetchChapterStatus: 'fetching',
        };
  
      case FETCH_CHAPTER_FILTER_SUCCESS:
        return {
          ...state,
          chapters: payload,
          fetchChapterStatus: 'success',
        };

    case FETCH_SINGLE_CHAPTER_FAILURE:
      return {
        ...state,
        fetchSingleChapterStatus: 'failure',
      };

    case FETCH_SINGLE_CHAPTER_REQUESTED:
      return {
        ...state,
        fetchSingleChapterStatus: 'fetching',
      };

    case FETCH_SINGLE_CHAPTER_SUCCESS:
      return {
        ...state,
        fetchSingleChapterStatus: 'success',
        form: {
          ...state.form,
          ...payload,
        },
      };
    case UPDATE_CHAPTER_FORM:
      return {
        ...state,
        form: payload,
      };

    case LOCATION_CHANGE:
      return { ...initialState };

    default:
      return state;
  }
}
